import axios from "axios";

export async function showPaymentPortal(stripe_id: string) {
  await axios
    .post(
      process.env.REACT_APP_BACKEND_URL + "/payments/create-checkout-session",
      {
        lookup_key: process.env.REACT_APP_STRIPE_PRICE_LOOKUP_KEY,
        customer: stripe_id,
      }
    )
    .then((data) => {
      window.location.replace(data.data.url);
    })
    .catch((e) => console.log(e));
}
