import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./";

const initialState = {};
const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  // enable dev tools chrome extension
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    process.env.REACT_APP_DEV_MODE === "true"
    ? compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : applyMiddleware(...middleware)
);

export default store;
