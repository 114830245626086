import {
  GET_USER_FAVOURITES,
  DELETE_USER_FAVOURITE,
  SORT_FAVS_AZ,
  SORT_FAVS_ZA,
  SORT_FAVS_BY_DATE,
} from "../enums";

interface favouritesAction {
  type:
    | "GET_USER_FAVOURITES"
    | "DELETE_USER_FAVOURITE"
    | "SORT_FAVS_AZ"
    | "SORT_FAVS_ZA"
    | "SORT_FAVS_BY_DATE"; // ADD OTHERS HERE
  favourites?: Favourites[];
  deletedId?: number;
}

const initialState: FavouritesState = {
  favourites: [],
  currentSortType: "",
};

export default function favouritesReducer(
  state = initialState,
  action: favouritesAction
) {
  switch (action.type) {
    case GET_USER_FAVOURITES:
      return {
        ...state,
        favourites: action.favourites,
      };
    case DELETE_USER_FAVOURITE:
      // remove the fav from the array
      let newFavourites = [...state.favourites];
      // find the index with the id
      const index = newFavourites.findIndex(
        (fav) => fav.item.id === action.deletedId
      );

      if (index > -1) {
        // splice it out
        newFavourites.splice(index, 1);
      }
      return {
        ...state,
        favourites: newFavourites,
      };

    case SORT_FAVS_AZ:
      let newFavs = [...state.favourites];
      newFavs.sort((a, b) => a.item.name.localeCompare(b.item.name));

      return {
        ...state,
        favourites: newFavs,
      };

    case SORT_FAVS_ZA:
      let newFav = [...state.favourites];
      newFav.sort((a, b) => a.item.name.localeCompare(b.item.name)).reverse();

      return {
        ...state,
        favourites: newFav,
      };
    case SORT_FAVS_BY_DATE:
      let newFa = [...state.favourites];
      newFa.sort(
        (a, b) =>
          new Date(a.updatedAt).getMilliseconds() -
          new Date(b.updatedAt).getMilliseconds()
      );

      return {
        ...state,
        favourites: newFa,
      };
    default:
      return state;
  }
}
