import React, { Component } from "react";
import { DetailTestimonialsView } from "../views";

class DetailTestimonialsController extends Component {
  render() {
    return <DetailTestimonialsView />;
  }
}

export default DetailTestimonialsController;
