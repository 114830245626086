export const showModal = ({
  component,
  data,
  position = "centered",
  className,
  onClose,
  isFixed,
  style,
}: ModalInputs) => {
  return {
    type: "SHOW_MODAL",
    component,
    data,
    position,
    className,
    onClose,
    isFixed,
    style,
  };
};

export const hideModal = () => {
  return {
    type: "HIDE_MODAL",
  };
};
