import { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modalActions";
import { resetCss } from "../../utils/modal";
import BasketFooter from "../basket/BasketFooter";
import BasketItemsGrid from "../basket/BasketItemsGrid";
import { Icon } from "../icons/Icon";
import BasketSummary from "../items/BasketSummary";
import SortDropdownButtonCart from "../naviagtions/itemNavs/SortDropdownButtonCart";

interface Props {
  hideModal?: any;
}
class BasketView extends Component<Props, {}> {
  closeBasket = async () => {
    await this.props.hideModal();

    resetCss();
  };

  render() {
    return (
      <div className="o-modal__container">
        <div className="o-basket-view--container u-slide-in--left">
          <div className="u-flex">
            <button
              onClick={() => this.closeBasket()}
              className="u-hide--mobile u-background--magenta u-br--left--medium u-flex u-justify-center u-align-center"
              style={{ width: "64px", height: "70px" }}
            >
              <Icon id="clear" size="m" stroke="#ffffff" />
            </button>
            <div className="u-w-all">
              <div className="u-h--70px">
                <div className="u-background--secondary u-flex u-align-center u-w-all u-justify-between u-h-all">
                  <button
                    onClick={() => this.closeBasket()}
                    className="u-hide--desktop u-flex u-justify-center u-align-center"
                    style={{ width: "64px", height: "64px" }}
                  >
                    <Icon id="clear" size="m" stroke="#ffffff" />
                  </button>
                  <div className="u-bold u-main-font--small u-ph--20 u-color-white">
                    Your cart
                  </div>
                  <div className="u-background--magenta u-br--left--medium u-h-all">
                    <BasketSummary showBackground={true} showModalCart={true} />
                  </div>
                </div>
              </div>

              <div className="u-background--white u-w-all">
                <SortDropdownButtonCart />

                <div id="basket-items">
                  {/* Basket Items card grid that must be scrollable */}
                  <BasketItemsGrid />
                </div>
                <BasketFooter />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { hideModal })(BasketView);
