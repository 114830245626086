import { Component } from "react";
import { Icon } from "../icons/Icon";
import PublicListCard from "./PublicListCard";

interface Props {
  cat: PublicListStoreSortCategory;
}

interface State {
  showItems: boolean;
}

class PublicListCategory extends Component<Props, State> {
  state = {
    showItems: true,
  };
  render() {
    return (
      <div>
        <button
          className="u-border--top--grey u-w-all u-flex u-justify-between u-align-center u-p--8 u-background--darkgrey"
          onClick={() => this.setState({ showItems: !this.state.showItems })}
        >
          <div className="u-bold u-pl--8 u-main-font--14--small">
            {this.props.cat.name}
          </div>
          <Icon
            id={this.state.showItems ? "chevron_down" : "chevron_right"}
            stroke="#aeaeae"
            fill="none"
            size="m"
            className={
              this.state.showItems
                ? "u-rotate--negative360deg"
                : "u-rotate--negative90deg"
            }
          />
        </button>
        {this.state.showItems ? (
          <div>
            {this.props.cat.items.map((item) => {
              return <PublicListCard listItem={item} key={item.id} />;
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

export default PublicListCategory;
