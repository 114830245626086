import React, { Component } from "react";
import { HomeV2View } from "../views";

class HomeV2Controller extends Component {
  render() {
    return <HomeV2View />;
  }
}

export default HomeV2Controller;
