export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toTitleCase(text: string) {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const createClone = (object: any): any =>
  JSON.parse(JSON.stringify(object));

export function findColesAndWoolsPricing(itemData: ItemData) {
  let selected_id = 0;
  let colesPricing: any;
  let woolsPricing: any;

  // if there is not price for one vendor return the price we do have
  if (itemData.name.includes("Coles")) {
    selected_id = 2;
  } else if (itemData.name.includes("Woolworths")) {
    selected_id = 1;
  }

  //If match item is available
  if (itemData.matchedItem && itemData.matchedItem.length > 0) {
    //If item is exclusive to Coles
    if (selected_id === 1) {
      colesPricing = itemData.matchedItem[0].item_pricing[0] || undefined;
      woolsPricing =
        itemData.item_pricing.find((pricing) => pricing.vendor_id === 2) ||
        undefined;
    } else if (selected_id === 2) {
      //If item is exclusive to Woolies
      colesPricing =
        itemData.item_pricing.find((pricing) => pricing.vendor_id === 1) ||
        undefined;
      woolsPricing = itemData.matchedItem.item_pricing || undefined;
    } else {
      colesPricing =
        itemData.item_pricing.find((pricing) => pricing.vendor_id === 1) ||
        undefined;
      woolsPricing =
        itemData.item_pricing.find((pricing) => pricing.vendor_id === 2) ||
        undefined;
    }
  } else {
    colesPricing =
      itemData.item_pricing.find((pricing) => pricing.vendor_id === 1) ||
      undefined;
    woolsPricing =
      itemData.item_pricing.find((pricing) => pricing.vendor_id === 2) ||
      undefined;
  }

  return {
    colesPricing,
    woolsPricing,
  };
}

export function getWordyDate(date: string) {
  const dateObject = new Date(date);

  return dateObject.toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}
