import { Component } from "react";
import { connect } from "react-redux";
import { saveFilterChange } from "../../../redux/actions/filterActions";
import {
  filterItems,
  removeFilter,
  sortItemsOnApi,
  toggleUnavailableProducts,
} from "../../../redux/actions/itemActions";
import { hideModal, showModal } from "../../../redux/actions/modalActions";
import Checkbox from "../../checkbox/Checkbox";
import { Icon } from "../../icons/Icon";
import NavBar from "../NavBar";
import CategoriesNavigation from "./CategoriesNavigation";

interface Props {
  sortItemsOnApi: any;
  filterItems: any;
  items: ItemsState;
  saveFilterChange: any;
  removeFilter: any;
  categories: CategoryState;
  showModal: any;
  hideModal: any;
  toggleUnavailableProducts: (isUnavailableProductsHidden: boolean) => void;
  isUnavailableProductsHidden?: boolean;
  searchTerm?: string;
}

interface State {
  activeSortId: number | null;
}

class SortAndFilterDropdown extends Component<Props, State> {
  state = {
    activeSortId: null,
  };

  showSortsModal = () => {
    const modalContent = () => (
      <div className="o-modal__container">
        <NavBar
          secondaryBackgroundClass=" u-background--secondary"
          mainBackgroundClass="u-background--magenta"
        />
        <CategoriesNavigation />
        {this.expandedSort()}
      </div>
    );

    this.props.showModal({ component: modalContent });
  };

  showFiltersModal = () => {
    const modalContent = () => (
      <div className="o-modal__container">
        <NavBar
          secondaryBackgroundClass=" u-background--secondary"
          mainBackgroundClass="u-background--magenta"
        />
        <CategoriesNavigation />
        {this.expandedFilters()}
      </div>
    );

    this.props.showModal({ component: modalContent });
  };

  updateSortSelection = (sort: SortOptions) => {
    this.setState({ activeSortId: sort.sort_id });
    if (sort.sort_id === this.state.activeSortId) {
      const crawler_id = this.props.categories.selectedNode?.crawler_id;
      const searchTerm = this.props.searchTerm;
      this.props.sortItemsOnApi("", crawler_id, searchTerm);
      this.setState({ activeSortId: null });
    } else {
      sort.callback();
    }

    this.props.hideModal();
  };

  updateFilterSelection = (filter: FilterOption) => {
    // ensure connected to redux
    if (this.props.items) {
      // if filter is already selected
      if (
        this.props.items.appliedFilters.some(
          (applied) => applied.filter_id === filter.filter_id
        )
      ) {
        // remove the filter
        this.props.removeFilter(filter);
        // throw the modal up again to show the changes
        this.showFiltersModal();
      } else {
        this.props.saveFilterChange(filter.filter_id);
        // run any action it may need to do
        filter?.action();
        // throw the modal up again to show the changes
        this.showFiltersModal();
      }
    }
  };

  toggleUnavailableProducts = () => {
    this.props.toggleUnavailableProducts(
      !this.props.isUnavailableProductsHidden
    );
  };

  expandedSort = () => {
    const crawler_id = this.props.categories.selectedNode?.crawler_id;
    const searchTerm = this.props.searchTerm;
    const sorts: SortOptions[] = [
      {
        sort_id: 1,
        name: "Name (A-Z)",
        callback: () =>
          this.props.sortItemsOnApi("A-Z", crawler_id, searchTerm),
      },
      {
        sort_id: 2,
        name: "Name (Z-A)",
        callback: () =>
          this.props.sortItemsOnApi("Z-A", crawler_id, searchTerm),
      },
      {
        sort_id: 3,
        name: "Price (High to Low)",
        callback: () =>
          this.props.sortItemsOnApi("HighestPrice", crawler_id, searchTerm),
      },
      {
        sort_id: 4,
        name: "Price (Low to High)",
        callback: () =>
          this.props.sortItemsOnApi("LowestPrice", crawler_id, searchTerm),
      },
      {
        sort_id: 7,
        name: "Biggest Savings",
        callback: () =>
          this.props.sortItemsOnApi("Difference", crawler_id, searchTerm),
      },
    ];
    return (
      <div className="">
        <div className="u-w-all  u-flex u-align-center u-background--white ">
          <button
            className=" u-p--24 u-pl--32 u-w-all u-flex u-align-center u-border--bottom--grey"
            onClick={() => this.props.hideModal()}
          >
            <Icon id="chevron_up_down" size="s" />
            <div className="u-main-font--small u-color-charcoal u-text-oneline u-ml--16">
              Sort by{" "}
              <span className="u-bold"> {this.props.items.sortTypeName}</span>
            </div>
          </button>
        </div>
        <div className="u-background--white u-w-all">
          {sorts.map((sort, index) => {
            return (
              <div
                className="u-flex u-align-center u-border--bottom--grey "
                key={index}
              >
                <button
                  className="u-pt--16 u-pr--16 u-pb--16 u-pl--60 u-w-all u-main-font--small "
                  onClick={() => this.updateSortSelection(sort)}
                >
                  <div
                    id={"sort-" + sort.sort_id}
                    className={
                      "u-semi-bold u-text-left" +
                      (this.state.activeSortId === sort.sort_id
                        ? " u-color-magenta"
                        : " u-color-charcoal")
                    }
                  >
                    {sort.name}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  expandedFilters = () => {
    const filters: FilterOption[] = [
      {
        filter_id: 1,
        name: "On special",
        action: () =>
          this.props.filterItems({
            filter_id: 1,
            name: "On special",
            enum: "Special",
          }),
      },
      {
        filter_id: 5,
        name: "Exclusive to Coles",
        action: async () => {
          // remove the exclusive to wools filter (if present)
          this.props.removeFilter({
            filter_id: 6,
            name: "Exclusive to Woolworths",
            action: () => {},
          });

          // add the exclusive to coles fitler
          this.props.filterItems({
            filter_id: 5,
            name: "Exclusive to Coles",
            enum: "ExclusiveColes",
          });
          return;
        },
      },
      {
        filter_id: 6,
        name: "Exclusive to Woolworths",
        action: async () => {
          // remove the coles filter (if present)
          this.props.removeFilter({
            filter_id: 5,
            name: "Exclusive to Coles",
            enum: "ExclusiveColes",
            action: () => {},
          });

          // add wools
          this.props.filterItems({
            filter_id: 6,
            name: "Exclusive to Woolworths",
            enum: "ExclusiveWools",
            action: () => {},
          });

          return;
        },
      },
      {
        filter_id: 7,
        name: "Available at both",
        action: async () => {
          // remove the coles filter (if present)
          this.props.removeFilter({
            filter_id: 5,
            name: "Exclusive to Coles",
            enum: "ExclusiveColes",
            action: () => {},
          });

          // remove the exclusive to wools filter (if present)
          this.props.removeFilter({
            filter_id: 6,
            name: "Exclusive to Woolworths",
            action: () => {},
          });

          // Add Available to both
          this.props.filterItems({
            filter_id: 7,
            name: "Available at both",
            enum: "AvailableAtBoth",
            action: () => {},
          });

          return;
        },
      },
    ];
    return (
      <div className="u-border--bottom--grey">
        <div className="u-w-all u-background--white">
          <button
            className="u-ph--12 u-pv--24 u-flex u-align-center u-w-all u-justify-end"
            onClick={() => this.props.hideModal()}
          >
            <div className="u-main-font--small u-pr--12 u-pl--32 u-w-all u-text-left">
              Select <span className="u-bold">Filters</span>
            </div>
            <Icon id="sliders" size="s" />
          </button>
        </div>

        <div className="u-background--white u-fixed u-w-all">
          <div className="">
            {filters.map((filter, index) => {
              return (
                <div
                  className="u-flex u-align-center u-pv--16 u-ph--24 u-border--top--grey"
                  key={index}
                >
                  <button
                    className={
                      this.props.items.appliedFilters.some(
                        (applied) => applied.filter_id === filter.filter_id
                      )
                        ? "u-background--magenta"
                        : "u-background--grey"
                    }
                    style={{
                      width: "25px",
                      height: "25px",
                      borderRadius: "2px",
                    }}
                    onClick={() => this.updateFilterSelection(filter)}
                  ></button>
                  <div
                    className={
                      (this.props.items.appliedFilters.some(
                        (applied) => applied.filter_id === filter.filter_id
                      )
                        ? "u-color-magenta"
                        : "u-color-charcoal ") +
                      " u-main-font--small u-ph--12 u-semi-bold"
                    }
                  >
                    {filter.name}
                  </div>
                </div>
              );
            })}
            <div className="u-flex u-align-center">
              <button
                className="u-background--magenta u-pv--16 u-w-all u-main-font--small u-color-white"
                onClick={() => this.props.hideModal()}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const isUnavailableProductsHidden =
      this.props.isUnavailableProductsHidden || false;
    return (
      <div className="u-w-all u-justify-between u-flex u-align-center u-background--white u-border--bottom--grey">
        <Checkbox
          label="Unavailable"
          isChecked={isUnavailableProductsHidden}
          action={this.toggleUnavailableProducts}
        />

        <button
          className={"u-p--24 u-flex u-justify-between u-align-center"}
          onClick={() => this.showSortsModal()}
        >
          <Icon id="chevron_up_down" size="s" />
        </button>
        <button
          className="u-ph--12 u-pv--24 u-flex u-align-center"
          onClick={() => this.showFiltersModal()}
        >
          <div className="u-main-font--small "></div>
          <Icon id="sliders" size="s" />
        </button>
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  items: state.items,
  categories: state.categories,
  isUnavailableProductsHidden: state.items.isUnavailableProductsHidden,
  searchTerm: state.search.searchTerm,
});

export default connect(mapStateToProps, {
  sortItemsOnApi,
  filterItems,
  removeFilter,
  saveFilterChange,
  showModal,
  hideModal,
  toggleUnavailableProducts,
})(SortAndFilterDropdown);
