export function saveToLocalStorage(state: any) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("items", serializedState);
  } catch (error) {
    console.error(error);
  }
}

export function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("items");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (error) {
    console.error("ERROR:", error);
    return undefined;
  }
}

export function removeItemsOnStorage() {
  localStorage.removeItem("items");
}
