import { Component } from "react";

import { connect } from "react-redux";
import { sortItems, sortItemsOnApi } from "../../../redux/actions/itemActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  hideModal: any;
  sortItems: any;
  sortItemsOnApi: any;
  items: ItemsState;
  categories: CategoryState;
  searchTerm?: string;
}

interface State {
  activeSortId: number | null;
}

class SortDropdownModal extends Component<Props, State> {
  state = {
    activeSortId: null,
  };

  updateFilterSelection = (sort: SortOptions) => {
    this.setState({ activeSortId: sort.sort_id });
    if (sort.sort_id === this.state.activeSortId) {
      const crawler_id = this.props.categories.selectedNode?.crawler_id;
      const searchTerm = this.props.searchTerm;
      this.props.sortItemsOnApi("", crawler_id, searchTerm);
      this.setState({ activeSortId: null });
    } else {
      sort.callback();
    }
  };

  render() {
    const crawler_id = this.props.categories.selectedNode?.crawler_id;
    const searchTerm = this.props.searchTerm;

    const sorts: SortOptions[] = [
      {
        sort_id: 1,
        name: "Name (A-Z)",
        callback: () =>
          this.props.sortItemsOnApi("A-Z", crawler_id, searchTerm),
      },
      {
        sort_id: 2,
        name: "Name (Z-A)",
        callback: () =>
          this.props.sortItemsOnApi("Z-A", crawler_id, searchTerm),
      },
      {
        sort_id: 3,
        name: "Price (High to Low)",
        callback: () =>
          this.props.sortItemsOnApi("HighestPrice", crawler_id, searchTerm),
      },
      {
        sort_id: 4,
        name: "Price (Low to High)",
        callback: () =>
          this.props.sortItemsOnApi("LowestPrice", crawler_id, searchTerm),
      },
      {
        sort_id: 7,
        name: "Biggest Savings",
        callback: () =>
          this.props.sortItemsOnApi("Difference", crawler_id, searchTerm),
      },
    ];

    return (
      <div className="o-modal__container">
        <div className=" u-flex u-justify-center">
          <div className="o-caret-marker"></div>
        </div>
        <div
          className="u-background--white u-pt--8  u-br--vsmall"
          style={{
            marginLeft: "-50px",
            marginRight: "50px",
            width: "230px",
          }}
        >
          {sorts.map((sort, index) => {
            const isSelected =
              this.state.activeSortId === sort.sort_id || false;
            const isLast = index === sorts.length - 1;

            return (
              <div
                className={
                  "u-flex u-align-center u-pv--12 u-ph--24" +
                  (isLast ? "" : " u-border--bottom--grey")
                }
                key={index}
              >
                <button
                  className={
                    isSelected ? "u-background--magenta" : "u-background--grey"
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "2px",
                  }}
                  onClick={() => this.updateFilterSelection(sort)}
                ></button>
                <div
                  className={
                    "u-main-font--vsmall u-ph--12 u-semi-bold" +
                    (isSelected ? " u-color-magenta" : " u-color-charcoal")
                  }
                  style={{ fontSize: "14px" }}
                >
                  {sort.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  items: state.items,
  categories: state.categories,
  searchTerm: state.search.searchTerm,
});

export default connect(mapStateToProps, {
  hideModal,
  sortItems,
  sortItemsOnApi,
})(SortDropdownModal);
