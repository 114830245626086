import mixpanel from "mixpanel-browser";

const debugOnly = process.env.REACT_APP_MIXPANEL_ENABLED === "false";

export function init() {
  // if (debugOnly) {
  //   console.log("init", process.env.REACT_APP_MIXPANEL_TOKEN);
  //   return;
  // }

  if (!process.env.REACT_APP_MIXPANEL_TOKEN) {
    console.log("Mixpanel disabled - no token provided");
    return;
  }

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
    ignore_dnt: true,
  });
}

export function track(eventString: string, data?: any) {
  if (debugOnly) {
    console.log("track", eventString);
    return;
  }

  mixpanel.track(eventString, data ? data : null);
}

export function captureDataOnSignUp(user: UserState) {
  if (debugOnly) {
    console.log("track", "user_sign_up");
    return;
  }

  const { email, auth0_id, first_name, last_name } = user;
  const name = first_name + " " + last_name;

  mixpanel.identify(auth0_id.toString());

  mixpanel.people.set({
    $name: name,
    $first_name: first_name,
    $last_name: last_name,
    $email: email,
  });
}

// export function identify({ userId, userEmail, userName, justCreated }) {
//   if (debugOnly) {
//     if (consoleLogMixpanel) {
//       console.log("identify", userId, userEmail, userName, justCreated);
//     }
//     return;
//   }

//   mixpanel.identify(userId);

//   if (justCreated) {
//     mixpanel.people.set({ $created: new Date().toISOString() });
//   }

//   if (userEmail) {
//     mixpanel.people.set({
//       $email: userEmail,
//     });
//   }

//   if (userName) {
//     mixpanel.people.set({
//       $first_name: userName,
//     });
//   }
// }

export function optOut() {
  if (!debugOnly) {
    mixpanel.opt_out_tracking();
  }
}

export function optIn() {
  if (!debugOnly) {
    mixpanel.opt_in_tracking();
  }
}

export function hasOptedIn() {
  return debugOnly ? true : mixpanel.has_opted_in_tracking();
}

export function hasOptedOut() {
  return debugOnly ? false : mixpanel.has_opted_out_tracking();
}
