import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modalActions";
import { saveNewSearch } from "../../redux/actions/searchActions";

interface Props {
  marginTop: number;
  hideModal: any;
  saveNewSearch: any;
}
interface State {
  searchTerm: string;
  items: { id: string; name: string }[];
}

class NavBarSearchMobile extends Component<Props, State> {
  state = {
    searchTerm: "",
    items: [],
  };

  submitSearchTerm = async (query?: string) => {
    let value = query ? query : this.state.searchTerm;

    await this.props.saveNewSearch(value);
    await this.props.hideModal();
    window.location.reload();
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchTerm: e.currentTarget.value });
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.charCode || e.keyCode || e.key;
    // if user presses enter
    if (characterCode === 13) {
      // @ts-ignore
      this.submitSearchTerm(e.target.value);
    }
  };

  handleOnSearch = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.charCode || e.keyCode || e.key;

    // if user presses escape
    if (characterCode === 27) {
      this.clearSuggestions();
      return;
    }

    // if user presses enter
    if (characterCode !== 13) {
      // @ts-ignore
      const query = e.target.value;

      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + `/suggestions?q=${query}`
      );

      this.setState({ items: response.data, searchTerm: query });
    }
  };

  clearSuggestions = () => {
    this.setState({ items: [] });
  };

  // connect to redux search value
  render() {
    const items: { id: string; name: string }[] = this.state.items;
    return (
      <div
        style={{
          marginTop: this.props.marginTop, // the distance to overlay this on the screen
        }}
      >
        <div className="u-flex u-justify-between u-align-center u-background--white">
          <div className="u-w-all">
            <input
              autoFocus
              className="u-w-all u-pv--20 u-ph--32 u-flex u-align-center u-main-font--small"
              type="text"
              placeholder="Search products"
              onKeyUp={(e) => this.handleOnSearch(e)}
              onKeyDown={(e) => this.onKeyPress(e)}
            />
          </div>
          <div className="u-ph--16">
            <button
              className="u-br--small u-background--magenta u-pv--8 u-ph--20 u-color-white u-main-font--small"
              onClick={() => this.submitSearchTerm()}
            >
              Search
            </button>
          </div>
        </div>
        {items.length > 0 && (
          <div
            onClick={() => this.clearSuggestions()}
            style={{
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              zIndex: 2,
            }}
          >
            <div
              className="u-background--white u-pv--12 u-ph--8"
              id="search-suggestions"
            >
              <div className="u-main-font--vsmall u-color-darkgrey u-ph--4">
                Suggestions
              </div>
              <div className="u-pv--12">
                {items.map((item) => {
                  return (
                    <div
                      className="u-w-all u-hoverover--darker-background u-cursor--pointer u-pv--12 u-ph--8"
                      key={item.id}
                      onClick={() => this.submitSearchTerm(item.name)}
                    >
                      {item.name}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(null, { saveNewSearch, hideModal })(NavBarSearchMobile);
