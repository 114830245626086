import React, { Component } from "react";
import { Link } from "react-router-dom";
import { shareURL } from "../../utils/listFunctions";
import { Icon } from "../icons/Icon";
import Logo from "../naviagtions/Logo";

interface Props {
  listId: string;
}

interface State {
  magnified: boolean;
}

class PublicListsHeader extends Component<Props, State> {
  state = {
    magnified: false,
  };
  componentDidMount = () => {
    // Inititalise the zoom value by default
    // @ts-ignore
    document.body.style.zoom = "100%";
  };

  zoom = () => {
    if (this.state.magnified) {
      //@ts-ignore
      document.body.style.zoom = "100%";
    } else {
      //@ts-ignore
      document.body.style.zoom = "120%";
    }
    this.setState({ magnified: !this.state.magnified });
  };

  shareUrl = async () => {
    shareURL(
      process.env.REACT_APP_FRONTEND_URL + "/lists/" + this.props.listId
    );
  };

  render() {
    return (
      <div className="u-background--secondary u-w-all ">
        <div className="u-background--magenta u-w-all u-br--bottomleft--large">
          <div className="u-w-all u-flex u-justify-between u-align-center u-pv--16 u-pl--20 u-br--bottomleft--large ">
            <Link to="/browse" className="u-flex u-align-center">
              <Logo type="long" />
            </Link>
            <div className="u-flex u-align-center u-ph--20">
              {this.state.magnified ? (
                <button className="u-ph--8" onClick={this.zoom}>
                  <Icon id="minify" size="l" />
                </button>
              ) : (
                <button className="u-ph--8" onClick={this.zoom}>
                  <Icon id="magnify" size="l" />
                </button>
              )}
              <button className="u-ph--8" onClick={this.shareUrl}>
                <Icon id="share" size="l" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PublicListsHeader;
