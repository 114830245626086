import { Component } from "react";
import { connect } from "react-redux";
import {
  updateUserMarketingEmailsPreference,
  updateUserShoppingPreference,
  updateUserSystemEmailsPreference,
} from "../../redux/actions/userActions";
import { MARKETING_EMAILS, SYSTEM_EMAILS } from "../../redux/enums";

interface Props {
  user: UserState;
  updateUserSystemEmailsPreference: any;
  updateUserShoppingPreference: any;
  updateUserMarketingEmailsPreference: (sendMarketingEmails: boolean) => void;
}

class PreferencesView extends Component<Props, {}> {
  changeUserEmailPreference = (
    sendEmails: boolean,
    mailOptionId: typeof MARKETING_EMAILS | typeof SYSTEM_EMAILS
  ) => {
    mailOptionId === MARKETING_EMAILS
      ? this.props.updateUserMarketingEmailsPreference(sendEmails)
      : this.props.updateUserSystemEmailsPreference(sendEmails);
  };

  render() {
    const pageWidth = document.body.scrollWidth;

    const mailOptions = [
      // Disable this until marketing emails are added to grocerize
      {
        id: MARKETING_EMAILS,
        text: "Mailing list - includes latest news.",
        isSelected: this.props.user.send_marketing_emails === true,
      },
      {
        id: SYSTEM_EMAILS,
        text: "System emails - includes price alerts, savings updates.",
        isSelected: this.props.user.send_system_emails === true,
      },
    ];
    const shoppingPrefs = [
      {
        id: 1,
        text: "Coles",
        userSelected: this.props.user.shopping_preference === "Coles",
      },
      {
        id: 2,
        text: "Woolworths",
        userSelected: this.props.user.shopping_preference === "Woolworths",
      },
      {
        id: 3,
        text: "No preference",
        userSelected: this.props.user.shopping_preference === "No preference",
      },
    ];
    return (
      <div className="u-w-all u-h-all u-background--white">
        <div className="u-hide--desktop u-background--grey u-w-all u-flex u-justify-between">
          <div className="u-main-font--medium u-bold  u-pv--24 u-ph--32">
            Preferences
          </div>
        </div>
        <div className="u-hide--mobile u-flex u-justify-center u-border--bottom--grey">
          <div className="u-p--48--desktop  " style={{ width: "80%" }}>
            <div className="u-main-font--large u-color-charcoal u-bold u-pv--32">
              Preferences
            </div>
          </div>
        </div>
        <div className="u-flex u-justify-center u-border--bottom--grey">
          <div
            className="u-p--48--desktop  u-pt--32--mobile u-pb--20--mobile"
            style={{ width: "80%" }}
          >
            <div className="u-bold ">Emails</div>
            {mailOptions.map((option, index) => {
              console.log(option);
              return (
                <div
                  className="u-flex u-align-center u-pv--16 u-accent-violet "
                  key={index}
                >
                  <input
                    type="checkbox"
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                    checked={option.isSelected}
                    onChange={(e) => {
                      this.changeUserEmailPreference(
                        e.currentTarget.checked,
                        option.id
                      );
                    }}
                  ></input>
                  <div className="u-main-font--14--small u-ml--16">
                    {option.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="u-flex u-justify-center u-border--bottom--grey">
          <div
            className="u-p--48--desktop u-pt--32--mobile u-pb--20--mobile"
            style={{ width: "80%" }}
          >
            <div className="u-flex">
              <div className="u-bold">Category Order:</div>
              <div className="u-ml--8">For 'shopping list mode'</div>
            </div>
            <div className="u-pv--16 u-main-font--14--small">
              Order the categories as to how you would navigate your grocery
              store for shopping list mode.
            </div>
            {/* Change this when feature is ready... */}
            <div className="u-pv--8 u-color-magenta">Coming soon!</div>
          </div>
        </div>
        <div className="u-flex u-justify-center u-border--bottom--grey">
          <div
            className="u-p--48--desktop u-pt--32--mobile u-pb--20--mobile"
            style={{ width: "80%" }}
          >
            <div className="u-bold">Shopping preference</div>
            <div className="u-pv--16 u-main-font--14--small">
              I prefer to shop at
            </div>
            <div
              className={
                "u-flex " +
                (pageWidth < 600 ? " u-flex-column " : " ") +
                " u-justify-between u-align-left o-signup__vendor-pref-container u-pt--8 u-pb--16"
              }
            >
              {shoppingPrefs.map((pref, index) => {
                return (
                  <div
                    className="u-flex u-align-center u-pv--8 u-accent-violet"
                    key={index}
                  >
                    <input
                      type="checkbox"
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                      checked={pref.userSelected}
                      onChange={() => {
                        this.props.updateUserShoppingPreference(pref.text);
                      }}
                    ></input>
                    <div className="u-main-font--14--small u-pl--16 u-mr--32">
                      {pref.text}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="u-flex u-justify-center u-border--bottom--grey">
          <div
            className="u-p--48--desktop  u-pv--32--mobile "
            style={{ width: "80%" }}
          >
            <div className=" u-bold">Cancel Premium</div>
            <div className="u-pv--16 u-main-font--14--small">
              Cancelling Premium will automatically transition you to a free
              account. Any days remaining in Premium will be forfieted.
            </div>
            <button
              className="u-background--magenta u-br--small  u-main-font--medium u-color-white"
              style={{
                width: "230px",
                height: "52px",
                fontSize: "14px",
              }}
            >
              Cancel Premium
            </button>
          </div>
        </div> */}
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});
export default connect(mapStateToProps, {
  updateUserSystemEmailsPreference,
  updateUserShoppingPreference,
  updateUserMarketingEmailsPreference,
})(PreferencesView);
