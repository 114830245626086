const initialState = {
  searchTerm: null,
  allSearchTerms: [],
};

interface SearchAction {
  type: "NEW_SEARCH" | "BULK_SEARCH" | "DELETE_ITEM" | "REMOVE_BULK"; // ADD OTHERS HERE
  searchTerm?: string;
  allSearchTerms?: string[];
}

export default function searchReducer(
  state = initialState,
  action: SearchAction
) {
  switch (action.type) {
    case "NEW_SEARCH":
      // if the user has already started a bulk search, add to their list
      if (
        state.allSearchTerms.length &&
        action.searchTerm &&
        // @ts-ignore
        !state.allSearchTerms.includes(action.searchTerm)
      ) {
        return {
          ...state,
          searchTerm: action.searchTerm,
          allSearchTerms: [...state.allSearchTerms, action.searchTerm],
        };
      } else {
        return {
          ...state,
          searchTerm: action.searchTerm,
        };
      }
    case "BULK_SEARCH":
      return {
        ...state,
        allSearchTerms: action.allSearchTerms,
      };
    case "DELETE_ITEM":
      const newSearchTerms = state.allSearchTerms.filter(
        (term) => term !== action.searchTerm
      );
      return {
        ...state,
        searchTerm: newSearchTerms[0] || "",
        allSearchTerms: newSearchTerms,
      };
    case "REMOVE_BULK":
      return {
        ...state,
        allSearchTerms: [],
        searchTerm: "",
      };
    default:
      return state;
  }
}
