import React, { Component } from "react";
import GrocerizeShoppingSplits from "./GrocerizeShoppingSplits";

class GrocerizeListTotalSummary extends Component<{}, {}> {
  render() {
    return (
      <div className="u-background--grey u-pv--20--desktop u-ml--66--desktop u-pt--32 u-pt--12--mobile u-flex u-justify-center u-align-center">
        <div
          className="u-w-all u-flex u-justify-between"
          style={{
            maxWidth: "1234px", // 1300(designed width) - 66px (padding-left)
          }}
        >
          <div className="u-hide--mobile">
            <div
              className="u-bold u-main-font--charcoal"
              style={{ fontSize: "23px" }}
            >
              List totals
            </div>
          </div>
          <GrocerizeShoppingSplits />
        </div>
      </div>
    );
  }
}

export default GrocerizeListTotalSummary;
