import React, { Component } from "react";
import { connect } from "react-redux";
import { httpClient } from "../../../clients/auth/addTokenToHTTPRequests";
import { getLists } from "../../../redux/actions/listActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  list: List;
  getLists: any;
  hideModal: any;
}

interface State {
  showDelete: boolean;
}

class DeleteListModalContent extends Component<Props, State> {
  state = {
    showDelete: false,
  };

  showDeleteList = async () => {
    this.setState({ showDelete: true });
  };

  deleteList = async () => {
    if (this.props.list.id) {
      await httpClient.instance
        .delete("/user/lists?list_id=" + this.props.list.id)
        .then(async () => {
          await this.props.getLists();
          await this.props.hideModal();
        });
    }
  };

  render() {
    const list = this.props.list;
    return (
      <div className="u-border--bottom--darkgrey o-modal__container">
        {this.state.showDelete ? (
          <div className="u-background--grey u-w-all u-p--24 u-main-font--small ">
            <div className="u-color-magenta u-bold">Delete list</div>
            <div className="u-pv--12">
              Delete <span className="u-bold">"{list.title}"</span>?
            </div>
            <button
              className="u-background--magenta u-color-white u-ph--16 u-pv--8 u-br--small"
              onClick={() => this.deleteList()}
            >
              Delete
            </button>
          </div>
        ) : (
          <button
            className="u-p--24  u-main-font--small u-semi-bold u-w-all u-text-left"
            onClick={() => this.showDeleteList()}
          >
            Delete list
          </button>
        )}
      </div>
    );
  }
}

export default connect(null, { getLists, hideModal })(DeleteListModalContent);
