import { Component, ReactNode } from "react";
import { Icon } from "../icons/Icon";

interface Props {
  notification: Notification;
}

class Banner extends Component<Props> {
  state = {
    is_open: true,
  };
  closeNotification = () => {
    this.setState({ is_open: false });
  };

  render(): ReactNode {
    return (
      this.state.is_open && (
        <div>
          <div className="u-slide-in--left u-background--brightRed u-h--50 u-text-center u-bold u-color-white u-justify-center u-flex u-align-center u-outline--grey">
            <p className="u-text-center">{this.props.notification.title}</p>
            <button
              className="u-absolute u-right--18"
              onClick={() => this.closeNotification()}
            >
              <Icon id="clear" stroke="white" size="s" />
            </button>
          </div>
        </div>
      )
    );
  }
}

export default Banner;
