import axios from "axios";
import { Dispatch } from "react";

export const getCategoryTreeFromDB = () => (dispatch: Dispatch<any>) => {
  return axios
    .get(process.env.REACT_APP_BACKEND_URL + `/category/tree`)
    .then((res) => {
      dispatch(categoryTreeData(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

const categoryTreeData = (data: CategoryTreeNode[]) => {
  return {
    type: "GET_TREE",
    categoryTree: data,
  };
};

export const saveNewCategoryFilterSelection = (
  selectedNode: CategoryTreeNode | null
) => {
  return {
    type: "UPDATE_SELECTED_NODE",
    selectedNode,
  };
};

export const saveNewCategoryNavigationSelection = (
  selectedNode: CategoryTreeNode | null
) => {
  return {
    type: "UPDATE_NAVIGATION_NODE",
    selectedNode,
  };
};
