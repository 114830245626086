import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { getLists } from "../../redux/actions/listActions";
import { showModal } from "../../redux/actions/modalActions";
import LoginSignupButtons from "../auth/LoginSignupButtons";
import { Icon } from "../icons/Icon";
import LoginPrompt from "../popups/StandardPopup";
import UserGuidePopup from "../popups/UserGuidePopup";
import SocialMediaButton from "./itemNavs/SocialMediaButton";

interface Props {
  showModal: any;
  auth0: any;
  user: UserState;
  lists: ListsState;
  getLists: any;
  favourites: FavouritesState;
  priceAlerts: PriceAlertsState;
}

interface State {
  favouritesCount: number;
  listsCount: number;
  priceAlertsCount: number;
}

class NavLinks extends Component<Props, State> {
  state = {
    favouritesCount: 0,
    listsCount: 0,
    priceAlertsCount: 0,
  };

  componentDidMount = () => {
    if (!this.props.lists.lists.length) {
      this.props.getLists();
    }
    // find initial counts
    this.setState({
      favouritesCount: this.props.favourites.favourites.length | 0,
      listsCount: this.props.lists.lists.length | 0,
      priceAlertsCount: this.props.priceAlerts.priceAlerts?.length | 0,
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    // monitor changes in favourites
    if (prevProps.favourites.favourites !== this.props.favourites.favourites) {
      this.setState({
        favouritesCount: this.props.favourites.favourites.length,
      });
    }

    if (prevProps.lists.lists !== this.props.lists.lists) {
      this.setState({ listsCount: this.props.lists.lists.length });
    }

    if (
      prevProps.priceAlerts.priceAlerts !==
        this.props.priceAlerts.priceAlerts &&
      this.props.priceAlerts.priceAlerts // seeing an initialisation error that I cannot solve here
    ) {
      this.setState({
        priceAlertsCount: this.props.priceAlerts.priceAlerts.length,
      });
    }
  };

  showPopup = () => {
    const modalContent = () => <LoginPrompt />;
    this.props.showModal({ component: modalContent });
  };

  showUserGuidePopup = () => {
    const modalContent = () => <UserGuidePopup />;
    this.props.showModal({ component: modalContent });
  };

  render() {
    const { user, isAuthenticated } = this.props.auth0;

    return (
      <div className="u-flex u-w-all u-main-font--vsmall u-align-center">
        {/* This is temporarily disabled since this is not yet ready for the launching */}
        <div className="u-flex u-align-center u-border--right--white u-pv--8 u-ph--12">
          <SocialMediaButton />

          <div
            className=" u-cursor--pointer"
            id="user-info"
            key={"1"}
            onClick={() => this.showUserGuidePopup()}
          >
            <button className={"u-link u-color-white u-ph--12 u-semi-bold"}>
              <Icon id="question_mark_white" fill="transparent" size="xl" />
            </button>
          </div>
          <div
            className="u-cursor--pointer"
            key={"2"}
            onClick={() => this.showPopup()}
          >
            {/* <button
              className={"u-link u-color-white u-ph--12 u-semi-bold"}
              style={{ fontSize: "14px" }}
            >
              Features
            </button> */}
          </div>
        </div>
        {isAuthenticated ? (
          <div
            className="u-color-white u-ph--16  u-semi-bold"
            style={{ fontSize: "14px" }}
          >
            <Link to="/my/details">
              Hi{" "}
              {this.props.user?.first_name
                ? this.props.user.first_name
                : user.name}
            </Link>
            <div className="u-flex u-pt--8">
              <Link
                className="u-flex u-align-center  u-w-all u-text-oneline"
                to="/my/alerts"
              >
                <div className="u-text-oneline u-pr--4">Price alerts</div>
                <div
                  className=" u-br--all u-background--white u-color-magenta u-flex u-justify-center u-align-center"
                  style={{ width: "26px", height: "26px" }}
                >
                  {this.state.priceAlertsCount}
                </div>
              </Link>

              <Link
                className="u-flex  u-align-center u-pl--32 u-w-all u-text-oneline"
                to="/my/lists"
              >
                <div className="u-ph--4 u-text-oneline">My Lists</div>
                <div
                  className="u-br--all u-background--white u-color-magenta u-flex u-justify-center u-align-center"
                  style={{ width: "26px", height: "26px" }}
                >
                  {this.state.listsCount}
                </div>
              </Link>

              <Link
                className="u-flex  u-align-center u-pl--32 u-w-all u-text-oneline"
                to="/my/favourites"
              >
                <div className="u-ph--4 u-text-oneline">Favourites</div>
                <div
                  className="u-br--all u-background--white u-color-magenta u-flex u-justify-center u-align-center"
                  style={{ width: "26px", height: "26px" }}
                >
                  {this.state.favouritesCount}
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <LoginSignupButtons />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  lists: state.lists,
  favourites: state.favourites,
  priceAlerts: state.priceAlerts,
});

export default withAuth0<any>(
  connect(mapStateToProps, { showModal, getLists })(NavLinks)
);
