import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import { createClone, findColesAndWoolsPricing } from "../../utils/UIFunctions";
import { sortByVendor } from "../../utils/itemPricingFunctions";
import { loadFromLocalStorage } from "../../utils/localStorageFunctions";
import {
  ADD_LIST_TO_BASKET,
  ADD_TO_BASKET,
  CHANGE_SORT,
  CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL,
  CHANGE_VENDOR_TO_BUY_FROM,
  CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL,
  CLEAR_BASKET,
  COLES,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  LOAD_BASKET,
  REMOVE_FROM_BASKET,
  REPLACE_BASKET,
  UPDATE_BASKET_METADATA,
  WOOLWORTHS,
} from "../enums";

interface basketAction {
  type:
    | "ADD_TO_BASKET"
    | "CLEAR_BASKET"
    | "INCREASE_QUANTITY"
    | "DECREASE_QUANTITY"
    | "REMOVE_FROM_BASKET"
    | "CHANGE_SORT"
    | "CHANGE_VENDOR_TO_BUY_FROM"
    | "CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL"
    | "UPDATE_BASKET_METADATA"
    | "CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL"
    | "REPLACE_BASKET"
    | "LOAD_BASKET"
    | "ADD_LIST_TO_BASKET"
    | "SET_INITIAL_COUNT_OF_COLES_AND_WOOLS_ITEMS";
  item: ItemData;
  id: number;
  vendorId: 1 | 2 | undefined;
  basket: ItemData[];
  isAutoMode: boolean;
  isVendorSelected?: boolean;
}

const initialState: BasketState = loadFromLocalStorage() ?? {
  basketItems: [],
  basketMetaData: {
    woolworthsOnlyTotal: 0,
    colesOnlyTotal: 0,
    grocerizeTotal: 0,
    totalSavingsUsingGrocerize: 0,

    WoolsTotalUsingGrocerize: 0,
    colesTotalUsingGrocerize: 0,

    initialCountOfColesItems: 0,
    initialCountOfWoolsItems: 0,

    countOfColesItems: 0,
    countOfWoolsItems: 0,

    isOptimisedPrice: true,
    isAllColes: false,
    isAllWools: false,

    itemsUniqueToColes: 0,
    itemsUniqueToWoolworths: 0,

    sortTypeName: "Order Added",
  },
};

export default function searchReducer(
  state = initialState,
  action: basketAction
) {
  switch (action.type) {
    case CLEAR_BASKET: {
      clearCurrentBasket();
      return {
        initialState,
      };
    }
    case INCREASE_QUANTITY: {
      let newBasketItems = [...state.basketItems];
      // Find the item to update
      const index = newBasketItems.findIndex(
        (item) => item.id === action.item.id
      );
      // Increase quantity
      newBasketItems[index].quantity =
        (newBasketItems[index].quantity || 0) + 1; // Typescript is being stupid here

      // tell the db
      updateItemInBasket({
        item_id: action.item.id,
        quantity: newBasketItems[index].quantity,
      });

      return {
        ...state,
        basketItems: newBasketItems,
      };
    }
    case DECREASE_QUANTITY: {
      // Copy state
      let newBasketItems = createClone(state.basketItems);
      // Find the item to update
      const index = newBasketItems.findIndex(
        (item: ItemData) => item.id === action.item.id
      );

      // Decrease quantity
      newBasketItems[index].quantity =
        (newBasketItems[index].quantity || 1) - 1; // Typescript is being stupid here I think

      // check if this new value is less than 0
      if ((newBasketItems[index].quantity || 0) <= 0) {
        // if it less remove it from the basket
        newBasketItems = [
          ...state.basketItems.filter((item) => {
            return item.id !== action.item.id;
          }),
        ];

        // remove from db
        updateItemInBasket({
          item_id: action.item.id,
          quantity: 0,
        });

        return {
          ...state,
          basketItems: newBasketItems,
        };
      } else {
        // tell the db
        updateItemInBasket({
          item_id: action.item.id,
          quantity: newBasketItems[index].quantity,
        });
        // save new value to state
        return {
          ...state,
          basketItems: newBasketItems,
        };
      }
    }
    case ADD_TO_BASKET: {
      // add the quanty and date
      let newBasketItem = action.item;
      newBasketItem.quantity = 1;
      newBasketItem.timestampAddedToBasket = new Date();
      // find the cheapestVendorId and set that as the selected vendor to by from
      newBasketItem.selected_vendor_id = findTheCheapestVendorPricing(
        newBasketItem,
        state.basketMetaData
      ).vendor_id;

      // clone and replace current basket lists
      let newBasketItems = state.basketItems ? [...state.basketItems] : []; // need to make a brand new copy in memory so components track changes : https://stackoverflow.com/questions/35917210/react-component-not-updating-when-store-state-has-changed

      // check for duplicates
      const index = newBasketItems.findIndex(
        (item) => item.id === newBasketItem.id
      );

      if (index > -1) {
        // if duplicate increase quantity to 1 (as this product must be in basket with quantity of 0)
        newBasketItems[index].quantity = 1;
      } else {
        // else add new item
        newBasketItems.push(newBasketItem);
      }

      // tell the db
      updateItemInBasket({
        item_id: action.item.id,
        quantity: 1,
        selected_vendor_id: newBasketItem.selected_vendor_id,
      });

      return {
        ...state,
        basketItems: newBasketItems,
      };
    }
    case REMOVE_FROM_BASKET: {
      // clone and replace current items
      const newBasketItems = [
        ...state.basketItems.filter((item) => {
          return item.id !== action.item.id;
        }),
      ];

      // tell the db
      updateItemInBasket({
        item_id: action.item.id,
        quantity: 0,
      });

      return {
        ...state,
        basketItems: newBasketItems,
      };
    }
    case CHANGE_SORT: {
      let newBasketItems = [...state.basketItems];
      let sortName = state.basketMetaData.sortTypeName;
      if (action.id === 1) {
        // sort by when it was added to basket
        newBasketItems.sort((a, b) => {
          return (
            (a.timestampAddedToBasket
              ? a.timestampAddedToBasket.getMilliseconds()
              : 0) -
            (b.timestampAddedToBasket
              ? b.timestampAddedToBasket.getMilliseconds()
              : 0)
          );
        });

        sortName = "Order Added";
      } else if (action.id === 2) {
        // sort by Name (A-Z)
        newBasketItems.sort((a, b) => {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });

        sortName = "Name (A-Z)";
      } else if (action.id === 3) {
        // sort by Name (Z-A)
        newBasketItems.sort((a, b) => {
          var textA = a.name.toUpperCase();
          var textB = b.name.toUpperCase();
          return textA > textB ? -1 : textA < textB ? 1 : 0;
        });

        sortName = "Name (Z-A)";
      } else if (action.id === 4) {
        // sort on the largest price
        newBasketItems.sort((a, b) => {
          // find the largest price from wools or coles from both prods
          const maxPriceOfA = Math.max(
            a.item_pricing[0]?.price || 0,
            a.item_pricing[1]?.price || 0
          );
          const maxPriceOfB = Math.max(
            b.item_pricing[0]?.price || 0,
            b.item_pricing[1]?.price || 0
          );
          // put the largest price first
          return maxPriceOfB - maxPriceOfA;
        });
      } else if (action.id === 5) {
        // sort on the largest price
        newBasketItems.sort((a, b) => {
          // find the largest price from wools or coles from both prods
          const maxPriceOfA = Math.max(
            a.item_pricing[0]?.price || 0,
            a.item_pricing[1]?.price || 0
          );
          const maxPriceOfB = Math.max(
            b.item_pricing[0]?.price || 0,
            b.item_pricing[1]?.price || 0
          );
          // put the largest price first
          return maxPriceOfA - maxPriceOfB;
        });
      }

      return {
        basketItems: newBasketItems,
        basketMetaData: { ...state.basketMetaData, sortTypeName: sortName },
      };
    }
    case CHANGE_VENDOR_TO_BUY_FROM: {
      let newBasketItems = [...state.basketItems];
      // Find the item to update
      const index = newBasketItems.findIndex(
        (item) => item.id === action.item.id
      );

      // set the vendor as per user
      newBasketItems[index].selected_vendor_id = action.vendorId;

      // tell the db
      updateItemInBasket({
        item_id: action.item.id,
        selected_vendor_id: action.vendorId,
      });

      return {
        ...state,
        basketItems: newBasketItems,
      };
    }
    case CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL: {
      let newBasketItems = [...state.basketItems];
      // for all products
      newBasketItems.forEach((item) => {
        const pricing = item.item_pricing;
        // if the item has the ability to change, change it
        if (pricing.length === 2) {
          // set the vendor as per user
          if (pricing[0].available && pricing[1].available) {
            item.selected_vendor_id = action.vendorId;
          }
        }
      });

      return {
        ...state,
        basketItems: newBasketItems,
      };
    }
    case CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL: {
      let newBasketItems = [...state.basketItems];

      // for all products
      newBasketItems.forEach((item) => {
        const pricing = item.item_pricing;
        // if the item has the ability to change, change it
        if (pricing.length === 2) {
          // set the vendor who the cheapest possible price
          item.selected_vendor_id = findTheCheapestVendorPricing(
            item,
            state.basketMetaData
          ).vendor_id;
        }
      });

      return {
        ...state,
        basketItems: newBasketItems,
      };
    }

    case UPDATE_BASKET_METADATA: {
      let basketMetaData = {
        ...state.basketMetaData,
        initialCountOfColesItems:
          computeTotalItemsWithLowestPriceBasedOnVendor(
            state.basketItems,
            COLES
          ) || 0,
        initialCountOfWoolsItems:
          computeTotalItemsWithLowestPriceBasedOnVendor(
            state.basketItems,
            WOOLWORTHS
          ) || 0,
      };

      basketMetaData = updateBasketMetaData(
        state.basketItems,
        basketMetaData,
        action.isAutoMode
      );

      if (action.isVendorSelected) {
        basketMetaData.isOptimisedPrice = false;
      }

      return {
        ...state,
        basketMetaData,
      };
    }
    case LOAD_BASKET: {
      return {
        ...state,
        basketItems: action.basket,
      };
    }
    case REPLACE_BASKET: {
      // update db
      saveCurrentBasket(action.basket);

      return {
        ...state,
        basketItems: action.basket,
      };
    }
    case ADD_LIST_TO_BASKET: {
      let newBasket = reduceDuplicatesInBasket([
        ...state.basketItems,
        ...action.basket,
      ]);

      saveCurrentBasket(newBasket);

      return {
        ...state,
        basketItems: newBasket,
      };
    }

    default: {
      return state;
    }
  }
}

function reduceDuplicatesInBasket(basket: ItemData[]) {
  var seen: any = {};
  basket = basket.filter(function (entry) {
    var previous;
    // Have we seen this item id before?
    if (seen.hasOwnProperty(entry.id)) {
      // Yes, grab it and add this data to it
      previous = seen[entry.id];
      previous.quantity += entry.quantity;

      // Don't keep this entry, we've merged it into the previous one
      return false;
    }

    // Remember that we've seen it
    seen[entry.id] = entry;

    // Keep this one, we'll merge any others that match into it
    return true;
  });

  return basket;
}

// note these are further explained here: https://www.notion.so/Grocerize-Page-38f312514f2246a69ed47046d7ca39bd

function updateBasketMetaData(
  basketItems: ItemData[],
  basketMetaData: BasketMetaDataState,
  isAutoMode?: boolean
) {
  let updatedMetaData = {
    woolworthsOnlyTotal: 0,
    colesOnlyTotal: 0,
    grocerizeTotal: 0,
    totalSavingsUsingGrocerize: 0,

    WoolsTotalUsingGrocerize: 0,
    colesTotalUsingGrocerize: 0,

    initialCountOfColesItems: basketMetaData?.initialCountOfColesItems,
    initialCountOfWoolsItems: basketMetaData?.initialCountOfWoolsItems,

    countOfColesItems: 0,
    countOfWoolsItems: 0,

    isManualMode: false,
    isOptimisedPrice: true,
    isAllColes: true,
    isAllWools: true,

    itemsUniqueToColes: 0,
    itemsUniqueToWoolworths: 0,
    sortTypeName: basketMetaData?.sortTypeName,
  };

  // if there are items in the basket
  if (basketItems && basketItems.length) {
    basketItems.forEach((item) => {
      // if the item has any price(s) we want to calculate savings
      if (item.item_pricing.length > 0) {
        if (isAutoMode) {
          item.selected_vendor_id = findTheCheapestVendorPricing(
            item,
            updatedMetaData
          ).vendor_id;
        }
        // How many does the user want of it? We will multiply this later
        const quantity = item.quantity || 0;

        // get the two pricings details
        const { colesPricing, woolsPricing } = findColesAndWoolsPricing(item);

        // if price doesn't exist set to be 0
        const colesPrice =
          colesPricing?.price && colesPricing.available
            ? colesPricing.price
            : 0;
        const woolsPrice =
          woolsPricing?.price && woolsPricing.available
            ? woolsPricing.price
            : 0;

        updateUnavailableStats(
          updatedMetaData,
          colesPrice,
          woolsPrice,
          quantity
        );

        updateVendorTotals(
          updatedMetaData,
          colesPricing,
          woolsPricing,
          quantity,
          item.quantity
        );

        updateGrocerizeTotal(
          updatedMetaData,
          colesPricing,
          woolsPricing,
          quantity,
          item.quantity
        );

        updateAllItemsFromOneVendor(
          item,
          colesPrice,
          woolsPrice,
          updatedMetaData
        );

        updateOptimisedPriceFlag(item, updatedMetaData);

        updateCurrentBasketTotals(
          item.selected_vendor_id,
          updatedMetaData,
          colesPricing,
          woolsPricing,
          quantity,
          item.quantity
        );
      }
    });

    // calc total savings
    updatedMetaData.totalSavingsUsingGrocerize =
      calcTotalSavings(updatedMetaData);
  }

  return updatedMetaData;
}

function updateUnavailableStats(
  updatedMetaData: BasketMetaDataState,
  colesPrice: number,
  woolsPrice: number,
  quantity: number
) {
  // if products are unavailable from a vendor but available in the ohter, update the metadata
  if (colesPrice === 0 && woolsPrice !== 0) {
    updatedMetaData.itemsUniqueToWoolworths++;

    updatedMetaData.itemsUniqueToWoolworths =
      updatedMetaData.itemsUniqueToWoolworths * quantity;
  }
  if (woolsPrice === 0 && colesPrice !== 0) {
    updatedMetaData.itemsUniqueToColes++;

    updatedMetaData.itemsUniqueToColes =
      updatedMetaData.itemsUniqueToColes * quantity;
  }
}
function updateVendorTotals(
  updatedMetaData: BasketMetaDataState,
  colesPricing: ItemPricings,
  woolsPricing: ItemPricings,
  quantity: number,
  totalQuantity?: number
) {
  // update the vendor spends
  // Note: if an item isn't available in one vendor, we add the other vendors price as to not indicate it is cheaper
  updatedMetaData.woolworthsOnlyTotal += computePricing(
    woolsPricing ?? colesPricing,
    quantity,
    totalQuantity
  );
  updatedMetaData.colesOnlyTotal += computePricing(
    colesPricing ?? woolsPricing,
    quantity,
    totalQuantity
  );
}

function updateGrocerizeTotal(
  updatedMetaData: BasketMetaDataState,
  colesPricing: ItemPricings,
  woolsPricing: ItemPricings,
  quantity: number,
  totalQuantity?: number
) {
  const colesPrice = computePricing(colesPricing, quantity, totalQuantity);
  const woolsPrice = computePricing(woolsPricing, quantity, totalQuantity);
  // find the cheapest price
  const cheapestPrice =
    colesPrice === 0
      ? woolsPrice
      : // if wools unavailable use coles
      woolsPrice === 0
      ? colesPrice
      : // if both available use the cheapest
      colesPrice > woolsPrice
      ? woolsPrice
      : colesPrice;

  updatedMetaData.grocerizeTotal += cheapestPrice;
}

function updateOptimisedPriceFlag(
  item: ItemData,
  updatedMetaData: BasketMetaDataState
) {
  if (
    item.selected_vendor_id !==
    findTheCheapestVendorPricing(item, updatedMetaData).vendor_id
  ) {
    // if both coles and woolies price is available
    if (item.item_pricing.length === 2 && item.selected_vendor_id !== null) {
      const selectedVendor = item.item_pricing.find(
        (pricing) => pricing.vendor_id === item.selected_vendor_id
      );

      if (
        selectedVendor!.price >
        findTheCheapestVendorPricing(item, updatedMetaData).price
      ) {
        // set the list as unoptimised
        updatedMetaData.isOptimisedPrice = false;
      }
    }
  }
}

function updateCurrentBasketTotals(
  selected_vendor_id: number | undefined,
  updatedMetaData: BasketMetaDataState,
  colesPricing: ItemPricings,
  wooliesPricing: ItemPricings,
  quantity: number,
  totalQuantity?: number
) {
  const colesPrice = colesPricing?.price;
  const woolsPrice = wooliesPricing?.price;

  const colesTotalPricing = computePricing(
    colesPricing,
    quantity,
    totalQuantity
  );

  const woolsTotalPricing = computePricing(
    wooliesPricing,
    quantity,
    totalQuantity
  );
  // Update the baskets total costs from vendors
  if (selected_vendor_id === 1) {
    updatedMetaData.colesTotalUsingGrocerize += colesTotalPricing;
    updatedMetaData.countOfColesItems += quantity;
  } else if (selected_vendor_id === 2) {
    updatedMetaData.WoolsTotalUsingGrocerize += woolsTotalPricing;
    updatedMetaData.countOfWoolsItems += quantity;
  } else {
    // if no preference stated, we should use the cheapest *real* price
    // a price is real if it is not zero currently
    if (colesPrice === 0) {
      updatedMetaData.WoolsTotalUsingGrocerize += woolsTotalPricing;
      updatedMetaData.countOfWoolsItems += quantity;
    } else if (woolsPrice === 0) {
      updatedMetaData.colesTotalUsingGrocerize += colesTotalPricing;
      updatedMetaData.countOfColesItems += quantity;
    } else if (colesPrice > woolsPrice) {
      updatedMetaData.WoolsTotalUsingGrocerize += woolsTotalPricing;
      updatedMetaData.countOfWoolsItems += quantity;
    } else if (woolsPrice > colesPrice) {
      updatedMetaData.colesTotalUsingGrocerize += colesTotalPricing;
      updatedMetaData.countOfColesItems += quantity;
    } else if (woolsPrice === colesPrice) {
      if (
        updatedMetaData.countOfColesItems > updatedMetaData.countOfWoolsItems
      ) {
        updatedMetaData.colesTotalUsingGrocerize += colesTotalPricing;
        updatedMetaData.countOfColesItems += quantity;
      } else {
        updatedMetaData.WoolsTotalUsingGrocerize += woolsTotalPricing;
        updatedMetaData.countOfWoolsItems += quantity;
      }
    }
  }
}

function computePricing(
  pricing: ItemPricings,
  quantity: number,
  totalQuantity?: number
) {
  let totalPrice = 0;

  const multibuy_price = pricing?.multibuy_price;
  const multibuy_quantity = pricing?.multibuy_quantity;
  const price = pricing ? pricing.price : 0;
  if (multibuy_price && multibuy_quantity) {
    const basePromoPrice = multibuy_price;
    const accumulatedPrice = basePromoPrice / multibuy_quantity;
    const quantityAffectedByAccumulation = totalQuantity
      ? totalQuantity - multibuy_quantity
      : 0;

    totalPrice += basePromoPrice;

    if (quantityAffectedByAccumulation > 0) {
      totalPrice += quantityAffectedByAccumulation * accumulatedPrice;
    }

    return totalPrice;
  }
  return quantity * price;
}

function updateAllItemsFromOneVendor(
  item: ItemData,
  colesPrice: number,
  woolsPrice: number,
  updatedMetaData: BasketMetaDataState
) {
  // on each calc we declare isAllWools and isAllColes as true

  // if the user hasn't declared a preference
  if (item.selected_vendor_id == null) {
    // if both prices exist
    if (colesPrice !== 0 && woolsPrice !== 0) {
      // and coles is more expensive
      if (colesPrice > woolsPrice) {
        // we will have defaulted to pick wools and hence the list cannot be all coles
        updatedMetaData.isAllColes = false;
      } else {
        //  we will have defaulted to coles hence the list cannot be all wools
        updatedMetaData.isAllWools = false;
      }
    }
  } else {
    //only update isAllColes or isAllWools if both coles and woolies price is available
    if (item.item_pricing.length === 2) {
      // a vendor has been picked hence
      if (item.selected_vendor_id !== 1) {
        updatedMetaData.isAllColes = false;
      } else {
        updatedMetaData.isAllWools = false;
      }
    }
  }
}

function calcTotalSavings(basketMetaData: BasketMetaDataState) {
  const mostExpensiveVendor =
    basketMetaData.colesOnlyTotal > basketMetaData.woolworthsOnlyTotal
      ? basketMetaData.colesOnlyTotal
      : basketMetaData.woolworthsOnlyTotal;

  const totalPrice =
    (basketMetaData.colesTotalUsingGrocerize || 0) +
    (basketMetaData.WoolsTotalUsingGrocerize || 0);

  const totalSavings =
    Math.round(100 * (mostExpensiveVendor - totalPrice)) / 100;

  return totalSavings <= 0 ? 0 : totalSavings;
}

function findTheCheapestVendorPricing(
  itemData: ItemData,
  updatedMetaData: BasketMetaDataState
) {
  const pricing = sortByVendor(itemData.item_pricing);

  const { initialCountOfColesItems, initialCountOfWoolsItems } =
    updatedMetaData;

  // if there is not price for one vendor return the price we do have
  if (pricing.length === 1 && pricing[0].available) {
    return pricing[0];
  } else {
    const colesPricing = pricing[0];
    const woolsPricing = pricing[1];
    if (colesPricing.price === woolsPricing.price) {
      if (
        initialCountOfColesItems > initialCountOfWoolsItems &&
        colesPricing.available
      ) {
        return colesPricing;
      }

      if (
        initialCountOfWoolsItems > initialCountOfColesItems &&
        woolsPricing.available
      ) {
        return woolsPricing;
      }
      // if items in coles and woolworth are the same total use coles as vendor same implementation in the backend
      return colesPricing.available ? colesPricing : woolsPricing;
    }

    if (colesPricing.price < woolsPricing.price && colesPricing.available) {
      return colesPricing;
    }

    if (woolsPricing.price < colesPricing.price && woolsPricing.available) {
      return woolsPricing;
    }

    if (woolsPricing.available && !colesPricing.available) {
      return woolsPricing;
    }

    if (colesPricing.available && !woolsPricing.available) {
      return colesPricing;
    }
    return colesPricing.available ? colesPricing : woolsPricing;
  }
}

function computeTotalItemsWithLowestPriceBasedOnVendor(
  basketItems: ItemData[],
  vendorId: typeof COLES | typeof WOOLWORTHS
) {
  if (typeof basketItems !== "undefined" && basketItems.length > 0) {
    const items = basketItems?.map((item) => {
      if (item.item_pricing.length === 1) {
        if (
          item.item_pricing[0].vendor_id === vendorId &&
          item.item_pricing[0].available
        ) {
          return item.quantity;
        }
        return 0;
      }

      if (item.item_pricing.length === 2) {
        const firstVendor = vendorId === COLES ? COLES : WOOLWORTHS;
        const secondVendor = vendorId === COLES ? WOOLWORTHS : COLES;

        const firstVendorPricing = item.item_pricing.find(
          (pricing) => pricing.vendor_id === firstVendor
        );

        const secondVendorPricing = item.item_pricing.find(
          (pricing) => pricing.vendor_id === secondVendor
        );

        if (
          firstVendorPricing?.price! < secondVendorPricing?.price! &&
          firstVendorPricing?.available &&
          secondVendorPricing?.available
        ) {
          return item.quantity;
        }

        return 0;
      }
    });

    return items.reduce((total, number) => total! + number!, 0);
  }
  return 0;
}

// on each action we should update the current basket. Will need endpoint
const saveCurrentBasket = async (basket: ItemData[]) => {
  await httpClient.instance.post(
    process.env.REACT_APP_BACKEND_URL + "/user/lists/current",
    {
      title: "",
      is_current_basket: true,
      items: basket,
    }
  );
};

const clearCurrentBasket = async () => {
  await httpClient.instance.delete(
    process.env.REACT_APP_BACKEND_URL + "/user/lists/current"
  );
};

interface ListItemUpdateReq {
  item_id: number;
  quantity?: number;
  selected_vendor_id?: number;
}

export const updateItemInBasket = async ({
  item_id,
  quantity,
  selected_vendor_id,
}: ListItemUpdateReq) => {
  const fieldsToUpdate = Object.assign(
    {},
    item_id && { item_id },
    typeof quantity !== undefined && { quantity },
    selected_vendor_id && { selected_vendor_id }
  );

  await httpClient.instance.patch(
    process.env.REACT_APP_BACKEND_URL + "/user/lists/current/item",
    fieldsToUpdate
  );
};
