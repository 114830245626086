import { withAuth0 } from "@auth0/auth0-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import { getUserData } from "../../redux/actions/userActions";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import { showModal } from "../../redux/actions/modalActions";

interface Props {
  auth0: any;
  user: UserState;
  getUserData: any;
  showModal: any;
}

interface State {
  user: UserState | {};
  wantsToDelete: boolean;
  isLoading: boolean;
}

class MyDetails extends Component<Props, State> {
  state = {
    user: {},
    wantsToDelete: false,
    isLoading: false,
  };
  componentDidMount = async () => {
    // get user data if not in state already
    await this.props.getUserData();

    //Monitor the changes when a delete account is confirmed
    this.showLoadingIndicator();
    window.addEventListener("click", this.showLoadingIndicator);
  };

  componentWillUnmount() {
    window.removeEventListener("click", this.showLoadingIndicator);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
  };

  logout = () => {
    this.setState({ isLoading: true });
    this.props.auth0.logout();
  };

  getWhiteSpaceHeight = () => {
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect().bottom || 0;
    const footerHeight =
      document.getElementById("footer")?.getBoundingClientRect().height || 215;
    const whiteSpaceHeight = window.innerHeight - (headerHeight + footerHeight);

    return whiteSpaceHeight + "px";
  };

  deleteAccount = async () => {
    this.setState({ isLoading: true });

    await httpClient.instance.delete("/user")
    .then((response) => {
      this.setState({ isLoading: false });
      this.props.auth0.logout();
    })
    .catch((err) => {
      this.setState({ isLoading: false });
      console.log(err);
    });
  };

  showLoadingIndicator = () => {
    if (this.state.isLoading) {
      const modalContent = () => (
        <div className="o-modal__container o-modal u-w-all">
          <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
            <GrocerizeLoadingWheel />
          </div>
        </div>
      );

      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    return (
      <div className="u-w-all u-background--white">
        <div className="u-flex u-justify-center u-border--bottom--grey">
          <div className="u-p--48--desktop  " style={{ width: "80%" }}>
            <div className="u-main-font--large u-color-charcoal u-bold u-pv--32">
              My details
            </div>
          </div>
        </div>
        <div className="u-flex u-justify-center u-border--bottom--grey">
          <div
            className="u-p--48--desktop  u-pt--32--mobile "
            style={{ width: "80%" }}
          >
            <div className="u-main-font--small u-color-charcoal u-bold u-pb--32">
              Contact details
            </div>
            <div
              className="u-main-font--small u-color-charcoal  u-pb--12"
              style={{ fontSize: 14 }}
            >
              Name
            </div>
            <div className="u-flex--desktop u-w-all ">
              <div className="u-w-all u-border--darkgrey u-br--vsmall u-background--grey u-mr--12">
                <div className="u-p--16 ">{this.props.user.first_name}</div>
              </div>
              <div className="u-w-all u-border--darkgrey u-br--vsmall u-background--grey u-ml--12--desktop u-mt--12--mobile">
                <div className="u-p--16 ">{this.props.user.last_name}</div>
              </div>
            </div>

            <div className="u-flex--desktop u-w-all u-pt--48 u-pb--32--mobile ">
              <div className="u-w-all">
                <div
                  className="u-main-font--small u-color-charcoal u-pb--12"
                  style={{ fontSize: 14 }}
                >
                  Email
                </div>
                <div className="u-border--darkgrey u-br--vsmall u-background--grey u-mr--12 u-p--16 u-overflow-scroll--mobile">
                  <div className="">{this.props.auth0.user.email}</div>
                </div>
              </div>
              <div className="u-w-all">
                <div
                  className="u-main-font--small u-color-charcoal u-pb--12 u-pt--12--mobile"
                  style={{ fontSize: 14 }}
                >
                  Postcode
                </div>
                <div className="u-border--darkgrey u-br--vsmall u-background--grey u-mr--12 u-p--16">
                  <div className="">{this.props.user.postcode}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Logout */}
        <div className="u-flex u-justify-center u-border--bottom--grey u-p--48--desktop">
          <div style={{ width: "80%" }}>
            <div className="u-pv--32--mobile ">
              <div className="u-main-font--small u-color-charcoal u-bold ">
                Logout
              </div>
              <div
                className="u-main-font--small u-color-charcoal u-pt--16 u-pb--16"
                style={{ fontSize: 14 }}
              >
                Logout of your account
              </div>
              <button
                className="u-background--magenta u-br--small  u-main-font--small u-bold u-color-white"
                style={{ width: "230px", height: "52px", fontSize: "14px" }}
                onClick={() => this.logout()}
              >
                Log out
              </button>
            </div>
          </div>
        </div>
        {/* Delete your account */}
        <div className="u-flex u-justify-center u-border--bottom--grey u-p--48--desktop">
          <div style={{ width: "80%" }}>
            <div className="u-pv--32--mobile ">
              <div className="u-main-font--small u-color-charcoal u-bold">
                Delete your account
              </div>
              {this.state.wantsToDelete ? (
                <>
                  <div className="u-main-font--small u-color-charcoal u-pt--16 u-pb--16" style={{ fontSize: 14 }}>
                    Are you sure? It can't be undone.
                  </div>
                  <button
                    className=" u-main-font--small u-bold u-color-white u-br--small"
                    style={{
                      width: "230px",
                      height: "52px",
                      backgroundColor: "red",
                    }}
                    onClick={() => this.deleteAccount()}
                  >
                    Yes, I am sure
                  </button>
                </>
              ) : (
                <>
                  <div
                    className="u-main-font--small u-color-charcoal u-pt--16 u-pb--16"
                    style={{ fontSize: "14px" }}
                  >
                    Delete account and all associated data.
                  </div>
                  <button
                    className="u-background--magenta u-br--small  u-main-font--medium u-bold u-color-white"
                    style={{
                      width: "230px",
                      height: "52px",
                      fontSize: "14px",
                    }}
                    onClick={() => this.setState({ wantsToDelete: true })}
                  >
                    Delete your account
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0<any>(
  connect(mapStateToProps, { getUserData, showModal })(MyDetails)
);
