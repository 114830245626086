import React, { Component, RefObject } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { saveNewCategoryFilterSelection } from "../../../redux/actions/categoryAction";
import { toggleHalfThePriceProducts } from "../../../redux/actions/itemActions";
import { hideModal, showModal } from "../../../redux/actions/modalActions";
import { saveNewSearch } from "../../../redux/actions/searchActions";
import { searchHasValue } from "../../../utils/searchFunctions";

import axios from "axios";
import variables from "../../../styles/utils/variables.scss";
import { resetCss } from "../../../utils/modal";
import { Icon } from "../../icons/Icon";
import SearchSuggestionsPopup from "../../popups/SearchSuggestionsPopup";

interface Props {
  search: SearchState;
  categories: CategoryState;
  isHalfThePriceClicked?: boolean;
  saveNewSearch: any;
  saveNewCategoryFilterSelection: any;
  searchInput: RefObject<HTMLInputElement>;
  toggleHalfThePriceProducts: (
    isHalfThePriceClicked: boolean,
    callTheAPI: boolean
  ) => void;
  showModal: any;
  hideModal: any;
}

interface State {
  userHasSearched: boolean;
  items: { id: string; name: string }[];
  topMargin: number;
  bannerHeight: number;
}

class SearchProducts extends Component<Props, State> {
  state = {
    userHasSearched: false,
    items: [],
    topMargin: 242,
    bannerHeight: 0,
  };

  suggestionsRef: any = React.createRef();

  componentDidMount = () => {
    document.addEventListener("click", this.handleClickOutside, false);
    window.addEventListener("scroll", this.onScroll);
  };

  componentDidUpdate = (prevProps: Props) => {
    // if user searches, reset to display component
    if (this.state.userHasSearched) {
      this.setState({ userHasSearched: false });
    }

    if (
      (prevProps.categories.selectedNode !==
        this.props.categories.selectedNode &&
        this.props.searchInput.current) ||
      (this.props.isHalfThePriceClicked !== prevProps.isHalfThePriceClicked &&
        this.props.searchInput.current)
    ) {
      this.props.searchInput.current.value = "";
    }

    if (
      prevProps.search.searchTerm !== this.props.search.searchTerm &&
      this.props.searchInput.current
    ) {
      this.props.searchInput.current.value = this.props.search.searchTerm;
    }

    document.removeEventListener("click", this.handleClickOutside, false);
  };

  onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const characterCode = e.charCode || e.keyCode || e.key;
    // if user presses enter
    if (characterCode === 13) {
      this.submitSearchTerm();
      document.body.style.overflow = "auto";
    }

    // if user presses escape
    if (characterCode === 27) {
      this.clearSuggestions();
    }
  };

  onScroll = () => {
    this.handleOnScroll();
  };

  submitSearchTerm = async (query?: string) => {
    let value = query ? query : this.props.searchInput?.current?.value;

    await this.props.saveNewSearch(value);

    await this.props.toggleHalfThePriceProducts(false, false);

    if (!searchHasValue(this.props.search)) {
      await this.props.saveNewCategoryFilterSelection(null);
    }

    this.setState({ userHasSearched: true });

    this.clearSuggestions();
  };

  handleOnSearch = async (e: any) => {
    const characterCode = e.charCode || e.keyCode || e.key;
    // if user presses escape
    if (characterCode === 27) {
      return this.clearSuggestions();
    }
    // if user presses input except enter
    if (characterCode !== 13) {
      const query = this.props.searchInput?.current?.value;

      if (query?.length === 0) return;

      this.searchApi(query);

      this.handleOnScroll();
    }
  };

  handleOnScroll = () => {
    const pixelsScrolled =
      document.body.scrollTop || document.documentElement.scrollTop;

    const headerHeight =
      document.getElementById("search-navbar")?.getBoundingClientRect()
        .height || 0;

    const bannerHeight =
      document.getElementById("banner")?.getBoundingClientRect().height || 0;

    if (pixelsScrolled > headerHeight) {
      this.setState({
        topMargin: 242 - headerHeight,
      });
    } else {
      this.setState({ topMargin: 242 + bannerHeight - pixelsScrolled });
    }
  };

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.suggestionsRef.current &&
      !this.suggestionsRef.current.contains(event.target)
    ) {
      this.clearSuggestions();
    }
  };

  clearSuggestions = () => {
    this.props.hideModal();
    this.setState({ items: [] });
    resetCss();
  };

  debounce = (func: (query: string) => {}, timeout = 500) => {
    let timer: any;

    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  };

  searchApi = this.debounce(async (query: string) => {
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + `/suggestions?q=${query}`
    );
    if (response.data.length > 0) {
      this.setState({ items: response.data });

      this.openSuggestionModal();
    }
  });

  openSuggestionModal = () => {
    const modalSearchSuggestions = () => (
      <SearchSuggestionsPopup items={this.state.items} />
    );

    const top = this.state.topMargin + this.state.bannerHeight;
    this.props.showModal({
      component: modalSearchSuggestions,
      style: {
        top: top + "px",
      },
    });

    document.body.style.overflow = "hidden";
  };

  render() {
    let fill = variables.violetColor;

    if (window.innerWidth <= 600) {
      fill = "#fff";
    }
    if (this.state.userHasSearched) {
      if (searchHasValue(this.props.search)) {
        return <Redirect to={"/browse?s=" + this.props.search.searchTerm} />;
      }
      return <Redirect to="/browse" />;
    }
    return (
      <div className="u-w-all">
        <div className="u-flex u-align-center u-h-all">
          <button onClick={() => this.submitSearchTerm()}>
            <Icon id="search" size="l" fill={fill} />
          </button>
          <input
            defaultValue={this.props.search.searchTerm}
            type="text"
            placeholder="Search for products"
            className="u-main-font--vsmall u-w-all u-semi-bold"
            style={{ paddingLeft: "13px", border: "none", fontSize: "14px" }} // to align with the icon
            ref={this.props.searchInput}
            onKeyUp={(e) => this.handleOnSearch(e)}
            onKeyDown={(e) => this.onKeyPress(e)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  search: state.search,
  categories: state.categories,
  isHalfThePriceClicked: state.items.isHalfThePriceClicked,
  searchInput: React.createRef<HTMLInputElement>(),
});

export default connect(mapStateToProps, {
  saveNewSearch,
  saveNewCategoryFilterSelection,
  toggleHalfThePriceProducts,
  showModal,
  hideModal,
})(SearchProducts);
