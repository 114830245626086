import React, { Component } from "react";
import { connect } from "react-redux";
import { findListAndReplaceBasket } from "../../../redux/actions/basketActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  findListAndReplaceBasket: any;
  hideModal: any;
  list: List;
}

interface State {
  showReplaceListConfirm: boolean;
}

class ReplaceCartWithListModalContentMobile extends Component<Props, State> {
  state = {
    showReplaceListConfirm: false,
  };
  replaceCartWithList = async (list: List) => {
    this.props.findListAndReplaceBasket(list.id);

    this.props.hideModal();
  };

  render() {
    const list = this.props.list;

    return (
      <div className="u-border--bottom--darkgrey">
        {this.state.showReplaceListConfirm ? (
          <div className="u-background--grey u-w-all u-p--24 u-main-font--small ">
            <div className="u-color-magenta u-bold">Add list to cart</div>
            <div className="u-pv--12">
              Replace your cart with{" "}
              <span className="u-bold">"{list.title}"</span>?
            </div>
            <button
              className="u-background--magenta u-color-white u-ph--16 u-pv--8 u-br--small"
              onClick={() => this.replaceCartWithList(list)}
            >
              Replace
            </button>
          </div>
        ) : (
          <button
            className="u-p--24  u-main-font--small u-semi-bold u-w-all u-text-left"
            onClick={() => this.setState({ showReplaceListConfirm: true })}
          >
            Replace your cart with this list
          </button>
        )}
      </div>
    );
  }
}

export default connect(null, { findListAndReplaceBasket, hideModal })(
  ReplaceCartWithListModalContentMobile
);
