import { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { routes } from "../../utils/routes";

import { track } from "../../clients/analytics/mixpanel";
import BasketCounter from "../buttons/BasketCounter";
import { Icon } from "../icons/Icon";
import BasketView from "../views/BasketView";

interface Props {
  showModal: any;
  showBackground: boolean;
  showModalCart?: boolean;
  basket: BasketState;
}

interface State {
  showReturnToCartButton: boolean;
}

class BasketSummary extends Component<Props, State> {
  state = {
    showReturnToCartButton: false,
  };

  openBasketModal = () => {
    if(!process.env.REACT_APP_DEV_MODE) {
      track("open_basket_view", {
        countOfItems: this.props.basket?.basketItems?.length || 0,
      });
    }
    const modalContent = () => <BasketView />;

    this.props.showModal({ component: modalContent });
  };

  calculateSavingsFromBasket = () => {
    const { woolworthsOnlyTotal, colesOnlyTotal, grocerizeTotal } =
      this.props.basket?.basketMetaData;

    let totalSavings = grocerizeTotal
      ? Math.max(woolworthsOnlyTotal, colesOnlyTotal) - grocerizeTotal
      : 0;

    if (totalSavings < 0) {
      totalSavings = 0;
    }

    return totalSavings.toFixed(2);
  };

  render() {
    if (this.state.showReturnToCartButton) {
      return (
        <Link to={routes.browse}>
          <button
            className={
              "u-pl--8 u-h-all u-flex u-justify-flex-end" +
              (this.props.showBackground ? " u-background--magenta" : "")
            }
          >
            <div className="u-flex  u-align-center ">
              <Icon
                id="chevron_left"
                size="m"
                fill="transparent"
                className="u-rotate--90deg"
              />
              <div className="u-text-oneline u-color-white u-main-font--small u-semi-bold">
                Return to shopping
              </div>

              <BasketCounter />
            </div>
          </button>
        </Link>
      );
    }
    return (
      <button
        className={
          "u-br--left--medium u-h--webkit-fill" +
          (this.props.showModalCart ? " u-pr--24--desktop " : " ") +
          " u-w-180px--desktop" +
          (this.props.showBackground ? " u-background--magenta" : "")
        }
        onClick={() => this.openBasketModal()}
      >
        <div
          className="u-flex u-justify-between u-color-white u-align-center u-h-all u-pv--8 u-ph--16--desktop"
          id="basket-summary"
        >
          <div
            className={
              "o-basket-summary-container u-color-white u-flex u-flex-column u-align-center"
            }
            style={{ lineHeight: "8px" }}
          >
            <div className="u-text-oneline">up to</div>
            <div className="u-main-font--small u-text-oneline">
              $
              <span className="u-bold">
                {this.calculateSavingsFromBasket()}
              </span>
            </div>
            <div className="u-text-oneline">in savings</div>
          </div>
          <BasketCounter />
        </div>
      </button>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});
export default connect(mapStateToProps, { showModal })(BasketSummary);
