import React, { Component } from "react";
import { Icon } from "../../icons/Icon";

import { showModal } from "../../../redux/actions/modalActions";
import { connect } from "react-redux";
import { changeSort } from "../../../redux/actions/basketActions";

interface Props {
  changeSort?: any;
}

interface State {
  isExpanded: boolean;
  selectedSort: number;
}

class SortDropdownButtonCart extends Component<Props, State> {
  state = {
    isExpanded: false,
    selectedSort: 1,
  };

  componentDidMount() {
    // monitor click if sort items is expanded to fade in the background
    this.fadeInBackground();
    window.addEventListener("click", this.fadeInBackground);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.fadeInBackground);
  }

  //Fade the background once sort items is expanded
  fadeInBackground = () => {
    const isSortExpanded = this.state.isExpanded;
    const basketItemsElement = document.getElementById("basket-items");

    if (isSortExpanded) {
      basketItemsElement!.className +=
        " u-background--fadein u-z-index--0 u-opacity--point5";
    } else {
      basketItemsElement!.className = " ";
    }
  };

  updateSelectedBasketFilter = (id: number) => {
    this.props.changeSort(id);
    this.setState({ selectedSort: id, isExpanded: false });
  };

  render() {
    const basketSortTypes = [
      { id: 1, name: "Order added" },
      { id: 2, name: "Name (A-Z)" },
      { id: 3, name: "Name (Z-A)" },
      { id: 4, name: "Price (High to Low)" },
      { id: 5, name: "Price (Low to High)" },
    ];

    if (this.state.isExpanded) {
      return (
        <div className="u-border--bottom--darkgrey">
          <button
            id="basket-item-sort-dropdown"
            className="u-flex u-align-center u-justify-left u-pl--28 u-background--grey u-w-all "
            style={{
              borderRadius: "2px",
              height: "66px",
            }}
            onClick={() => this.setState({ isExpanded: false })}
          >
            <Icon id="chevron_up_down" size="s" />
            <div
              className="u-main-font--vsmall u-color-charcoal u-text-oneline u-ml--16 u-semi-bold"
              style={{ fontSize: "14px" }}
            >
              Sort by{" "}
              <span className="u-semi-bold">
                {basketSortTypes[this.state.selectedSort - 1].name}
              </span>
            </div>
          </button>
          <div
            className="u-absolute u-background--white u-w-all u-justify-left"
            style={{ zIndex: 4 }}
          >
            {basketSortTypes.map((type, index) => {
              return (
                <button
                  key={index}
                  className={
                    "u-w-all u-text-left u-main-font--small u-semi-bold u-border--bottom--grey u-pl--60 " +
                    (this.state.selectedSort === type.id
                      ? " u-color-magenta"
                      : " ")
                  }
                  style={{
                    fontSize: "14px",
                    height: "45px",
                    backgroundColor: "",
                  }}
                  onClick={() => this.updateSelectedBasketFilter(type.id)}
                >
                  {type.name}
                </button>
              );
            })}
          </div>
        </div>
      );
    }

    return (
      <button
        id="basket-item-sort-dropdown"
        key={1}
        className="u-flex u-align-center u-justify-left u-w-all u-pl--28"
        data-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
        style={{
          borderRadius: "2px",
          height: "66px",
        }}
        onClick={() => this.setState({ isExpanded: true })}
      >
        <Icon id="chevron_up_down" size="s" />
        <div
          className="u-main-font--vsmall u-color-charcoal u-text-oneline u-ml--16 u-semi-bold"
          style={{ fontSize: "14px" }}
        >
          Sort by{" "}
          <span className="u-bold">
            {basketSortTypes[this.state.selectedSort - 1].name}
          </span>
        </div>
      </button>
    );
  }
}

export default connect(null, { showModal, changeSort })(SortDropdownButtonCart);
