import { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "../icons/Icon";

interface Props {
  listUrlAddress: string;
  basket?: BasketState;
}

interface State {}

class GrocerizeShareListModalMobile extends Component<Props, State> {
  render() {
    return (
      <div className="o-modal__container ">
        <div className="u-w-all o-modal--content-bottom">
          <div className="u-background--magenta u-color-white u-br--top--medium u-p--32 u-flex u-justify-center ">
            <div className="">
              <div className="u-flex">
                <div className="u-pr--32">
                  <Icon id="celebrate" size="xxxl" />
                </div>
                <div className="u-pv--24">
                  <div className="u-bold u-main-font--medium ">Congrats!</div>
                  <div>You just saved up to</div>
                  <div className="u-bold u-main-font--medium">
                    $
                    {
                      this.props?.basket?.basketMetaData
                        .totalSavingsUsingGrocerize
                    }
                    !
                  </div>
                </div>
              </div>
              {/* <div className="u-flex">
                <SimpleSharingButton type="instagram" />
                <SimpleSharingButton type="facebook" />
              </div> */}
            </div>
          </div>
          <a
            href={
              process.env.REACT_APP_FRONTEND_URL +
              "/lists/" +
              this.props.listUrlAddress
            }
            target="_blank"
            rel="noreferrer"
            className="u-background--green u-flex u-justify-center "
          >
            <div className="u-color-white u-p--24 u-bold u-main-font--medium">
              Open list in new tab
            </div>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});
export default connect(mapStateToProps, null)(GrocerizeShareListModalMobile);
