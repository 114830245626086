import React, { Component } from "react";
import { connect } from "react-redux";
import { httpClient } from "../../../clients/auth/addTokenToHTTPRequests";
import { getLists } from "../../../redux/actions/listActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  list: List;
  getLists: any;
  hideModal: any;
}

interface State {
  showRename: boolean;
  title: string;
}

class RenameListModalContentMobile extends Component<Props, State> {
  state = {
    showRename: false,
    title: "",
  };
  showRenameList = async () => {
    this.setState({ showRename: true });
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.currentTarget.value });
  };

  saveTitleChanged = async () => {
    await httpClient.instance
      .patch("/user/lists", {
        list_id: this.props.list.id,
        title: this.state.title,
      })
      .then(async () => {
        await this.props.getLists();
        await this.props.hideModal();
      });
  };

  render() {
    const list = this.props.list;
    return (
      <div className="u-border--bottom--darkgrey o-modal__container">
        {this.state.showRename ? (
          <div className="u-background--grey u-w-all u-p--24 u-main-font--small ">
            <div className="u-color-magenta u-bold">Rename list</div>
            <div className="u-pv--12">
              Rename <span className="u-bold">"{list.title}"</span>?
            </div>
            <div className="u-flex u-justify-between u-background--white u-p--12">
              <input
                autoFocus
                className=""
                placeholder={"List name?"}
                value={this.state.title}
                type="text"
                onChange={(e) => this.searchKeyStrokeChange(e)}
              />
              <button
                className="u-background--magenta u-color-white u-ph--16 u-pv--8 u-br--small"
                onClick={() => this.saveTitleChanged()}
              >
                Save
              </button>
            </div>
          </div>
        ) : (
          <button
            className="u-p--24  u-main-font--small u-semi-bold u-w-all u-text-left"
            onClick={() => this.showRenameList()}
          >
            Rename list
          </button>
        )}
      </div>
    );
  }
}

export default connect(null, { getLists, hideModal })(
  RenameListModalContentMobile
);
