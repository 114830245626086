import React, { Component } from "react";
import { IndexView } from "../views";

class IndexController extends Component {
  render() {
    return <IndexView />;
  }
}

export default IndexController;
