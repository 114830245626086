import React, { Component } from "react";

class ItemCardMobileLoading extends Component<{}, {}> {
  getVendorNameFromId = (id: number) => {
    if (id === 1) {
      return "Coles";
    } else {
      return "Woolworths";
    }
  };

  render() {
    return (
      <div
        className="u-background--white u-br--small u-relative u-border--darkgrey"
        style={{
          width: "360px",
          height: "267px",
        }}
      >
        <div className=" u-ph--24 u-pv--8">
          <div className="u-m0auto u-w-all  u-text-unselectable u-flex u-align-center">
            {/* Image */}
            <div
              className="u-glint--animation u-mh--24"
              style={{ height: "88px", width: "88px" }}
            />
            {/* Title */}
            <div
              className="u-color-charcoal u-main-font--small u-bold u-text-unselectable "
              style={{
                height: "70px",
                width: "150px",
                lineHeight: "18px",
                fontSize: "14px",
              }}
            >
              <div
                className="u-glint--animation"
                style={{
                  height: "18px",
                  width: "100%",
                  marginTop: "6px",
                }}
              ></div>
              <div
                className="u-glint--animation"
                style={{
                  height: "18px",
                  width: "100%",
                  marginTop: "6px",
                }}
              ></div>
            </div>
          </div>
          {/* Comparisons */}
          <div>
            <div className="u-flex">
              <div className="u-w-all">
                <div
                  className="u-glint--animation"
                  style={{
                    height: "18px",
                    width: "100%",

                    marginTop: "6px",
                  }}
                ></div>

                <div
                  className="u-glint--animation"
                  style={{
                    height: "22px",
                    width: "100%",
                    marginTop: "6px",
                  }}
                ></div>

                <div
                  className="u-glint--animation"
                  style={{
                    height: "18px",
                    width: "100%",

                    marginTop: "6px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {/* button */}
        <div className="u-flex u-justify-center">
          <div
            className="u-glint--animation "
            style={{
              height: "50px",
              width: "85%",
              marginTop: "24px",
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default ItemCardMobileLoading;
