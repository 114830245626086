import React, { Component } from "react";

class BasketItemCardLoading extends Component<{}, {}> {
  render() {
    return (
      <div
        className="u-background--white u-w-all u-border--bottom--darkgrey "
        style={{ height: "200px" }}
      >
        <div className="u-flex  u-mt--32 u-ml--48  u-mr--32  u-align-center">
          <div
            className=" u-mr--32 u-object-fit--cover u-glint--animation u-br--all"
            style={{ width: "75px", height: "75px" }}
          />
        </div>
        <div className="u-flex u-mt--24 u-ph--20 u-align-center">
          <div
            className="u-glint--animation"
            style={{
              width: "138px",
              height: "58px",
            }}
          ></div>
          <div
            className="u-flex u-justify-between u-mh--8"
            style={{ height: "91px", width: "252px" }}
          >
            <div
              className="u-glint--animation"
              style={{ width: "125px", height: "91px" }}
            ></div>
            <div
              className="u-glint--animation"
              style={{ width: "125px", height: "91px" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasketItemCardLoading;
