import { Component } from "react";
import { Icon } from "../../icons/Icon";

import { connect } from "react-redux";
import { saveNewCategoryFilterSelection } from "../../../redux/actions/categoryAction";
import { hideModal } from "../../../redux/actions/modalActions";
import HalfThePrice from "../../buttons/HalfThePrice";
import {
  getListOfNextCategories,
  getParentOfNode,
  removeLastCatOfURL,
} from "./itemNavigationFunctions";

import variables from "../../../styles/utils/variables.scss";
interface Props {
  topLevelCategoriesArray: CategoryTreeNode[];
  hideModal: any;
  categories: CategoryState;
  saveNewCategoryFilterSelection: any;
}

interface State {
  selectedId: number | null;
  showOptions: boolean;
  displayedCategories: CategoryTreeNode[];
  backNewLabel: string;
  hasChildren: boolean;
  arrTopLevelCatNames: string[];
}

class CategoryNavMobile extends Component<Props, State> {
  state = {
    selectedId: null,
    showOptions: false,
    displayedCategories: [],
    backNewLabel: "",
    hasChildren: false,
    arrTopLevelCatNames: [],
  };

  componentDidMount = () => {
    //Get the list of names of the top level categories
    if (
      this.props.topLevelCategoriesArray.length !==
      this.state.arrTopLevelCatNames.length
    ) {
      this.props.topLevelCategoriesArray.map((cat: CategoryTreeNode) => {
        this.setState((state) => ({
          arrTopLevelCatNames: [...state.arrTopLevelCatNames, cat.name],
        }));
      });
    }

    this.updateDisplayedCategories();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.categories?.categoryTree !==
        prevProps.categories?.categoryTree ||
      this.props.categories?.selectedNode !== prevProps.categories?.selectedNode
    ) {
      this.updateDisplayedCategories();
    }
  };

  updateDisplayedCategories = () => {
    // Can only update if the tree has loaded
    if (this.props.categories.categoryTree) {
      // find the next level of category nodes to be displayed
      const nextLevel = getListOfNextCategories(
        this.props.categories.categoryTree,
        this.props.categories.selectedNode
      );

      // render the next level of nodes
      if (nextLevel !== undefined) {
        this.setState({ displayedCategories: nextLevel });
      } else {
        // get the parent of the selected
        const parent = getParentOfNode(
          this.props.categories.categoryTree,
          this.props.categories.selectedNode
        );

        // if it has children show them
        if (parent?.children) {
          this.setState({
            displayedCategories: parent?.children,
          });
        } else {
          // just show the tree
          this.setState({
            displayedCategories: this.props.categories.categoryTree,
          });
        }
      }
    }
  };

  stylingOfOption = (catNode: CategoryTreeNode) => {
    let styling =
      "u-w-all u-flex u-justify-between u-ph--20 u-pv--16 u-border--bottom--grey";

    if (
      catNode.id === this.props.categories.selectedNode?.id &&
      catNode.name === this.props.categories.selectedNode.name &&
      !this.state.hasChildren
    ) {
      styling += " u-color-magenta";
    } else {
      styling += " u-color-charcoal";
    }

    return styling;
  };

  clickOfCategory = (catNode: CategoryTreeNode) => {
    let holdChildren = Object.assign([], catNode.children);
    let showAllParentCat = Object.assign([], catNode);

    if (
      catNode.children?.length &&
      catNode.children?.length > 0 &&
      !catNode.children[0].name.includes("Show All")
    ) {
      showAllParentCat.children = [];
      showAllParentCat.name = "Show All " + catNode.name;
      holdChildren.unshift(showAllParentCat);
      catNode.children = holdChildren;
    }

    //Change the Back label according to categoy name if it has children
    if (catNode.children?.length && catNode.children?.length > 0) {
      this.setState({ backNewLabel: catNode.name });
    }
    let hasChildren =
      catNode.children?.length && catNode.children?.length > 0 ? true : false;
    this.setState({ hasChildren });
    // if already has been clicked
    if (this.state.selectedId === catNode.id) {
      this.setState({ selectedId: 0 });
    } else {
      if (!hasChildren) {
        this.setState({ selectedId: 0 });
      } else {
        this.setState({ selectedId: catNode.id });
      }
    }

    // add the category node id to redux as the selected node
    this.props.saveNewCategoryFilterSelection(catNode);

    // then close modal
    if (!hasChildren) {
      this.props.hideModal();
    }
  };

  moveBackUpCatTree = () => {
    // find the paarent
    let parent = getParentOfNode(
      this.props.categories.categoryTree,
      this.props.categories.selectedNode
    );
    // update redux's selected node
    this.props.saveNewCategoryFilterSelection(parent);

    // if parent
    if (parent) {
      this.setState({ backNewLabel: parent.name });
      // if the parent has children lets render then (otherwise keep rendering what we are)
      if (parent?.children) {
        // set that parent as the visible
        this.setState({
          displayedCategories: parent?.children,
        });
      }
    } else {
      // if there is no found parent we want to show the nodes with no parents
      this.setState({
        displayedCategories: this.props.categories.categoryTree,
      });
      this.setState({ backNewLabel: "" });
    }

    if (this.state.backNewLabel === "") {
      this.resetTreeHome();
    }

    // edit the url
    removeLastCatOfURL();
  };

  resetTreeHome = () => {
    this.props.saveNewCategoryFilterSelection(null);

    this.props.hideModal();
  };

  render() {
    const violet = variables.violetColor;

    return (
      <div className="u-background--white u-h-all u-w-all u-fixed">
        <div className="u-flex u-justify-between">
          <button
            className="u-ph--20 u-pv--20 u-flex u-align-center"
            onClick={() => this.moveBackUpCatTree()}
          >
            <Icon
              id="chevron_left"
              fill="none"
              stroke={violet}
              className="u-rotate--90deg"
            />
            <div className="u-main-font--small u-bold u-flex u-align-center u-color-magenta u-semi-bold">
              {this.state.backNewLabel ? this.state.backNewLabel : "Back"}
            </div>
          </button>
          <button
            className="u-ph--20 u-pv--12"
            onClick={() => this.resetTreeHome()}
          >
            <Icon id="house" size="m" fill={violet} />
          </button>
        </div>

        <div
          className="u-overflow-scroll--mobile"
          style={{ maxHeight: `calc(100vh - 174px)` }}
        >
          <HalfThePrice isForMobile={true} />

          {this.state.displayedCategories.map((cat: CategoryTreeNode) => {
            return (
              <div
                className={this.stylingOfOption(cat)}
                onClick={() => this.clickOfCategory(cat)}
                key={"top-level-cat" + cat.id}
              >
                <div
                  className="u-main-font--small u-bold u-flex u-align-center"
                  style={
                    cat.name.includes("Show All")
                      ? { fontWeight: 750 }
                      : { fontWeight: 600 }
                  }
                >
                  {cat.name}
                </div>
                {cat.children?.length && cat.children.length > 0 ? (
                  <Icon
                    id="chevron_right"
                    fill="none"
                    className="u-rotate--negative90deg u-stroke--magenta"
                  />
                ) : cat.name.includes("Show All ") ? (
                  ""
                ) : (
                  <Icon
                    id="grid"
                    fill="none"
                    size="s"
                    className=" u-stroke--magenta"
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  categories: state.categories,
});

export default connect(mapStateToProps, {
  hideModal,
  saveNewCategoryFilterSelection,
})(CategoryNavMobile);
