import React, { Component } from "react";
import { connect } from "react-redux";
import Logo from "../naviagtions/Logo";

interface Props {
  basket?: BasketState;
}

class GrocerizeShoppingSplits extends Component<Props, {}> {
  render() {
    return (
      <div className="u-flex u-w-all--mobile u-ph--8 u-pr--20--mobile">
        {/* <GrocerizeShoppingSavingsRectangle /> */}

        <div
          id="grocerize-shopping-split"
          className="u-background--magenta u-br--small u-flex u-align-center u-color-white u-ph--24 u-pv--12 u-mr--8 u-hide--mobile"
          style={{ width: "195px", height: "76px" }}
        >
          <div style={{ width: "32px" }}>
            <Logo type="short" />
          </div>
          <div className="u-text-right u-w-all">
            <div>Your Savings</div>
            <div className="u-bold u-pt--8" style={{ fontSize: "18px" }}>
              $
              {this.props.basket?.basketMetaData.totalSavingsUsingGrocerize.toFixed(
                2
              )}
            </div>
          </div>
        </div>
        {/* <BasketSummaryRectangle /> */}
        <div className="u-mr--8">
          <div
            className="u-background--darkgrey u-br--small u-main-font--small u-p--16 u-ph--16--desktop u-pt--12 u-text-center--mobile u-w-200px--desktop "
            style={{ fontSize: "14px", height: "75px" }}
          >
            <div
              className="u-color-grey u-semi-bold"
              style={{ color: "#32323250" }}
            >
              Total
            </div>

            <div className="u-w-all u-flex u-justify-between u-pt--8 u-color-charcoal u-align-baseline">
              <div className="u-semi-bold u-hide--mobile">Cost</div>
              <div className="u-bold u-main-font--medium">
                $
                {(
                  (this.props.basket?.basketMetaData.colesTotalUsingGrocerize ||
                    0) +
                  (this.props.basket?.basketMetaData.WoolsTotalUsingGrocerize ||
                    0)
                ).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="u-background--darkgrey u-br--small u-mt--4 u-ph--16 u-pv--12 ">
            <div className="u-main-font--small u-semi-bold u-color-charcoal u-flex u-w-all u-justify-between u-align-center  ">
              <div className="u-hide--mobile" style={{ fontSize: "14px" }}>
                Items
              </div>
              <div className="u-text-right u-text-center--mobile u-w-all u-main-font--small">
                {(this.props.basket?.basketMetaData.countOfColesItems || 0) +
                  (this.props.basket?.basketMetaData.countOfWoolsItems || 0)}
              </div>
            </div>
          </div>
        </div>
        {/* <ColesSummaryRectangle */}
        <div className="u-w-all--mobile">
          <div
            className="u-background--white u-br--small u-main-font--small u-ph--20 u-pt--8 u-mr--8 u-text-center--mobile u-w-175px--desktop "
            style={{ fontSize: "14px", height: "75px" }}
          >
            <div className="u-semi-bold u-color-coles">Coles</div>

            <div className="u-w-all u-pt--12 u-color-charcoal">
              <div className="u-bold u-main-font--medium">
                $
                {this.props.basket?.basketMetaData.colesTotalUsingGrocerize.toFixed(
                  2
                )}
              </div>
            </div>
          </div>
          <div className="u-background--white u-br--small u-mt--4 u-ph--20 u-pv--12 u-mr--8">
            <div
              className="u-main-font--small u-semi-bold u-color-charcoal u-flex u-w-all u-justify-between u-align-center"
              style={{ fontSize: "14px" }}
            >
              <div className="u-text-right u-text-center--mobile u-w-all u-flex u-justify-between u-main-font--small">
                <div className="u-hide--desktop u-semi-bold">Items</div>
                {this.props.basket?.basketMetaData.countOfColesItems}
              </div>
            </div>
          </div>
        </div>
        {/* <WoolworthsSummaryRectangle */}
        <div className="u-w-all--mobile">
          <div
            className="u-background--white u-br--small u-main-font--small u-ph--20 u-pt--8 u-text-center--mobile u-w-175px--desktop "
            style={{ fontSize: "14px", height: "75px" }}
          >
            <div className="u-semi-bold u-color-woolworths">Woolworths</div>

            <div className="u-w-all u-pt--12 u-color-charcoal">
              <div className="u-bold u-main-font--medium">
                $
                {this.props.basket?.basketMetaData.WoolsTotalUsingGrocerize.toFixed(
                  2
                )}
              </div>
            </div>
          </div>
          <div className="u-background--white u-br--small u-mt--4 u-ph--20 u-pv--12">
            <div
              className="u-main-font--small u-semi-bold u-color-charcoal u-flex u-w-all u-justify-between u-align-center"
              style={{ fontSize: "14px" }}
            >
              <div className="u-text-right u-text-center--mobile u-w-all u-flex u-justify-between u-main-font--small">
                <div className="u-hide--desktop u-semi-bold">Items</div>
                {this.props.basket?.basketMetaData.countOfWoolsItems}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, null)(GrocerizeShoppingSplits);
