import React, { Component } from "react";
import BasketItemCard from "./BasketItemCard";
import BasketItemCardLoading from "./BasketItemCardLoading";
import { connect } from "react-redux";

interface Props {
  basket?: BasketState;
}
interface State {
  isLoading: boolean;
  availableHeightOfItemsGrid: number;
  basketItems: ItemData[];
  footerHeight: number;
}

class BasketItemsGrid extends Component<Props, State> {
  state = {
    isLoading: false,
    availableHeightOfItemsGrid: 0,
    basketItems: [],
    footerHeight: 0,
  };
  componentDidMount() {
    // monitor resize of window for the height of the cart
    this.calcHeights();
    window.addEventListener("resize", this.calcHeights);

    // add basket items from redux
    this.setState({ basketItems: this.props.basket?.basketItems || [] });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.calcHeights);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.basket !== this.props.basket) {
      if (prevProps.basket?.basketItems !== this.props.basket?.basketItems) {
        this.setState({ basketItems: this.props.basket?.basketItems || [] });
      }
    }
  };

  calcHeights = () => {
    const sortBarHeight =
      document
        .getElementById("basket-item-sort-dropdown")
        ?.getBoundingClientRect().bottom || 132;
    const footerHeight =
      document.getElementById("basket-footer")?.offsetHeight || 1280;

    this.setState({
      availableHeightOfItemsGrid: window.innerHeight - sortBarHeight,
      footerHeight,
    });
  };

  render() {
    if (this.state.isLoading) {
      return <BasketItemCardLoading />;
    }

    return (
      <div
        className="u-h-all u-background--white"
        style={{
          height: this.state.availableHeightOfItemsGrid,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          paddingBottom: this.state.footerHeight,
        }}
      >
        <div style={{ overflowY: "auto" }}>
          {this.state.basketItems.length ? (
            this.state.basketItems?.map((item, index) => {
              return <BasketItemCard itemData={item} key={index} />;
            })
          ) : (
            <div className="u-m--24 u-main-font--small u-color-magenta u-semi-bold u-flex u-justify-center">
              Your basket is empty.
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, null)(BasketItemsGrid);
