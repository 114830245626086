import React, { Component } from "react";

interface Props {
  title: string;
  body: string;
}

interface State {
  isClicked: boolean;
}

class FAQAccordian extends Component<Props, State> {
  state = {
    isClicked: false,
  };
  render() {
    if (this.state.isClicked) {
      return (
        <div
          className="u-background--white u-border--magenta u-main-font--medium u-ph--48 u-pv--20 u-mv--12"
          onClick={() => this.setState({ isClicked: false })}
        >
          <div className="u-bold" style={{ fontSize: 14 }}>
            {this.props.title}
          </div>
          <div className="u-pt--12" style={{ fontSize: 14 }}>
            {this.props.body}
          </div>
        </div>
      );
    }
    return (
      <div
        className=" u-background--grey u-bold u-main-font--medium u-ph--48 u-pv--20 u-mv--12"
        onClick={() => this.setState({ isClicked: true })}
        style={{ fontSize: 14 }}
      >
        {this.props.title}
      </div>
    );
  }
}

export default FAQAccordian;
