import {
  GET_USER_FAVOURITES,
  DELETE_USER_FAVOURITE,
  SORT_FAVS_AZ,
  SORT_FAVS_ZA,
  SORT_FAVS_BY_DATE,
} from "../enums";
import { Dispatch } from "react";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";

export const getUserFavourites = () => async (dispatch: Dispatch<any>) => {
  return await httpClient.instance
    .get("/user/favourites")
    .then((res) => {
      dispatch({
        type: GET_USER_FAVOURITES,
        favourites: res.data,
      });
    })
    .catch((e) => {
      // if error save favs as empty
      console.log(e);
      dispatch({
        type: GET_USER_FAVOURITES,
        favourites: [],
      });
    });
};

export const addToUserFavourite =
  (itemId: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .post("/user/favourites/", {
        item_id: itemId,
      })
      .then(async (res) => {
        // this doesnt update it
        dispatch(getUserFavourites());
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const deleteUserFavourite =
  (itemId: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .delete("/user/favourites/" + itemId)
      .then((res) => {
        dispatch({
          type: DELETE_USER_FAVOURITE,
          deletedId: itemId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const sortUserFavourite = (type: string) => {
  return {
    type:
      type === "A-Z"
        ? SORT_FAVS_AZ
        : type === "Z-A"
        ? SORT_FAVS_ZA
        : SORT_FAVS_BY_DATE,
  };
};
