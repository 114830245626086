import { Component } from "react";
import { Icon } from "../../icons/Icon";

import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { saveNewCategoryNavigationSelection } from "../../../redux/actions/categoryAction";
import {
  removeFilter,
  toggleHalfThePriceProducts,
  toggleUnavailableProducts,
} from "../../../redux/actions/itemActions";
import { hideModal, showModal } from "../../../redux/actions/modalActions";
import { saveNewSearch } from "../../../redux/actions/searchActions";
import HalfThePrice from "../../buttons/HalfThePrice";
import Logo from "../Logo";
import NavBar from "../NavBar";
import CategoryNavigationMobile from "./CategoryNavigationMobile";

interface Props extends RouteComponentProps {
  showModal: any;
  hideModal: any;
  isFixed?: boolean;
  categories?: CategoryState;
  removeFilter: any;
  saveNewCategoryNavigationSelection: any;
  saveNewSearch: any;
  isCategoryClick?: boolean;
  toggleUnavailableProducts: (isUnavailableProductsHidden: boolean) => void;
  toggleHalfThePriceProducts: (
    isHalfThePriceClicked: boolean,
    callTheAPI: boolean
  ) => void;
  isHalfThePriceClicked?: boolean;
}

interface State {
  windowWidth: number;
  fixedToTop: boolean;
  topLevelCategoriesArray: CategoryTreeNode[];
  isExpanded: boolean;
}

class CategoriesNavigation extends Component<Props, State> {
  state = {
    windowWidth: 0,
    fixedToTop: false,
    topLevelCategoriesArray: [],
    isHalfThePriceClicked: false,
    isExpanded: false,
  };

  componentDidMount() {
    // get window screen width and height and set it for the components
    this.setScreenWidth();

    // track if the user rotates or changes
    window.addEventListener("resize", this.setScreenWidth);

    // get the parent categories from API
    this.getCatsToDisplay();

    if (!this.props.categories?.selectedNode) {
      this.setState({ isExpanded: false });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenWidth);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.categories?.categoryTree !== prevProps.categories?.categoryTree
    ) {
      this.getCatsToDisplay();
    }
  };

  getCatsToDisplay = () => {
    this.setState({
      topLevelCategoriesArray: this.props.categories?.categoryTree || [],
    });
  };

  setScreenWidth = () => {
    const windowWidth = window.innerWidth;
    this.setState({ windowWidth });
  };

  maximumCategoriesToDisplay = () => {
    const screenWidth = this.state.windowWidth - 200; // excluded 100 px for +more button width
    const averageWidthPerLetter = 7.9; // estimated from inspecting element (and includes padding)
    let widthTakenByElements = 150; // init with 50 from paddingleft plus 100 for +more button

    let index = -1;
    // while the width is not exceeded
    while (widthTakenByElements < screenWidth) {
      // increment the index
      index++;

      // if all fit we can stop
      if (index === this.state.topLevelCategoriesArray.length) {
        break;
      }

      const category: CategoryTreeNode =
        this.state.topLevelCategoriesArray[index];

      if (category && category.name) {
        // init as 8px padding left and right
        let estimatedWidthOfElement = 16;

        // add estimated length from text
        estimatedWidthOfElement += category.name.length * averageWidthPerLetter;

        // add estimated element to width taken by all elements
        widthTakenByElements += estimatedWidthOfElement;
      }
    }

    return index;
  };

  stylingOfOption = (cat: CategoryTreeNode, direction: "left" | "bottom") => {
    let styling =
      "u-ph--12 u-pv--24 u-text-oneline u-bold u-color-white u-hoverover--darker-background";

    if (cat.id === this.props.categories?.selectedNode?.id) {
      styling += " u-border--" + direction + "--magenta--heavy";
    }
    return styling;
  };

  clickOfCategory = async (cat: CategoryTreeNode) => {
    if (this.props.location.pathname === "/my/grocerize") {
      this.props.history.push(`/browse/${cat.url_slug}`);
    }

    // save the cat to redux as the selected node
    await this.props.saveNewSearch("");
    await this.props.saveNewCategoryNavigationSelection(cat);

    await this.props.removeFilter();
    await this.props.toggleUnavailableProducts(true);
  };

  clickOfMobileCategoryMenu = () => {
    this.setState({ isExpanded: !this.state.isExpanded });

    if (this.state.isExpanded === false) {
      const modalContent = () => (
        <div className="o-modal__container">
          <NavBar
            secondaryBackgroundClass=" u-background--secondary"
            mainBackgroundClass="u-background--magenta"
          />
          {this.renderMobileHTML(true)}

          <CategoryNavigationMobile
            topLevelCategoriesArray={this.state.topLevelCategoriesArray}
          />
        </div>
      );

      this.props.showModal({ component: modalContent });
    } else {
      this.props.hideModal();
    }
  };

  renderMobileHTML = (isExpanded: boolean) => {
    const selectedNode = this.props.categories?.selectedNode;

    return (
      <div id="mobile-cat-menu">
        <div
          className="u-w-all u-background--secondary  u-ph--32 u-pv--16 u-flex u-justify-between u-align-center"
          onClick={() => this.clickOfMobileCategoryMenu()}
        >
          <div className="u-bold u-main-font--small u-color-white">
            Browse categories
          </div>
          <div className="u-align-center u-flex">
            {isExpanded ? (
              <Icon
                id="chevron_down"
                fill="white"
                className="u-rotate--negative360deg"
              />
            ) : (
              <Icon
                id="chevron_right"
                fill="white"
                className="u-rotate--negative90deg"
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const elementsToRender = this.maximumCategoriesToDisplay();
    //Estimated line height of every list entry as per element check
    const estimatedLineHeight = 18;
    let plusMoreElements =
      this.state.topLevelCategoriesArray.length - elementsToRender;
    //Minimum no. of categories in the +More section before showing the vertical scrollbar
    let minNumOfCategories = 12;
    let estimatedVerticalPadding = 40;
    let hoveredDivHeight =
      plusMoreElements > minNumOfCategories
        ? estimatedVerticalPadding * minNumOfCategories +
          estimatedLineHeight * plusMoreElements
        : estimatedVerticalPadding * plusMoreElements +
          estimatedLineHeight * plusMoreElements;

    if (this.state.windowWidth < 600) {
      return <>{this.renderMobileHTML(false)}</>;
    }

    return (
      <div className="u-w-all u-background--secondary">
        {this.state.topLevelCategoriesArray.length > 0 ? (
          <div
            id="categories"
            className={
              "u-w-all u-flex u-justfiy-between u-main-font--vsmall u-pr--32 " +
              (this.state.fixedToTop ? " u-top--0 u-fixed" : "")
            }
          >
            <div
              id="cat-nav-container"
              className="u-flex u-w-all u-sub-background u-align-center"
              style={{ paddingLeft: this.props.isFixed ? "24px" : "52px" }}
            >
              {this.props.isFixed ? (
                <div className="u-fadein u-flex u-align-center">
                  <Logo type="short" />
                </div>
              ) : null}
              <div className="u-pr--16 u-pl--40">
                <HalfThePrice />
              </div>
              {this.state.topLevelCategoriesArray.map(
                (cat: CategoryTreeNode, index: number) => {
                  if (elementsToRender > index) {
                    return (
                      <div className="" key={index}>
                        <button
                          key={index}
                          id={"top-level-cat" + cat.id}
                          className={this.stylingOfOption(cat, "bottom")}
                          onClick={() => this.clickOfCategory(cat)}
                          style={{ fontSize: "14px" }}
                        >
                          {cat.name}
                        </button>
                      </div>
                    );
                  }
                }
              )}
            </div>

            {
              // if there are hidden elements show them in the more button
              this.state.topLevelCategoriesArray.length ===
              elementsToRender ? null : (
                <div
                  className="u-relative u-color-magenta u-pv--20 u-ph--20 u-text-oneline u-bold u-hoverover--dropdown-menu u-flex u-align-center"
                  style={{ fontSize: "15px" }}
                >
                  + More
                  <div
                    className="u-hoverover--dropdown-menu--content u-absolute u-background--secondary u-right--0"
                    style={{
                      zIndex: 1,
                      top: "64px",
                      height: hoveredDivHeight + "px",
                    }}
                  >
                    <div
                      className="u-relative u-overflow--scroll--thinner"
                      style={{ overflowY: "auto", height: "99%" }}
                    >
                      {this.state.topLevelCategoriesArray.map(
                        (cat: CategoryTreeNode, index: number) => {
                          if (elementsToRender <= index) {
                            return (
                              <div key={index}>
                                <button
                                  id={"top-level-cat" + cat.id}
                                  className={
                                    "u-text-left u-ph--24 u-pv--20 " +
                                    this.stylingOfOption(cat, "left")
                                  }
                                  onClick={() => this.clickOfCategory(cat)}
                                  style={{ fontSize: "14px" }}
                                >
                                  {cat.name}
                                </button>
                              </div>
                            );
                          }
                        }
                      )}
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  categories: state.categories,
  isHalfThePriceClicked: state.items.isHalfThePriceClicked,
});

export default withRouter(
  connect(mapStateToProps, {
    removeFilter,
    showModal,
    saveNewCategoryNavigationSelection,
    saveNewSearch,
    toggleUnavailableProducts,
    toggleHalfThePriceProducts,
    hideModal,
  })(CategoriesNavigation)
);
