import { Component } from "react";
import Footer from "../components/naviagtions/Footer";
import Header from "../components/naviagtions/Header";
import NavBar from "../components/naviagtions/NavBar";
import CategoryDrillDownFilter from "../components/naviagtions/itemNavs/CategoryDrillDownFilter";
import ListsDrillDownNav from "../components/naviagtions/listNavs/ListsDrillDownFilter";

interface Props {
  children: any;
  sidebarType: "items" | "options" | "none";
  headerType?: "magenta" | "grey";
  hideItemSearching?: boolean;
  isCategoryClick?: boolean;
}

interface State {
  fixedToTop: boolean;
  windowWidth: number;
  isMobile: boolean;
  sidebarWidth: number;
  scrollbarWidth: number;
}

class PageTemplate extends Component<Props, State> {
  state = {
    fixedToTop: false,
    windowWidth: 0,
    isMobile: false,
    sidebarWidth: 0,
    scrollbarWidth: 0,
  };

  componentDidMount() {
    // check scrolling
    this.onScroll();
    window.addEventListener("scroll", this.onScroll);

    // monitor resize of window
    this.setScreenWidth();
    window.addEventListener("resize", this.setScreenWidth);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenWidth);
    window.removeEventListener("scroll", this.onScroll);
  }

  setScreenWidth = () => {
    // get the widths to know how to position the item window
    const sidebarWidth = document.getElementById("sidebar")?.offsetWidth || 287; // hack: sometimes the renderer does not render the sidebar quick enough hence default value

    const scrollbarWidth =
      window.innerWidth === document.documentElement.clientWidth
        ? 28
        : window.innerWidth - document.documentElement.clientWidth;

    const root = document.getElementById("root");
    const windowWidth = root?.offsetWidth || 0;
    const isMobile = windowWidth <= 600 ? true : false;
    this.setState({ windowWidth, isMobile, sidebarWidth, scrollbarWidth });
  };

  onScroll = () => {
    // count pixels scrolled
    const pixelsScrolled =
      document.body.scrollTop || document.documentElement.scrollTop;

    // get height of the red navbar
    const headerHeight =
      document.getElementById("search-navbar")?.getBoundingClientRect()
        .height || 0;
    //NOTE: Not applicable for modals
    // once we scroll past the red navbar, fix elements to the top of the screen
    if (pixelsScrolled > headerHeight) {
      this.setState({ fixedToTop: true });
    } else {
      this.setState({ fixedToTop: false });
    }
  };

  render() {
    //  todo : would be nice to unify the method or seperate but not enough time
    const { windowWidth, isMobile, sidebarWidth } = this.state;
    return (
      <div className="u-w-all u-h-all">
        <div
          className={
            "u-w-all " +
            (this.state.fixedToTop && isMobile
              ? " u-top--0 u-fixed"
              : "u-relative")
          }
          style={{ zIndex: 1 }}
        >
          <NavBar
            secondaryBackgroundClass={
              this.props.headerType === "grey"
                ? // such as premium mode
                  "u-background--secondary"
                : // Allow only the inclusion of isMobile if hideItemSearching is not undefined
                this.props.hideItemSearching ||
                  (isMobile &&
                    typeof this.props.hideItemSearching !== "undefined")
                ? "u-background--white"
                : "u-background--secondary"
            }
            mainBackgroundClass={
              this.props.headerType === "grey"
                ? "u-background--secondary"
                : "u-background--magenta"
            }
          />
          {
            // if we are not showing cat item nav/search show nothing
            this.props.hideItemSearching ? null : this.props.sidebarType ===
              "items" ? (
              // if items page, fix the Header when user scrolls past navbar it stays visible / fixed to top
              <div
                className={
                  "u-w-all " +
                  (this.state.fixedToTop && !isMobile
                    ? " u-top--0 u-fixed"
                    : "")
                }
              >
                <Header
                  isFixed={this.state.fixedToTop}
                  isCategoryClick={this.props.isCategoryClick}
                />
              </div>
            ) : (
              // normal page scrolling
              <div className="u-w-all u-hide--mobile">
                <Header isCategoryClick={this.props.isCategoryClick} />
              </div>
            )
          }
        </div>

        <div className="u-background--grey u-flex u-mh-fit--desktop">
          {this.props.sidebarType === "options" ? (
            <div id="sidebar" className="u-hide--mobile ">
              <ListsDrillDownNav />
            </div>
          ) : this.props.sidebarType === "items" ? (
            <div
              id="sidebar"
              className={
                "u-hide--mobile " + (this.state.fixedToTop ? " u-fixed " : "")
              }
              style={{
                paddingTop: this.state.fixedToTop ? "40px" : "0px",
                height: this.state.fixedToTop ? "200%" : "",
              }}
            >
              <CategoryDrillDownFilter
                isSetSidebarHeight={this.state.fixedToTop ? true : false}
              />
            </div>
          ) : null}
          <div
            // What are these?
            style={
              this.props.sidebarType === "items"
                ? {
                    position: "absolute",
                    left: isMobile ? 0 : sidebarWidth,
                    width: isMobile ? "100%" : windowWidth - sidebarWidth,
                    paddingTop: this.state.fixedToTop
                      ? isMobile
                        ? "52px"
                        : "130px"
                      : "0px",
                  }
                : { width: "100%", position: "relative" }
            }
          >
            {this.props.children}
          </div>
        </div>
        {/* Do not show the footer on the items page */}
        {this.props.sidebarType === "items" ? null : <Footer />}
      </div>
    );
  }
}

export default PageTemplate;
