import { Component } from "react";
import { connect } from "react-redux";
import { track } from "../../clients/analytics/mixpanel";

import { trackCart } from "../../clients/analytics/cliquemarket";
import { showModal } from "../../redux/actions/modalActions";
import { Icon } from "../icons/Icon";
import BasketView from "../views/BasketView";

interface Props {
  basket?: BasketState;
  showModal?: any;
}

class BasketCounter extends Component<Props, {}> {
  calcBasktItems = () => {
    let total = 0;
    this.props.basket?.basketItems?.forEach((item) => {
      if (item.quantity) {
        total += item.quantity;
      } else {
        total++;
      }
    });
    return total;
  };

  openBasketModal = () => {
    const isIOS =
      //@ts-ignore
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const root = document.getElementById("root");
    let scrollWidth = root!.offsetWidth - root!.clientWidth;

    let scrollTop = 0;
    if (isIOS) {
      scrollTop = window.pageYOffset;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollTop}px`;
      document.body.style.width = "100%";
    } else {
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = scrollWidth + "px";
    }

    if(!process.env.REACT_APP_DEV_MODE) {
      this.trackCart();
    }

    const modalContent = () => <BasketView />;

    this.props.showModal({ component: modalContent });
  };

  trackCart = () => {
    this.sendToMixpanel();
    this.sendToCliqueMarket();
  };

  sendToMixpanel = () => {
    if (this.props.basket) {
      track("open_basket_view", {
        countOfItems: this.props.basket?.basketItems?.length || 0,
      });
    }
  };

  sendToCliqueMarket = () => {
    if (this.props.basket) {
      trackCart("view-cart", this.props.basket);
    }
  };

  render() {
    return (
      <div
        onClick={() => this.openBasketModal()}
        style={{ paddingBottom: "3px" }}
      >
        <div className="u-background--white u-br--all u-flex u-justify-center u-align-center u-w--26 u-h--26 u-ml--21--mobile u-ml--31--desktop u-mb--minus14">
          <div className="u-color-magenta u-main-font--vsmall u-bold u-text-center">
            {this.calcBasktItems() || 0}
          </div>
        </div>
        <div>
          <Icon id="trolley" height="22px" width="22px" />
        </div>

        <div
          className="u-color-white u-main-font--vsmall u-semi-bold u-hide--mobile"
          style={{ marginTop: "-4px" }}
        >
          view
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, { showModal })(BasketCounter);
