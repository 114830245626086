import { Component } from "react";
import { connect } from "react-redux";

import classnames from "classnames";

import { hideModal } from "../../redux/actions/modalActions";
import { resetCss } from "../../utils/modal";

interface Props {
  modal: ModalInputs;
  hideModal: any;
}

class Modal extends Component<Props, {}> {
  componentDidUpdate = (prevProps: Props) => {
    if (this.props.modal.status !== prevProps.modal.status) {
      if (this.props.modal.status === "hidden") {
        // fix the body to stop scrolling
        document.body.className = " ";
      } else {
        // unfix the body
        if (this.props.modal?.isFixed) {
          document.body.className +=
            "  u-overflow--scroll-x--hidden u-overflow--scroll--hidden u-overflow--scroll-y u-fixed u-vw";
        }
      }
    }
  };

  closeModalMethods = async () => {
    if (this.props.modal.onClose) {
      await this.props.modal.onClose();
    }
    await this.props.hideModal();

    resetCss();
  };

  render() {
    const { status, component, data, className, style } = this.props.modal;
    const modalIsHidden = status === "hidden";
    return (
      <div
        className={classnames("o-modal ", {
          "u-opacity--0 u-vis--hidden": modalIsHidden,
          className,
        })}
        style={style}
      >
        <div
          className="o-modal__background "
          // style={{ zIndex: -1 }}
          onClick={this.closeModalMethods}
        />
        {component && component(data)}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  modal: state.modal,
});

export default connect(mapStateToProps, { hideModal })(Modal);
