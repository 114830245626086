import axios from "axios";
import { Dispatch } from "react";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";

export const sortItems = (
  sortType: "A-Z" | "Z-A" | "HighestPrice" | "LowestPrice" | "Difference"
) => {
  return {
    type: "SORT_ITEMS",
    sortType,
  };
};

export const filterItems = (filter: FilterOption) => {
  return {
    type: "FILTER_ITEMS",
    filter,
  };
};

export const removeFilter = (filter: FilterOption) => {
  return {
    type: "REMOVE_FILTER",
    filter,
  };
};

export const toggleUnavailableProducts = (
  isUnavailableProductsHidden: boolean
) => {
  return {
    type: "TOGGLE_UNAVAILABLE_PRODUCTS",
    isUnavailableProductsHidden,
  };
};

export const sortItemsOnApi =
  (
    sortType:
      | ""
      | "A-Z"
      | "Z-A"
      | "HighestPrice"
      | "LowestPrice"
      | "Difference",
    categoryFilterId?: number,
    searchTerm?: string
  ) =>
  async (dispatch: Dispatch<ItemAction>) => {
    const searchPathname = getItemsApiQueryPathname(
      searchTerm,
      categoryFilterId,
      undefined,
      undefined,
      sortType
    );
    const response = await axios.get(
      process.env.REACT_APP_BACKEND_URL + searchPathname
    );

    let sortTypeName = sortType === "" ? "" : `Name (${sortType})`;

    if (sortType === "HighestPrice" || sortType === "LowestPrice") {
      sortTypeName =
        sortType === "HighestPrice"
          ? "Price (High to Low)"
          : "Price (Low to High)";
    }

    if (sortType === "Difference") {
      sortTypeName = "Biggest Savings";
    }

    dispatch({
      type: "DISPLAY_ITEMS",
      itemsToDisplay: response.data.items,
      pageNumber: response.data.page,
      totalPages: response.data.totalPages,
      totalProductsFound: response.data.totalProductsFound,
      sortType: sortType,
      sortTypeName: sortTypeName,
      matchedItem: [],
      closeMatchId: 1,
      currentItemId: response.data.currentItemId,
    });
  };

export const toggleHalfThePriceProducts =
  (
    isHalfThePriceClicked: boolean,
    callTheAPI: boolean = true,
    categoryFilterId?: number
  ) =>
  async (dispatch: Dispatch<ItemAction>) => {
    const searchPathname = getItemsApiQueryPathname(
      undefined,
      categoryFilterId,
      undefined,
      isHalfThePriceClicked
    );

    if (callTheAPI) {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND_URL + searchPathname
      );

      dispatch({
        type: "DISPLAY_ITEMS",
        itemsToDisplay: response.data.items,
        pageNumber: response.data.page,
        totalPages: response.data.totalPages,
        totalProductsFound: response.data.totalProductsFound,
        sortType: "A-Z",
        matchedItem: [],
        closeMatchId: 1,
        currentItemId: response.data.currentItemId,
      });

      if (isHalfThePriceClicked === false) {
        return dispatch({
          type: "TOGGLE_HALF_THE_PRICE_PRODUCTS",
          isHalfThePriceClicked: false,
        });
      }
    }

    dispatch({
      type: "TOGGLE_HALF_THE_PRICE_PRODUCTS",
      isHalfThePriceClicked: isHalfThePriceClicked,
    });
  };

export const getItemsToDisplay =
  (
    searchTerm: string,
    categoryFilterId: number,
    itemFilters: any,
    sortType: string,
    isHalfThePriceClicked: boolean
  ) =>
  async (dispatch: Dispatch<any>) => {
    // build the query structure
    const searchPathname = getItemsApiQueryPathname(
      searchTerm,
      categoryFilterId,
      undefined,
      isHalfThePriceClicked,
      sortType
    );

    // call the api
    return await axios
      .get(process.env.REACT_APP_BACKEND_URL + searchPathname)
      .then(async (res) => {
        const itemsArray: ItemData[] = res.data.items;

        // hack to make all values 2dp
        let matchedItem: ItemData | never[] = [];
        let index = 0;

        for (const item of itemsArray) {
          item.item_pricing.forEach((pricing) => {
            if (typeof pricing.price === "number") {
              pricing.price.toFixed(2);
            }
          });
        }

        dispatch({
          type: "DISPLAY_ITEMS",
          itemsToDisplay: itemsArray,
          pageNumber: res.data.page,
          totalPages: res.data.totalPages,
          totalProductsFound: res.data.totalProductsFound,
          sortType: sortType,
          matchedItem: matchedItem,
          currentItemId: res.data.currentItemId,
        });
      })
      .catch((err) => {
        // TODO - what should we do if this fails
        dispatch({
          type: "DISPLAY_ITEMS",
          itemsToDisplay: [],
          pageNumber: 0,
          totalPages: 0,
          totalProductsFound: 0,
          sortType: sortType,
          matchedItem: null,
        });
        console.log(err);
      });
  };

export const getMoreItems =
  (
    searchTerm: string,
    categoryFilterId: number,
    pageNumber: number,
    currentItemId: number,
    isHalfThePrice?: boolean,
    sortTypeName?: string
  ) =>
  async (dispatch: Dispatch<any>) => {
    // build the query structure
    const searchPathname = getItemsApiQueryPathname(
      searchTerm,
      categoryFilterId,
      undefined,
      isHalfThePrice,
      sortByOrder(sortTypeName)
    );

    // call the api
    return await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          searchPathname +
          "&page=" +
          pageNumber +
          "&currentItemId=" +
          currentItemId
      )
      .then((res) => {
        // extract the items data
        const itemsArray: ItemData[] = res.data.items;
        // hack to make all values 2dp
        itemsArray.forEach((item) => {
          item.item_pricing.forEach((pricing) => {
            if (typeof pricing.price === "number") {
              pricing.price.toFixed(2);
            }
          });
        });

        dispatch({
          type: "GET_MORE_ITEMS",
          itemsToDisplay: itemsArray,
          pageNumber: res.data.page,
          currentItemId: res.data.currentItemId,
          totalPages: res.data.totalPages,
          totalProductsFound: res.data.totalProductsFound,
        });
      })
      .catch((err) => {
        // TODO - what should we do if this fails
        console.log(err);
      });
  };

const sortByOrder = (sortTypeName?: string) => {
  let sortBy = "";

  if (!sortTypeName) return sortBy;

  if (sortTypeName === "Name (A-Z)") {
    sortBy = "A-Z";
  }

  if (sortTypeName === "Name (Z-A)") {
    sortBy = "Z-A";
  }

  if (sortTypeName === "Price (High to Low)") {
    sortBy = "HighestPrice";
  }

  if (sortTypeName === "Price (Low to High)") {
    sortBy = "LowestPrice";
  }

  if (sortTypeName === "Biggest Savings") {
    sortBy = "Difference";
  }

  return sortBy;
};

export const closeMatchItem =
  (itemId: number, availableItemId: number) => async () => {
    return await httpClient.instance
      .post("/item/close-match", {
        item_id: itemId,
        available_item_id: availableItemId,
      })
      .then(async (res) => {
        const close_match_id = res.data.id;
        await httpClient.instance
          .patch("/item/close-match", {
            item_id: itemId,
            close_match_id,
          })
          .then((res) => {})
          .catch((e) => {
            console.log(e);
          });
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const updateItemCloseMatchId =
  (itemId: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .patch("/item/close-match", {
        item_id: itemId,
      })
      .then((res) => {
        // console.log("updated the item table");
      })
      .catch((e) => {
        console.log(e);
      });
  };

export async function getItemCloseMatchId(itemId: number) {
  const item_id = itemId;
  return await httpClient.instance
    .get("/item/close-match?id=" + item_id)
    .then((res) => {
      return res.data;
    });
}

export const checkItemCloseMatch = async (itemData: ItemData) => {
  const itemName = itemData.name;
  const vendorId = itemData.selected_vendor_id === 1 ? 2 : 1;

  if (
    itemName.includes("Coles") ||
    itemName.includes("coles") ||
    itemName.includes("Woolworths") ||
    itemName.includes("woolworths")
  ) {
    // build the query structure
    const searchPathname = getItemsApiQueryPathname(itemName, 0, vendorId);

    // call the api
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + searchPathname)
      .then((res) => {
        const itemsArray: ItemData[] = res.data;
        const data = itemsArray.filter(
          (item) =>
            item.item_pricing.length === 1 &&
            (item.name.includes("Coles") || item.name.includes("Woolworths"))
        );
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    return null;
  }
};

function getItemsApiQueryPathname(
  searchTerm?: string,
  categoryFilterId?: number,
  vendorId?: number,
  isHalfThePrice?: boolean | undefined,
  sortBy?: string
) {
  // build the query structure
  let searchPathname = "";

  // use the search endpoint
  searchPathname += "/search?";

  if (searchTerm && searchTerm.length) {
    if (vendorId) {
      searchPathname +=
        "q=" + searchTerm + "&vendor_id= " + vendorId + "&limit=1&";
    } else {
      searchPathname += "q=" + searchTerm + "&";
    }
  }

  if (categoryFilterId) {
    searchPathname += "catId=" + categoryFilterId + "&";
  }

  if (isHalfThePrice) {
    searchPathname += "isHalfThePrice=1" + "&";
  }

  if (sortBy) {
    searchPathname += `sortBy=${sortBy}`;
  }
  return searchPathname;
}
