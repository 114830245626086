import React, { Component } from "react";
import { StyleGuideView } from "../views";

class StyleGuideController extends Component {
  render() {
    return <StyleGuideView />;
  }
}

export default StyleGuideController;
