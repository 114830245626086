import {
  GET_USER_PRICE_ALERTS,
  DELETE_USER_PRICE_ALERT,
  SORT_PRICE_ALERTS,
} from "../enums";

interface priceAlertAction {
  type:
    | "GET_USER_PRICE_ALERTS"
    | "DELETE_USER_PRICE_ALERT"
    | "SORT_PRICE_ALERTS"; // ADD OTHERS HERE
  priceAlerts?: PriceAlert[];
  item_ids?: number[];
  deletedId?: number;
  sortType?: "A-Z" | "Z-A" | "AscPrice" | "DescPrice";
}

const initialState: PriceAlertsState = {
  priceAlerts: [],
  item_ids: [],
  currentSortType: "",
};

export default function priceAlertReducer(
  state = initialState,
  action: priceAlertAction
) {
  switch (action.type) {
    case GET_USER_PRICE_ALERTS:
      return {
        ...state,
        priceAlerts: action.priceAlerts,
        item_ids: action.item_ids,
      };
    case DELETE_USER_PRICE_ALERT:
      // remove the fav from the array
      let newPriceAlerts = [...state.priceAlerts];
      // find the index with the id
      const index = newPriceAlerts.findIndex(
        (alert) => alert.item.id === action.deletedId
      );

      if (index > -1) {
        // splice it out
        newPriceAlerts.splice(index, 1);
      }
      return {
        ...state,
        priceAlerts: newPriceAlerts,
      };
    case SORT_PRICE_ALERTS:
      const newAlerts = [...state.priceAlerts];
      switch (action.sortType) {
        case "A-Z":
          newAlerts.sort((a, b) => a.item.name.localeCompare(b.item.name));
          break;
        case "Z-A":
          newAlerts
            .sort((a, b) => a.item.name.localeCompare(b.item.name))
            .reverse();
          break;

        case "DescPrice":
          newAlerts.sort((a, b) => a.alert_price - b.alert_price);
          break;
        case "AscPrice":
          newAlerts.sort((a, b) => b.alert_price - a.alert_price);
          break;
        default:
          break;
      }

      return {
        ...state,
        priceAlerts: newAlerts,
        currentSortType: action.sortType,
      };
    default:
      return state;
  }
}
