import { Dispatch } from "react";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import {
  ADD_LIST_TO_BASKET,
  ADD_TO_BASKET,
  CHANGE_SORT,
  CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL,
  CHANGE_VENDOR_TO_BUY_FROM,
  CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL,
  CLEAR_BASKET,
  DECREASE_QUANTITY,
  INCREASE_QUANTITY,
  LOAD_BASKET,
  REMOVE_FROM_BASKET,
  REPLACE_BASKET,
  UPDATE_BASKET_METADATA,
} from "../enums";

import { AxiosError } from "axios";
import store from "../store";

// all basket items should dispatch with this function
const dispatchAndUpdateMetadata = ({
  item,
  id,
  vendorId,
  type,
  basket,
  isAutoMode,
  isVendorSelected,
  dispatch,
}: {
  item?: ItemData;
  id?: number;
  vendorId?: 1 | 2 | undefined;
  basket?: ItemData[];
  type: string;
  isAutoMode?: boolean;
  isVendorSelected?: boolean;
  dispatch: Dispatch<any>;
}) => {
  // only dispatch values that are given
  const dataToDispatch = Object.assign(
    {},
    item && { item },
    id && { id },
    vendorId && { vendorId },
    type && { type },
    basket && { basket }
  );

  dispatch(dataToDispatch);
  dispatch({
    type: UPDATE_BASKET_METADATA,
    isAutoMode,
    isVendorSelected,
  });
};

export const addItemToBasket =
  (item: ItemData) => (dispatch: Dispatch<any>) => {
    dispatchAndUpdateMetadata({ type: ADD_TO_BASKET, item, dispatch });
  };

export const clearBasket = () => (dispatch: Dispatch<any>) => {
  dispatchAndUpdateMetadata({
    type: CLEAR_BASKET,
    dispatch,
  });
};

export const increaseBasketItemQuantity =
  (item: ItemData) => (dispatch: Dispatch<any>) => {
    dispatchAndUpdateMetadata({
      type: INCREASE_QUANTITY,
      item,
      dispatch,
    });
  };
export const decreaseBasketItemQuantity =
  (item: ItemData) => (dispatch: Dispatch<any>) => {
    dispatchAndUpdateMetadata({
      type: DECREASE_QUANTITY,
      item,
      dispatch,
    });
  };
export const removeFromBasket =
  (item: ItemData) => (dispatch: Dispatch<any>) => {
    dispatchAndUpdateMetadata({
      type: REMOVE_FROM_BASKET,
      item,
      dispatch,
    });
  };

export const changeSort = (id: number) => (dispatch: Dispatch<any>) => {
  dispatchAndUpdateMetadata({
    type: CHANGE_SORT,
    id,
    dispatch,
  });
};

export const changeVendorToBuyFrom =
  (item: ItemData, vendorId: 1 | 2 | undefined) =>
  async (dispatch: Dispatch<any>) => {
    // update redux state
    dispatchAndUpdateMetadata({
      type: CHANGE_VENDOR_TO_BUY_FROM,
      item,
      vendorId,
      dispatch,
    });
  };

export const changeVendorToBuyFromForAllItems =
  (vendorId: 1 | 2 | undefined) => (dispatch: Dispatch<any>) => {
    const isVendorSelected = true;
    dispatchAndUpdateMetadata({
      type: CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL,
      vendorId,
      isVendorSelected,
      dispatch,
    });
  };

export const changeVendorsToBeOptimisedForAllItems =
  () => (dispatch: Dispatch<any>) => {
    dispatchAndUpdateMetadata({
      type: CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL,
      dispatch,
    });
  };

export const loadBasket = (basket: ItemData[]) => (dispatch: Dispatch<any>) => {
  dispatchAndUpdateMetadata({
    type: LOAD_BASKET,
    basket,
    dispatch,
  });
};

export const findListAndReplaceBasket =
  (list_id: string) => async (dispatch: Dispatch<any>) => {
    const req = await httpClient.instance.get(
      "/user/lists?show_pricing=true&list_id=" + list_id
    );

    dispatchAndUpdateMetadata({
      type: REPLACE_BASKET,
      basket: req.data[0].items,
      dispatch,
    });
  };

export const findListAndAddToBasket =
  (list_id: string) => async (dispatch: Dispatch<any>) => {
    // TODO:  we may not need this call as it maybe in state
    const req = await httpClient.instance.get(
      "/user/lists?show_pricing=true&list_id=" + list_id
    );

    dispatchAndUpdateMetadata({
      type: ADD_LIST_TO_BASKET,
      basket: req.data[0].items,
      dispatch,
    });
  };

export const getCurrentBasket = () => async (dispatch: Dispatch<any>) => {
  await httpClient.instance
    .get("/user/lists/current?show_pricing=true")
    .then((res) => {
      const isAutoMode = store.getState().user.prefers_grocerize_auto_mode;

      dispatchAndUpdateMetadata({
        type: LOAD_BASKET,
        basket: res.data,
        isAutoMode,
        dispatch,
      });
    })
    .catch((error: AxiosError) => {
      if (error.response?.status === 404) {
        dispatchAndUpdateMetadata({
          type: LOAD_BASKET,
          basket: [],
          isAutoMode: true,
          dispatch,
        });
      }
    });
};
