import { Component } from "react";
import { connect } from "react-redux";
import { saveNewCategoryFilterSelection } from "../../redux/actions/categoryAction";
import { toggleHalfThePriceProducts } from "../../redux/actions/itemActions";
import { hideModal } from "../../redux/actions/modalActions";
import { saveNewSearch } from "../../redux/actions/searchActions";
import { resetCss } from "../../utils/modal";
import { searchHasValue } from "../../utils/searchFunctions";

interface Props {
  items: { id: string; name: string }[];
  saveNewSearch: any;
  hideModal: any;
  search: SearchState;
  toggleHalfThePriceProducts: (
    isHalfThePriceClicked: boolean,
    callTheAPI: boolean
  ) => void;
  saveNewCategoryFilterSelection: (selectedNode: CategoryTreeNode | null) => {
    type: string;
    selectedNode: CategoryTreeNode | null;
  };
}
class SearchSuggestionsPopup extends Component<Props> {
  search = async (value: string) => {
    this.props.saveNewSearch(value);

    await this.props.toggleHalfThePriceProducts(false, false);

    if (!searchHasValue(this.props.search)) {
      await this.props.saveNewCategoryFilterSelection(null);
    }
    this.props.hideModal();
    resetCss();
  };

  render() {
    return (
      <div className="u-fixed u-mt--4 u-ml--35 u-w-30pct u-z-index--2">
        <div className="u-background--white u-pv--12 u-ph--8 u-pl--18 u-br--vsmall u-border--charcoal--500 u-shadow">
          <div className="u-main-font--vsmall u-color-darkgrey">
            Suggestions
          </div>
          <div className="u-pv--12">
            {this.props.items.map((item) => {
              return (
                <div
                  className="u-w-all u-hoverover--darker-background u-cursor--pointer u-pv--12 u-ph--8"
                  key={item.id}
                  onClick={() => this.search(item.name)}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  search: state.search,
});

export default connect(mapStateToProps, {
  saveNewSearch,
  hideModal,
  saveNewCategoryFilterSelection,
  toggleHalfThePriceProducts,
})(SearchSuggestionsPopup);
