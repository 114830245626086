import React, { Component } from "react";
import { connect } from "react-redux";
import { httpClient } from "../../../clients/auth/addTokenToHTTPRequests";
import { getLists } from "../../../redux/actions/listActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  list: List;
  getLists: any;
  hideModal: any;
}

interface State {}

class DeleteListModalContent extends Component<Props, State> {
  deleteList = async () => {
    if (this.props.list.id) {
      await httpClient.instance
        .delete("/user/lists?list_id=" + this.props.list.id)
        .then(async () => {
          await this.props.getLists();
          await this.props.hideModal();
        });
    }
  };
  render() {
    return (
      <div
        className="u-background--white u-pt--8  u-br--vsmall"
        style={{
          marginLeft: "-250px",
          marginRight: "100px",
          width: "375px",
        }}
      >
        <div className="u-ph--32 u-pb--32 u-pt--24">
          <div className="u-color-magenta u-semi-bold u-main-font--small u-pb--8">
            Delete list
          </div>
          <div className="u-main-font--vsmall u-pb--16">
            Delete <span className="u-bold">"{this.props.list.title}"</span>
          </div>

          <button
            className="u-background--magenta u-br--small u-color-white u-p--8 u-ph--16 u-semi-bold u-main-font--vsmall"
            onClick={this.deleteList}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, { getLists, hideModal })(DeleteListModalContent);
