export function getListOfNextCategories(
  tree: CategoryTreeNode[],
  selectedNode?: CategoryTreeNode | null
) {
  if (selectedNode?.children?.length) {
    // if children, return them
    return selectedNode.children;
  } else if (selectedNode) {
    //  if there are no children nodes stay where we are
    // TODO FILTER ON THE SELECTED
    return;
  } else {
    // if no node is selected
    return tree;
  }
}

// append the category url slug to the url
export function addCategoryToURL(url_slug: string) {
  // get the search term
  const searchString = window.location.search;
  // add the new slug
  let catroute = window.location.pathname.toLowerCase();

  // check if the slug is already in on the last postion
  if (catroute.split("/").reverse()[0] !== url_slug) {
    catroute += "/" + url_slug;
    window.history.pushState({}, "", catroute + searchString);
  }
}

// swap the last url_slug with this one
export function swapLastElementOfURL(url_slug: string) {
  // get the search term
  const searchString = window.location.search;

  // get the current route
  let catroute = window.location.pathname.toLowerCase();

  if (catroute === "/browse") {
    catroute = catroute + "/browse";
  }
  // remove the last slug
  let routes = catroute.split("/");
  routes.pop();
  let newCatroute = routes.join("/");

  // add the new slug
  newCatroute += "/" + url_slug;
  window.history.pushState({}, "", newCatroute + searchString);
}

// change the url slugs depending on which nodes are clicked
export function updateURLSlugs(
  clickedNode?: CategoryTreeNode | null,
  currentNode?: CategoryTreeNode | null
) {
  // if the clicked node is null we must go back to the root
  if (!clickedNode) {
    window.history.pushState({}, "", "/browse");
  } else if (
    // the selected node and the clicked node have the same parent we need to swap the urlslugs
    clickedNode.parent_crawler_id === currentNode?.parent_crawler_id
  ) {
    // remove the previous last cat and add this one
    swapLastElementOfURL(clickedNode.url_slug);
  } else if (
    // if we are moving to the parent node of the current we are going back up a cat and need to remove it from the url
    currentNode?.parent_crawler_id === clickedNode.crawler_id
  ) {
    removeLastCatOfURL();
  } else {
    // add the cat slug to the url
    addCategoryToURL(clickedNode.url_slug);
  }
}

export function removeLastCatOfURL() {
  // get the search term
  const searchString = window.location.search;

  // split on /  and remove the last element (pop) then join them into a string
  let routeArray = window.location.pathname.toLowerCase().trim().split("/");
  routeArray.pop();
  const newPath = routeArray.join("/");
  // add to history
  window.history.pushState({}, "", newPath + searchString);
}

export function searchTreeForId(elements: CategoryTreeNode[], id: number) {
  let foundElement!: CategoryTreeNode;
  // for each element (at the top level)
  elements.forEach((element: CategoryTreeNode) => {
    // if a match
    if (element.crawler_id === id) {
      foundElement = element;
      return true;
    } else if (element.children?.length) {
      // search the children
      const result = searchTreeForId(element.children, id);

      // if found return it!
      if (result) {
        foundElement = result;
        return result;
      }
    }
    return;
  });

  return foundElement;
}

export function getParentOfNode(
  tree: CategoryTreeNode[],
  node: CategoryTreeNode | undefined | null
) {
  if (node) {
    // get the current displayed parent category from redux
    const parentCatId = node.parent_crawler_id;

    // if found
    if (parentCatId) {
      // find its parent
      const newDisplayed = searchTreeForId(tree, parentCatId);

      return newDisplayed;
    }
  }
  // else return null
  return null;
}
