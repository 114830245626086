import { findByVendor } from "../../utils/itemPricingFunctions";
import { COLES, WOOLWORTHS } from "../enums";

const initialState: ItemsState = {
  itemsToDisplay: [],
  matchedItem: "",
  closeMatchId: 0,
  unfilteredItems: [],
  sortTypeName: "",
  appliedFilters: [],
  currentPageNumber: 0,
  currentItemId: 1,
  totalPages: 0,
  totalProductsFound: 0,
  isUnavailableProductsHidden: true,
  isHalfThePriceClicked: false,
};

export default function filterReducer(
  state = initialState,
  action: ItemAction
) {
  switch (action.type) {
    case "DISPLAY_ITEMS":
      const unfilteredItems = initialItems(action.itemsToDisplay);

      return {
        ...state,
        sortTypeName: action.sortTypeName,
        itemsToDisplay: displayItems(unfilteredItems, state),
        matchedItem: action.matchedItem,
        closeMatchId: action.closeMatchId,
        unfilteredItems: unfilteredItems,
        currentPageNumber: action.pageNumber,
        currentItemId: action.currentItemId,
        totalPages: action.totalPages,
        totalProductsFound: action.totalProductsFound,
      };

    case "GET_MORE_ITEMS":
      // if there are extra items
      if (action?.itemsToDisplay?.length) {
        let updatedItems = initialItems(action.itemsToDisplay);
        // add the additional products
        let allSearchedItems = state?.unfilteredItems.concat(updatedItems);

        return {
          ...state,
          itemsToDisplay: displayItems(allSearchedItems, state),
          unfilteredItems: allSearchedItems,
          currentPageNumber: action.pageNumber,
          currentItemId: action.currentItemId,
          totalPages: action.totalPages,
        };
      } else {
        // do nothing
        return { ...state };
      }

    case "SORT_ITEMS":
      const newItems = state?.itemsToDisplay;
      let sortType = action.sortType ? action.sortType : state?.sortTypeName;

      switch (sortType) {
        case "A-Z":
          // sort alphabetically
          newItems.sort((a, b) => a.name.localeCompare(b.name));

          return {
            ...state,
            itemsToDisplay: newItems,
            sortTypeName: "Name (A-Z)",
          };
        case "Z-A":
          // sort antialphabetically
          newItems.sort((a, b) => a.name.localeCompare(b.name)).reverse();
          return {
            ...state,
            itemsToDisplay: newItems,
            sortTypeName: "Name (Z-A)",
          };
        case "HighestPrice":
          // sort on the largest price
          newItems.sort((a, b) => {
            // find the largest price from wools or coles from both prods
            const maxPriceOfA = Math.max(
              a.item_pricing[0]?.price || 0,
              a.item_pricing[1]?.price || 0
            );
            const maxPriceOfB = Math.max(
              b.item_pricing[0]?.price || 0,
              b.item_pricing[1]?.price || 0
            );
            // put the largest price first
            return maxPriceOfB - maxPriceOfA;
          });
          return {
            ...state,
            itemsToDisplay: newItems,
            sortTypeName: "Price (High to Low)",
          };
        case "LowestPrice":
          // sort on the largest price
          newItems.sort((a, b) => {
            // find the largest price from wools or coles from both prods
            const maxPriceOfA = Math.max(
              a.item_pricing[0]?.price || 0,
              a.item_pricing[1]?.price || 0
            );
            const maxPriceOfB = Math.max(
              b.item_pricing[0]?.price || 0,
              b.item_pricing[1]?.price || 0
            );
            // put the largest price first
            return maxPriceOfA - maxPriceOfB;
          });
          return {
            ...state,
            itemsToDisplay: newItems,
            sortTypeName: "Price (Low to High)",
          };
        case "Difference":
          // sort on the largest price
          newItems.sort((a, b) => {
            // find the largest price from wools or coles from both prods
            const priceDiffForA = Math.abs(
              (a.item_pricing[0]?.price || a.item_pricing[1]?.price) -
                (a.item_pricing[1]?.price || a.item_pricing[0]?.price)
            );
            const priceDiffForB = Math.abs(
              (b.item_pricing[0]?.price || b.item_pricing[1]?.price) -
                (b.item_pricing[1]?.price || b.item_pricing[0]?.price)
            );
            // put the largest price first
            return priceDiffForB - priceDiffForA;
          });
          return {
            ...state,
            itemsToDisplay: newItems,
            sortTypeName: "Biggest Savings",
          };

        default:
          return state;
      }

    case "REMOVE_FILTER":
      const currentlyAppliedFilters = [...state.appliedFilters];

      // remove the filter
      const index = currentlyAppliedFilters.findIndex(
        (filter) => filter.filter_id === action.filter?.filter_id
      );
      currentlyAppliedFilters.splice(index, 1);

      return {
        ...state,
        itemsToDisplay: displayItems(state.unfilteredItems, {
          ...state,
          appliedFilters: currentlyAppliedFilters,
        }),
        appliedFilters: currentlyAppliedFilters,
      };

    case "FILTER_ITEMS":
      let filtered: ItemData[] = [];

      // apply the additional filter
      if (action.filter?.enum === "Special") {
        filtered = state.itemsToDisplay.filter((item) => item.isSpecial);
      } else if (action.filter?.enum === "MatchingItemsOnly") {
        filtered = state.itemsToDisplay.filter(
          (item) => item.matchingItemsOnly
        );
      } else if (action.filter?.enum === "ExclusiveColes") {
        filtered = state.itemsToDisplay.filter((item) => item.exclusiveColes);
      } else if (action.filter?.enum === "ExclusiveWools") {
        filtered = state.itemsToDisplay.filter((item) => item.exclusiveWools);
      } else if (action.filter?.enum === "AvailableAtBoth") {
        filtered = state.itemsToDisplay.filter((item) => item.availableAtBoth);
      }

      return {
        ...state,
        itemsToDisplay: displayItems(filtered, state),
        appliedFilters: [...state.appliedFilters, action.filter], // add additional filter_id to array
      };

    case "TOGGLE_UNAVAILABLE_PRODUCTS":
      let itemsToDisplay = toggleUnavailableProducts(
        state,
        action.isUnavailableProductsHidden
      );

      state.isUnavailableProductsHidden = action.isUnavailableProductsHidden;
      itemsToDisplay = displayItems(itemsToDisplay, state);

      return {
        ...state,
        itemsToDisplay,
      };

    case "TOGGLE_HALF_THE_PRICE_PRODUCTS":
      let items = toggleHalfThePriceProducts(
        state,
        action.isHalfThePriceClicked
      );

      state.isHalfThePriceClicked = action.isHalfThePriceClicked;

      items = displayItems(items, state);

      return {
        ...state,
        itemsToDisplay: items,
      };

    case "GET_MATCHED_ITEMS":
      const itemsToCheck = state?.itemsToDisplay;
      let matchedItems;

      itemsToCheck.forEach((item) => {
        if (
          item.name.toLowerCase().includes("coles") ||
          item.name.toLowerCase().includes("woolworths")
        ) {
          matchedItems = item;
        }
      });

      return {
        ...state,
        matchedItems: matchedItems,
      };
    default:
      return state;
  }
}

function applyFiltersToItems(
  items: ItemData[],
  currentlyAppliedFilters: FilterOption[]
) {
  let newFilteredItems = [...items];

  // if filtering on specials
  if (
    currentlyAppliedFilters.findIndex((filter) => filter.enum === "Special") >
    -1
  ) {
    newFilteredItems = items.filter((item) => item.isSpecial);
  }
  // if filtering on matching items only
  if (
    currentlyAppliedFilters.findIndex(
      (filter) => filter.enum === "MatchingItemsOnly"
    ) > -1
  ) {
    newFilteredItems = items.filter((item) => item.matchingItemsOnly);
  }
  // if filtering on coles
  if (
    currentlyAppliedFilters.findIndex(
      (filter) => filter.enum === "ExclusiveColes"
    ) > -1
  ) {
    newFilteredItems = items.filter((item) => item.exclusiveColes);
  }
  // if filtering on wools
  if (
    currentlyAppliedFilters.findIndex(
      (filter) => filter.enum === "ExclusiveWools"
    ) > -1
  ) {
    newFilteredItems = items.filter((item) => item.exclusiveWools);
  }
  // if filtering on Available at both
  if (
    currentlyAppliedFilters.findIndex(
      (filter) => filter.enum === "AvailableAtBoth"
    ) > -1
  ) {
    newFilteredItems = items.filter((item) => item.availableAtBoth);
  }

  return newFilteredItems;
}

function isItemSpecial(item: ItemData) {
  const itemPricings = item.item_pricing;

  if (itemPricings.length === 0) {
    return false;
  }

  if (itemPricings.length === 2) {
    const itemOnColes = findByVendor(itemPricings, COLES);
    const itemOnWoolWorths = findByVendor(itemPricings, WOOLWORTHS);

    const isItemSpecialInColes =
      (itemOnColes?.special && itemOnColes?.available) || false;
    const isItemSpecialInWoolWorths =
      (itemOnWoolWorths?.special && itemOnWoolWorths?.available) || false;

    return isItemSpecialInColes || isItemSpecialInWoolWorths;
  }

  return (itemPricings[0].special && itemPricings[0].available) || false;
}

function isItemHalfThePrice(item: ItemData) {
  const itemPricings = item.item_pricing;

  if (itemPricings.length === 0) {
    return false;
  }

  if (itemPricings.length === 2) {
    return itemPricings.some((pricing) => isPriceHalf(pricing));
  }
  return isPriceHalf(itemPricings[0]);
}

function isPriceHalf(pricing: {
  special_original_price?: number;
  price: number;
}) {
  if (pricing && pricing.special_original_price) {
    return pricing.special_original_price / pricing.price === 2;
  }
  return false;
}

function displayItems(items: ItemData[], options: ItemsState) {
  const currentlyAppliedItemFilters = options.appliedFilters;

  if (currentlyAppliedItemFilters && currentlyAppliedItemFilters.length > 0) {
    items = applyFiltersToItems(items, currentlyAppliedItemFilters);
  }

  if (options.isUnavailableProductsHidden) {
    items = items.filter((item) =>
      item.item_pricing.some((pricing) => pricing.available)
    );
  }

  if (options.isHalfThePriceClicked) {
    items = items.filter(
      (item) => isItemSpecial(item) && isItemHalfThePrice(item)
    );

    items = items.map((item) => {
      item.isHalfThePrice = true;

      return item;
    });
  }

  return items || [];
}

function initialItems(itemsToDisplay?: ItemData[]) {
  itemsToDisplay?.forEach((item) => {
    item.isSpecial = isItemSpecial(item);
    item.isHalfThePrice = isItemHalfThePrice(item);

    const { close_match_a } = item;

    if (close_match_a !== null && close_match_a?.item_a) {
      close_match_a.item_a.isSpecial = isItemSpecial(close_match_a.item_a);
    }
    // item is exclusive if wools do not sell it
    item.exclusiveColes = !item.item_pricing.some(
      (pricing) => pricing.vendor_id === 2
    );
    // item is exclusive if coles do not sell it
    item.exclusiveWools = !item.item_pricing.some(
      (pricing) => pricing.vendor_id === 1
    );

    // item is available at both
    item.availableAtBoth = isItemAvailableAtBothVendors(item.item_pricing);
  });

  return itemsToDisplay || [];
}

function isItemAvailableAtBothVendors(itemPricings: ItemPricings[]) {
  if (itemPricings && itemPricings.length === 2) {
    const itemOnColes = findByVendor(itemPricings, COLES);
    const itemOnWoolWorths = findByVendor(itemPricings, WOOLWORTHS);

    return (
      itemOnColes?.available &&
      itemOnWoolWorths?.available &&
      itemOnColes?.price !== 0 &&
      itemOnWoolWorths?.price !== 0
    );
  }
  return false;
}

function toggleUnavailableProducts(
  state: ItemsState,
  isUnavailableProductsHidden: boolean | undefined
) {
  if (isUnavailableProductsHidden) {
    return state.itemsToDisplay.filter((item) =>
      item.item_pricing.some((pricing) => pricing.available)
    );
  }

  return [...state.unfilteredItems];
}

function toggleHalfThePriceProducts(
  state: ItemsState,
  isHalfThePriceClicked: boolean | undefined
) {
  if (isHalfThePriceClicked) {
    return state.itemsToDisplay.filter(
      (item) => isItemSpecial(item) && isItemHalfThePrice(item)
    );
  }
  return [...state.unfilteredItems];
}
