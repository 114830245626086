import axios, { AxiosInstance } from "axios";

export class HttpClient {
  instance: AxiosInstance;
  static tokenGenerator: () => string;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    this.instance.interceptors.request.use(
      async (config) => {
        const token = await this.getToken();

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
        };
      },
      (error) => {
        Promise.reject(error);
      }
    );

    return this;
  }

  static setTokenGenerator(tokenGenerator: () => string) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    if (!HttpClient.tokenGenerator) {
      throw new Error("No token generator defined");
    }
    return HttpClient.tokenGenerator();
  }
}

export const httpClient = new HttpClient();
