import { routes } from "../../utils/routes";
import { toTitleCase } from "../../utils/UIFunctions";

export const saveNewSearch = (searchTerm: string) => {
  // // add to mixpanel
  // track("user_searched", searchTerm);

  // if the url doesn't contain the search term save it
  if (!window.location.href.toString().includes(searchTerm)) {
    // change URL manually to be faster
    window.history.pushState({}, "", routes.browse + "?s=" + searchTerm);
  }

  searchTerm = toTitleCase(searchTerm);

  return {
    type: "NEW_SEARCH",
    searchTerm,
  };
};

export const saveBulkSearch = (allSearchTerms: string[]) => {
  return {
    type: "BULK_SEARCH",
    allSearchTerms,
  };
};

export const removeBulkSearchTerm = (searchTerm: string) => {
  return {
    type: "DELETE_ITEM",
    searchTerm,
  };
};

export const removeBulkList = () => {
  return {
    type: "REMOVE_BULK",
  };
};
