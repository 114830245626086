import { Component } from "react";
import { connect } from "react-redux";
import { grocerizeMyListTotalSavings } from "../../redux/actions/listActions";
import { showModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { getWordyDate } from "../../utils/UIFunctions";
import { Icon } from "../icons/Icon";
import ListCardMobile from "./ListCardMobile";
import ListOptionsModal from "./listOptions/ListOptionsModal";

interface Props {
  listMetaData: List;
  showModal?: any;
  basket?: BasketState;
  user?: UserState;
}

class ListCard extends Component<Props, {}> {
  clickedOptions = (list: List | undefined) => {
    if (list?.id === undefined) {
      return;
    }

    const button = document.getElementById(list.id.toString());

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = () => (
        <div
          style={{
            marginTop: postionOfButton.bottom + 20 + "px", // fudge factor as the button size is not working?
            marginLeft: postionOfButton.left - 40 + "px", // place content left and caret right
            width: postionOfButton.right - postionOfButton.left + "px",
          }}
        >
          <ListOptionsModal list={list} />
        </div>
      );

      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    const violet = variables.violetColor;
    const list = this.props.listMetaData;

    if (!list) {
      return null;
    }
    return (
      <>
        <div className={`u-hide--desktop`}>
          <ListCardMobile listMetaData={list} />
        </div>
        <div
          className={`u-hide--mobile u-ph--48 u-pv--12 u-border--bottom--grey u-flex u-justify-center `}
          style={{ height: "121px" }}
        >
          <div
            className="u-w-80pct u-flex u-align-center u-main-font--vsmall"
            style={{ fontSize: "14px" }}
          >
            <div className="u-w-25pct u-flex u-align-center">
              <div>{list.title}</div>
            </div>

            <div
              style={{ width: "116px" }}
              className=" u-text-oneline u-text-right u-ph--8 u-flex u-align-center u-justify-end"
            >
              {getWordyDate(list.createdAt)}
            </div>
            <div
              className=" u-bold u-background--grey u-mh--8 u-h-all u-flex u-align-center u-justify-end"
              style={{ width: "116px" }}
            >
              <div className="u-ph--24 u-flex u-align-center u-flex">
                ${list?.grocerizeShopTotal.toFixed(2)}
              </div>
            </div>
            <div
              style={{ width: "116px" }}
              className="u-text-right  u-background--magenta u-mh--8 u-h-all u-flex u-align-center u-justify-end"
            >
              <div className="u-ph--24 u-flex u-align-center u-flex u-bold u-color-white">
                ${grocerizeMyListTotalSavings(list.items)}
              </div>
            </div>
            <div
              style={{ width: "90px" }}
              className=" u-bold u-background--grey u-mh--8 u-h-all u-flex u-align-center u-justify-end"
            >
              <div className="u-ph--24 u-flex u-align-center u-flex">
                {list.itemsCount}
              </div>
            </div>
            <button
              id={list.id?.toString()}
              className=" u-pl--40 u-flex u-align-center"
              onClick={() => this.clickedOptions(list)}
            >
              Options
              <div className="u-pl--4 u-flex u-align-center">
                <Icon
                  id="chevron_right"
                  size="m"
                  stroke={violet}
                  fill="none"
                  className="u-rotate--negative90deg"
                />
              </div>
            </button>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { showModal })(ListCard);
