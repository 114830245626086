import axios from "axios";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Icon } from "../components/icons/Icon";
import PublicListCategory from "../components/listsPublic/PublicListCategory";
import PublicListsHeader from "../components/listsPublic/PublicListsHeader";
import Toggle from "../components/listsPublic/Toggle";
import { totalQuantities } from "../utils/listFunctions";

import PageTemplate from "./PageTemplate";

interface MatchParams {
  id: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

interface State {
  windowWidth: number;
  timeRemaining: string;
  loading: boolean;
  colesItems?: PublicListStoreSortCategory[];
  woolsItems?: PublicListStoreSortCategory[];
  selectedVendor: "coles" | "wools";
  totalWoolworthsItems: number;
  totalColesItems: number;
  screenOrientation: string;
}

class PublicListsPage extends Component<Props, State> {
  state: State = {
    windowWidth: 0,
    selectedVendor: "coles",
    woolsItems: [],
    colesItems: [],
    timeRemaining: "",
    loading: true,
    totalWoolworthsItems: 0,
    totalColesItems: 0,
    screenOrientation: "portrait",
  };

  componentDidMount = async () => {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          "/user/lists/current/publicUrl/" +
          this.props.match.params.id
      )
      .then((res) => {
        let colesItems: PublicListStoreSortCategory[] = res.data.coles;
        let woolsItems: PublicListStoreSortCategory[] = res.data.wools;

        const { totalColesItems, totalWoolworthsItems } = totalQuantities(
          colesItems,
          woolsItems
        );

        const timeRemaining = this.calcTimeRemainingString(res.data.updatedAt);

        this.setState({
          colesItems,
          woolsItems,
          timeRemaining,
          loading: false,
          selectedVendor: colesItems.length ? "coles" : "wools",
          totalColesItems,
          totalWoolworthsItems,
        });
      })
      .catch((e) => {
        console.log(e);
        // set state to trigger warning message
        this.setState({
          colesItems: [],
          woolsItems: [],
          loading: false,
          selectedVendor: "coles",
        });
      });

    // get window screen width and height and set it for the components
    this.setScreenWidth();
    // track if the user rotates or changes
    window.addEventListener("resize", this.setScreenWidth);
    window.addEventListener("resize", this.setScreenOrientation);
  };

  calcTimeRemainingString = (updated: string) => {
    const now = new Date();
    const updatedAt = new Date(updated);

    const hours = 23 - (now.getHours() - updatedAt.getHours());
    const minutes = 59 - (now.getMinutes() - updatedAt.getMinutes());

    return `${hours} hrs ${minutes} mins remaining`;
  };

  setScreenWidth = () => {
    const windowWidth = window.innerWidth;
    this.setState({ windowWidth });
  };

  setVendor = (vendor: "coles" | "wools") => {
    this.setState({ selectedVendor: vendor });
  };

  setScreenOrientation = () => {
    this.setState({ screenOrientation: window.screen.orientation.type });
  };

  render() {
    const remainingTimeString = this.state.timeRemaining;
    const listId = this.props.match.params.id;

    if (this.state.loading) {
      return (
        <PageTemplate sidebarType="none">
          <div className="u-background--white u-w-all">
            <div className="u-wrap" style={{ height: "60vh" }}>
              <div className=" u-bold u-color-magenta u-pt--48"></div>
            </div>
          </div>
        </PageTemplate>
      );
    }

    // if viewing on desktop, dont show lists
    if (
      this.state.windowWidth > 600 &&
      this.state.screenOrientation === "portrait"
    ) {
      return (
        <PageTemplate sidebarType="none">
          <div className="u-background--white u-w-all">
            <div className="u-wrap" style={{ height: "60vh" }}>
              <div className=" u-bold u-color-magenta u-pt--48">
                <Icon id="warning" size="xxxl" />
                <div className="u-main-font--vlarge">Sorry!</div>
                <div className="u-main-font--large">
                  Shopping lists are only visible on mobile
                </div>
              </div>
            </div>
          </div>
        </PageTemplate>
      );
    }

    // if no data
    if (
      (!this.state.colesItems && !this.state.woolsItems) ||
      (!this.state.colesItems?.length && !this.state.woolsItems?.length)
    ) {
      return (
        <div>
          <PublicListsHeader listId={listId} />
          <div className="u-w-all u-background--secondary u-p--16 u-flex u-justify-between u-color-white u-main-font--small u-semi-bold"></div>
          <div className="u-color-magenta u-bold u-p--12">
            <Icon id="warning" size="xxxl" />
            <div className="u-main-font--vlarge">Sorry!</div>
            <div className="u-main-font--large">
              This shopping lists doesn't exist or has expired.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {/* grocerise bar */}
        <PublicListsHeader listId={listId} />
        {/* banner title */}
        <div className="u-w-all u-background--secondary u-p--16 u-flex u-justify-between u-color-white u-main-font--small u-semi-bold">
          <div>{remainingTimeString}</div>
          <div>
            {`${
              this.state.totalColesItems + this.state.totalWoolworthsItems
            } items`}
          </div>
        </div>
        {/* toggle component */}
        <Toggle
          setVendor={(vendor: "coles" | "wools") => this.setVendor(vendor)}
          colesCount={this.state.totalColesItems}
          woolsCount={this.state.totalWoolworthsItems}
          initialVendor={this.state.selectedVendor}
        />
        {/* MAP shopping list items component */}
        {this.state.selectedVendor === "coles" &&
        this.state.colesItems?.length ? (
          this.state.colesItems.map((cat, index) => {
            return <PublicListCategory cat={cat} key={index} />;
          })
        ) : this.state.woolsItems?.length &&
          this.state.selectedVendor === "wools" ? (
          this.state.woolsItems.map((cat, index) => {
            return <PublicListCategory cat={cat} key={index} />;
          })
        ) : (
          <div className="u-main-font--medium u-color-charcoal u-bold u-p--24">
            No items
          </div>
        )}
      </div>
    );
  }
}

export default PublicListsPage;
