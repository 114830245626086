import {
  SAVE_USER_DATA,
  TOGGLE_AUTO_MODE,
  UPDATE_USER_MARKETING_EMAILS_PREFERENCE,
  UPDATE_USER_SAVINGS_LIMIT,
  UPDATE_USER_SHOPING_PREFERENCE,
  UPDATE_USER_SYSTEM_EMAILS_PREFERENCE,
} from "../enums";

interface userAction {
  type:
    | "SAVE_USER_DATA"
    | "UPDATE_USER_SHOPING_PREFERENCE"
    | "TOGGLE_AUTO_MODE"
    | "UPDATE_USER_SAVINGS_LIMIT"
    | "UPDATE_USER_SYSTEM_EMAILS_PREFERENCE"
    | "UPDATE_USER_MARKETING_EMAILS_PREFERENCE"; // ADD OTHERS HERE
  userData?: UserState;
  shoppingPreference?: string;
  manualPriceDifference?: number;
  prefersGrocerizeAutoMode?: boolean;
  sendSystemEmails?: boolean;
  sendMarketingEmails?: boolean;
}

const initialState: UserState = {
  id: 0,
  auth0_id: "",
  first_name: "",
  last_name: "",
  postcode: "",
  shopping_preference: "No preference",
  favourites: [],
  stripe_id: "",
  prefers_grocerize_auto_mode: true,
  isPremium: false,
  send_system_emails: true,
  send_marketing_emails: false,
};

export default function searchReducer(
  state = initialState,
  action: userAction
) {
  switch (action.type) {
    case SAVE_USER_DATA:
      return {
        ...state,
        id: action.userData?.id,
        auth0_id: action.userData?.auth0_id,
        first_name: action.userData?.first_name,
        last_name: action.userData?.last_name,
        postcode: action.userData?.postcode,
        shopping_preference: action.userData?.shopping_preference,
        email: action.userData?.email,
        stripe_id: action.userData?.stripe_id,
        isPremium: action.userData?.isPremium,
        trialStartedAt: action.userData?.trialStartedAt,
        daysLeftOnTrial: action.userData?.daysLeftOnTrial,
        send_system_emails: action.userData?.send_system_emails,
        deletedAt: action.userData?.deletedAt,
        send_marketing_emails: action.userData?.send_marketing_emails,
      };
    case UPDATE_USER_SHOPING_PREFERENCE:
      return {
        ...state,
        shopping_preference: action.shoppingPreference,
      };
    case TOGGLE_AUTO_MODE:
      return {
        ...state,
        prefers_grocerize_auto_mode: action.prefersGrocerizeAutoMode,
      };
    case UPDATE_USER_SAVINGS_LIMIT:
      return {
        ...state,
        manual_price_difference: action.manualPriceDifference,
      };
    case UPDATE_USER_SYSTEM_EMAILS_PREFERENCE:
      return {
        ...state,
        send_system_emails: action.sendSystemEmails,
      };
    case UPDATE_USER_MARKETING_EMAILS_PREFERENCE:
      return {
        ...state,
        send_marketing_emails: action.sendMarketingEmails,
      };
    default:
      return state;
  }
}
