import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../../../redux/actions/modalActions";
import LoginPrompt from "../../../popups/StandardPopup";
import MultiBuyPromo from "./MultiBuyPromo";
import SpecialPrice from "./SpecialPrice";

interface Props {
  item_pricing: ItemPricings;
  isCloseMatch?: boolean;
  id: number;
  showModal: any;
  hideModal: any;
  auth0: any;
  user: UserState;
  onClick?: any;
  isAvailableAtBoth?: boolean | undefined;
}

interface State {
  closeMatchTextColor: string;
  closeMatchBackground: string;
}

class ItemPricingDisplay extends Component<Props, State> {
  state = {
    closeMatchTextColor: "u-color-closeMatch",
    closeMatchBackground: "u-background--white",
  };
  getVendorNameFromId = (id: number) => {
    if (id === 1) {
      return "Coles";
    }
    return "Woolworths";
  };

  showPopup = (type: "login" | "subscribe") => {
    let modalContent;

    modalContent = () => <LoginPrompt type={"lists"} />;

    this.props.showModal({ component: modalContent });
  };

  itemMatching = (id: number, matchedItem: any) => {
    if (this.props.auth0.isAuthenticated) {
      this.props.hideModal();
      //Original item
      const originalItemId = id;
    } else {
      // Ask them to create an account
      this.showPopup("login");
    }
  };

  render() {
    const pricing = this.props.item_pricing;
    const isActive = pricing?.available;
    const vendorName = this.getVendorNameFromId(pricing?.vendor_id);
    const newBorderSpacing = vendorName === "Coles" ? " u-pr--8 " : " u-pl--8 ";
    const isSpecial =
      pricing.special && pricing.special_original_price
        ? pricing.special_original_price > pricing.price
        : false;

    return (
      <div
        className={
          "u-w-all u-h-116px--desktop" +
          (isActive ? " u-color-charcoal" : " u-color-grey") +
          newBorderSpacing +
          (this.props.isCloseMatch
            ? " u-border--closeMatch u-br--small u-mt--minus6 u-ph--16 u-pv--6 " +
              this.state.closeMatchBackground
            : "") +
          (vendorName === "Coles" && this.props.isCloseMatch
            ? " u-ml--minus16"
            : "") +
          (vendorName === "Woolworths" && this.props.isCloseMatch
            ? " u-mr--minus16 u-pr--0"
            : "")
        }
        onMouseOver={() => {
          this.setState({
            closeMatchTextColor: `${
              this.props.isCloseMatch && isActive ? "u-color-white" : ""
            }`,
            closeMatchBackground: `${
              this.props.isCloseMatch && isActive
                ? "u-background--closeMatch"
                : "u-background--grey"
            }`,
          });
        }}
        onMouseLeave={() => {
          this.setState({
            closeMatchTextColor: "u-color-closeMatch",
            closeMatchBackground: "u-background--white",
          });
        }}
        onClick={
          this.props.isCloseMatch && isActive ? this.props.onClick : null
        }
      >
        <div
          className={
            "u-bold u-main-font--vsmall " +
            (this.props.isCloseMatch && isActive
              ? this.state.closeMatchTextColor
              : isActive
              ? " u-color-" + vendorName.toLowerCase()
              : " u-color-grey")
          }
          style={{ fontSize: "13px" }}
        >
          {vendorName}
        </div>

        {/* Start */}
        <div
          className={
            "u-main-font--medium u-bold " +
            (this.props.isCloseMatch && isActive
              ? this.state.closeMatchTextColor
              : pricing?.available
              ? "u-color-charcoal"
              : "u-color-grey")
          }
        >
          {pricing.price === 0 || !isActive
            ? "N/A"
            : "$" + pricing.price.toFixed(2)}
        </div>

        {pricing.price === 0 || !isActive ? (
          "N/A"
        ) : pricing.cup_price && pricing.cup_volume ? (
          <div
            className={
              "u-main-font--vsmall " +
              (this.props.isCloseMatch
                ? this.state.closeMatchTextColor
                : "u-color-charcoal")
            }
          >
            $
            {pricing.cup_price && pricing.cup_price === "N/A"
              ? "N/A"
              : pricing.cup_price
              ? pricing.cup_price.toFixed(2)
              : " "}{" "}
            / {pricing.cup_volume}
          </div>
        ) : null}

        {/* End */}

        {isSpecial && pricing.special_original_price && (
          <SpecialPrice
            specialOriginalPrice={pricing.special_original_price}
            price={pricing.price}
            isCloseMatch={this.props.isCloseMatch}
            closeMatchBackground={this.state.closeMatchBackground}
            closeMatchTextColor={this.state.closeMatchTextColor}
          />
        )}
        {pricing.multibuy_quantity && pricing.multibuy_price && (
          <MultiBuyPromo
            multibuyQuantity={pricing.multibuy_quantity}
            multibuyPrice={pricing.multibuy_price}
            isCloseMatch={this.props.isCloseMatch}
            closeMatchBackground={this.state.closeMatchBackground}
            closeMatchTextColor={this.state.closeMatchTextColor}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0(
  connect(mapStateToProps, { showModal, hideModal })(ItemPricingDisplay)
);
