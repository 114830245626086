import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import {
  changeVendorsToBeOptimisedForAllItems,
  changeVendorToBuyFromForAllItems,
} from "../../redux/actions/basketActions";
import { COLES, GROCERIZE, WOOLWORTHS } from "../../redux/enums";

import { showModal } from "../../redux/actions/modalActions";
import Logo from "../naviagtions/Logo";
import LoginPrompt from "../popups/StandardPopup";

interface Props {
  auth0: any;
  user: UserState;
  showModal: any;
  basket: BasketState;
  changeVendorToBuyFromForAllItems?: any;
  changeVendorsToBeOptimisedForAllItems?: any;
}
interface State {
  selected: number;
}

class GrocerizePricesOverview extends Component<Props, State> {
  state = {
    selected: GROCERIZE,
  };

  componentDidUpdate = (prevProps: Props) => {
    const { isOptimisedPrice, isAllColes, isAllWools } =
      this.props.basket?.basketMetaData;

    if (
      prevProps.basket?.basketMetaData !== this.props.basket?.basketMetaData
    ) {
      // if isOptimised is true then the grocerize list should be selected
      if (isOptimisedPrice) {
        this.setState({ selected: GROCERIZE });
      } else if (isAllColes) {
        this.setState({ selected: COLES });
      } else if (isAllWools) {
        this.setState({ selected: WOOLWORTHS });
      } else {
        // we need this state because when isOptimisedPrice is false in redux we still have different value of state in here,
        // even if we force the state to use grocerize (split shop) they still have different value
        this.setState({ selected: -1 });
      }
    }
  };

  clickedToChangePricingOptions = (index: number) => {
    index === GROCERIZE
      ? this.props.changeVendorsToBeOptimisedForAllItems()
      : this.props.changeVendorToBuyFromForAllItems(index);
  };

  showPopup = (type: "login" | "subscribe" | "savings") => {
    let modalContent;

    if (type === "savings") {
      modalContent = () => <LoginPrompt type={"savings"} />;
    } else {
      modalContent = () => <LoginPrompt type={"lists"} />;
    }
    this.props.showModal({ component: modalContent });
  };

  renderGrocerizeMobilePrice() {
    return (
      <button
        className={
          "u-w-all u-br--small u-flex u-ph--32 u-pv--24 u-justify-between" +
          (this.state.selected === GROCERIZE
            ? " u-background--magenta u-color-white"
            : " u-background--grey u-color-magenta ")
        }
        onClick={() => {
          this.clickedToChangePricingOptions(GROCERIZE);
        }}
      >
        <div
          className={
            "u-flex u-align-center u-w-all u-justify-between" +
            (this.state.selected === GROCERIZE
              ? " u-color-white"
              : " u-color-charcoal")
          }
        >
          <div className="u-flex u-align-center">
            {this.state.selected === GROCERIZE ? (
              <Logo type="short" />
            ) : (
              <Logo type="shortFilled" />
            )}

            <div
              className="u-bold u-pl--20 u-text-left"
              style={{
                lineHeight: "18px",
                marginTop: "-4px",
                fontSize: "14px",
              }}
            >
              Grocerize <br /> (Shop split)
            </div>
          </div>

          <div className="u-bold u-pt--8" style={{ fontSize: "18px" }}>
            ${this.props.basket?.basketMetaData.grocerizeTotal.toFixed(2)}
          </div>
        </div>
      </button>
    );
  }

  renderColesMobilePrice() {
    return (
      <div className="u-w-all">
        <button
          className={
            "u-sticky u-mt--12 u-w-all u-br--small u-flex u-ph--32 u-pv--24 u-align-center u-justify-between" +
            (this.state.selected === COLES
              ? "  u-background--coles u-color-white u-border--magenta--heavy "
              : "  u-background--grey u-color-coles ")
          }
          onClick={() => {
            this.clickedToChangePricingOptions(COLES);
          }}
        >
          <div className="u-flex u-align-center">
            <div
              className={
                "u-bold" +
                (this.state.selected === COLES
                  ? " u-color-white "
                  : " u-color-coles ")
              }
              style={{ fontSize: "37px" }}
            >
              C
            </div>
            <div
              className="u-bold u-pl--20 u-text-left"
              style={{
                lineHeight: "18px",
                marginTop: "-4px",
                fontSize: "14px",
              }}
            >
              Coles{" "}
              <div
                className={
                  this.state.selected === COLES
                    ? " u-color-white"
                    : " u-color-charcoal"
                }
              >
                {" "}
                (Whole shop){" "}
              </div>
            </div>
          </div>

          <div
            className={
              " u-bold u-pt--8 " +
              (this.state.selected === COLES
                ? " u-color-white"
                : " u-color-charcoal")
            }
            style={{ fontSize: "18px" }}
          >
            ${this.props.basket?.basketMetaData.colesOnlyTotal.toFixed(2)}
          </div>
        </button>
        {this.state.selected === COLES &&
        this.props.basket?.basketMetaData.itemsUniqueToColes > 0
          ? this.showSwapsAndExclusives(
              COLES,
              this.props.basket.basketMetaData.itemsUniqueToWoolworths,
              0
            )
          : null}
      </div>
    );
  }

  renderWoolsMobilePrice() {
    return (
      <div className="u-w-all">
        <button
          className={
            "u-sticky u-mt--12 u-w-all u-br--small u-flex u-ph--32 u-pv--24 u-align-center u-justify-between" +
            (this.state.selected === WOOLWORTHS
              ? " u-background--woolworths u-color-white u-border--magenta--heavy "
              : " u-background--grey u-color-woolworths ")
          }
          onClick={() => {
            this.clickedToChangePricingOptions(WOOLWORTHS);
          }}
        >
          <div className="u-flex u-align-center">
            <div
              className={
                "u-bold" +
                (this.state.selected === WOOLWORTHS
                  ? " u-color-white"
                  : " u-color-woolworths")
              }
              style={{ fontSize: "37px" }}
            >
              W
            </div>
            <div
              className="u-bold u-pl--20 u-text-left"
              style={{
                lineHeight: "18px",
                marginTop: "-4px",
                fontSize: "14px",
              }}
            >
              Woolworths
              <div
                className={
                  this.state.selected === WOOLWORTHS
                    ? " u-color-white"
                    : " u-color-charcoal"
                }
              >
                {" "}
                (Whole shop){" "}
              </div>
            </div>
          </div>

          <div
            className={
              " u-bold u-pt--8 " +
              (this.state.selected === WOOLWORTHS
                ? " u-color-white"
                : " u-color-charcoal")
            }
            style={{ fontSize: "18px" }}
          >
            ${this.props.basket?.basketMetaData.woolworthsOnlyTotal.toFixed(2)}
          </div>
        </button>
        {this.state.selected === WOOLWORTHS &&
        this.props.basket?.basketMetaData.itemsUniqueToColes > 0
          ? this.showSwapsAndExclusives(
              WOOLWORTHS,
              this.props.basket.basketMetaData.itemsUniqueToColes,
              0
            )
          : null}
      </div>
    );
  }

  renderGrocerizeDesktopPrice() {
    return (
      <div className=" u-mr--8 u-top--0" id="grocerize">
        <button
          className={
            "third-tour u-text-left u-br--small u-main-font--small u-ph--20 u-pv--16" +
            (this.state.selected === GROCERIZE
              ? " u-background--magenta u-color-white"
              : " u-background--grey u-color-magenta ")
          }
          onClick={() => {
            this.clickedToChangePricingOptions(GROCERIZE);
          }}
          style={{ width: "200px", height: "120px" }}
        >
          {this.state.selected === GROCERIZE ? (
            <Logo type="short" />
          ) : (
            <Logo type="shortFilled" />
          )}
          <div
            className="u-bold"
            style={{
              lineHeight: "18px",
              marginTop: "-4px",
              fontSize: "14px",
            }}
          >
            Grocerize
          </div>
          <div
            className={
              this.state.selected === GROCERIZE ? "" : " u-color-charcoal"
            }
          >
            <div className="u-bold" style={{ fontSize: "14px" }}>
              (Shop Split)
            </div>
            <div style={{ fontSize: "18px" }}>
              <span className="u-bold">
                ${this.props.basket?.basketMetaData.grocerizeTotal.toFixed(2)}
              </span>
            </div>
          </div>
        </button>
      </div>
    );
  }

  renderColesDesktopPrice() {
    return (
      <div className=" u-mr--8 u-top--0" style={{ width: "175px" }} id="coles">
        <button
          className={
            "second-tour u-text-left u-w-all u-br--small u-main-font--small u-ph--20 u-pv--16 " +
            (this.state.selected === COLES
              ? " u-background--coles u-color-white u-border--magenta--heavy "
              : " u-background--grey u-color-coles ")
          }
          style={{ height: "120px" }}
          onClick={() => {
            this.clickedToChangePricingOptions(COLES);
          }}
        >
          <div className="u-bold" style={{ fontSize: "37px" }}>
            C
          </div>
          <div
            style={{
              lineHeight: "18px",
              marginTop: "8px",
              fontSize: "14px",
            }}
            className=" u-bold"
          >
            Coles
          </div>
          <div
            className={
              this.state.selected === COLES
                ? " u-color-white"
                : " u-color-charcoal"
            }
          >
            <div className="u-bold" style={{ fontSize: "14px" }}>
              (Whole Shop)
            </div>

            <div style={{ fontSize: "18px" }}>
              <span className="u-bold ">
                ${this.props.basket?.basketMetaData.colesOnlyTotal.toFixed(2)}
              </span>
            </div>
          </div>
        </button>
        {this.props.basket?.basketMetaData.itemsUniqueToWoolworths > 0
          ? this.showSwapsAndExclusives(
              COLES,
              this.props.basket.basketMetaData.itemsUniqueToWoolworths,
              0
            )
          : null}
      </div>
    );
  }

  renderWoolsDesktopPrice() {
    return (
      <div className=" u-mr--8" style={{ width: "175px" }} id="wools">
        <button
          className={
            "first-tour u-w-all u-text-left u-br--small u-main-font--small u-ph--20 u-pv--16 " +
            (this.state.selected === WOOLWORTHS
              ? " u-background--woolworths u-color-white u-border--magenta--heavy "
              : " u-background--grey u-color-woolworths ")
          }
          style={{ height: "120px" }}
          onClick={() => {
            this.clickedToChangePricingOptions(WOOLWORTHS);
          }}
        >
          <div className="u-bold" style={{ fontSize: "37px" }}>
            W
          </div>
          <div
            style={{
              lineHeight: "18px",
              marginTop: "8px",
              fontSize: "14px",
            }}
            className="u-bold"
          >
            Woolworths
          </div>
          <div
            className={
              this.state.selected === WOOLWORTHS
                ? " u-color-white"
                : " u-color-charcoal"
            }
          >
            <div className=" u-bold" style={{ fontSize: "14px" }}>
              (Whole Shop)
            </div>

            <div style={{ fontSize: "18px" }}>
              <span className="u-bold ">
                $
                {this.props.basket?.basketMetaData.woolworthsOnlyTotal.toFixed(
                  2
                )}
              </span>
            </div>
          </div>
        </button>
        {this.props.basket?.basketMetaData.itemsUniqueToColes > 0
          ? this.showSwapsAndExclusives(
              WOOLWORTHS,
              this.props.basket.basketMetaData.itemsUniqueToColes,
              0
            )
          : null}
      </div>
    );
  }

  showSwapsAndExclusives = (
    vendorIdSelected: typeof COLES | typeof WOOLWORTHS,
    uniqueCount: number,
    swapsCount: number
  ) => {
    // todo add swaps count
    return (
      <div
        className="u-w-all u-flex u-justify-center--mobile u-align-center u-background--darkgrey u-main-font--vsmall u-semi-bold u-p--4 u-ph--8 u-br--small u-color-charcoal"
        style={{ marginTop: "-20px", paddingTop: "28px" }}
      >
        <div
          className="u-main-font--medium u-color-charcoal"
          style={{ fontSize: "20px" }}
        >
          {vendorIdSelected === WOOLWORTHS ? "C" : "W"}
        </div>
        <div className="u-ml--8">
          Includes {uniqueCount} item{uniqueCount > 1 ? "s" : ""} exclusive to{" "}
          {vendorIdSelected === WOOLWORTHS ? "Coles" : "W'worths"}
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        <div className="u-hide--desktop u-w-all u-ph--8 u-pv--20 u-border--bottom--darkgrey u-pr--24--mobile">
          {/* <GrocerizePrice /> */}
          {this.renderGrocerizeMobilePrice()}
          {/* <ColesPricing/> */}
          {this.renderColesMobilePrice()}
          {/* <WoolworthsPricing/> */}
          {this.renderWoolsMobilePrice()}
        </div>
        <div
          className="u-hide--mobile u-mv--12"
          style={{ marginTop: "47px", height: "170px" }} // height to amke space for swaps and uniques divs
        >
          <div className="u-float--right ">
            <div className="u-flex">
              {/* <GrocerizePrice /> */}
              {this.renderGrocerizeDesktopPrice()}
              {/* <ColesPricing/> */}
              {this.renderColesDesktopPrice()}
              {/* <WoolworthsPricing/> */}
              {this.renderWoolsDesktopPrice()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  basket: state.basket,
});

export default withAuth0(
  connect(mapStateToProps, {
    changeVendorToBuyFromForAllItems,
    changeVendorsToBeOptimisedForAllItems,
    showModal,
  })(GrocerizePricesOverview)
);
