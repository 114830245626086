import { Component } from "react";
import { connect } from "react-redux";
import { getMoreItems } from "../../redux/actions/itemActions";
import ThreeDots from "../buttons/ThreeDots";

interface Props {
  items: ItemsState;
  search: SearchState;
  categories: CategoryState;
  getMoreItems: any;
  spacerMarginTop: string;
}

interface State {
  currentPage: number;
  isLoading: boolean;
}

class PaginationClicker extends Component<Props, State> {
  state = {
    currentPage: 1,
    isLoading: false,
  };

  async loadMoreItems() {
    try {
      this.setState({ isLoading: true });
      await this.props.getMoreItems(
        this.props.search.searchTerm,
        this.props.categories.selectedNode?.crawler_id,
        this.props.items.currentPageNumber + 1,
        this.props.items.currentItemId,
        this.props.items.isHalfThePriceClicked,
        this.props.items.sortTypeName
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    const isLoading = this.state.isLoading;

    if (this.props.items.currentPageNumber === this.props.items.totalPages) {
      return null;
    }

    return (
      <div
        className="u-w-all u-flex u-justify-center u-pv--24 u-align-center u-relative"
        style={{ bottom: 0 }}
      >
        <button
          onClick={() => this.loadMoreItems()}
          className="u-p--16 u-bold u-text-center u-background--magenta u-color-white u-br--small u-w-200px--desktop u-h--50px"
        >
          <div>{isLoading ? <ThreeDots /> : "Load more products"}</div>
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  items: state.items,
  search: state.search,
  categories: state.categories,
});

export default connect(mapStateToProps, { getMoreItems })(PaginationClicker);
