import React, { Component } from "react";

import { hideModal } from "../../../redux/actions/modalActions";
import { connect } from "react-redux";
import { saveFilterChange } from "../../../redux/actions/filterActions";
import { filterItems, removeFilter } from "../../../redux/actions/itemActions";

interface Props {
  hideModal?: any;
  saveFilterChange?: any;
  filters?: FilterState;
  items?: ItemsState;
  filterItems: any;
  removeFilter: any;
}

class FiltersDropdownModal extends Component<Props, {}> {
  updateFilterSelection = (filter: FilterOption) => {
    // ensure connected to redux
    if (this.props.items) {
      // if filter is already selected
      if (
        this.props.items.appliedFilters.some(
          (applied) => applied.filter_id === filter.filter_id
        )
      ) {
        // remove the filter
        this.props.removeFilter(filter);
      } else {
        this.props.saveFilterChange(filter.filter_id);

        filter?.action();
      }
    }
  };

  render() {
    const filters: FilterOption[] = [
      {
        filter_id: 1,
        name: "On special",
        action: () =>
          this.props.filterItems({
            filter_id: 1,
            name: "On special",
            enum: "Special",
          }),
      },
      // {
      //   filter_id: 2,
      //   name: "Favourite item",
      //   action: () =>
      //     this.props.filterItems({
      //       filter_id: 2,
      //       name: "Favourite item",
      //       enum: "Favourite",
      //     }),
      // },
      // {
      //   filter_id: 3,
      //   name: "Price alert list",
      //   action: () =>
      //     this.props.filterItems({
      //       filter_id: 3,
      //       name: "Price alert list",
      //       enum: "PriceAlert",
      //     }),
      // },
      // { filter_id: 4, name: "Close match items" },
      {
        filter_id: 5,
        name: "Exclusive to Coles",
        action: async () => {
          // remove the exclusive to wools filter (if present)
          this.props.removeFilter({
            filter_id: 6,
            name: "Exclusive to Woolworths",
            action: () => {},
          });

          // add the exclusive to coles fitler
          this.props.filterItems({
            filter_id: 5,
            name: "Exclusive to Coles",
            enum: "ExclusiveColes",
          });
          return;
        },
      },
      {
        filter_id: 6,
        name: "Exclusive to Woolworths",
        action: async () => {
          // remove the coles filter (if present)
          this.props.removeFilter({
            filter_id: 5,
            name: "Exclusive to Coles",
            enum: "ExclusiveColes",
            action: () => {},
          });

          // add wools
          this.props.filterItems({
            filter_id: 6,
            name: "Exclusive to Woolworths",
            enum: "ExclusiveWools",
            action: () => {},
          });

          return;
        },
      },
      {
        filter_id: 7,
        name: "Available at both",
        action: async () => {
          // remove the coles filter (if present)
          this.props.removeFilter({
            filter_id: 5,
            name: "Exclusive to Coles",
            enum: "ExclusiveColes",
            action: () => {},
          });

          // remove the exclusive to wools filter (if present)
          this.props.removeFilter({
            filter_id: 6,
            name: "Exclusive to Woolworths",
            action: () => {},
          });

          // Add Available to both
          this.props.filterItems({
            filter_id: 7,
            name: "Available at both",
            enum: "AvailableAtBoth",
            action: () => {},
          });

          return;
        },
      },
    ];
    return (
      <div className="o-modal__container">
        <div className="u-w-all u-flex u-justify-center">
          <div className="o-caret-marker"></div>
        </div>
        <div
          className="u-background--white u-br--vsmall u-pt--8"
          style={{
            marginLeft: "-175px",
            marginRight: "100px",
            width: "313px", // this is design
          }}
        >
          {filters.map((filter, index) => {
            let isSelected =
              this.props.items?.appliedFilters.some(
                (applied) => applied.filter_id === filter.filter_id
              ) || false;
            const isLast = index === filters.length - 1;

            return (
              <div
                className={
                  "u-flex u-align-center u-pv--12 u-ph--24" +
                  (isLast ? "" : " u-border--bottom--grey")
                }
                key={index}
              >
                <button
                  className={
                    isSelected ? "u-background--magenta" : "u-background--grey"
                  }
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "2px",
                  }}
                  onClick={() => this.updateFilterSelection(filter)}
                ></button>
                <div
                  className={
                    "u-main-font--vsmall u-ph--12 u-semi-bold" +
                    (isSelected ? " u-color-magenta" : " u-color-charcoal")
                  }
                  style={{ fontSize: "14px" }}
                >
                  {filter.name}
                </div>
              </div>
            );
          })}
          <div className="u-flex u-align-center ">
            <button
              className="u-background--magenta u-w-all u-pv--16 u-main-font--small u-color-white u-br--bottom--vsmall u-semi-bold"
              onClick={() => this.props.hideModal()}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  filters: state.filters,
  items: state.items,
});

export default connect(mapStateToProps, {
  hideModal,
  saveFilterChange,
  filterItems,
  removeFilter,
})(FiltersDropdownModal);
