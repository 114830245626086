import { TOGGLE_TOUR_GUIDE } from "../enums";

const initialState: TourModalState = {
  isOpen: false,
};

export default function tourReducer(
  state = initialState,
  action: { type: string }
) {
  switch (action.type) {
    case TOGGLE_TOUR_GUIDE:
      return {
        isOpen: !state.isOpen,
      };
    default: {
      return state;
    }
  }
}
