import { withAuth0 } from "@auth0/auth0-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { sortUserFavourite } from "../../redux/actions/favouritesActions";
import FavouritesRows from "../favourites/FavouritesRows";
import FavouritesSortButton from "../favourites/favouritesOptions/FavouritesSortButton";
import FavouritesSortDropdown from "../favourites/favouritesOptions/FavouritesSortDropdown";

interface Props {
  user: UserState;
  auth0: any;
  showModal: any;
  sortUserFavourite: any;
  favourites: FavouritesState;
}

interface State {}

class FavouritesView extends Component<Props, State> {
  componentDidUpdate = (prevProps: Props) => {
    if (this.props.favourites.favourites !== prevProps.favourites.favourites) {
      this.setState({ favourites: this.props.favourites.favourites });
    }
  };
  render() {
    const isMobile = window.innerWidth < 600;
    return (
      <div className="u-w-all u-background--white">
        {isMobile ? (
          <div className="u-hide--desktop u-bakground--grey u-w-all u-h-all--desktop u-flex u-justify-between u-background--grey">
            <div
              className="u-main-font--medium u-bold  u-pv--24 u-ph--32"
              style={{ fontSize: 16 }}
            >
              Favourites
              <span className="u-color-magenta u-ph--12">
                {this.props.favourites.favourites.length}
              </span>
            </div>
            <FavouritesSortDropdown />
          </div>
        ) : (
          <div className="u-hide--mobile u-border--bottom--grey u-ph--48 u-pt--48 u-pb--12 u-flex u-justify-center">
            <div className="u-w-80pct">
              <div className="u-main-font--large u-pt--32 u-bold u-flex u-align-baseline ">
                Favourites
                <span className="u-color-magenta u-ph--12">
                  {this.props.favourites.favourites.length}
                </span>
              </div>
              <div className="u-mt--48 u-flex u-align-center u-main-font--vsmall ">
                <FavouritesSortButton favourites={this.props.favourites} />
              </div>
            </div>
          </div>
        )}
        <div id="favourites" className="u-h-all ">
          <FavouritesRows />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  favourites: state.favourites,
});

export default withAuth0(
  connect(mapStateToProps, { showModal, sortUserFavourite })(FavouritesView)
);
