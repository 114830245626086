import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../../redux/actions/modalActions";
import variables from "../../../../styles/utils/variables.scss";
import { Icon } from "../../../icons/Icon";
import LoginPrompt from "../../../popups/StandardPopup";
import SaveItemToListModal from "./SaveItemToListModal";

interface Props {
  auth0: any;
  showModal: any;
  user: UserState;
  itemData: ItemData;
  forDisplay?: boolean;
}

interface State {}

class SaveItemToListButton extends Component<Props, State> {
  clickedToSaveToList = () => {
    // are they loggedin?
    if (this.props.auth0.isAuthenticated) {
      this.showAddToListModal();
    } else {
      // Ask them to create an account
      this.showPopup("login");
    }
  };

  showPopup = (type: "login" | "subscribe") => {
    let modalContent;

    modalContent = () => <LoginPrompt type={"lists"} />;

    this.props.showModal({ component: modalContent });
  };

  showAddToListModal = () => {
    const itemCard = document.getElementById(this.props.itemData.id.toString());
    const pageWidth = document.body.scrollWidth;
    const header = document.getElementById("header");

    if (header) {
      const originalPositionOfCard = itemCard?.getBoundingClientRect();
      const alignToTop = false;
      if (originalPositionOfCard!.top < header?.clientHeight) {
        itemCard?.scrollIntoView(alignToTop);
      }
    }

    if (itemCard) {
      const postionOfCard = itemCard.getBoundingClientRect();
      const modalContent = () => (
        <div
          className="u-overflow-hidden"
          style={
            pageWidth > 600
              ? {
                  marginTop: postionOfCard.top + "px",
                  marginLeft: postionOfCard.left + "px",
                  width: postionOfCard.right - postionOfCard.left + "px",
                }
              : { width: "100vw" }
          }
        >
          <SaveItemToListModal
            itemData={this.props.itemData}
            heightOfCard={postionOfCard.bottom - postionOfCard.top}
          />
        </div>
      );
      this.props.showModal({
        position: pageWidth > 600 ? "" : "bottom",
        component: modalContent,
        isFixed: false,
      });
    }
  };

  render() {
    const violet = variables.violetColor;

    return (
      <button
        className="u-align-center u-text-center u-flex"
        onClick={this.clickedToSaveToList}
        disabled={this.props?.forDisplay ? this.props?.forDisplay : false}
      >
        <div>
          <Icon
            id="chevron_down"
            size="s"
            fill="none"
            stroke={violet}
            className="u-rotate--negative360deg"
          />
        </div>
        <div className="u-main-font--vsmall u-semi-bold u-color-charcoal ">
          Save to list
        </div>
      </button>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0(
  connect(mapStateToProps, { showModal })(SaveItemToListButton)
);
