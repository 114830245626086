interface ModalDropdownState {
  isOpen: boolean;
}
const initialState: ModalDropdownState = {
  isOpen: false,
};
export default function modalDropdownReducer(
  state = initialState,
  action: any
) {
  switch (action.type) {
    case "OPEN_MODAL_DROPDOWN":
      return {
        isOpen: true,
      };
    case "CLOSE_MODAL_DROPDOWN":
      return initialState;
    default:
      return state;
  }
}
