import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { Icon } from "../icons/Icon";
import { openModalDropdown } from "../../redux/actions/modalDropdownActions";
import GrocerizeSortItemsModalDropdown from "./GrocerizeSortItemsModalDropdown";

interface Props {
  basket: BasketState;
  openModalDropdown: () => void;
  modalDropdown: ModalDropdownState;
}

class GrocerizeSortItemsButton extends Component<Props> {
  handleClickToExpand = () => {
    if (!this.props.modalDropdown.isOpen) {
      this.props.openModalDropdown();
    }
  };

  render() {
    const sortTypeName = this.props.basket.basketMetaData.sortTypeName;

    return (
      <>
        <div className="u-hide--mobile">
          <button
            className="u-flex u-align-center u-justify-center u-h-all u-background--grey u-pl--24 u-pv--8"
            style={{
              borderRadius: "2px",
            }}
            onClick={this.handleClickToExpand}
          >
            <Icon id="chevron_up_down" size="s" />
            <div className="u-main-font--vsmall u-color-charcoal u-text-oneline u-ml--16 u-main-font--14--small">
              Sort by <span className="u-bold">{sortTypeName}</span>
            </div>
          </button>

          <div className="u-mt--16 u-ml--16 u-absolute">
            <div style={{ marginLeft: "-20px" }}>
              <GrocerizeSortItemsModalDropdown />
            </div>
          </div>
        </div>
        <div className="u-hide--desktop">
          <div className="u-relative u-z-index--2">
            <button
              className="u-flex u-align-center u-w-all u-h-all u-background--grey u-pv--24 u-pl--32"
              onClick={this.handleClickToExpand}
            >
              <Icon id="chevron_up_down" size="s" />
              <div className="u-main-font--vsmall u-color-charcoal u-text-oneline u-ml--12 u-main-font--14--small">
                Sort by <span className="u-bold"> {sortTypeName} </span>
              </div>
            </button>
          </div>

          <GrocerizeSortItemsModalDropdown />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    basket: state.basket,
    modalDropdown: state.modalDropdown,
  };
};
export default connect(mapStateToProps, { openModalDropdown })(
  GrocerizeSortItemsButton
);
