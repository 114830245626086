import { Component } from "react";
import variables from "../../styles/utils/variables.scss";
import { Icon } from "../icons/Icon";

interface Props {
  label: string;
  isChecked: boolean;
  action: (isSelected: boolean) => void;
}
interface State {
  isSelected: boolean;
}
class Checkbox extends Component<Props, State> {
  state = {
    isSelected: this.props.isChecked ? this.props.isChecked : false,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.isChecked !== this.props.isChecked) {
      this.setState({ isSelected: this.props.isChecked });
    }
  };

  onChangeHandler = () => {
    this.setState({
      isSelected: !this.state.isSelected,
    });

    this.props.action(!this.state.isSelected);
  };

  render() {
    const { isSelected } = this.state;
    const { label } = this.props;
    const violet = variables.violetColor;

    return (
      <div className="u-flex u-align-center u-justify-center">
        {!isSelected && (
          <button
            className={
              isSelected ? "u-background--magenta" : "u-background--darkgrey"
            }
            style={{ width: "25px", height: "25px", borderRadius: "2px" }}
            onClick={this.onChangeHandler}
          />
        )}

        {isSelected && (
          <Icon id="tick" size="xs" stroke={violet} fill={violet} />
        )}
        <div
          className={`u-cursor--pointer u-main-font--small u-text-oneline u-semi-bold u-ml--16 ${
            isSelected ? " u-color-magenta" : " u-color-charcoal"
          }`}
          style={{ fontSize: "14px" }}
          onClick={this.onChangeHandler}
        >
          {label}
        </div>
      </div>
    );
  }
}

export default Checkbox;
