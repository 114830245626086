import { Component } from "react";
import { connect } from "react-redux";
import {
  getCategoryTreeFromDB,
  saveNewCategoryFilterSelection,
} from "../../redux/actions/categoryAction";
import { getItemsToDisplay } from "../../redux/actions/itemActions";

import PaginationClicker from "../naviagtions/PaginationClicker";
import ItemCard from "./ItemCard/ItemCard";
import ItemCardLoading from "./ItemCard/ItemCardLoading";

interface State {
  isLoading: boolean;
  itemsToDisplay: ItemData[];
}

interface Props {
  search: SearchState;
  categories: CategoryState;
  items: ItemsState;
  getItemsToDisplay: any;
}

class ItemsGrid extends Component<Props, State> {
  state = {
    isLoading: true,
    itemsToDisplay: [],
  };

  componentDidMount = () => {
    // find products related to it
    this.getItems();
  };

  componentDidUpdate = (prevProps: Props) => {
    // if search changes or catergory filter changes
    if (prevProps.search.searchTerm !== this.props.search.searchTerm) {
      prevProps.categories.selectedNode = null;
      this.props.categories.selectedNode = null;
    }

    if (
      prevProps.categories.selectedNode !== this.props.categories.selectedNode
    ) {
      prevProps.search.searchTerm = "";
      this.props.search.searchTerm = "";
    }

    if (
      prevProps.search.searchTerm !== this.props.search.searchTerm ||
      prevProps.categories.selectedNode !== this.props.categories.selectedNode
    ) {
      // get new items to display
      this.getItems();

      // show loading screen
      this.setState({ isLoading: true });
    }

    // if redux items state updates, display them
    if (prevProps.items.itemsToDisplay !== this.props.items.itemsToDisplay) {
      this.setState({
        itemsToDisplay: this.props.items.itemsToDisplay,
        isLoading: false,
      });
    }
  };

  getItems = () => {
    const searchTerm = this.props.search.searchTerm;
    const categoryFilterId = this.props.categories.selectedNode?.crawler_id;
    const itemFilters = this.props.items.appliedFilters;
    const sortType = this.props.items.sortTypeName;
    const isHalfThePriceClicked = this.props.items.isHalfThePriceClicked;

    this.props.getItemsToDisplay(
      searchTerm,
      categoryFilterId,
      itemFilters,
      sortType,
      isHalfThePriceClicked
    );
  };

  getWhiteSpaceHeight = () => {
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect().bottom || 0;
    const whiteSpaceHeight = window.innerHeight - headerHeight;
    const newItemsHeight = window.innerHeight - whiteSpaceHeight;
    const maxMarginTop = 200;

    const dynamicHeight =
      newItemsHeight > maxMarginTop
        ? maxMarginTop + "px"
        : newItemsHeight + "px";

    return dynamicHeight;
  };

  render() {
    const itemsToDisplay = this.state.itemsToDisplay;
    const pageSpacerMarginTop = this.getWhiteSpaceHeight();

    return (
      <>
        <div className="u-w-all u-h-all u-background--grey">
          {this.state.isLoading && (
            <div className="u-background--grey u-w-all u-h-all u-grid--items u-ph--16 u-pb--16 u-mb--16">
              <ItemCardLoading />
              <ItemCardLoading />
              <ItemCardLoading />
              <ItemCardLoading />
              <ItemCardLoading />
            </div>
          )}
          {itemsToDisplay.length === 0 && (
            <div
              className="u-main-font--large u-color-magenta u-semi-bold u-w-all u-p--32 u-ph--48 u-pb--16 u-mb--16"
              style={{ height: window.innerHeight }}
            >
              Sorry, we can't find any items for{" "}
              <span className="u-bold">"{this.props.search.searchTerm}"</span>
              <br />
              <span className="u-main-font--small">
                Please try again with fewer or different words
              </span>
            </div>
          )}
          {itemsToDisplay.length > 0 && (
            <>
              <div className="u-background--grey u-grid--items u-pt--4--mobile u-ph--16 u-pb--16 u-mb--16">
                {itemsToDisplay.map((item, index) => {
                  return (
                    <ItemCard itemData={item} key={item["id"] + "-" + index} />
                  );
                })}
              </div>
              <PaginationClicker spacerMarginTop={pageSpacerMarginTop} />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  search: state.search,
  categories: state.categories,
  items: state.items,
});

export default connect(mapStateToProps, {
  saveNewCategoryFilterSelection,
  getCategoryTreeFromDB,
  getItemsToDisplay,
})(ItemsGrid);
