import { Dispatch } from "react";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import { findColesAndWoolsPricing } from "../../utils/UIFunctions";

export const getLists = () => async (dispatch: Dispatch<any>) => {
  return await httpClient.instance
    .get("/user/lists?show_pricing=true")
    .then((res) => {
      // cherry pick no current baskets
      let lists: List[] = [];
      // seperate into lists and baskets
      res.data.forEach((list: List) => {
        if (!list.is_current_basket) {
          lists.push(list);
        }
      });

      dispatch({
        type: "GET_LISTS",
        lists: lists,
      });
    })
    .catch((e) => {
      console.log(e);
    });
};

interface ListDefintion {
  title: string;
  is_current_basket: boolean;
  items: ItemData[];
}
export const createList = async (list: ListDefintion) => {
  const { title, is_current_basket, items } = list;
  await httpClient.instance
    .post("/user/lists", {
      title,
      is_current_basket,
      items,
    })
    .then((data) => {
      return data;
    })
    .catch((e) => {
      console.log(e);
    });
};

interface ListItemDefinition {
  list_id: number;
  quantity: number;
  item_id: number;
}

export const addItemsToList = async (listItem: ListItemDefinition) => {
  const { list_id, quantity, item_id } = listItem;

  await httpClient.instance.post("/user/lists/items", {
    list_id,
    quantity,
    item_id,
  });
};

export const sortLists = (
  sortType: "A-Z" | "Z-A" | "AscPrice" | "DescPrice" | "DescSavings" | "Created"
) => {
  return {
    type: "SORT_LISTS",
    sortType,
  };
};

export const prettifySortTypeIdToString = (
  sortType:
    | ""
    | "A-Z"
    | "Z-A"
    | "AscPrice"
    | "DescPrice"
    | "DescSavings"
    | "Created"
) => {
  switch (sortType) {
    case "A-Z":
      return "Name (A-Z)";
    case "Z-A":
      return "Name (Z-A)";
    case "AscPrice":
      return "Price (High to Low)";
    case "DescPrice":
      return "Price (Low to High)";
    case "DescSavings":
      return "Largest Savings";
    case "Created":
      return "Date Created";
    default:
      return;
  }
};

/**
 * Compute the savings total in the my list page
 * @param listItems
 * @returns totalSavings
 */
export const grocerizeMyListTotalSavings = (listItems: any[]) => {
  let woolworthsOnlyTotal = 0;
  let colesOnlyTotal = 0;
  let colesTotalUsingGrocerize = 0;
  let woolsTotalUsingGrocerize = 0;
  let totalSavings = 0;

  listItems.forEach((item) => {
    if (item.item_pricing.length) {
      const quantity = item.quantity || 0;

      // get the two pricings details
      const { colesPricing, woolsPricing } = findColesAndWoolsPricing(item);
      // if price doesn't exist set to be 0
      const colesPrice =
        colesPricing?.price && colesPricing.available ? colesPricing.price : 0;
      const woolsPrice =
        woolsPricing?.price && woolsPricing.available ? woolsPricing.price : 0;

      woolworthsOnlyTotal += quantity * (woolsPrice ? woolsPrice : colesPrice);
      colesOnlyTotal += quantity * (colesPrice ? colesPrice : woolsPrice);

      // Update the baskets total costs from vendors
      if (item.selected_vendor_id === 1) {
        colesTotalUsingGrocerize += colesPrice * quantity;
      } else if (item.selected_vendor_id === 2) {
        woolsTotalUsingGrocerize += woolsPrice * quantity;
      } else {
        // if no preference stated, we should use the cheapest *real* price
        // a price is real if it is not zero currently
        if (colesPrice === 0) {
          woolsTotalUsingGrocerize += woolsPrice * quantity;
        } else if (woolsPrice === 0) {
          colesTotalUsingGrocerize += colesPrice * quantity;
        } else if (colesPrice > woolsPrice) {
          woolsTotalUsingGrocerize += woolsPrice * quantity;
        } else {
          colesTotalUsingGrocerize += colesPrice * quantity;
        }
      }
    }

    const mostExpensiveVendor =
      colesOnlyTotal > woolworthsOnlyTotal
        ? colesOnlyTotal
        : woolworthsOnlyTotal;

    const totalPrice =
      (colesTotalUsingGrocerize || 0) + (woolsTotalUsingGrocerize || 0);

    totalSavings = Math.round(100 * (mostExpensiveVendor - totalPrice)) / 100;
  });
  return totalSavings.toFixed(2);
};
