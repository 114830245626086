import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { resetCss } from "../../utils/modal";
import { Icon } from "../icons/Icon";
interface Props {
  showModal: any;
  hideModal: any;
  type?:
    | "favourites"
    | "alerts"
    | "lists"
    | "cart"
    | "manual_mode"
    | "savings"
    | "toggles";
  auth0: any;
}

interface State {
  id: string;
  title: string;
  image: string;
  imageAlt: string;
  body: string;
  premiumFeature: boolean;
}

class StandardPopup extends Component<Props, State> {
  state = {
    id: "",
    title: "",
    image: "",
    imageAlt: "",
    body: "",
    premiumFeature: false,
  };

  componentDidMount = () => {
    const popups = [
      {
        id: "favourites",
        title: "Add items to your list of favourites!",
        image: "../popups/favourites.svg",
        imageAlt: "A heart becoming fully colored in",
        body: "Star your favourite products and use the ‘favourite products’ filter to find them quickly when shopping!",
        premiumFeature: false,
      },
      {
        id: "alerts",
        title: "Add price alerts to items and save!",
        image: "../popups/alerts.svg",
        imageAlt: "Being able to create alerts",
        body: "Add price alerts to your favourite products and we’ll notify you when they drop in price!",
        premiumFeature: false,
      },
      {
        id: "lists",
        title: "Add items to your other lists!",
        image: "../popups/lists.svg",
        imageAlt: "Add items to lists to quickly review",
        body: "Create, modify and share lists to make your shopping experience quick and easy!",
        premiumFeature: false,
      },
      {
        id: "cart",
        title: "Add items to the cart and we’ll compare the prices for you!",
        image: "../popups/carts.svg",
        imageAlt: "Create carts to quickly compare basket prices",
        body: "Build and save multiple lists, compare prices and create a shopping list to go! Sign up for Free!",
        premiumFeature: false,
      },
      {
        id: "savings",
        title:
          "Upgrade an unlock the Grocerize Maximise Savings (Shop Split) function",
        image: "../popups/savings.svg",
        imageAlt: "Save money by grocerizing your shopping list",
        body: "Let the Grocerize algorithm create your shopping list for you to maximise your savings! ",
        premiumFeature: false,
      },
      {
        id: "manual_mode",
        title:
          "Upgrade an unlock ‘Manual Mode’, allowing you to fine tune your savings!",
        image: "../popups/manual_mode.svg",
        imageAlt:
          "Save time by fine tuning when you want to go to the other vendor",
        body: "Use manual mode to only move items in your shopping list that are over a certain savings threshold. e.g. Only show me items at Coles when I can save over $4.00 per item!",
        premiumFeature: false,
      },
      {
        id: "toggles",
        title:
          "Upgrade and unlock the ability to customise your shopping list!",
        image: "../popups/toggles.svg",
        imageAlt: "Toggle individual items to by from specific vendors",
        body: "Customise your shopping list by toggling between Coles and Woolworths products",
        premiumFeature: false,
      },
    ];

    let popupDefinition;
    if (this.props.type) {
      popupDefinition =
        popups.find((item) => item.id === this.props.type) || popups[0];
    } else {
      popupDefinition = popups[Math.floor(Math.random() * popups.length)];
    }

    this.setState(popupDefinition);
  };

  showSignUp = () => {
    this.props.auth0.loginWithRedirect({
      login_hint: "signup",
      redirect_uri: "/signup",
    });
  };

  showLogIn = () => {
    this.props.auth0.loginWithRedirect({
      login_hint: "login",
    });
  };

  closeModal = () => {
    resetCss();
    this.props.hideModal();
  };

  render() {
    const violet = variables.violetColor;
    return (
      <div className="u-br--small o-modal__container">
        <div
          className="o-modal--content-centered u-modal--content-bottom--mobile u-w-all--mobile u-relative"
          style={{
            width: window.innerWidth > 600 ? "500px" : "100%",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18 }}
            onClick={() => this.closeModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>
          <div
            style={{
              fontSize: "14px",
            }}
            className="u-main-font--small u-color-charcoal u-background--white u-br--top--small"
          >
            <div className="u-hide--desktop" style={{ paddingTop: 18 }}>
              <Icon id="grocerize" fill={violet} width={115} height={29} />
            </div>

            <div className="u-ph--48 u-text-center u-mauto u-pb--30--desktop ">
              <div className="u-hide--mobile u-pt--48--desktop">
                <Icon id="grocerize" fill={violet} width={115} height={48} />
              </div>
              <div className=" u-align-middle u-w-all u-text-center u-pb--48 u-pt--20">
                <div className="u-main-font--small u-bold">
                  {this.state.title}
                </div>
                <div className="u-pt--24">
                  <img
                    style={{ maxHeight: 80, minWidth: 200, maxWidth: 300 }}
                    src={this.state.image}
                    alt={this.state.imageAlt}
                  />
                </div>
                <div className="u-pt--24 u-ph--8 u-pb--24">
                  {this.state.body}
                </div>
                {this.props.auth0.isAuthenticated ? null : (
                  <div className="u-pt--24--desktop u-ph--8 u-pt--12--mobile">
                    Already have an account,{" "}
                    <button
                      className="u-color-magenta"
                      onClick={() => this.showLogIn()}
                    >
                      Login
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.props.auth0.isAuthenticated ? (
            <button
              className="u-w-all u-background--magenta u-pv--20 u-bold
                u-color-white u-br--bottom--small"
              onClick={() => this.closeModal()}
            >
              Start saving!
            </button>
          ) : (
            <button
              className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-br--bottom--small"
              onClick={() => this.showSignUp()}
            >
              Sign up and start saving!
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withAuth0<any>(
  connect(null, { showModal, hideModal })(StandardPopup)
);
