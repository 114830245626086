import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../../redux/actions/modalActions";
import variables from "../../../../styles/utils/variables.scss";
import { Icon } from "../../../icons/Icon";
import LoginPrompt from "../../../popups/StandardPopup";
import SaveItemToPriceAlertsModal from "./SaveItemToPriceAlertsModal";

interface Props {
  auth0: any;
  showModal: any;
  user: UserState;
  priceAlerts: PriceAlertsState;
  itemData: ItemData;
  forDisplay?: boolean;
}

interface State {}

class SavePriceAlertButton extends Component<Props, State> {
  clickedAddPriceAlert = () => {
    // are they loggedin?
    if (this.props.auth0.isAuthenticated) {
      //  TODO : add or remove alert
      this.showAddToPriceAlertsModal();
    } else {
      // Ask them to create an account
      this.showPopup("login");
    }
  };

  showPopup = (type: "login" | "subscribe") => {
    let modalContent;

    modalContent = () => <LoginPrompt type={"alerts"} />;

    this.props.showModal({ component: modalContent });
  };

  showAddToPriceAlertsModal = () => {
    const itemCard = document.getElementById(this.props.itemData.id.toString());

    const header = document.getElementById("header");

    const pageWidth = document.body.scrollWidth;

    if (header) {
      const originalPositionOfCard = itemCard?.getBoundingClientRect();
      const alignToTop = false;
      if (originalPositionOfCard!.top < header?.clientHeight) {
        itemCard?.scrollIntoView(alignToTop);
      }
    }

    if (itemCard) {
      const positionOfCard = itemCard.getBoundingClientRect();
      const modalContent = () => (
        <div
          className="u-overflow-hidden"
          style={
            pageWidth > 600
              ? {
                  marginTop: positionOfCard?.top + "px",
                  marginLeft: positionOfCard?.left + "px",
                  width: positionOfCard.right - positionOfCard.left + "px",
                }
              : { width: "100vw" }
          }
        >
          <SaveItemToPriceAlertsModal
            itemData={this.props.itemData}
            heightOfCard={positionOfCard.bottom - positionOfCard.top}
          />
        </div>
      );
      this.props.showModal({
        position: pageWidth > 600 ? "" : "bottom",
        component: modalContent,
        isFixed: false,
      });
    }
  };

  render() {
    const violet = variables.violetColor;
    return (
      <button
        className="u-align-center u-text-center u-flex"
        onClick={this.clickedAddPriceAlert}
        disabled={this.props?.forDisplay ? this.props?.forDisplay : false}
      >
        <div className="u-ph--4">
          {this.props.priceAlerts.item_ids?.includes(
            this.props.itemData.crawler_id
          ) ? (
            <Icon id="tick" size="xs" stroke={violet} fill={violet} />
          ) : (
            <Icon id="add" size="xs" stroke={violet} fill={violet} />
          )}
        </div>
        {this.props.priceAlerts.item_ids?.includes(
          this.props.itemData.crawler_id
        ) ? (
          <div className="u-main-font--vsmall u-semi-bold u-color-pink">
            View Alert
          </div>
        ) : (
          <div className="u-main-font--vsmall u-semi-bold u-color-charcoal">
            Price Alert
          </div>
        )}
      </button>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  priceAlerts: state.priceAlerts,
});
export default withAuth0(
  connect(mapStateToProps, { showModal })(SavePriceAlertButton)
);
