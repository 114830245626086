import { withAuth0 } from "@auth0/auth0-react";
import React, { Component } from "react";
import DeleteFavouriteModalContent from "./DeleteFavouriteModalContent";

interface Props {
  auth0: any;
  itemData: ItemData;
}

interface State {
  showDelete: boolean;
}

class FavouritesOptionsModal extends Component<Props, State> {
  state = {
    showDelete: false,
  };
  deleteFavourite = async () => {
    this.setState({ showDelete: true });
  };

  render() {
    const FavouriteOptions = [
      {
        name: "Delete Favourite Item",
        function: this.deleteFavourite,
      },
    ];
    return (
      <div className="o-modal__container">
        <div className="u-w-all u-flex u-justify-end">
          <div className="o-caret-marker"></div>
        </div>
        {this.state.showDelete ? (
          <DeleteFavouriteModalContent itemData={this.props.itemData} />
        ) : (
          <div
            className="u-background--white u-pt--8  u-br--vsmall"
            style={{
              marginLeft: "-50px",
              marginRight: "100px",
              width: "230px",
            }}
          >
            {FavouriteOptions.map((option, index) => {
              const isLast = index === FavouriteOptions.length - 1;
              return (
                <button
                  className={
                    "u-w-all u-text-left u-semi-bold u-flex u-align-center u-hoverover--darker-background u-pv--12 u-ph--24" +
                    (isLast ? "" : " u-border--bottom--grey")
                  }
                  key={index}
                  style={{ fontSize: "14px" }}
                  onClick={() => option.function()}
                >
                  {option.name}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withAuth0(FavouritesOptionsModal);
