import { Component } from "react";

import Logo from "./Logo";
import NavLinks from "./NavLinks";

import { withAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { getUserData } from "../../redux/actions/userActions";
import LoginSignupButtons from "../auth/LoginSignupButtons";
import BasketCounter from "../buttons/BasketCounter";
import { Icon } from "../icons/Icon";
import BasketSummary from "../items/BasketSummary";
import UserGuidePopup from "../popups/UserGuidePopup";
import NavBarSearchMobile from "./NavBarSearchMobile";
import SocialMediaButton from "./itemNavs/SocialMediaButton";
interface Props {
  showModal: any;
  auth0: any;
  mainBackgroundClass: string;
  secondaryBackgroundClass: string;
  getUserData: any;
  user: UserState;
}

interface State {
  showMobileSearch: boolean;
  hasSignedUp: boolean;
  isFirstTimeLoaded: any;
}

class NavBar extends Component<Props, State> {
  state = {
    showMobileSearch: false,
    hasSignedUp: true,
    isFirstTimeLoaded: null,
  };

  componentDidMount = async () => {
    await this.props.getUserData();
    await this.checkTypeOfUser();

    this.checkIfFirstPageLoad();
    this.checkGuidePopoverStat();
  };

  checkTypeOfUser = async () => {
    // if there is a first name they have already signed up before, hence this is a login not a signup
    if (this.props.user.email?.length && this.props.user.deletedAt === null) {
      // if they have, they are login in
      this.setState({ hasSignedUp: true });
    } else {
      // if there is no name, they need to signup
      this.setState({ hasSignedUp: false });
    }
  };

  searchClicked = () => {
    this.setState({ showMobileSearch: true });

    const modalContent = () => (
      <div className="o-modal__container">
        {this.navbarHTML("u-background--white")}
        <NavBarSearchMobile marginTop={0} />
      </div>
    );

    this.props.showModal({ component: modalContent });
  };

  checkGuidePopoverStat = () => {
    const isFirstTimeLoaded = sessionStorage.getItem("first_load_time");
    this.setState({
      isFirstTimeLoaded: isFirstTimeLoaded === "1" ? true : false,
    });
  };

  checkIfFirstPageLoad = () => {
    var firstTime = sessionStorage.getItem("first_load_time");
    if (!firstTime) {
      // first time loaded!
      sessionStorage.setItem("first_load_time", "1");
    }
  };

  showUserGuidePopup = () => {
    const isDesktopView = window.innerWidth <= 600 ? false : true;
    const modalContent = () => <UserGuidePopup isView={isDesktopView} />;

    this.props.showModal({ component: modalContent });
  };

  getFirstTimeUser = () => {
    return localStorage.getItem("isFirstTimeUser");
  };

  isMobile = () =>
    Math.min(window.screen.width, window.screen.height) < 768 ||
    navigator.userAgent.indexOf("Mobi") > -1;

  navbarHTML = (cornerBackgroundClass: string) => {
    return (
      <div id="search-navbar" className="u-h--75px--mobile">
        <div className={"u-w-all " + cornerBackgroundClass}>
          <div
            className={
              "u-w-all u-flex u-justify-between u-pv--24--desktop u-pv--8--mobile u-pl--20--desktop u-br--bottomleft--large u-align-center u-ph--12--mobile u-h--75px--mobile " +
              this.props.mainBackgroundClass
            }
          >
            <div className="u-ph--4">
              <Logo />
            </div>
            <div className="u-hide--mobile u-flex u-align-center u-h-all u-pr--32">
              <NavLinks />
            </div>
            <div className="u-hide--desktop u-flex u-align-center u-pv--4 u-justify-between">
              <div className="u-w-all u-flex u-align-center">
                {!this.props.auth0.isAuthenticated && <BasketCounter />}
                <div className="u-hide--mobile">
                  <SocialMediaButton />
                </div>

                <div
                  className="u-pv--4 u-pl--12--mobile"
                  onClick={() => this.searchClicked()}
                >
                  <Icon id="search" height="25px" width="25px" fill="#fff" />
                </div>
              </div>
              <div className="u-border--right--white u-pv--20 u-h-all u-pl--12-exclude-small-mobile u-pl--8"></div>
              <div className="u-pl--8">
                {this.props.auth0.isAuthenticated ? (
                  <BasketSummary showBackground={false} />
                ) : (
                  <LoginSignupButtons />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <>
        {this.navbarHTML(
          this.props.secondaryBackgroundClass || "u-background--white"
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0<any>(
  connect(mapStateToProps, { showModal, getUserData })(NavBar)
);
