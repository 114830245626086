import { Component } from "react";

interface Props {
  priceAlert: PriceAlert;
  label: string;
}
class PriceAlertStatus extends Component<Props, {}> {
  render() {
    const { is_active } = this.props.priceAlert;
    const { label } = this.props;
    return (
      <div>
        {is_active && (
          <div className="u-background--pink u-color-white u-pv--8 u-ph--20 u-main-font--vsmall u-br--bottom--small">
            {label}
          </div>
        )}
      </div>
    );
  }
}

export default PriceAlertStatus;
