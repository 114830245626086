import { Component } from "react";
import { connect } from "react-redux";
import { saveNewCategoryFilterSelection } from "../../../redux/actions/categoryAction";
import { Icon } from "../../icons/Icon";
import {
  getListOfNextCategories,
  getParentOfNode,
} from "./itemNavigationFunctions";

interface Props {
  items: ItemsState;
  categories: CategoryState;
  saveNewCategoryFilterSelection?: any;
  isSetSidebarHeight?: boolean;
}

interface State {
  displayedCategories: CategoryTreeNode[];
}

class CategoryDrillDownNav extends Component<Props, State> {
  state = {
    displayedCategories: [],
  };

  componentDidMount = () => {
    this.updateDisplayedCategories();
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      this.props.categories?.categoryTree !==
        prevProps.categories?.categoryTree ||
      this.props.categories?.selectedNode !==
        prevProps.categories?.selectedNode ||
      this.props.items.itemsToDisplay !== prevProps.items.itemsToDisplay
    ) {
      this.updateDisplayedCategories();
    }
  };

  updateDisplayedCategories = () => {
    // Can only update if the tree has loaded
    if (this.props.categories.categoryTree) {
      // find the next level of category nodes to be displayed
      const nextLevel = getListOfNextCategories(
        this.props.categories.categoryTree,
        this.props.categories.selectedNode
      );

      // render them
      if (nextLevel) {
        this.setState({ displayedCategories: nextLevel });
      } else {
        // there are no children to display
        this.setState({ displayedCategories: [] });
      }
    }
  };

  onClickOfcatNode = (catNode: CategoryTreeNode) => {
    // if there are children cats
    if (catNode?.children?.length) {
      // display the children of the clicked node as the visible
      this.setState({ displayedCategories: catNode.children });
    }

    // add the category node id to redux as the selected node
    this.props.saveNewCategoryFilterSelection(catNode);
  };

  moveBackUpCatTree = () => {
    // find the paarent
    const parent = getParentOfNode(
      this.props.categories.categoryTree,
      this.props.categories.selectedNode
    );

    // update redux's selected node
    this.props.saveNewCategoryFilterSelection(parent);

    // if parent
    if (parent) {
      // if the parent has children lets render then (otherwise keep rendering what we are)
      if (parent?.children) {
        // set that parent as the visible
        this.setState({
          displayedCategories: parent?.children,
        });
      }
    } else {
      // if there is no found parent we want to show the nodes with no parents
      this.setState({
        displayedCategories: this.props.categories.categoryTree,
      });
    }
  };

  displayLowestLevelDrillDown = () => {
    // get the parent_id of the selected tree
    if (this.props.categories?.selectedNode) {
      // find that parent
      const parent = getParentOfNode(
        this.props.categories.categoryTree,
        this.props.categories?.selectedNode
      );
      // display its children
      if (parent?.children) {
        return this.displayDrillDownOptions(parent.children);
      }
    }
  };

  stylingOfOption = (catNode: CategoryTreeNode) => {
    let styling = " u-text-left u-align-left u-pv--8 u-w-all u-ph--8 u-pl--40";

    if (catNode.id === this.props.categories.selectedNode?.id) {
      styling += " u-color-magenta  u-background--grey "; // dirty hack to inset the left border
    } else {
      styling += " u-color-charcoal ";
    }

    return styling;
  };

  displayDrillDownOptions = (listOfCategories: CategoryTreeNode[]) => {
    return listOfCategories.map((catNode: CategoryTreeNode, index: number) => {
      return (
        <button
          className="u-w-all u-hoverover--darker-background "
          onClick={() => this.onClickOfcatNode(catNode)}
          key={index}
        >
          <div className="u-main-font--vsmall u-semi-bold u-flex">
            <div
              className={this.stylingOfOption(catNode)}
              style={{ fontSize: "14px" }}
            >
              {catNode.name}
              {/*NOTE(AW): Commented out until we can do product counts per category*/}
              {catNode.children && catNode.children.length === 0 && (
                <span className="u-color-grey u-pl--8 u-no-decoration u-text-left u-align-left">
                  {this.formatTotalItemsCount(catNode.total_count)}
                </span>
              )}
              {catNode.children && catNode.children.length > 0 && (
                <span className="u-color-grey u-pl--8 u-no-decoration u-text-left u-align-left">
                  +
                </span>
              )}
            </div>
          </div>
        </button>
      );
    });
  };

  formatTotalItemsCount = (total: number) => total.toLocaleString();

  getWhiteSpaceHeight = () => {
    const searchNavBottom =
      document.getElementById("search-navbar")?.getBoundingClientRect()
        .bottom || 0;
    const headerHeight =
      document.getElementById("header")?.getBoundingClientRect().height || 0;
    const backBtnHeight =
      document.getElementById("backBtn")?.getBoundingClientRect().height || 75;

    const whiteSpaceHeight =
      window.innerHeight - (searchNavBottom + headerHeight);

    return whiteSpaceHeight;
  };

  render() {
    const list = this.state.displayedCategories;

    let estimatedHeightPerElement = 20;
    let remainingAvailableSpace = this.getWhiteSpaceHeight();
    const elementHeights = estimatedHeightPerElement * list.length;
    let innerSideBarHeight = "90%";

    if (elementHeights > remainingAvailableSpace) {
      //will show vertical scrollbar
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      innerSideBarHeight = "80%";
    }

    return (
      <div
        className="u-background--white u-main-font--vsmall u-h-all"
        style={{ width: "250px" }}
      >
        <button
          id="backBtn"
          className="u-w-all u-color-magenta u-main-font--small u-bold u-text-left u-flex u-align-center u-pl--40 u-border--bottom--grey"
          style={{ height: "75px" }}
          onClick={() => this.moveBackUpCatTree()}
        >
          <Icon
            id="chevron_left"
            fill="none"
            stroke="#9300DA"
            size="m"
            className="u-rotate--90deg"
          />
          <span className="u-ph--8">Back</span>
        </button>

        <div
          id="test"
          className="u-overflow--scroll-y--hidden "
          style={{
            height: remainingAvailableSpace + "px",
          }}
        >
          <div
            className="u-mt--24 u-overflow--scroll--thinner u-pb--24 u-border--bottom--grey"
            style={{
              height: this.props.isSetSidebarHeight
                ? "85%"
                : innerSideBarHeight,
              overflowY: "auto",
            }}
          >
            {list.length === 0
              ? this.displayLowestLevelDrillDown()
              : this.displayDrillDownOptions(list)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  categories: state.categories,
  items: state.items,
});

export default connect(mapStateToProps, { saveNewCategoryFilterSelection })(
  CategoryDrillDownNav
);
