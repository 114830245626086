import { GET_USER_PRICE_ALERTS, DELETE_USER_PRICE_ALERT } from "../enums";
import { Dispatch } from "react";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";

export const getUserPriceAlerts = () => async (dispatch: Dispatch<any>) => {
  return await httpClient.instance
    .get("/user/price-alert")
    .then((res) => {
      if (res.data) {
        dispatch({
          type: GET_USER_PRICE_ALERTS,
          item_ids: createArrayOfItemIds(res.data),
          priceAlerts: res.data,
        });
      }
    })
    .catch((e) => {
      // if error save favs as empty
      console.log(e);
      dispatch({
        type: GET_USER_PRICE_ALERTS,
        favourites: [],
      });
    });
};

const createArrayOfItemIds = (data: PriceAlert[]) => {
  return data.map((alert) => alert.item.crawler_id);
};

export const addPriceAlert =
  (itemId: number, alertPrice: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .post("/user/price-alert/", {
        item_id: itemId,
        alert_price: alertPrice,
      })
      .then(async (res) => {
        dispatch(getUserPriceAlerts());
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const updatePriceAlert =
  (itemId: number, alertPrice: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .patch("/user/price-alert", {
        item_id: itemId,
        alert_price: alertPrice,
      })
      .then(async (res) => {
        dispatch(getUserPriceAlerts());
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const deletePriceAlert =
  (itemId: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .delete("/user/price-alert/" + itemId)
      .then((res) => {
        dispatch({
          type: DELETE_USER_PRICE_ALERT,
          deletedId: itemId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const sortPriceAlert = (
  sortType: "A-Z" | "Z-A" | "AscPrice" | "DescPrice"
) => {
  return {
    type: "SORT_PRICE_ALERTS",
    sortType,
  };
};

export const prettifySortTypeIdToString = (
  sortType:
    | ""
    | "A-Z"
    | "Z-A"
    | "AscPrice"
    | "DescPrice"
    | "DescSavings"
    | "Created"
) => {
  switch (sortType) {
    case "A-Z":
      return "Name (A-Z)";
    case "Z-A":
      return "Name (Z-A)";
    case "AscPrice":
      return "Price (High to Low)";
    case "DescPrice":
      return "Price (Low to High)";
    default:
      return;
  }
};
