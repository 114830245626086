import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import {
  capitalizeFirstLetter,
  findColesAndWoolsPricing,
} from "../../utils/UIFunctions";
import ShoppingPreferenceOptionsModal from "./ShoppingPreferenceOptionsModal";
import { Icon } from "../icons/Icon";
import { changeVendorToBuyFrom } from "../../redux/actions/basketActions";
import {
  updateUserSavingsLimit,
  updateUserShoppingPreference,
} from "../../redux/actions/userActions";

interface Props {
  user: UserState;
  showModal: any;
  basket: any;
  changeVendorToBuyFrom: any;
  updateUserSavingsLimit: any;
  updateUserShoppingPreference: any;
}

interface State {
  manualPriceDifference: number;
  userVendorPreference: number;
}

class GrocerizeManualSlider extends Component<Props, State> {
  state = {
    manualPriceDifference: this.props.user.manual_price_difference || 10,
    userVendorPreference:
      this.props.user.shopping_preference === "Coles" ? 1 : 2,
  };

  componentDidUpdate = (prevProps: Props) => {
    // if the selected vendor changes update state
    if (this.props.user !== prevProps.user) {
      this.setState({
        userVendorPreference:
          this.props.user.shopping_preference === "Coles" ? 1 : 2,
      });
    }
  };

  clickedToChangeShoppingPreference = () => {
    const button = document.getElementById("vendor-toggle");

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = () => (
        <div
          style={{
            marginTop: postionOfButton.bottom + 20 + "px",
            marginLeft: postionOfButton.left + "px",
            width: postionOfButton.right - postionOfButton.left + "px",
          }}
        >
          <ShoppingPreferenceOptionsModal />
        </div>
      );
      this.props.showModal({ component: modalContent });
    }
  };

  clickedToToggleShoppingPreference = () => {
    const newPreference =
      this.props.user.shopping_preference === "Coles" ? "Woolworths" : "Coles";
    this.props.updateUserShoppingPreference(newPreference);
  };

  clickedToApplyManualSavingsLimit = () => {
    const basketItems = this.props.basket.basketItems;

    // Index's: Coles = 1, Woolies = 2
    for (let i = 0; i < basketItems.length; i++) {
      const item = basketItems[i];
      // Check if item has both pricing options
      if (item.item_pricing.length > 1) {
        // Check if item is available at both stores
        if (item.item_pricing[0].available && item.item_pricing[1].available) {
          const { woolsPricing, colesPricing } = findColesAndWoolsPricing(item);

          const woolsPrice = woolsPricing?.price || 0;
          const colesPrice = colesPricing?.price || 0;

          let actualSavings = Math.abs(woolsPrice - colesPrice);
          let updatedVendorId: 1 | 2 | undefined;

          // Check if actual savings exceeds user minimum savings amount
          if (actualSavings >= this.state.manualPriceDifference) {
            // Set to cheapest option
            updatedVendorId = woolsPrice > colesPrice ? 1 : 2;
            this.props.changeVendorToBuyFrom(item, updatedVendorId);
          } else {
            // Set to prefered vendor
            this.props.changeVendorToBuyFrom(
              item,
              this.state.userVendorPreference
            );
          }
        }
      }
    }

    this.props.updateUserSavingsLimit(this.state.manualPriceDifference);
  };

  componentDidMount = () => {
    if (this.props.user.manual_price_difference !== undefined) {
      this.clickedToApplyManualSavingsLimit();
    }
  };

  render() {
    const disableButton =
      this.props.user.manual_price_difference ===
      this.state.manualPriceDifference;
    const vendorColor =
      this.state.userVendorPreference === 1
        ? " u-color-coles "
        : " u-color-woolworths";
    return (
      <div>
        <div className="u-hide--desktop u-w-all u-ph--8 u-pv--20 u-border--bottom--darkgrey">
          <button
            className="u-w-all u-br-small u-flex u-ph--32 u-pv--16 u-align-center"
            onClick={() => this.clickedToToggleShoppingPreference()}
          >
            {this.state.userVendorPreference === 2 ? (
              <Icon
                id="chevron_left"
                fill="none"
                className="u-stroke--magenta u-rotate--90deg"
              />
            ) : (
              <></>
            )}
            <div className="u-flex u-w-all u-justify-center">
              <div className="u-flex u-align-center">
                <div
                  className={"u-bold" + vendorColor}
                  style={{ fontSize: "37px" }}
                >
                  {this.state.userVendorPreference === 1 ? "C" : "W"}
                </div>
                <div className={"u-bold u-pl--16 u-text-left" + vendorColor}>
                  {this.state.userVendorPreference === 1
                    ? "Coles"
                    : "Woolworths"}
                </div>
              </div>
            </div>
            {this.state.userVendorPreference === 1 ? (
              <Icon
                id="chevron_right"
                fill="none"
                className="u-stroke--magenta u-rotate--negative90deg "
              />
            ) : (
              <></>
            )}
          </button>
          <div className="u-main-font--small u-w-all u-text-center">
            is my preference, except where I can save over
          </div>
          <div className="u-flex u-w-all u-align-center u-justify-center u-pv--16">
            <div
              className="u-color-grey"
              style={{ marginRight: "-20px", marginLeft: "20px" }}
            >
              $
            </div>
            <input
              className="u-border--darkgrey u-pv--12 u-text-align-center u-bold"
              style={{ width: "100px" }}
              value={this.state.manualPriceDifference.toFixed(2)}
              type="number"
              step="0.5"
              onChange={(e) => {
                this.setState({
                  manualPriceDifference: parseFloat(e.currentTarget.value),
                });
                this.clickedToApplyManualSavingsLimit();
              }}
            ></input>
          </div>
        </div>
        <div
          className="u-hide--mobile u-mv--12 u-pv--24 "
          style={{ marginTop: "47px", height: "170px" }} // height to amke space for swaps and uniques divs
        >
          <div className="u-flex u-align-center">
            <button
              className="u-flex"
              id="vendor-toggle"
              onClick={() => {
                this.clickedToChangeShoppingPreference();
              }}
            >
              <Icon id="chevron_up_down" size="s" />
              <div className="u-ph--12 u-bold">
                {capitalizeFirstLetter(
                  this.props.user.shopping_preference.toString()
                )}
              </div>
            </button>

            <div className="u-main-font--small">
              is my preference, except where I can save over
            </div>
            <div
              className="u-color-grey"
              style={{ marginRight: "-20px", marginLeft: "20px" }}
            >
              $
            </div>
            <input
              className="u-border--darkgrey u-pv--12 u-text-align-center u-bold"
              style={{ width: "100px" }}
              value={this.state.manualPriceDifference.toFixed(2)}
              type="number"
              step="0.5"
              onChange={(e) => {
                this.setState({
                  manualPriceDifference: parseFloat(e.currentTarget.value),
                });
              }}
            ></input>
            <div className="u-main-font--small u-pl--8">per item</div>
          </div>
          <div className="u-pv--12 u-flex">
            <input
              type="range"
              min="0"
              max="20"
              step="0.5"
              value={this.state.manualPriceDifference}
              style={{ width: "100%" }}
              onChange={(e) => {
                this.setState({
                  manualPriceDifference: parseFloat(e.currentTarget.value),
                });
              }}
            ></input>
            <button
              className={
                "u-br--small u-p--12 u-ml--16 " +
                (disableButton
                  ? "u-background--darkgrey u-color-charcoal"
                  : "u-background--magenta u-color-white")
              }
              style={{ minWidth: "160px" }}
              onClick={() => this.clickedToApplyManualSavingsLimit()}
              disabled={
                // Disable button if price differnece is already set
                disableButton
              }
            >
              Apply to items
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  basket: state.basket,
});

export default connect(mapStateToProps, {
  changeVendorToBuyFrom,
  updateUserSavingsLimit,
  updateUserShoppingPreference,
  showModal,
})(GrocerizeManualSlider);
