const initialState = {
  readColesNotification: false,
};

interface ColesNotificationAction {
  type: "TOGGLE_COLES_NOTIFICATION";
  readColesNotification: boolean;
}
export default function colesNotificationReducer(
  state = initialState,
  action: ColesNotificationAction
) {
  switch (action.type) {
    case "TOGGLE_COLES_NOTIFICATION":
      return {
        ...state,
        readColesNotification: action.readColesNotification,
      };
    default:
      return state;
  }
}
