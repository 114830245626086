import React, { Component } from "react";

interface Props {
  pricing: ItemPricings | undefined;
  missingVendorId?: 1 | 2 | undefined; // if passed in we assume there is not data
}

class BasketVendorPricing extends Component<Props, {}> {
  render() {
    const { pricing, missingVendorId } = this.props;

    return (
      <div className="u-background--grey u-mh--4 u-br--vsmall u-color-charcoal u-mr--8--desktop u-mr--2--mobile u-w-half u-flex">
        <div
          className="u-main-font--small u-bold u-color-grey u-ph--12 u-flex u-justify-center u-align-center"
          style={{ fontSize: "14px" }}
        >
          {!pricing && missingVendorId === 1 ? (
            //   if no pricing for coles
            "Coles"
          ) : missingVendorId === 2 ? (
            //   if no pricing for woolies
            "Woolworths"
          ) : pricing?.vendor_id === 1 ? (
            <span className=" u-color-coles">C</span>
          ) : (
            <span className="u-color-woolworths">W</span>
          )}
        </div>
        <div className="u-flex u-justify-center u-align-center">
          <div>
            <div
              className="u-main-font--small u-semi-bold"
              style={{ fontSize: "14px", lineHeight: "18px" }}
            >
              {pricing?.price ? "$" + pricing.price.toFixed(2) : null}
            </div>
            <div
              className="u-main-font--small "
              style={{ fontSize: "10px", lineHeight: "10px" }}
            >
              {pricing?.cup_price && pricing.cup_volume
                ? "$" + pricing?.cup_price + " / " + pricing?.cup_volume
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasketVendorPricing;
