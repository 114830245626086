import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import FavouritesView from "../components/views/FavouritesView";
import PriceAlertsView from "../components/views/PriceAlertsView";

import ListView from "../components/views/ListsView";
import MyDetails from "../components/views/MyDetails";
import PageTemplate from "./PageTemplate";
import PreferencesView from "../components/views/PreferencesView";

interface MatchParams {
  content: string;
}
interface Props extends RouteComponentProps<MatchParams> {}

class ListsHome extends Component<Props, {}> {
  getContent = () => {
    const { content } = this.props.match.params;
    switch (content) {
      case "lists":
        return <ListView />;
      case "alerts":
        return <PriceAlertsView />;
      case "favourites":
        return <FavouritesView />;
      case "details":
        return <MyDetails />;
      case "preferences":
        return <PreferencesView />;
      default:
        return <ListView />;
    }
  };
  render() {
    return (
      <PageTemplate
        headerType="magenta"
        sidebarType="options"
        hideItemSearching={true}
      >
        {this.getContent()}
      </PageTemplate>
    );
  }
}

export default ListsHome;
