import React, { Component } from "react";
import { connect } from "react-redux";
import { toggleTourGuide } from "../../redux/actions/tourActions";

interface Props {
  tourGuideModal: TourModalState;
  toggleTourGuide: () => void;
}

interface State {
  currentStep: number;
  totalSteps: number;
  positionTop: number;
  positionLeft: number;
  offSetY: number;
  offSetX: number;
}
class TourGuideModal extends Component<Props, State> {
  state = {
    currentStep: 1,
    totalSteps: 10,
    positionTop: 0,
    positionLeft: 0,
    offSetY: 220,
    offSetX: 85,
  };

  nextTour = () => {
    this.setState({
      currentStep: (this.state.currentStep += 1),
    });

    this.positionModalRelativeToElement();
  };

  componentDidMount = () => {
    if (!this.hasFinishedInteractiveGuide()) {
      this.props.toggleTourGuide();
    }

    this.setInitialState();
  };

  closeTour = () => {
    this.setInitialState();
    this.userHasFinishedInteractiveGuide();
    this.props.toggleTourGuide();
  };

  elementRelativeToTourGuide = (step?: number | undefined) => {
    const element = document.getElementById(
      this.getTourGuideContent(step).target
    );
    const clientDimensions = element?.getBoundingClientRect();
    const height = clientDimensions?.height;
    return {
      element: clientDimensions,
      height,
    };
  };

  heightOfTourGuideCard = () => {
    const guideCard = document.getElementById("guide-card");

    return {
      heightOfCard: guideCard?.getBoundingClientRect().height || 100,
      widthOfCard: guideCard?.getBoundingClientRect().width || 200,
    };
  };

  hasFinishedInteractiveGuide = () => {
    return localStorage.getItem("has-finished-interactive-guide") === null
      ? false
      : true;
  };

  positionModalRelativeToElement = () => {
    const { element, height } = this.elementRelativeToTourGuide(
      this.state.currentStep
    );

    const { heightOfCard } = this.heightOfTourGuideCard();

    if (element && height) {
      const halfHeight = height / 2;
      this.setState({
        positionTop:
          element.bottom - this.state.offSetY + window.scrollY - halfHeight,
        positionLeft: element.left - this.state.offSetX,
      });

      if (element.bottom + heightOfCard > window.screen.height) {
        this.scrollToElement(element.bottom);
      }
    }

    if (this.state.currentStep === this.state.totalSteps) {
      this.setState({
        positionTop: 0,
        positionLeft: document.documentElement.clientWidth - 280,
      });

      this.scrollToElement(0, 0);
    }
  };

  scrollToElement = (x: number, y: number | undefined = undefined) => {
    window.scroll({
      top: x,
      left: y,
      behavior: "smooth",
    });
  };

  setInitialState = () => {
    const firstStep = 1;
    const { element } = this.elementRelativeToTourGuide(firstStep);

    this.setState({
      currentStep: 1,
      totalSteps: 10,
      positionTop: element!.bottom - this.state.offSetY + window.scrollY,
      positionLeft: element!.left - this.state.offSetX,
    });
  };

  userHasFinishedInteractiveGuide = () => {
    localStorage.setItem("has-finished-interactive-guide", "yes");
  };

  getTourGuideContent = (step?: number | undefined) => {
    const stepId = step ? step : this.state.currentStep;

    switch (stepId) {
      case 2:
        return {
          id: 2,
          target: "coles",
          content:
            "Select Coles (whole shop) to toggle all your items into a Coles list.",
        };
      case 3:
        return {
          id: 3,
          target: "grocerize",
          content:
            "Select Grocerize (split shop) to split your list between Woolworths and Coles to maximise your savings.",
        };
      case 4:
        return {
          id: 4,
          target: "manual-mode",
          content:
            "Manual mode allows you to refine your split list even further based on price thresholds of items.",
        };
      case 5:
        return {
          id: 5,
          target: "grocerize-shopping-split",
          content:
            "This is the current amount of money you are saving based on your selections.",
        };
      case 6:
        return {
          id: 6,
          target: "PushToGrocerizeButton",
          content:
            "Create a shopping list you can access on your mobile phone and take to the shops or share with others.",
        };
      case 7:
        return {
          id: 7,
          target: "ChromeExtPushToColesButton",
          content:
            "Push items in the Coles column to the Coles website. You'll need to download the Grocerize chrome extension.",
        };
      case 8:
        return {
          id: 8,
          target: "ChromeExtPushToWoolsButton",
          content:
            "Push items in the Woolworths column to the Woolworths website. You'll need to download the Grocerize chrome extension.",
        };
      case 9:
        return {
          id: 9,
          target: "grocerize-toggle-button",
          content:
            "Manually create your list by toggling items between Woolworths and Coles.",
        };
      case 10:
        return {
          id: 10,
          target: "basket-summary",
          content: "You can return/edit to your cart here at any time.",
        };
      default:
        return {
          id: 1,
          target: "wools",
          content:
            "Select Woolworths (whole shop) to toggle all your items into a Woolworths list.",
        };
    }
  };

  render() {
    const isOpen = this.props.tourGuideModal?.isOpen;

    const top = this.state.positionTop;
    const left = this.state.positionLeft;

    return (
      <div
        className={!isOpen ? "u-opacity--0 u-vis--hidden" : "u-absolute"}
        style={{
          top,
          left,
          width: "200px",
          maxWidth: "200px",
        }}
      >
        <div className="o-modal-dropdown__container">
          <div className="u-flex u-hide--mobile u-justify-center">
            <div className="o-caret-marker--purple"></div>
          </div>
          <div
            className="u-background--purple u-br--vsmall u-color-white u-ph--16 u-pv--12"
            id="guide-card"
          >
            <div className="u-pv--8">
              {this.state.currentStep}/{this.state.totalSteps}
            </div>
            <div>{this.getTourGuideContent().content}</div>
            <div className="u-pv--8 u-mt--4">
              <button
                className="u-background--vividCyan u-main-font--small u-color-white u-br--vsmall u-semi-bold u-pv--8 u-ph--32"
                onClick={
                  this.state.currentStep !== this.state.totalSteps
                    ? this.nextTour
                    : this.closeTour
                }
              >
                {this.state.currentStep !== this.state.totalSteps
                  ? "Next"
                  : "Close"}
              </button>
            </div>
          </div>
        </div>
        <div
          className={`o-modal-dropdown__overlay }`}
          onClick={this.closeTour}
        />
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  tourGuideModal: state.tourGuideModal,
});
export default connect(mapStateToProps, { toggleTourGuide })(TourGuideModal);
