import { Component } from "react";
import UploadListButton from "../../buttons/UploadListButton";
import BasketSummary from "../../items/BasketSummary";
import SearchProducts from "./SearchProducts";

class SearchNav extends Component<{}, {}> {
  render() {
    return (
      <div className="u-h--70px u-hide--mobile u-w-all u-background--white u-flex u-justify-between  u-border--bottom--grey  u-pl--20 ">
        <SearchProducts />
        <div className="u-flex">
          <div className="u-ph--32 u-flex">
            <UploadListButton />
          </div>

          <BasketSummary showBackground={true} />
        </div>
      </div>
    );
  }
}

export default SearchNav;
