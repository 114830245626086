import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import { showModal } from "../../redux/actions/modalActions";
import { getUserData } from "../../redux/actions/userActions";
import { routes } from "../../utils/routes";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import { Icon } from "../icons/Icon";

interface Props {
  showModal: any;
  getUserData: any;
  auth0: any;
  user: UserState;
  onComplete: any;
}

interface State {
  firstName: string;
  firstNameError: string;
  lastName: string;
  lastNameError: string;
  postcode: string;
  postcodeError: string;
  shoppingPreference: string;
  TandC: boolean;
  TandCError: string;
  mailing: boolean;
  attemptedToSubmit: boolean;
  showWelcome: boolean;
  isLoading: boolean;
  isSubmitted: boolean;
  isAccepted: boolean;
}

class SignupForm extends Component<Props, State> {
  state = {
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    postcode: "",
    postcodeError: "",
    shoppingPreference: "none",
    TandC: false,
    TandCError: "",
    mailing: false,
    attemptedToSubmit: false,
    showWelcome: false,
    isLoading: false,
    isSubmitted: false,
    isAccepted: false,
  };

  submitSignupFormDetails = async () => {
    const isValid = this.validateForm();
    this.setState({ attemptedToSubmit: true });

    if (isValid) {
      const { firstName, lastName, postcode, shoppingPreference } = this.state;
      const { user } = this.props.auth0;

      // add user data to db
      await httpClient.instance
        .post("/user", {
          auth0_id: user.sub,
          first_name: firstName,
          last_name: lastName,
          postcode,
          shopping_preference: shoppingPreference,
          email: user.email,
        })
        .then(async () => {
          //Once signed up there is no stripe id hence we need to get user again once created account
          await this.props.getUserData();
          this.setState({ showWelcome: true });
          this.setState({ isLoading: false });
          this.setState({ isSubmitted: true });
        });
    }
  };

  validateForm = () => {
    const validation = {
      firstName: this.validateFirstName(),
      lastName: this.validateLastName(),
      postcode: this.validatePostCode(),
      shopping_preference: this.validateShoppingPreference(),
      TandC: this.validateTandC(),
    };

    // if all checks pass
    if (Object.values(validation).every((field) => field === true)) {
      return true;
    } else {
      return false;
    }
  };

  validateFirstName = () => {
    if (this.state.firstName.trim().length === 0) {
      this.setState({ firstNameError: "Your first name cannot be blank" });
      return false;
    }

    this.setState({ firstNameError: "" });
    return true;
  };

  validateLastName = () => {
    if (this.state.lastName.trim().length === 0) {
      this.setState({ lastNameError: "Your last name cannot be blank" });
      return false;
    }

    this.setState({ lastNameError: "" });
    return true;
  };

  validatePostCode = () => {
    // must match regex - need to confirm if right
    // Source for AU postcode regex: https://www.etl-tools.com/regular-expressions/is-australian-post-code.html
    if (
      !String(this.state.postcode)
        .toLowerCase()
        .match(
          /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/
        )
    ) {
      this.setState({ postcodeError: "You must enter a valid postcode" });
      return false;
    } else if (this.state.postcode.length !== 4) {
      this.setState({ postcodeError: "You must enter a valid postcode" });
      return false;
    }

    this.setState({ postcodeError: "" });
    return true;
  };

  validateShoppingPreference = () => {
    // if none given set as none
    if (this.state.shoppingPreference === "") {
      this.setState({ shoppingPreference: "none" });
    }

    return true;
  };

  validateTandC = () => {
    // if none given set as none
    if (this.state.TandC) {
      this.setState({
        TandCError: "",
      });
      return true;
    }

    this.setState({
      TandCError: "To use grocerize you need to accept the terms",
    });
    return false;
  };

  // For Mobile View Rendering
  render() {
    const redirect_url =
      window.location.origin.toString() + "/sign-up-terms-of-use";

    if (this.state.isAccepted === false) {
      if (this.state.showWelcome) {
        return <Redirect to={routes.welcome} />;
      }
      return (
        <>
          <div>
            <div className=" u-flex u-justify-center">
              <Icon
                id="shortfilledlogo"
                height={55}
                width={55}
                className="u-mt--48  u-flex u-justify-center u-align--center"
              />
            </div>
            <div className="u-flex u-flex-column u-main-font--small u-br--top--small u-color-charcoal u-background--white o-signup-card--content">
              <div className=" u-ph--32 u-pt--48">
                <div
                  className="u-main-font--medium u-color-charcoal u-bold u-text-center"
                  style={{ fontSize: "21px" }}
                >
                  Last step!
                </div>
              </div>

              <div className="u-p--32">
                <div>
                  <div>Your details</div>
                  <div className="u-relative u-mt--12">
                    <input
                      className="o-signup-input"
                      placeholder="First Name"
                      value={this.state.firstName}
                      autoFocus={true}
                      onChange={(e) =>
                        this.setState({ firstName: e.currentTarget.value })
                      }
                      onBlur={() => this.validateFirstName()}
                    />
                  </div>
                  {this.state.firstNameError &&
                  (this.state.firstName !== "" ||
                    this.state.attemptedToSubmit) ? (
                    <div className="u-pt--4 u-color-magenta u-main-font--vsmall">
                      {"* " + this.state.firstNameError}
                    </div>
                  ) : null}
                  <div className="u-mt--12">
                    <input
                      className="o-signup-input "
                      placeholder="Last name"
                      onChange={(e) =>
                        this.setState({ lastName: e.currentTarget.value })
                      }
                      onBlur={() => this.validateLastName()}
                    />
                  </div>
                  {this.state.lastNameError &&
                  (this.state.lastName !== "" ||
                    this.state.attemptedToSubmit) ? (
                    <div className="u-pt--4 u-color-magenta u-main-font--vsmall">
                      {"* " + this.state.lastNameError}
                    </div>
                  ) : null}
                </div>

                {/* postcode */}
                <div className="u-pt--32">
                  <div>Your postcode (used to match prices to your area)</div>
                  <input
                    type="number"
                    className="o-signup-input u-mt--12"
                    placeholder="Postcode"
                    onChange={(e) => {
                      this.setState({ postcode: e.currentTarget.value });
                    }}
                    onBlur={() => this.validatePostCode()}
                  />
                  {this.state.postcodeError &&
                  (this.state.postcode !== "" ||
                    this.state.attemptedToSubmit) ? (
                    <div className="u-pt--4 u-color-magenta u-main-font--vsmall">
                      {"* " + this.state.postcodeError}
                    </div>
                  ) : null}
                </div>

                {/* Preferences */}
                <div className="u-pt--32 u-border--bottom--grey">
                  <div className="u-pb--8">I prefer to shop at </div>
                  <div className="u-flex u-justify-between u-align-center o-signup__vendor-pref-container u-pt--8 u-pb--16">
                    <button
                      className="u-flex u-align-center u-w-all u-p--4 "
                      onClick={() =>
                        this.setState({ shoppingPreference: "coles" })
                      }
                    >
                      <div
                        className={
                          "u-br--vsmall " +
                          (this.state.shoppingPreference === "coles"
                            ? "u-background--magenta"
                            : "u-background--darkgrey")
                        }
                        style={{ width: "25px", height: "25px" }}
                      />
                      <div className="u-pl--12">Coles</div>
                    </button>
                    <button
                      className="u-flex u-align-center u-w-all u-p--4 "
                      onClick={() =>
                        this.setState({ shoppingPreference: "woolworths" })
                      }
                    >
                      <div
                        className={
                          "u-br--vsmall " +
                          (this.state.shoppingPreference === "woolworths"
                            ? "u-background--magenta"
                            : "u-background--darkgrey")
                        }
                        style={{ width: "25px", height: "25px" }}
                      />
                      <div className="u-pl--12">Woolworths</div>
                    </button>
                    <button
                      className=" u-flex u-align-center u-w-all u-p--4 "
                      onClick={() =>
                        this.setState({ shoppingPreference: "no-preference" })
                      }
                    >
                      <div
                        className={
                          "u-br--vsmall " +
                          (this.state.shoppingPreference === "no-preference"
                            ? "u-background--magenta"
                            : "u-background--darkgrey")
                        }
                        style={{ width: "25px", height: "25px" }}
                      />
                      <div className="u-text-oneline u-pl--12">
                        No preference
                      </div>
                    </button>
                  </div>
                </div>

                {/* Terms and conditions */}
                <div className="u-pt--12">
                  <button
                    className="u-flex u-align-center u-p--6"
                    onClick={() => this.setState({ TandC: !this.state.TandC })}
                  >
                    <div
                      className={
                        "u-br--vsmall " +
                        (this.state.TandC
                          ? "u-background--magenta"
                          : "u-background--darkgrey")
                      }
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div className="u-pl--12 u-text-left">
                      Yes, I have read the Grocerize{" "}
                      <a
                        className=" u-underline"
                        href={redirect_url} //{process.env.REACT_APP_FRONTEND_URL + routes.publicTermsOfUse}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Terms and Conditions
                      </a>
                    </div>
                  </button>
                  <div className="u-pl--40 u-pb--48">
                    <span className="u-color-magenta u-main-font--vsmall">
                      {" "}
                      * To use Grocerize you need to accept the Terms and
                      Conditions.
                    </span>
                  </div>
                  {this.state.TandCError &&
                  (this.state.TandCError !== "" ||
                    this.state.attemptedToSubmit) ? (
                    <div className="u-pt--4 u-color-magenta u-main-font--vsmall">
                      {"* " + this.state.TandCError}
                    </div>
                  ) : null}

                  {/* Mailing - DISABLED UNTIL MARKETING EMAILS ENABLED LATER */}
                  {/* <button
                    className="u-flex u-pt--12 u-align-center u-p--6"
                    onClick={() =>
                      this.setState({ mailing: !this.state.mailing })
                    }
                  >
                    <div
                      className={
                        "u-br--vsmall " +
                        (this.state.mailing
                          ? "u-background--magenta"
                          : "u-background--darkgrey")
                      }
                      style={{ width: "25px", height: "25px" }}
                    />
                    <div className="u-pl--12 u-text-left">
                      Yes, I want to join the Grocerize mailing list.
                    </div>
                  </button> */}
                </div>
              </div>
            </div>
            <div className="u-fixed u-w-all" style={{ bottom: 0 }}>
              <button
                className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white"
                onClick={() => {
                  this.setState({ isLoading: true });
                  this.submitSignupFormDetails();
                }}
              >
                Start saving
              </button>
            </div>
          </div>
          {/* Show loading state */}
          {this.state.isLoading ? (
            <div className="o-modal">
              <div className="o-modal__background "></div>
              <div className="o-modal__container o-modal u-w-all">
                <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
                  <GrocerizeLoadingWheel />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* Show welcome modal */}
          {/* {this.state.showWelcome ? (
            <div className="o-modal">
              <div className="o-modal__background "></div>
              <div className="o-modal__container o-modal u-w-all">
                <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
                  <Welcome
                    onClickFunc={() => {
                      this.setState({ showWelcome: false });
                      this.setState({ isAccepted: true });
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )} */}
        </>
      );
    } else {
      // pass to shopping page
      return <Redirect to="/browse" />;
    }
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default withAuth0<any>(
  connect(mapStateToProps, { showModal, getUserData })(SignupForm)
);
