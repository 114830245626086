// Basket Enums
export const ADD_TO_BASKET = "ADD_TO_BASKET";
export const CLEAR_BASKET = "CLEAR_BASKET";
export const INCREASE_QUANTITY = "INCREASE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const REMOVE_FROM_BASKET = "REMOVE_FROM_BASKET";
export const CHANGE_SORT = "CHANGE_SORT";
export const CHANGE_VENDOR_TO_BUY_FROM = "CHANGE_VENDOR_TO_BUY_FROM";
export const CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL =
  "CHANGE_VENDOR_TO_BUY_FROM_FOR_ALL";
export const CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL =
  "CHANGE_VENDOR_TO_BE_OPTIMAL_FOR_ALL";
export const UPDATE_BASKET_METADATA = "UPDATE_BASKET_METADATA";
export const REPLACE_BASKET = "REPLACE_BASKET";
export const LOAD_BASKET = "LOAD_BASKET";
export const ADD_LIST_TO_BASKET = "ADD_LIST_TO_BASKET";

// User enums
export const SAVE_USER_DATA = "SAVE_USER_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_USER_SHOPING_PREFERENCE = "UPDATE_USER_SHOPING_PREFERENCE";
export const UPDATE_USER_SAVINGS_LIMIT = "UPDATE_USER_SAVINGS_LIMIT";
export const TOGGLE_AUTO_MODE = "TOGGLE_AUTO_MODE";
export const UPDATE_USER_SYSTEM_EMAILS_PREFERENCE =
  "UPDATE_USER_SYSTEM_EMAILS_PREFERENCE";
export const UPDATE_USER_MARKETING_EMAILS_PREFERENCE =
  "UPDATE_USER_MARKETING_EMAILS_PREFERENCE";

// Favourite enums
export const GET_USER_FAVOURITES = "GET_USER_FAVOURITES";
export const DELETE_USER_FAVOURITE = "DELETE_USER_FAVOURITE";
export const SORT_FAVS_BY_DATE = "SORT_FAVS_BY_DATE";
export const SORT_FAVS_ZA = "SORT_FAVS_ZA";
export const SORT_FAVS_AZ = "SORT_FAVS_AZ";

// Price alert enums
export const GET_USER_PRICE_ALERTS = "GET_USER_PRICE_ALERTS";
export const DELETE_USER_PRICE_ALERT = "DELETE_USER_PRICE_ALERT";
export const SORT_PRICE_ALERTS = "SORT_PRICE_ALERTS";

// Vendor enums
export const GROCERIZE = 0;
export const COLES = 1;
export const WOOLWORTHS = 2;

// Email Preference
export const MARKETING_EMAILS = Number(1);
export const SYSTEM_EMAILS = Number(2);

export const TOGGLE_TOUR_GUIDE = "TOGGLE_TOUR_GUIDE";
export const TOGGLE_COLES_NOTIFICATION = "TOGGLE_COLES_NOTIFICATION";
