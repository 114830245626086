import React, { Component } from "react";
import { UnauthorizedView } from "../views";

class UnauthorizedController extends Component {
  render() {
    return <UnauthorizedView />;
  }
}

export default UnauthorizedController;
