import { Component } from "react";
import { Icon } from "../../../icons/Icon";

interface Props {
  isHalfThePrice: boolean;
}

class HalfThePriceIndicator extends Component<Props, {}> {
  render() {
    const halfThePrice = "1/2";
    const { isHalfThePrice } = this.props;
    return (
      <div
        className={`${
          isHalfThePrice ? "u-background--magenta" : ""
        } u-w--40px u-h--40px u-pl--8 u-pt--8 u-br--tr`}
      >
        {isHalfThePrice ? (
          <span className="u-color-white u-main-font--14--small u-bold-black">
            {halfThePrice}
          </span>
        ) : null}
      </div>
    );
  }
}

export default HalfThePriceIndicator;
