import { Component } from "react";
import variables from "../../styles/utils/variables.scss";
import { routes } from "../../utils/routes";
import { Icon } from "../icons/Icon";

interface Props {}

interface State {
  pageItems: any[];
  current: number;
}

class GrocerizeUserGuideMobile extends Component<Props, State> {
  state = {
    pageItems: [
      {
        id: 1,
        title: "More Information",
        buttonLabel: "Next",
        subButtonLabel: "Show me more!",
      },
      {
        id: 2,
        title: "How to use Grocerize",
        buttonLabel: "Start saving!",
      },
    ],
    current: 0,
    mobilePageView: false,
  };

  grocerizeSteps = () => {
    const howToUseContent = [
      {
        id: 1,
        title: "Step 1",
        body: "Search for your preferred items and see the real time price comparison between Woolworths and Coles.",
      },
      {
        id: 2,
        title: "Step 2",
        body: "Add your items to the Grocerize™ cart and watch your savings tally as you go.",
      },
      {
        id: 3,
        title: "Step 3",
        body: "In your cart, click “Grocerize™ List” and your shop will automatically be split between the two supermarkets to maximise your savings.",
      },
      {
        id: 4,
        title: "Hint",
        body: "click the ‘?’ for tips on how to best use the Grocerize™ page.",
      },
      {
        id: 5,
        title: "Step 4",
        body: "Push your lists from Grocerize™ (desktop only) directly to the online carts of both Woolworths and Coles, or create a digital shopping list on your phone to take in store.",
      },
    ];

    return (
      <div className="u-w-all u-pt--32">
        <div className="u-flex u-flex-column">
          {howToUseContent.map((option, index) => {
            return index !== 3 ? (
              <div className="u-flex u-pb--16">
                <div className="u-flex u-justify-left u-align-center u-w-40pct u-pv--4 u-pr--16">
                  <div className="u-ph--20 u-pv--12 u-text-transform--uppercase u-bold u-br--small u-background--darkgrey u-flex u-justify-left u-align-center">
                    {option.title}
                  </div>
                </div>
                <div className="u-flex u-w-all">
                  <div
                    className="u-w-all  u-flex u-justify-left u-no-decoration"
                    style={{ textAlign: "start" }}
                  >
                    <p>{option.body}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="u-flex u-flex-column u-pb--16">
                <div className="u-flex u-w-all">
                  <div
                    className="u-w-all u-ph--20 u-pv--12 u-flex u-justify-left u-no-decoration u-border--darkgrey u-br--small"
                    style={{ textAlign: "start" }}
                  >
                    <Icon id="question_mark" fill="transparent" size="xl" />
                    <span className="u-pl--12 u-pv--10 u-flex u-justify-center">
                      <p className="u-text-transform--uppercase u-bold">
                        {option.title}:
                      </p>
                      <p className="u-ph--12">{option.body}</p>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  nextSlide = () => {
    this.setState({
      current:
        this.state.current === this.state.pageItems.length - 1
          ? 0
          : this.state.current + 1,
    });
  };

  prevSlide = () => {
    this.setState({
      current:
        this.state.current === 0
          ? this.state.pageItems.length - 1
          : this.state.current - 1,
    });
  };

  handleDotClick = (selectedIndex: number) => {
    if (selectedIndex < this.state.current) {
      this.prevSlide();
    } else if (selectedIndex > this.state.current) {
      this.nextSlide();
    }
  };

  grocerizeDescription = () => {
    return (
      <div
        className="u-ph--12 u-align-center u-justify-center"
        style={{ paddingTop: "27px", paddingBottom: "27px" }}
      >
        <p className="u-pv--16">
          Grocerize&trade; is an easy-to-use price comparison platform enabling
          you to save time and money every time you shop for groceries at
          Woolworths and Coles.
        </p>
        <p className="u-pv--16">
          Find your preferred items and compare prices in real time, in one
          place—then split your list between supermarkets to maximise your
          savings.
        </p>
        <p className="u-pv--16">
          Finally, push your lists from Grocerize™ directly to the online carts
          of both Woolworths and Coles, or create a digital shopping list on
          your phone to take in store.
        </p>
        <p className="u-pv--16">
          There are loads of features to help you plan, compare, and SAVE every
          time you shop, and best of all-it&#8217;s FREE!
        </p>
      </div>
    );
  };

  renderPage = () => {
    const url = routes.browse;
    const violet = variables.violetColor;

    return (
      <div className="">
        <div
          className="u-modal--content-bottom--mobile u-w-all--mobile u-relative u-overflow--scroll-y--mobile u-overflow--scroll-x--hidden"
          style={{
            // paddingTop: "60px",
            width: window.innerWidth > 600 ? "700px" : "100%",
          }}
        >
          <div>
            <div className="u-main-font--small u-color-charcoal u-background--white u-br--small ">
              {/* Contents Here */}
              {this.state.pageItems.map((option, index) => {
                console.log("index: ", index);
                return (
                  <div
                    key={index}
                    className={
                      "u-h-all u-w-all u-relative u-cursor--pointer " +
                      (index === this.state.current
                        ? "u-slide-carousel active"
                        : "u-slide-carousel")
                    }
                    style={{
                      fontSize: "14px",
                      margin: "0 3px",
                    }}
                  >
                    {index === this.state.current && (
                      <>
                        <div
                          style={{ paddingLeft: 18 }}
                          className="u-w-all u-flex u-pt--32"
                        >
                          <div
                            className="u-w-40pct"
                            style={{ marginLeft: "-10px" }}
                          >
                            <Icon
                              id="grocerize"
                              fill={violet}
                              width={115}
                              height={29}
                            />
                          </div>

                          <div
                            className="u-w-60pct"
                            style={{ marginLeft: "-15px" }}
                          >
                            <span className="u-text-center u-bold u-main-font--medium ">
                              {option.title}&trade;
                            </span>
                          </div>
                        </div>
                        <div
                          className="u-ph--48 u-text-center u-mauto u-flex u-flex-column u-justify-center u-align-center"
                          style={{ lineHeight: "17px" }}
                        >
                          {index === 0
                            ? this.grocerizeDescription()
                            : this.grocerizeSteps()}
                        </div>

                        <div className="u-w-all u-flex u-justify-center u-align-center u-ph--48--desktop u-min-height--48  u-pv--16 u-ph--16--mobile">
                          <button
                            className="u-background--magenta u-br--small u-w-all"
                            onClick={() =>
                              index === 0
                                ? this.nextSlide()
                                : (window.location.href = url)
                            }
                          >
                            <div className="u-color-white u-p--12 u-main-font--small u-bold u-text-unselectable ">
                              {option.buttonLabel}
                            </div>
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
              {/* Dots */}
              <div className="u-pt--12 u-pb--24">
                <div
                  className="u-carousel-dots u-bottom--0"
                  style={{ transform: "translateX(-50%)" }}
                >
                  {this.state.pageItems
                    .slice(0, this.state.pageItems.length)
                    .map((option, index) => {
                      return (
                        <button
                          key={index}
                          onClick={() => this.handleDotClick(index)}
                          className={
                            index === this.state.current
                              ? "u-dot active"
                              : "u-dot"
                          }
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return this.renderPage();
  }
}

export default GrocerizeUserGuideMobile;
