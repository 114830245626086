import React, { Component } from "react";
import { NotFoundView } from "../views";

class NotFoundController extends Component {
  render() {
    return <NotFoundView />;
  }
}

export default NotFoundController;
