import React, { Component } from "react";
import { connect } from "react-redux";
import { sortLists } from "../../../redux/actions/listActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  hideModal: any;
  sortLists: any;
}

interface State {}

class ListsSortModal extends Component<Props, State> {
  updateFilterSelection = (sort: SortOptions) => {
    if (sort.callback) {
      sort.callback();
    }
    this.props.hideModal();
  };
  render() {
    const sorts: SortOptions[] = [
      {
        sort_id: 1,
        name: "Name (A-Z)",
        callback: () => this.props.sortLists("A-Z"),
      },
      {
        sort_id: 2,
        name: "Name (Z-A)",
        callback: () => this.props.sortLists("Z-A"),
      },
      {
        sort_id: 3,
        name: "Price (High to Low)",
        callback: () => this.props.sortLists("AscPrice"),
      },
      {
        sort_id: 4,
        name: "Price (Low to High)",
        callback: () => this.props.sortLists("DescPrice"),
      },
      {
        sort_id: 7,
        name: "Largest Savings",
        callback: () => this.props.sortLists("DescSavings"),
      },
      {
        sort_id: 8,
        name: "Date Created",
        callback: () => this.props.sortLists("Created"),
      },
    ];

    return (
      <div className="o-modal__container">
        <div className="u-w-all ">
          <div className="o-caret-marker"></div>
        </div>
        <div
          className="u-background--white u-pt--8  u-br--vsmall"
          style={{
            marginLeft: "-20px",
            marginRight: "100px",
            width: "260px",
          }}
        >
          {sorts.map((sort, index) => {
            // const isSelected =
            //   this.props.items.sortTypeName === sort.name || false;
            const isLast = index === sorts.length - 1;

            return (
              <div
                className={
                  "u-flex u-align-center u-pv--12 u-ph--24" +
                  (isLast ? "" : " u-border--bottom--grey")
                }
                key={index}
              >
                <button
                  className="u-w-all u-text-left u-pl--32"
                  style={{
                    borderRadius: "2px",
                  }}
                  onClick={() => this.updateFilterSelection(sort)}
                >
                  <div
                    className={
                      "u-main-font--vsmall u-ph--12 u-semi-bold u-w-all"
                    }
                    style={{ fontSize: "14px" }}
                  >
                    {sort.name}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  lists: state.lists,
});

export default connect(mapStateToProps, { hideModal, sortLists })(
  ListsSortModal
);
