import { Component } from "react";
import { Icon } from "../../icons/Icon";

import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modalActions";

import SortDropdownModal from "./SortDropdownModal";

interface Props {
  showModal: any;
  items: ItemsState;
}

class SortDropdownButton extends Component<Props, {}> {
  state = {
    isExpanded: false,
    selectedSort: 1,
  };

  handleClickToExpand = () => {
    const button = document.getElementById("item-sort-dropdown");

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = () => (
        <div
          style={{
            marginTop: postionOfButton.bottom + "px",
            marginLeft: postionOfButton.left + "px",
            width: postionOfButton.right - postionOfButton.left + "px",
          }}
        >
          <SortDropdownModal />
        </div>
      );
      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    return (
      <button
        id="item-sort-dropdown"
        className="u-flex u-align-center u-justify-center u-h-all u-background--grey u-pl--24 "
        style={{
          borderRadius: "2px",
        }}
        onClick={() => this.handleClickToExpand()}
      >
        <Icon id="chevron_up_down" size="s" />
        <div
          className="u-main-font--vsmall u-hide--tablet u-color-charcoal u-text-oneline u-ml--16 u-semi-bold"
          style={{ fontSize: "14px" }}
        >
          Sort by{" "}
          {window.innerWidth > 600 && (
            <span className="u-bold">{this.props.items.sortTypeName}</span>
          )}
        </div>
      </button>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  items: state.items,
});

export default connect(mapStateToProps, { showModal })(SortDropdownButton);
