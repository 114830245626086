interface listActions {
  type: "GET_LISTS" | "SORT_LISTS";
  lists?: List[];
  sortType?:
    | "A-Z"
    | "Z-A"
    | "AscPrice"
    | "DescPrice"
    | "DescSavings"
    | "Created";
}

const initialState: ListsState = {
  lists: [],
  currentSortType: "",
};

export default function listReducer(state = initialState, action: listActions) {
  switch (action.type) {
    case "GET_LISTS":
      return {
        ...state,
        lists: action.lists,
      };
    case "SORT_LISTS":
      const newLists = [...state.lists];
      switch (action.sortType) {
        case "A-Z":
          newLists.sort((a, b) => a.title.localeCompare(b.title));
          break;
        case "Z-A":
          newLists.sort((a, b) => a.title.localeCompare(b.title)).reverse();
          break;

        case "DescPrice":
          newLists.sort((a, b) => a.grocerizeShopTotal - b.grocerizeShopTotal);
          break;
        case "AscPrice":
          newLists.sort((a, b) => b.grocerizeShopTotal - a.grocerizeShopTotal);
          break;
        case "DescSavings":
          newLists.sort(
            (a, b) => b.grocerizeTotalSavings - a.grocerizeTotalSavings
          );
          break;
        case "Created":
          newLists.sort(
            (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
          );
          break;
        default:
          break;
      }

      return {
        ...state,
        lists: newLists,
        currentSortType: action.sortType,
      };
    default:
      return state;
  }
}
