import React, { Component } from "react";
import { connect } from "react-redux";
import { sortUserFavourite } from "../../../redux/actions/favouritesActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  hideModal: any;
  sortUserFavourite: any;
}

interface State {}

class FavouritesSortModal extends Component<Props, State> {
  updateFilterSelection = (sort: SortOptions) => {
    if (sort.callback) {
      sort.callback();
    }
    this.props.hideModal();
  };

  render() {
    const sorts: SortOptions[] = [
      {
        sort_id: 1,
        name: "Name (A-Z)",
        callback: () => this.props.sortUserFavourite("A-Z"),
      },
      {
        sort_id: 2,
        name: "Name (Z-A)",
        callback: () => this.props.sortUserFavourite("Z-A"),
      },
      {
        sort_id: 3,
        name: "Date Created",
        callback: () => this.props.sortUserFavourite("Created"),
      },
    ];
    return (
      <div className="o-modal__container">
        <div className="u-w-all ">
          <div className="o-caret-marker"></div>
        </div>
        <div
          className="u-background--white u-pt--8  u-br--vsmall"
          style={{
            marginLeft: "-20px",
            marginRight: "100px",
            width: "260px",
          }}
        >
          {sorts.map((sort, index) => {
            const isLast = index === sorts.length - 1;

            return (
              <div
                className={
                  "u-flex u-align-center u-pv--12 u-ph--24" +
                  (isLast ? "" : " u-border--bottom--grey")
                }
                key={index}
              >
                <button
                  className="u-w-all u-text-left u-pl--32"
                  style={{
                    borderRadius: "2px",
                  }}
                  onClick={() => this.updateFilterSelection(sort)}
                >
                  <div
                    className={
                      "u-main-font--vsmall u-ph--12 u-semi-bold u-w-all"
                    }
                    style={{ fontSize: "14px" }}
                  >
                    {sort.name}
                  </div>
                </button>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  favourites: state.favourites,
});

export default connect(mapStateToProps, { hideModal, sortUserFavourite })(
  FavouritesSortModal
);
