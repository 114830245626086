import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../../redux/actions/modalActions";
import {
  addPriceAlert,
  updatePriceAlert,
} from "../../../../redux/actions/priceAlertActions";
import { Icon } from "../../../icons/Icon";

interface Props {
  itemData: ItemData;
  addPriceAlert: any;
  updatePriceAlert: any;
  heightOfCard: number;
  hideModal: any;
  priceAlerts: PriceAlertsState;
}

interface State {
  newAlertPrice: number;
  successfullySubmitted: boolean;
  imageLoadHasFailed: boolean;
  alertExists: boolean;
  attemptedToSubmit: boolean;
  priceAlertError: string;
}

class SaveItemToPriceAlertsModal extends Component<Props, State> {
  state = {
    newAlertPrice: 1.0,
    successfullySubmitted: false,
    imageLoadHasFailed: false,
    alertExists: false,
    priceAlertError: "",
    attemptedToSubmit: false,
  };

  componentDidMount() {
    const priceAlerts = this.props.priceAlerts.priceAlerts || [];

    priceAlerts.map((alert) => {
      if (alert.item.id === this.props.itemData.id) {
        this.setState({
          alertExists: true,
          newAlertPrice: alert.alert_price,
        });
        return true;
      } else {
        return false;
      }
    });
  }

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.currentTarget.value;

    if (val === "" || val === null) {
      val = "1.00";
    } else {
      val = val.split(".").join("");

      // Inserts a decimal point in the input
      let inputLength = val.length - 2;

      val =
        val.substring(0, inputLength) +
        "." +
        val.substring(inputLength, val.length);
    }

    this.setState({ newAlertPrice: parseFloat(val) });
  };

  savePriceAlert() {
    this.setState({ attemptedToSubmit: true });
    if (this.state.newAlertPrice === 0) {
      this.setState({
        priceAlertError: "You must enter an amount greater than 0",
      });
    } else {
      this.setState({ priceAlertError: "" });
      if (this.state.alertExists) {
        this.props.updatePriceAlert(
          this.props.itemData.id,
          this.state.newAlertPrice
        );
      } else {
        this.props.addPriceAlert(
          this.props.itemData.id,
          this.state.newAlertPrice
        );
      }

      this.setState({ successfullySubmitted: true });
    }
  }

  hideModalAfterTimeout = () => {
    setTimeout(() => this.props.hideModal(), 1300);
  };

  render() {
    const itemData: ItemData = this.props.itemData;
    const pageWidth = document.body.scrollWidth;
    let header;
    pageWidth > 600 ? (header = 240) : (header = 140);
    const footer = 50;
    const cardHeight = "290px";

    if (this.state.successfullySubmitted) {
      this.hideModalAfterTimeout();
    }

    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return (
      <div className="o-modal__container u-relative u-w-inherit">
        <div className="u-bottom--0--mobile o-modal--content u-w-inherit u-w-all--mobile ">
          <div
            className=" u-relative u-overflow-hidden"
            style={
              pageWidth > 600
                ? { height: this.props.heightOfCard }
                : { height: cardHeight }
            }
          >
            <div className="u-w-all u-h-all  u-slide-in--bottom">
              <div
                className="u-flex--mobile u-align-center--mobile u-background--white u-br--top--small u-outline--pink--top--3"
                style={{ height: header, zIndex: 0 }}
              >
                <div className="u-w-half--mobile u-text-center u-flex u-justify-center u-text-unselectable">
                  {itemData.image_url &&
                  this.state.imageLoadHasFailed === false ? (
                    <img
                      style={
                        pageWidth < 600
                          ? { height: "100px", marginTop: "16px" }
                          : { height: "140px", marginTop: "16px" }
                      }
                      src={itemData.image_url}
                      alt={itemData.name}
                      onError={() => {
                        this.setState({ imageLoadHasFailed: true });
                      }}
                    />
                  ) : (
                    <div
                      className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                      style={{ height: "140px", width: "140px" }}
                    >
                      No image found.
                    </div>
                  )}
                </div>
                <div className="u-ph--24">
                  <div
                    className="u-color-charcoal u-main-font--small u-semi-bold u-text-unselectable  u-pt--8 "
                    style={{
                      height: "64px",
                      lineHeight: "18px",
                      fontSize: "14px",
                    }}
                  >
                    <div
                      className=" u-truncate--3l u-overflow-hidden u-align-center u-flex"
                      style={{
                        whiteSpace: "break-spaces",
                        display: "-webkit-box",
                      }}
                    >
                      {itemData.name}
                    </div>
                  </div>
                </div>
              </div>
              {this.state.successfullySubmitted ? (
                <div
                  className="u-background--darkgrey u-br--bottom--small--desktop u-w-all u-h-all u-flex u-justify-center u-align-center u-outline--pink--left--3 u-outline--pink--right--3
                  u-outline--pink--bottom--3"
                  style={
                    pageWidth > 600
                      ? { height: this.props.heightOfCard - header }
                      : { height: "150px" }
                  }
                >
                  <div className="o-animate-tick ">
                    <Icon id="tickAnimated" size="xl" />
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className="u-background--darkgrey u-flex u-flex-column u-justify-center u-p--12 u-align-center u-outline--pink--left--3 u-outline--pink--right--3"
                    style={
                      pageWidth > 600
                        ? {
                            height: this.props.heightOfCard - (header + footer),
                          }
                        : { height: "100px" }
                    }
                  >
                    <div className="u-bold" style={{ fontSize: 14 }}>
                      {(this.state.alertExists ? "Modify " : "Set ") +
                        "Price Alert"}
                    </div>
                    <div className="u-flex--mobile u-pt--12--mobile">
                      <div className="u-pv--12 u-pr--24--mobile u-main-font--vsmall">
                        Alert me when the price drops to
                      </div>
                      <div className="u-flex u-justify-center u-align-center">
                        <div
                          className="u-color-darkgrey"
                          style={{ marginRight: "-20px", zIndex: 2 }}
                        >
                          $
                        </div>
                        <input
                          className="u-background--grey u-pv--12 u-text-align-center u-bold"
                          style={{ width: "100px" }}
                          value={formatter.format(
                            Number(this.state.newAlertPrice)
                          )}
                          placeholder="1.00"
                          type="text"
                          step="0.01"
                          maxLength={5}
                          pattern="[+-]?\d+(?:[.,]\d+)?"
                          onChange={(e) => this.searchKeyStrokeChange(e)}
                        />
                      </div>
                      {this.state.priceAlertError &&
                      (this.state.newAlertPrice !== null ||
                        this.state.attemptedToSubmit) ? (
                        <div
                          className="u-pt--2 u-color-magenta u-main-font--vsmall"
                          style={{ paddingLeft: "2px" }}
                        >
                          {"* " + this.state.priceAlertError}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <button
                    className={
                      "u-w-all u-flex u-justify-center u-align-center u-background--pink u-br--bottom--small--desktop"
                    }
                    onClick={() => this.savePriceAlert()}
                    style={{ height: footer }}
                  >
                    <div className="u-color-white u-bold u-main-font--small u-flex u-align-center">
                      Save Alert
                    </div>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  priceAlerts: state.priceAlerts,
});

export default connect(mapStateToProps, {
  hideModal,
  addPriceAlert,
  updatePriceAlert,
})(SaveItemToPriceAlertsModal);
