/* eslint-disable jsx-a11y/anchor-is-valid */
import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { Icon } from "../icons/Icon";
import NavBarMobile from "./NavBarMobile";

interface Props {
  type?: "long" | "beta" | "short" | "mobile" | "shortFilled";
  showModal?: any;
}

class Logo extends Component<Props, {}> {
  showPages = () => {
    const modalContent = () => <NavBarMobile />;
    this.props.showModal({ component: modalContent });
  };

  render() {
    const redirect_url = window.location.origin.toString() + "/browse";

    switch (this.props.type) {
      case "long":
        return <Icon id="longlogo" width="120px" height="30px" />;
      case "beta":
        return (
          <Icon id="grocerize_beta_logo_white" width="120px" height="30px" />
        );
      case "short":
        return <Icon id="shortlogo" width="28px" height="28px" />;
      case "shortFilled":
        return <Icon id="shortfilledlogo" width="28px" height="28px" />;
      case "mobile":
        return <Icon id="mobilelogo" height="23px" width="60px" />;
      default:
        return (
          <div className="u-h-all u-flex u-align-center">
            {/* dev note: wanted to user react router <Link .. /> but it does not recognise when the search params change */}
            <div className="u-hide--mobile">
              <a href={redirect_url}>
                <Icon id="longlogo" width="175px" height="34px" />
              </a>
            </div>
            <div
              className="u-hide--desktop"
              style={{ height: "40px" }}
              onClick={() => this.showPages()}
            >
              <Icon id="mobilelogo" height="40px" width="40px" />
            </div>
          </div>
        );
    }
  }
}

export default connect(null, { showModal })(Logo);
