import { Component } from "react";
import { connect } from "react-redux";
import AddToBasketButton from "./components/AddToBasketButton";
import FavouriteIndicator from "./components/FavouriteIndicator";
import HalfThePriceIndicator from "./components/HalfThePriceIndicator";
import ItemPricing from "./components/ItemPricing";
import SaveItemToListButton from "./components/SaveItemToListButton";
import SavePriceAlertButton from "./components/SavePriceAlertButton";
import PriceAlertIndicator from "./components/SpecialPriceIndicator";

interface Props {
  itemData: ItemData;
  basket?: BasketState;
  closeMatchItem?: ItemData | undefined;
  closeMatchItemInBasket?: ItemData | undefined;
  quantityOfCloseMatchItemInBasket: number;
  isProductUnavailable: boolean;
}

interface State {
  windowWidth: number;
  quantityOfItemInBasket: number;
  itemInBasket?: ItemData | undefined;
  imageLoadHasFailed: boolean;
  closeMatchImageLoadHasFailed: boolean;
  flipCard: boolean;
}

class ItemCardMobile extends Component<Props, State> {
  state = {
    windowWidth: 0,
    quantityOfItemInBasket: 0,
    itemInBasket: undefined,
    imageLoadHasFailed: false,
    closeMatchImageLoadHasFailed: false,
    flipCard: false,
  };

  getVendorNameFromId = (id: number) => {
    if (id === 1) {
      return "Coles";
    } else {
      return "Woolworths";
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.basket?.basketItems !== this.props.basket?.basketItems) {
      if (this.props.basket?.basketItems?.length) {
        // check if product is in the basket
        const itemInBasket = this.props.basket?.basketItems.find(
          (item) => item.id === this.props.itemData.id
        );

        if (itemInBasket?.quantity) {
          // the product has been added
          this.setState({
            itemInBasket,
            quantityOfItemInBasket: itemInBasket.quantity,
          });
        }
      } else {
        // the product has been removed from basket
        this.setState({ itemInBasket: undefined, quantityOfItemInBasket: 0 });
      }
    }
  };

  flipCard = () => {
    this.setState({
      flipCard: !this.state.flipCard,
    });
  };

  render() {
    const itemData: ItemData = this.props.itemData;
    if (itemData === undefined) {
      return null;
    }
    return (
      <div>
        <div
          id={this.props.itemData.id.toString()}
          className={
            "card u-background--white u-br--small u-relative u-border--darkgrey " +
            (this.state.flipCard ? "flip" : "reset")
          }
          style={{}}
        >
          <div className=" u-ph--24 u-pv--8 ">
            <div className="u-m0auto u-w-all u-flex u-justify-center u-text-unselectable ">
              {itemData.image_url && !this.state.imageLoadHasFailed ? (
                <img
                  id="item-image"
                  style={{ height: "88px", width: "88px" }}
                  src={itemData.image_url}
                  alt={itemData.name}
                  onError={() => {
                    this.setState({ imageLoadHasFailed: true });
                  }}
                />
              ) : (
                <div
                  className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                  style={{ height: "140px", width: "140px" }}
                >
                  No image found.
                </div>
              )}

              <div
                className="u-ph--24 u-flex u-align-center u-text-unselectable"
                style={{
                  height: "72px",
                  lineHeight: "18px",
                  fontSize: "14px",
                }}
              >
                <div className=" ">
                  <div
                    className="u-truncate--4l u-overflow-hidden u-bold u-color-charcoal "
                    style={{
                      whiteSpace: "break-spaces",
                      display: "-webkit-box",
                    }}
                  >
                    {itemData.name}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="u-flex u-text-unselectable"
              style={{ marginTop: "30px", marginBottom: "8px" }}
            >
              {
                <ItemPricing
                  itemData={itemData}
                  itemCloseMatch={this.props.closeMatchItem}
                  toggleCloseMatch={this.flipCard}
                />
              }
            </div>
            {/* </div> */}

            <AddToBasketButton
              itemData={
                this.state.itemInBasket
                  ? this.state.itemInBasket
                  : this.props.itemData
              }
              quantityOfItemInBasket={this.state.quantityOfItemInBasket}
            />
            <div
              className="u-flex u-justify-between u-ph--32 u-pv--20"
              style={{ paddingBottom: "2px" }}
            >
              <SaveItemToListButton itemData={itemData} />
              <SavePriceAlertButton itemData={itemData} />
            </div>
          </div>
          <div className="u-flex u-justify-between u-top--0 u-absolute u-w-all">
            {itemData.isSpecial && !itemData.isHalfThePrice && (
              <PriceAlertIndicator isSpecial={itemData.isSpecial || false} />
            )}

            {itemData.isSpecial && itemData.isHalfThePrice && (
              <HalfThePriceIndicator isHalfThePrice={itemData.isHalfThePrice} />
            )}

            {this.props.closeMatchItem ? (
              <div className="u-bold u-background--blue u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                Close match available
              </div>
            ) : this.props.isProductUnavailable ? (
              <div className="u-bold u-background--darkgrey u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                Product Unavailable
              </div>
            ) : (
              <></>
            )}
            <FavouriteIndicator item_id={itemData.id} />
          </div>
        </div>
        {/* CLOSE MATCH ITEM CARD - BEHIND ITEM CARD*/}
        {this.props.closeMatchItem ? (
          <div
            className={
              "card u-background--white u-br--small u-relative u-border--closeMatch " +
              (this.state.flipCard ? "reset" : "flip")
            }
            style={{
              marginTop: "-300px",
            }}
          >
            <div className=" u-ph--24 u-pv--8  ">
              <div className="u-m0auto u-w-all u-flex u-justify-center u-text-unselectable ">
                {this.props.closeMatchItem.image_url &&
                !this.state.closeMatchImageLoadHasFailed ? (
                  <img
                    id="item-image"
                    style={{ height: "88px", width: "88px" }}
                    src={this.props.closeMatchItem.image_url}
                    alt={this.props.closeMatchItem.name}
                    onError={() => {
                      this.setState({ closeMatchImageLoadHasFailed: true });
                    }}
                  />
                ) : (
                  <div
                    className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                    style={{ height: "140px", width: "140px" }}
                  >
                    No image found.
                  </div>
                )}
                {/* </div> */}
                {/* <div className="u-ph--24"> */}
                <div
                  className="u-ph--24 u-flex u-align-center u-text-unselectable "
                  style={{
                    height: "72px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  <div className="">
                    <div
                      className=" u-truncate--5l u-overflow-hidden u-align-center u-flex"
                      style={{
                        whiteSpace: "break-spaces",
                        display: "-webkit-box",
                      }}
                    >
                      {this.props.closeMatchItem.name}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="u-flex u-text-unselectable "
                style={{ marginTop: "30px", marginBottom: "8px" }}
              >
                {
                  <ItemPricing
                    itemData={this.props.closeMatchItem}
                    itemCloseMatch={this.props.itemData}
                    toggleCloseMatch={this.flipCard}
                  />
                }
              </div>
              {/* </div> */}

              <AddToBasketButton
                itemData={
                  this.props.closeMatchItemInBasket
                    ? this.props.closeMatchItemInBasket
                    : this.props.closeMatchItem
                }
                quantityOfItemInBasket={
                  this.props.quantityOfCloseMatchItemInBasket
                }
              />
              <div
                className="u-flex u-justify-between u-ph--32 u-pv--20"
                style={{ paddingBottom: "2px" }}
              >
                <SaveItemToListButton itemData={this.props.closeMatchItem} />
                <SavePriceAlertButton itemData={this.props.closeMatchItem} />
              </div>
            </div>
            <div className="u-flex u-justify-between u-top--0 u-absolute u-w-all">
              <PriceAlertIndicator
                isSpecial={this.props.closeMatchItem.isSpecial || false} // if logged in and is watched we should show the icon
              />
              {this.props.closeMatchItem ? (
                <div className="u-bold u-background--blue u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                  Close match
                </div>
              ) : this.props.isProductUnavailable ? (
                <div className="u-bold u-background--darkgrey u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                  Product Unavailable
                </div>
              ) : (
                <></>
              )}
              <FavouriteIndicator item_id={this.props.closeMatchItem.id} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, null)(ItemCardMobile);
