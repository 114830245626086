import React, { Component } from "react";
import { connect } from "react-redux";
import { deleteUserFavourite } from "../../../redux/actions/favouritesActions";
import { hideModal } from "../../../redux/actions/modalActions";
import { Icon } from "../../icons/Icon";

interface Props {
  itemData: ItemData;
  hideModal: any;
  deleteUserFavourite: any;
}

interface State {
  successfullyDeleted: boolean;
}

class DeleteFavouriteModalContentMobile extends Component<Props, State> {
  state = {
    successfullyDeleted: false,
  };

  deletePriceAlert() {
    this.props.deleteUserFavourite(this.props.itemData.id);
    this.setState({
      successfullyDeleted: true,
    });
  }

  hideModalAfterTimeout = () => {
    setTimeout(() => this.props.hideModal(), 1300);
  };

  render() {
    if (this.state.successfullyDeleted) {
      this.hideModalAfterTimeout();
    }
    return (
      <div className="o-modal__container u-relative u-w-inherit">
        <div className="u-bottom--0--mobile o-modal--content u-w-inherit u-w-all--mobile">
          <div className="u-w-all u-background--magenta u-br--top--medium">
            <div className="u-vw u-p--24 u-flex u-justify-between u-align-center">
              <div className="u-color-white u-main-font--medium u-bold">
                Delete Favourite?
              </div>
            </div>
            <div className="u-background--white u-ph--24">
              <div className="u-main-font--medium u-pv--16">
                Delete{" "}
                <span className="u-bold">
                  "{this.props.itemData.name}"
                </span>
              </div>
              <div className="u-pb--16 u-flex u-align-center">
                <button
                  className="u-background--magenta u-br--small u-color-white u-p--8 u-ph--16 u-semi-bold u-main-font--medium"
                  onClick={() => this.deletePriceAlert()}
                >
                  Delete
                </button>
                {this.state.successfullyDeleted ? (
                  <div className="u-ph--24 u-color-green u-bold u-flex">
                    <Icon id="tick" size="m" />
                    <div className="u-ml--8">Favourite Deleted</div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { deleteUserFavourite, hideModal })(
  DeleteFavouriteModalContentMobile
);
