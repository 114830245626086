const initialState = {
  component: undefined,
  data: {},
  status: "hidden",
  className: "",
  position: "centered",
  onClose: "",
  isFixed: true,
  style: undefined,
};

export default function modalReducers(state = initialState, action: any) {
  switch (action.type) {
    case "SHOW_MODAL":
      return {
        component: action.component,
        data: action.data,
        status: "visible",
        className: action.className,
        position: action.position,
        onClose: action.onClose,
        isFixed: action.isFixed,
        style: action.style,
      };

    case "HIDE_MODAL":
      return initialState;

    default:
      return state;
  }
}
