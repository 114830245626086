import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { routes } from "../../utils/routes";

interface Props {
  auth0: any;
}

interface State {}

interface NavLink {
  href: string;
  text: string;
  onClick: any;
}

class LoginSignupButtons extends Component<Props, State> {
  showSignUp = () => {
    if (this.props.auth0.isAuthenticated) {
      return <Redirect to={routes.browse} />;
    }
    const redirect_url = window.location.origin.toString() + "/browse";
    this.props.auth0.loginWithRedirect({
      login_hint: "signup",
      redirect_url,
    });
  };

  showLogIn = () => {
    const redirect_uri = window.location.origin.toString() + "/browse";
    this.props.auth0.loginWithRedirect({
      login_hint: "login",
      redirect_uri,
    });
  };

  render() {
    const loggedOutLinks = [
      { href: "/", text: "Login", onClick: this.showLogIn },
      { href: "/", text: "Signup", onClick: this.showSignUp },
    ];

    return (
      <div className="u-flex">
        {loggedOutLinks.map((link: NavLink, index: number) => {
          return (
            <button
              className="u-cursor--pointer"
              key={index}
              onClick={link.onClick}
            >
              <div
                className={
                  "u-main-font--14--small--desktop u-main-font--small-mobile u-link u-color-white u-ph--8--exclude-small-mobile u-pv--12 u-semi-bold u-ml--12--desktop u-ph--2"
                }
              >
                {link.text}
              </div>
            </button>
          );
        })}
      </div>
    );
  }
}

export default withAuth0<any>(LoginSignupButtons);
