import { updateURLSlugs } from "../../components/naviagtions/itemNavs/itemNavigationFunctions";

interface categoryAction {
  type: "GET_TREE" | "UPDATE_SELECTED_NODE" | "UPDATE_NAVIGATION_NODE"; // ADD OTHERS HERE
  categoryTree: CategoryTreeNode[];
  selectedNode?: CategoryTreeNode | null;
}

const initialState: CategoryState = {
  categoryTree: [],
};

export default function searchReducer(
  state = initialState,
  action: categoryAction
) {
  switch (action.type) {
    case "GET_TREE":
      return {
        ...state,
        categoryTree: action.categoryTree,
      };
    case "UPDATE_SELECTED_NODE":
      // change the url to reflect the cat we are in
      updateURLSlugs(action.selectedNode, state.selectedNode);
      return {
        ...state,
        selectedNode: action.selectedNode,
      };
    case "UPDATE_NAVIGATION_NODE":
      // change the url to reflect the cat we are in but clear the url first
      updateURLSlugs();
      updateURLSlugs(action.selectedNode, state.selectedNode);

      return {
        ...state,
        selectedNode: action.selectedNode,
      };
    default:
      return state;
  }
}
