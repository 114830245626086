import React, { Component } from "react";
import { connect } from "react-redux";
import { findListAndReplaceBasket } from "../../../redux/actions/basketActions";
import { hideModal } from "../../../redux/actions/modalActions";
import { getWordyDate } from "../../../utils/UIFunctions";
import { Icon } from "../../icons/Icon";
import AddListToCartModalContentMobile from "./AddListToCartModalContentMobile";
import DeleteListModalContentMobile from "./DeleteListModalContentMobile";
import RenameListModalContentMobile from "./RenameListModalContentMobile";
import ReplaceCartWithListModalContentMobile from "./ReplaceCartWithListModalContentMobile";

interface Props {
  list: List;
  hideModal: any;
}

interface State {}

class ListOptionsModalMobile extends Component<Props, State> {
  render() {
    const list = this.props.list;

    return (
      <div className="o-modal__container">
        <div className="u-w-all o-modal--content-bottom u-slide-in--bottom ">
          <div className="u-relative u-overflow-hidden ">
            <div className="">
              <div className="u-w-all u-background--magenta u-br--top--medium">
                <div className="u-vw  u-p--24 u-flex u-justify-between u-align-center">
                  <div className="u-color-white u-main-font--small u-bold">
                    {list.title}
                    <span className=" u-semi-bold u-main-font--vsmall u-ph--12">
                      {getWordyDate(list.updatedAt)}
                    </span>
                  </div>
                  <button onClick={this.props.hideModal}>
                    <Icon id="clear" stroke="#e1e1e1" />
                  </button>
                </div>
              </div>
              <div className="u-background--white">
                {/* Add to cart */}
                <AddListToCartModalContentMobile list={list} />
                {/* Replace cart with list */}
                <ReplaceCartWithListModalContentMobile list={list} />
                {/* Rename */}
                <RenameListModalContentMobile list={list} />
                {/* Delete */}
                <DeleteListModalContentMobile list={list} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { findListAndReplaceBasket, hideModal })(
  ListOptionsModalMobile
);
