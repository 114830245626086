import { Component } from "react";
import { connect } from "react-redux";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import { hideModal } from "../../redux/actions/modalActions";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import { Icon } from "../icons/Icon";

interface Props {
  hideModal: any;
}

interface State {}

class ChangedPasswordEmailModal extends Component<Props, State> {
  state = {
    emailSent: false,
    isLoading: true,
  };
  componentDidMount = async () => {
    // tell the backend to send the email
    await httpClient.instance
      .get(process.env.REACT_APP_BACKEND_URL + "/user/reset_password")
      .then(() => {
        this.setState({ emailSent: true, isLoading: false });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ emailSent: false, isLoading: false });
      });
  };
  render() {
    return (
      <div className="u-br--small o-modal__container">
        <div
          className="o-modal--content-centered u-modal--content-bottom--mobile u-w-all--mobile u-relative"
          style={{
            width: window.innerWidth > 600 ? "500px" : "100%",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18 }}
            onClick={() => this.props.hideModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>
          {this.state.isLoading ? (
            <GrocerizeLoadingWheel />
          ) : (
            <>
              <div
                style={{
                  fontSize: "14px",
                }}
                className="u-main-font--small u-color-charcoal   u-background--white u-br--top--small"
              >
                <div className="u-ph--48  u-text-center u-mauto ">
                  <div
                    className=" u-align-middle u-w-all u-text-center"
                    style={{ paddingTop: 60 }}
                  >
                    <div className="u-main-font--small u-bold">
                      We've just sent you an email to reset your password.
                    </div>
                    <div className="u-pt--24">
                      <img
                        style={{ maxHeight: 80, minWidth: 200, maxWidth: 300 }}
                        src="/popups/mail.svg"
                        alt="An email with a notification bubble"
                      />
                    </div>
                    <div className="u-pt--24 u-ph--8 u-pb--24">
                      Please open your email and follow the instructions to
                      reset your passsword.
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-br--bottom--small"
                onClick={() => this.props.hideModal()}
              >
                Close
              </button>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default connect(null, { hideModal })(ChangedPasswordEmailModal);
