import React, { Component } from "react";

interface Props {}

interface State {}

class Loading extends Component<Props, State> {
  render() {
    return (
      <div className="u-w-all u-h-all u-background--white">
        <div
          style={{ paddingLeft: "120px", paddingTop: "50px" }}
          className="u-color-magenta u-main-font--large"
        >
          Loading...
        </div>
      </div>
    );
  }
}

export default Loading;
