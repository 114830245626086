import React, { Component } from "react";
import { COLES, WOOLWORTHS } from "../../redux/enums";

interface Props {
  onClickHandler: any;
  isToggled: boolean;
  pricing: ItemPricings | null;
  vendorId: typeof COLES | typeof WOOLWORTHS;
}

class GrocerizeVendorPricing extends Component<Props, {}> {
  render() {
    return (
      <button
        onClick={() => this.props.onClickHandler()}
        className={
          "u-w-half--mobile u-w-175px--desktop u-p--2 u-br--small" +
          (!this.props.isToggled ||
          (this.props.pricing?.price === 0 && !this.props.pricing?.available)
            ? " u-background--grey"
            : this.props.vendorId === COLES
            ? " u-background--coles "
            : " u-background--woolworths ")
        }
      >
        <div
          className={
            "u-text-left u-br--small--inset u-background--grey  u-ph--20 u-pv--12 u-h-all u-w-all" +
            (this.props.vendorId === WOOLWORTHS ? " u-pl--32" : " ")
          }
        >
          <div
            className={
              "u-bold u-main-font--vsmall" +
              (this.props.pricing?.price === 0 ||
              !this.props?.pricing ||
              !this.props.pricing?.available
                ? " u-color-grey"
                : !this.props.isToggled
                ? " u-color-darkgrey"
                : this.props.vendorId === COLES
                ? " u-color-coles"
                : " u-color-woolworths")
            }
            style={{ fontSize: "14px", lineHeight: "14px" }}
          >
            {this.props.vendorId === COLES ? "Coles" : "Woolworths"}
          </div>
          <div
            className={
              "u-semi-bold u-pt--8" +
              (this.props.pricing?.price === 0 ||
              !this.props?.pricing ||
              !this.props.pricing?.available
                ? " u-color-grey u-bold"
                : " u-color-charcoal ")
            }
            style={{ fontSize: "14px" }}
          >
            {typeof this.props.pricing?.price === "number" &&
            this.props.pricing?.available
              ? "$" + this.props.pricing.price.toFixed(2)
              : "N/A"}
          </div>
          {/* need to refactor this */}

          {this.props.pricing && this.props.pricing?.available ? (
            <div
              className={
                "u-main-font--vsmall u-pt--4 " +
                (this.props.pricing?.price === 0 ? " u-color-grey" : "")
              }
              style={{ fontSize: "10px" }}
            >
              ${this.props.pricing?.cup_price} /{" "}
              {this.props.pricing?.cup_volume}
            </div>
          ) : (
            <div
              className={"u-main-font--vsmall u-pt--12 u-color-grey"}
              style={{ fontSize: "10px" }}
            ></div>
          )}
        </div>
      </button>
    );
  }
}

export default GrocerizeVendorPricing;
