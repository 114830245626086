import React, { Component } from "react";
import { withAuth0 } from "@auth0/auth0-react";
import { Icon } from "../icons/Icon";
import { hideModal, showModal } from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";

interface Props {
  auth0: any;
  showModal: any;
  email: string;
}

interface State {
  isLoading: boolean;
  hasClickedToVerify: boolean;
}

class VerifyEmail extends Component<Props, State> {
  state = {
    isLoading: false,
    hasClickedToVerify: false,
  };

  reloadSignupPage = async () => {
    this.setState({ isLoading: true });

    // need to login the user again as the token needs to be updated
    // This is the simplist way currently (other than writing a new endpoint to check it)
    const { loginWithPopup } = this.props.auth0;
    await loginWithPopup().then(() => {
      // record that the user has clicked the button once as to show a different message to them (which wont be shown once verified)
      this.setState({ hasClickedToVerify: true });
    });

    // toggle loading to show user something is happening after a click (if they haven't verified nothing changes)
    setTimeout(() => this.setState({ isLoading: false }), 1000);
  };

  // For Mobile View Rendering
  render() {
    const { user } = this.props.auth0;

    return (
      <>
        <div className="u-main-font--small u-color-charcoal u-background--white u-text-center u-relative">
          <div className="u-p--32 u-pv--64">
            <div className=" u-flex u-justify-center">
              <Icon
                id="shortfilledlogo"
                height={55}
                width={55}
                className="u-mt--48  u-flex u-justify-center u-align--center"
              />
            </div>
            <div className="u-h-all">
              <div className="u-bold u-main-font--medium u-pt--32">
                Hmm... you're email doesn't seem verified
              </div>
              <div
                className="u-main-font--small u-pv--32 u-ph--48"
                style={{ fontSize: "14px" }}
              >
                We have sent an email to <b>{user.email ? user.email : (this.props.email ? this.props.email : "")}</b>
                <br />
                <br />
                <br />
                <span className="u-bold">
                  Please try to click the verification link in the email.
                </span>
                <br />
                <br />
                <br />
                <span className="">
                  Upon veriyfing your email you will be re-directed back to
                  Grocerize.
                </span>
              </div>
            </div>
          </div>
          <div className="u-fixed u-w-all" style={{ bottom: 0 }}>
            <button
              className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white"
              onClick={() => this.reloadSignupPage()}
            >
              I have verified my account!
            </button>
          </div>
        </div>
        {/* Show loading state */}
        {this.state.isLoading ? (
          <div className="o-modal">
            <div className="o-modal__background "></div>
            <div className="o-modal__container o-modal u-w-all">
              <div className="o-modal__content u-flex u-justify-center u-align-center u-h-all u-w-all">
                <GrocerizeLoadingWheel />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withAuth0<any>(
  connect(null, { hideModal, showModal })(VerifyEmail)
);
