import { withAuth0 } from "@auth0/auth0-react";
import { Component } from "react";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { routes } from "../../utils/routes";
import GrocerizeUserGuideMobile from "../grocerize/GrocerizeUserGuideMobile";
import { Icon } from "../icons/Icon";

interface Props {
  showModal: any;
  hideModal: any;
  auth0: any;
  isView?: boolean;
}

interface State {
  pageItems: any[];
  current: number;
  onFirstPage: boolean;
  moreFeatures: boolean;
}

class UserGuidePopup extends Component<Props, State> {
  state = {
    pageItems: [
      {
        id: 1,
        title: "So you’re ready to save some money!",
        buttonLabel: "Get Started",
        subButtonLabel: "Show me more!",
      },
      {
        id: 2,
        title: "How to use Grocerize",
        buttonLabel: "Start saving!",
      },
    ],
    current: 0,
    onFirstPage: true,
    url: process.env.REACT_APP_FRONTEND_URL,
    moreFeatures: false,
  };

  storeUserInteraction = () => {
    localStorage.setItem("has-visited-website", "yes");
  };

  grocerizeSteps = () => {
    const howToUseContent = [
      {
        id: 1,
        title: "Step 1",
        body: "Search for your preferred items and see the real time price comparison between Woolworths and Coles.",
      },
      {
        id: 2,
        title: "Step 2",
        body: "Add your items to the Grocerize™ cart and watch your savings tally as you go.",
      },
      {
        id: 3,
        title: "Step 3",
        body: "In your cart, click “Grocerize™ List” and your shop will automatically be split between the two supermarkets to maximise your savings.",
      },
      {
        id: 4,
        title: "Hint",
        body: "click the ‘?’ for tips on how to best use the Grocerize™ page.",
      },
      {
        id: 5,
        title: "Step 4",
        body: "Push your lists from Grocerize™ (desktop only) directly to the online carts of both Woolworths and Coles, or create a digital shopping list on your phone to take in store.",
      },
    ];

    return (
      <div className="u-w-all u-pt--16">
        <div className="u-flex u-flex-column">
          {howToUseContent.map((option, index) => {
            return index !== 3 ? (
              <div className="u-flex u-pb--16" key={index}>
                <div className="u-flex u-justify-left u-align-center u-w-40pct u-pv--4">
                  <div className="u-ph--20 u-pv--12 u-text-transform--uppercase u-bold u-br--small u-background--darkgrey u-flex u-justify-left u-align-center">
                    {option.title}
                  </div>
                </div>
                <div className="u-flext u-w-all">
                  <div
                    className="u-w-all  u-flex u-justify-left u-no-decoration"
                    style={{ textAlign: "start" }}
                  >
                    <p>{option.body}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="u-flex u-pb--16" key={index}>
                <div className="u-flex u-justify-left u-align-center u-w-40pct u-pv--4">
                  <div className="u-ph--20 u-pv--12 u-text-transform--uppercase u-bold u-br--small u-flex u-justify-left u-align-center"></div>
                </div>
                <div className="u-flext u-w-all">
                  <div
                    className="u-w-all u-ph--20 u-pv--20 u-flex u-justify-left u-no-decoration u-border--darkgrey u-br--small"
                    style={{ textAlign: "start" }}
                  >
                    <Icon id="question_mark" fill="transparent" size="xl" />
                    <span className="u-pl--12 u-pv--8 u-flex u-justify-center">
                      <p className="u-text-transform--uppercase u-bold">
                        {option.title}:
                      </p>
                      <p className="u-ph--12">{option.body}</p>
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  nextSlide = () => {
    this.setState({
      current:
        this.state.current === this.state.pageItems.length - 1
          ? 0
          : this.state.current + 1,
    });
  };

  prevSlide = () => {
    this.setState({
      current:
        this.state.current === 0
          ? this.state.pageItems.length - 1
          : this.state.current - 1,
    });
  };

  handleDotClick = (selectedIndex: number) => {
    if (selectedIndex < this.state.current) {
      this.prevSlide();
    } else if (selectedIndex > this.state.current) {
      this.nextSlide();
    }
  };

  closeModal = () => {
    this.props.hideModal();
    this.storeUserInteraction();
  };

  removeLocalStorage = () => {
    if (localStorage.getItem("isFirstTimeUser")) {
      localStorage.removeItem("isFirstTimeUser");
    }
    this.clearUserGuideStatus();
    this.closeModal();
  };

  clearUserGuideStatus = () => {
    if (sessionStorage.getItem("first_load_time")) {
      sessionStorage.setItem("first_load_time", "2");
    }
  };

  grocerizeDescription = () => {
    return (
      <div
        className="u-ph--12"
        style={{ paddingTop: "27px", paddingBottom: "27px" }}
      >
        <iframe
          width="600"
          height="300"
          src="https://www.youtube.com/embed/iEAunRscrlg"
          title="Welcome to Grocerize"
          //@ts-ignore
          frameborder="1"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    );
  };

  loadPageView = () => {
    window.open(routes.mobileUserGuide, "_blank", "noopener,noreferrer");
  };

  gotoLandingPage = () => {
    window.open(routes.landing, "_blank", "noopener,noreferrer");
  };

  moreInfo = () => {
    this.setState({ onFirstPage: false });
  };

  mobileLanding = () => {
    const violet = variables.violetColor;
    return (
      <div className="u-flex u-flex-column u-main-font--small u-color-charcoal u-background--white u-br--top--small u justify-center u-align-center">
        <button
          id="guide-close-btn"
          className="u-absolute"
          style={{ marginTop: 18, right: 18 }}
          onClick={() => {
            this.clearUserGuideStatus();
            this.closeModal();
          }}
        >
          <Icon id="clear" stroke="grey" size="s" />
        </button>
        <div className="u-ph--48 u-mauto ">
          <div
            className="u-align-middle u-w-al "
            style={{ paddingTop: "35px" }}
          >
            <div className="u-text-center">
              <Icon id="grocerize" fill={violet} width={115} height={35} />
            </div>
            {this.state.onFirstPage === true && (
              <>
                <div className="u-pv--20">
                  <p className="u-bold u-main-font--medium u-text-center u-text-oneline">
                    Start Saving Today!
                  </p>
                </div>
                <div className="u-pv--4">
                  <div className="u-w-all u-flex-column u-flex u-ph--48--desktop u-min-height--48  u-pb--4">
                    <div className="u-flex u-pb--12">
                      <div className="u-bold">
                        <p className="u-flex u-justify-center u-align-center u-background--darkgrey u-br--small u-w--34--mobile u-h--34--mobile">
                          1
                        </p>
                      </div>
                      <div className="u-ml--18--mobile">
                        Browse and find your items and add them to your{" "}
                        <span className="u-bold">Compare Cart</span>
                      </div>
                    </div>
                    <div className="u-flex u-pb--12">
                      <div className="u-bold">
                        <p className="u-flex u-justify-center u-align-center u-background--darkgrey u-br--small u-w--34--mobile u-h--34--mobile">
                          2
                        </p>
                      </div>
                      <div className="u-ml--18--mobile">
                        Open your cart and{" "}
                        <span className="u-bold">Grocerize&trade; List, </span>
                        we'll calculate the best prices for you automatically
                      </div>
                    </div>
                    <div className="u-flex u-pb--24">
                      <div className="u-bold">
                        <p className="u-flex u-justify-center u-align-center u-background--darkgrey u-br--small u-w--34--mobile u-h--34--mobile">
                          3
                        </p>
                      </div>
                      <div className="u-ml--18--mobile">
                        We'll create you a shopping list to take in-store or you
                        can push your items through to the Coles and/or
                        Woolworths website to buy online
                      </div>
                    </div>
                    <button
                      className="u-background--green u-br--small u-w-all u-pv--20"
                      onClick={() => {
                        if (localStorage.getItem("isFirstTimeUser")) {
                          localStorage.removeItem("isFirstTimeUser");
                        }

                        this.moreInfo();
                      }}
                    >
                      <div className="u-color-white u-main-font--small--mobile u-bold u-text-unselectable">
                        More Information
                      </div>
                    </button>
                  </div>
                </div>
              </>
            )}

            {this.state.onFirstPage === false && (
              <>
                <div className="u-pv--20">
                  <p className="u-bold u-main-font--medium u-text-center u-text-oneline">
                    More Information
                  </p>
                </div>
                <div className="u-pv--4">
                  <iframe
                    className="u-w-all u-h-all"
                    src="https://www.youtube.com/embed/iEAunRscrlg"
                    title="Welcome to Grocerize"
                    //@ts-ignore
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="u-pv--24--mobile u-text-center">
                  Grocerize&trade; is available on{" "}
                  <span className="u-bold">mobile and desktop!</span> Start
                  saving today!
                </div>

                <div className="u-pv--4--mobile u-text-center u-mb--24">
                  <a className="u-bold u-main-font--medium" href="">
                    grocerize.com.au
                  </a>
                </div>
              </>
            )}

            {this.state.moreFeatures && <GrocerizeUserGuideMobile />}
          </div>
        </div>
        <div className="u-w-all u-mt--12">
          <div className="u-background--lightgrey u-flex u-justify-center u-align-center u-pv--32">
            <img src="./popups/logos_news.svg" alt="Logos of News" />
          </div>
          <button
            id="guide-skip-btn"
            className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-main-font--small--mobile"
            style={{ lineHeight: "19px" }}
            onClick={() => {
              this.removeLocalStorage();
            }}
          >
            I've got it, Start saving!
          </button>
        </div>
      </div>
    );
  };

  renderPage = () => {
    const isView = this.props.isView !== undefined ? this.props.isView : true;
    const violet = variables.violetColor;
    return (
      <div className="u-br--small o-modal__container">
        <div
          className="o-modal--content-centered u-modal--content-bottom--mobile u-w-all--mobile u-relative"
          style={{
            width: window.innerWidth > 600 ? "700px" : "100%",
          }}
        >
          {window.innerWidth > 600 ? (
            <div className="">
              <div className="u-pv--4 u-cursor">
                <button
                  className="u-absolute"
                  style={{
                    top: 28,
                    right: 18,
                  }}
                  onClick={() => this.closeModal()}
                >
                  <Icon id="clear" stroke="grey" size="s" />
                </button>
              </div>
              <div>
                <div className="u-main-font--small u-color-charcoal u-background--white u-br--small  u-overflow-hidden">
                  {/* Contents Here */}
                  {this.state.pageItems.map((option, index) => {
                    return (
                      <div
                        key={index}
                        className={
                          "u-h-all u-w-all u-relative u-cursor--pointer " +
                          (index === this.state.current
                            ? "u-slide-carousel active"
                            : "u-slide-carousel")
                        }
                        style={{
                          fontSize: "14px",
                          margin: "0 3px",
                        }}
                      >
                        {index === this.state.current && (
                          <>
                            <div
                              style={{ paddingLeft: 18 }}
                              className="u-w-all u-flex u-mt--32"
                            >
                              <div>
                                <Icon
                                  id="grocerize"
                                  fill={violet}
                                  width={70}
                                  height={29}
                                />
                              </div>
                            </div>
                            <div className="u-text-center u-bold u-main-font--medium ">
                              {option.title}
                            </div>
                            <div
                              className="u-ph--48 u-text-center u-mauto u-flex u-flex-column u-justify-center u-align-center"
                              style={{ lineHeight: "17px" }}
                            >
                              {index === 0
                                ? this.grocerizeDescription()
                                : this.grocerizeSteps()}
                            </div>

                            <div className="u-w-all u-ph--48--desktop u-min-height--48  u-pv--2">
                              <div className="u-flex u-justify-center u-align-center u-pb--24">
                                <img
                                  src="./popups/logos_news_long.svg"
                                  alt="Logos of News"
                                />
                              </div>
                              <button
                                className="u-background--magenta u-br--small u-w-all"
                                onClick={() => {
                                  index === 0
                                    ? this.nextSlide()
                                    : this.removeLocalStorage();
                                }}
                              >
                                <div className="u-color-white u-p--12 u-main-font--small u-bold u-text-unselectable ">
                                  {option.buttonLabel}
                                </div>
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    );
                  })}
                  {/* Dots */}
                  <div className="u-pv--24">
                    <div
                      className="u-carousel-dots u-bottom--0"
                      style={{ transform: "translateX(-50%)" }}
                    >
                      {this.state.pageItems
                        .slice(0, this.state.pageItems.length)
                        .map((option, index) => {
                          return (
                            <button
                              key={index}
                              onClick={() => this.handleDotClick(index)}
                              className={
                                index === this.state.current
                                  ? "u-dot active"
                                  : "u-dot"
                              }
                            />
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            this.mobileLanding()
          )}
        </div>
      </div>
    );
  };

  render() {
    return this.renderPage();
  }
}

export default withAuth0<any>(
  connect(null, { showModal, hideModal })(UserGuidePopup)
);
