import { Component } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../../icons/Icon";

class SocialMediaButton extends Component {
  render() {
    return (
      <>
        <Link
          to={{ pathname: "https://www.facebook.com/Grocerize/" }}
          target="_blank"
          className="u-ph--16"
        >
          <Icon id="facebook" fill="#ffffff" size="l" />
        </Link>
        <Link
          className="u-ph--16"
          to={{
            pathname: "https://www.instagram.com/grocerize/?hl=en",
          }}
          target="_blank"
        >
          <Icon id="instagram" fill="#ffffff" size="l" />
        </Link>
      </>
    );
  }
}

export default SocialMediaButton;
