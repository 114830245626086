import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../redux/actions/modalActions";
import { deletePriceAlert } from "../../../redux/actions/priceAlertActions";

interface Props {
  priceAlert: PriceAlert;
  hideModal: any;
  deletePriceAlert: any;
}

interface State {}

class DeletePriceAlertContent extends Component<Props, State> {
  render() {
    return (
      <div
        className="u-background--white u-pt--8  u-br--vsmall"
        style={{
          marginLeft: "-250px",
          marginRight: "100px",
          width: "375px",
        }}
      >
        <div className="u-ph--32 u-pb--32 u-pt--24">
          <div className="u-color-magenta u-semi-bold u-main-font--small u-pb--8">
            Delete Price Alert
          </div>
          <div className="u-main-font--vsmall u-pb--16">
            Delete{" "}
            <span className="u-bold">"{this.props.priceAlert.item.name}"</span>
          </div>

          <button
            className="u-background--magenta u-br--small u-color-white u-p--8 u-ph--16 u-semi-bold u-main-font--vsmall"
            onClick={async () => {
              await this.props.deletePriceAlert(
                this.props.deletePriceAlert(this.props.priceAlert.item.id)
              );
              await this.props.hideModal();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    );
  }
}

export default connect(null, { deletePriceAlert, hideModal })(DeletePriceAlertContent);
