import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { Icon } from "../icons/Icon";
import UploadListModal from "../lists/listOptions/UploadListModal";

interface Props {
  showModal: any;
}

class UploadListButton extends Component<Props, {}> {
  openUplodListModal = () => {
    const modalContent = () => <UploadListModal />;

    this.props.showModal({ component: modalContent });
  };

  render() {
    return (
      <button
        className="u-w-all u-flex u-align-center"
        onClick={this.openUplodListModal}
      >
        <Icon id="upload" size="s" />
        <div
          className="u-main-font--vsmall u-semi-bold u-color-charcoal u-ph--12 u-text-oneline"
          style={{ fontSize: "14px" }}
        >
          Upload list
        </div>
      </button>
    );
  }
}

export default connect(null, { showModal })(UploadListButton);
