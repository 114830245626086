import { Component } from "react";
import variables from "../../styles/utils/variables.scss";
import { Icon } from "../icons/Icon";

interface Props {}

interface State {}

class GrocerizeLoadingWheel extends Component<Props, State> {
  render() {
    const violet = variables.violetColor;
    return (
      <div className="u-br--small u-color-magenta u-flex u-justify-center u-align-center">
        <div className="o-loader"></div>
        <div
          style={{
            marginLeft: "-32px",
            marginTop: "6px",
          }}
        >
          <Icon id="shortfilledlogo" width="18px" height="28px" fill={violet} />
        </div>
      </div>
    );
  }
}

export default GrocerizeLoadingWheel;
