import { Component } from "react";
import { connect } from "react-redux";
import { hideModal, showModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { resetCss } from "../../utils/modal";
import GrocerizeLoadingWheel from "../grocerize/GrocerizeLoadingWheel";
import { Icon } from "../icons/Icon";

interface Props {
  showModal: any;
  hideModal: any;
}

class PriceUpdatePopup extends Component<Props> {
  closeModal = () => {
    resetCss();
    this.props.hideModal();
  };

  render() {
    const violet = variables.violetColor;
    return (
      <div className="u-br--small o-modal__container">
        <div
          className="o-modal--content-centered u-modal--content-bottom--mobile u-w-all--mobile u-relative"
          style={{
            width: window.innerWidth > 600 ? "500px" : "100%",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18 }}
            onClick={() => this.closeModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>
          <div
            style={{
              fontSize: "14px",
            }}
            className="u-main-font--small u-color-charcoal u-background--white u-br--small"
          >
            <div className="u-hide--desktop" style={{ paddingTop: 18 }}>
              <Icon id="grocerize" fill={violet} width={115} height={29} />
            </div>
            <div className="u-ph--48 u-text-center u-mauto u-pb--30--desktop">
              <div className="u-pt--48 u-pb--12 u-hide--mobile">
                <GrocerizeLoadingWheel />
              </div>

              <div className=" u-align-middle u-w-all u-text-center u-pb--48 u-pt--20">
                <div className="u-main-font--small u-bold u-pb--40--mobile">
                  Grocerize is still operational!
                </div>
                <div className="u-pt--24--desktop u-ph--8 u-pt--12--mobile">
                  Right now we are in the process of updating the overnight
                  specials. By 11am today these specials will be updated, so
                  jump back in then to plan, save and shop!
                </div>
              </div>
            </div>
            <button
              className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-br--bottom--small"
              onClick={() => this.props.hideModal()}
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  showModal,
  hideModal,
})(PriceUpdatePopup);
