// @ts-nocheck
/* eslint import/no-webpack-loader-syntax: off */
import * as React from "react";

import IconAdd from "-!svg-react-loader!./add.svg";
import IconTickAnimated from "-!svg-react-loader!./animatedTick.svg";
import IconCelebrate from "-!svg-react-loader!./celebrate.svg";
import IconChevronDown from "-!svg-react-loader!./chevron_down.svg";
import IconChevronLeft from "-!svg-react-loader!./chevron_left.svg";
import IconChevronRight from "-!svg-react-loader!./chevron_right.svg";
import IconChevronUpDown from "-!svg-react-loader!./chevron_up_down.svg";
import IconClear from "-!svg-react-loader!./clear.svg";
import IconCross from "-!svg-react-loader!./cross.svg";
import IconDollar from "-!svg-react-loader!./dollar.svg";
import IconFacebook from "-!svg-react-loader!./facebook.svg";
import IconFavorite from "-!svg-react-loader!./favorite.svg";
import IconGrid from "-!svg-react-loader!./grid.svg";
import IconGrocerizeBetaLogoWhite from "-!svg-react-loader!./grocerize_beta_logo_white.svg";
import IconGrocerizeG from "-!svg-react-loader!./grocerize_g.svg";
import IconGrocerizePremiumMagenta from "-!svg-react-loader!./grocerize_premium_magenta.svg";
import IconGrocerizePremiumWhite from "-!svg-react-loader!./grocerize_premium_white.svg";
import IconHeart from "-!svg-react-loader!./heart.svg";
import IconHouse from "-!svg-react-loader!./house.svg";
import IconInstagram from "-!svg-react-loader!./instagram.svg";
import IconLinkedin from "-!svg-react-loader!./linkedIn.svg";
import IconLongLogo from "-!svg-react-loader!./longLogo.svg";
import IconMagnify from "-!svg-react-loader!./magnify.svg";
import IconMail from "-!svg-react-loader!./mail.svg";
import IconMinify from "-!svg-react-loader!./minify.svg";
import IconMobileLogo from "-!svg-react-loader!./mobileLogo.svg";
import IconPlus from "-!svg-react-loader!./plus.svg";
import IconPremium from "-!svg-react-loader!./premium.svg";
import IconQuestionMark from "-!svg-react-loader!./question_mark.svg";
import IconQuestionMarkWhite from "-!svg-react-loader!./question_mark_white.svg";
import IconSearch from "-!svg-react-loader!./search.svg";
import IconShare from "-!svg-react-loader!./share.svg";
import IconShortFilledLogo from "-!svg-react-loader!./shortFilledLogo.svg";
import IconShortLogo from "-!svg-react-loader!./shortLogo.svg";
import IconSlideLeftArrow from "-!svg-react-loader!./slide_left_arrow.svg";
import IconSlideRightArrow from "-!svg-react-loader!./slide_right_arrow.svg";
import IconSliders from "-!svg-react-loader!./sliders.svg";
import IconStar from "-!svg-react-loader!./star.svg";
import IconSwap from "-!svg-react-loader!./swap.svg";
import IconThreeDots from "-!svg-react-loader!./threedots.svg";
import IconTick from "-!svg-react-loader!./tick.svg";
import IconTrolley from "-!svg-react-loader!./trolley.svg";
import IconUpload from "-!svg-react-loader!./upload.svg";
import IconWarning from "-!svg-react-loader!./warning.svg";
import IconYoutube from "-!svg-react-loader!./youtube.svg";

interface Props {
  id:
    | "add"
    | "celebrate"
    | "chevron_down"
    | "chevron_right"
    | "chevron_left"
    | "chevron_up_down"
    | "clear"
    | "cross"
    | "dollar"
    | "favourite"
    | "grocerize"
    | "grocerize_premium_white"
    | "grocerize_premium_magenta"
    | "heart"
    | "house"
    | "upload"
    | "facebook"
    | "linkedin"
    | "instagram"
    | "magnify"
    | "mail"
    | "minify"
    | "mobilelogo"
    | "longlogo"
    | "grocerize_beta_logo_white"
    | "plus"
    | "premium"
    | "search"
    | "share"
    | "shortlogo"
    | "shortfilledlogo"
    | "sliders"
    | "swap"
    | "star"
    | "tick"
    | "tickAnimated"
    | "threedots"
    | "trolley"
    | "warning"
    | "question_mark"
    | "question_mark_white"
    | "grid"
    | "slide_left_arrow"
    | "slide_right_arrow"
    | "youtube";
  size?: "xs" | "s" | "m" | "l" | "xl" | "xxl" | "xxxl";
  width?: any;
  height?: any;
  className?: any;
  fill?: string;
  stroke?: string;
}

class Icon extends React.Component<Props, {}> {
  sizes = {
    xs: "12px",
    s: "16px",
    m: "20px",
    l: "28px",
    xl: "40px",
    xxl: "64px",
    xxxl: "90px",
  };

  returnComponent = (id, w, h, className, fill, stroke) => {
    switch (id) {
      case "add":
        return (
          <IconAdd width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "celebrate":
        return (
          <IconCelebrate
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "chevron_down":
        return (
          <IconChevronDown
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "chevron_right":
        return (
          <IconChevronRight
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "chevron_left":
        return (
          <IconChevronLeft
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "chevron_up_down":
        return (
          <IconChevronUpDown
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "clear":
        return (
          <IconClear width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "dollar":
        return (
          <IconDollar width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "favorite":
        return (
          <IconFavorite width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "grocerize":
        return (
          <IconGrocerizeG
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "grocerize_premium_white":
        return (
          <IconGrocerizePremiumWhite
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "grocerize_premium_magenta":
        return (
          <IconGrocerizePremiumMagenta
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "heart":
        return (
          <IconHeart width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "house":
        return (
          <IconHouse width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "search":
        return (
          <IconSearch width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "sliders":
        return (
          <IconSliders width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "trolley":
        return (
          <IconTrolley width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "upload":
        return (
          <IconUpload width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "facebook":
        return (
          <IconFacebook width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "linkedin":
        return (
          <IconLinkedin width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "instagram":
        return (
          <IconInstagram
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "magnify":
        return (
          <IconMagnify width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "mail":
        return (
          <IconMail width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "minify":
        return (
          <IconMinify width={w} height={h} {...{ className, fill, stroke }} />
        );

      case "longlogo":
        return (
          <IconLongLogo width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "grocerize_beta_logo_white":
        return (
          <IconGrocerizeBetaLogoWhite
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "shortlogo":
        return (
          <IconShortLogo
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "shortfilledlogo":
        return (
          <IconShortFilledLogo
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "mobilelogo":
        return (
          <IconMobileLogo
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "plus":
        return (
          <IconPlus width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "premium":
        return (
          <IconPremium width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "swap":
        return (
          <IconSwap width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "star":
        return (
          <IconStar width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "share":
        return (
          <IconShare width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "tick":
        return (
          <IconTick width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "tickAnimated":
        return (
          <IconTickAnimated
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "cross":
        return (
          <IconCross width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "threedots":
        return (
          <IconThreeDots
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "warning":
        return (
          <IconWarning width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "question_mark":
        return (
          <IconQuestionMark
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "question_mark_white":
        return (
          <IconQuestionMarkWhite
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "grid":
        return (
          <IconGrid width={w} height={h} {...{ className, fill, stroke }} />
        );
      case "slide_left_arrow":
        return (
          <IconSlideLeftArrow
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "slide_right_arrow":
        return (
          <IconSlideRightArrow
            width={w}
            height={h}
            {...{ className, fill, stroke }}
          />
        );
      case "youtube":
        return (
          <IconYoutube width={w} height={h} {...{ className, fill, stroke }} />
        );
      default:
        return null;
    }
  };

  render = () => {
    const {
      id,
      size,
      width,
      height,
      className = "",
      fill = "currentColor",
      stroke,
    } = this.props;

    let w = this.sizes.m;
    let h = this.sizes.m;

    if (size) {
      w = h = this.sizes[size] ? this.sizes[size] : this.sizes.m;
    }

    if (width) {
      w = h = width;
    }

    if (height) {
      h = height;
    }

    if (!id) return null;

    return this.returnComponent(id, w, h, className, fill, stroke);
  };
}

export { Icon };
