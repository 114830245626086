import React, { Component } from "react";
import { routes } from "../utils/routes";
import PageTemplate from "./PageTemplate";

interface Props {}

interface State {}

class PrivacyPolicy extends Component<Props, State> {
  // Convert word file with https://wordtohtml.net/
  // note it does not format perfectly - you will need to check the lists start numbers, add the links
  render() {
    return (
      <div>
        <PageTemplate
          sidebarType="options"
          hideItemSearching={true}
          headerType="magenta"
        >
          <div className="u-wrap u-m--12 o-terms-of-use">
            <h1>Grocerize&rsquo;s Privacy Policy</h1>
            <h3>
              Grocerize Pty Ltd ACN 645 750 018 (Grocerize) respects your
              privacy and is committed to protecting your personal information
              as demonstrated in our Privacy Policy which tells you how we
              handle your personal information in accordance with
              the&nbsp;Privacy Act 1988&nbsp;(Cth) and the Australian Privacy
              Principles.
            </h3>
            <p>This Privacy Policy tells you how we:&nbsp;</p>
            <ul>
              <li>
                <p>collect and manage your personal information;</p>
              </li>
              <li>
                <p>use and disclosure your personal information;</p>
              </li>
              <li>
                <p>store and keep secure your personal information;&nbsp;</p>
              </li>
              <li>
                <p>use cookies and web-tracking tools;</p>
              </li>
              <li>
                <p>are transparent about our privacy practices;</p>
              </li>
              <li>
                <p>
                  can communicate with you about your personal information; and
                </p>
              </li>
              <li>
                <p>how you can delete personal information.&nbsp; </p>
              </li>
            </ul>
            <p>
              We will update our Privacy Policy if the way we handle your
              personal information changes or as required by law.&nbsp;
            </p>
            <ol>
              <li>How we collect and manage your personal information</li>
            </ol>
            <h3>1.1 Consent&nbsp;</h3>
            <p>
              By electing to use our services, you consent to us using your
              personal information in a manner consistent with this Privacy
              Policy, including monitoring your use of our website, mobile
              applications and direct marketing communications.&nbsp;
            </p>

            <h3>1.2 Types of information collected&nbsp;</h3>

            <p>
              We only collect information to the extent that this is reasonably
              necessary in connection with our service offerings and may
              include:&nbsp;
            </p>
            <ul>
              <li>
                <p>
                  Personal details&nbsp;including your name and postcode;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Contact details&nbsp;including your phone number and email
                  address;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Usage details&nbsp;such as information about how you use our
                  services, your preferences, browser type, operating system,
                  Internet Protocol (IP) address (a number that is automatically
                  assigned to your computer when you use the Internet, which may
                  vary from session to session), domain name, and/or a date/time
                  stamp for your visit;
                </p>
              </li>
              <li>
                <p>
                  Social media details&nbsp;including any information you allow
                  a social media site to share with us;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Loyalty program and membership&nbsp;details&nbsp;relating to
                  our services;
                </p>
              </li>
              <li>
                <p>
                  Feedback&nbsp;such as your comments, enquiries, complaints and
                  other feedback you provide.
                </p>
              </li>
            </ul>
            <p>
              If you decide not to provide us with certain personal information,
              we may not be able to be able to fully investigate your comments,
              enquiries or complaints or provide our services to you.&nbsp;
            </p>

            <h3>1.3 How we collect personal information&nbsp;</h3>

            <p>
              Our preference is to collect personal information directly from
              you unless it is unreasonable or impracticable for us to do
              so.&nbsp;
            </p>
            <p>
              Information will generally be collected in the course of our
              dealings with you, including:&nbsp;
            </p>
            <ul>
              <li>
                <p>
                  directly from you, such as when you apply for or use our
                  services, contact us with a comment, enquiry or complaint or
                  access our website, mobile applications or social media
                  platforms;&nbsp;
                </p>
              </li>
              <li>
                <p>our related businesses and partners;</p>
              </li>
              <li>
                <p>
                  our third party service providers, including IT, market
                  research and where competitions and promotions are run on our
                  behalf; and
                </p>
              </li>
              <li>
                <p>information that is publicly available.&nbsp;</p>
              </li>
            </ul>
            <p>
              You may also provide information to us about other individuals,
              such as where you nominate someone as an authorised representative
              on your account. In this case, you agree to inform those
              individuals that you are providing their personal information to
              us and about our Privacy Policy. In addition, you warrant that you
              have obtained the irrevocable consent to provide the personal
              information from those individuals whose personal information may
              be collected by us.&nbsp;
            </p>
            <p>
              We may combine and compare personal information about you with
              other information collected from or held by others. We do this to
              better understand your interests and preferences which helps us
              enhance your experience with us.&nbsp;
            </p>

            <h3>1.4 Why we collect personal information</h3>

            <p>
              We collect personal information for a variety of reasons,
              including:&nbsp;
            </p>
            <ul>
              <li>
                <p>to provide our services to you;&nbsp;</p>
              </li>
              <li>
                <p>
                  to communicate with you about our services and promotions and
                  to provide you marketing material we think may interest you;
                </p>
              </li>
              <li>
                <p>to verify your identity;</p>
              </li>
              <li>
                <p>
                  to better understand your preferences and interests to enable
                  us to provide you with a personalised experience and enhance
                  our service offerings;
                </p>
              </li>
              <li>
                <p>
                  to respond to your comments, enquiries and complaints which
                  also help us continuously improve our services;&nbsp;
                </p>
              </li>
              <li>
                <p>to operate, maintain and improve our network;&nbsp;</p>
              </li>
              <li>
                <p>
                  to conduct market research, improve our services and develop
                  new services;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  where we are legally authorised or required to collect the
                  information.&nbsp;
                </p>
              </li>
            </ul>
            <ol start={2}>
              <li>
                <p>How we use and disclose your personal information</p>
              </li>
            </ol>
            <p>
              We will not use or disclose personal information for the purposes
              other than those connected with the primary purpose of collection,
              or a reasonably related secondary purpose which we believe you
              should reasonably expect.&nbsp;
            </p>
            <p>
              Secondary purposes might include disclosure to maintenance
              personnel or other third party service providers who may be unable
              to avoid accessing personal information in the course of providing
              technical or other support services to us.&nbsp;
            </p>
            <p>
              We may also share your personal information with third parties
              including:
            </p>
            <ul>
              <li>
                <p>
                  Advertisers:&nbsp;we may, from time to time, engage marketing
                  strategies, including targeted online advertising and online
                  behavioural marketing and may disclose some of your personal
                  information to third parties, including advertisers and
                  partners. You agree that we may provide any of the data we
                  have collected from you in non-personally identifiable form to
                  those third parties . For example, we might use the fact that
                  you are located in Sydney to show you ads or offers for
                  relevant Sydney businesses, but we will not tell such
                  businesses who you are. If you engage with an advertisement,
                  the advertiser may collect personal information from
                  you.&nbsp;
                </p>
              </li>
              <li>
                <p>
                  Related Businesses:&nbsp;businesses or third-party websites we
                  are affiliated with may, from time to time, sell or provide
                  products or services to you through or in connection with our
                  services (either individually or jointly with us). We will
                  share your personal information with that affiliated business
                  only to the extent that it is related to such product or
                  service. We have no control over the privacy policies and
                  practices of third-party websites or businesses so if you
                  choose to interact with an affiliated website or business, you
                  should review all policies of that business or website.
                </p>
              </li>
              <li>
                <p>
                  Our Partners:&nbsp;we engage others to perform tasks on our
                  behalf and need to share your personal information with them
                  to provide products and services to you. Unless we tell you
                  otherwise, our partners do not have any right to use the
                  personal information we share with them beyond what is
                  necessary to assist us.
                </p>
              </li>
              <li>
                <p>
                  Reports/Analytics:&nbsp;We store data that is used in the form
                  of aggregated and generic statistics or reports in order to
                  better understand user profiles for the improvement of the
                  products offered by us.
                </p>
              </li>
            </ul>

            <h3>2.1 Direct marketing&nbsp;</h3>

            <p>
              We will not use or disclose personal information for the purposes
              of direct marketing to you unless:&nbsp;
            </p>
            <ul>
              <li>
                <p>
                  you have consented to receive direct marketing materials;
                  or&nbsp;
                </p>
              </li>
              <li>
                <p>
                  you would reasonably expect us to use your personal details
                  for this purpose; or&nbsp;
                </p>
              </li>
              <li>
                <p>
                  we believe you may be interested in the material but it is
                  impractical for us to obtain your consent.&nbsp;
                </p>
              </li>
            </ul>
            <p>
              We may contact you by post, email, telephone, our websites and
              mobile applications, and through social media. In every instance,
              we will ensure that our direct marketing material incorporates an
              option for you to elect to receive no further marketing
              communications.&nbsp;
            </p>
            <p>
              Even if you have requested not to receive further direct marketing
              communications, we may nevertheless continue to provide you with
              information about changes to our terms and conditions for the
              supply of goods or services, questionnaires and other factual
              information. This form of communication is not regarded as
              &ldquo;direct marketing&rdquo; under the Privacy Act.&nbsp;
            </p>

            <h3>2.2 Manage your preferences and unsubscribing</h3>

            <p>
              You can contact us at any time of you no longer wish to receive
              marketing materials from us, our related businesses or partners,
              or to update your preferences.&nbsp;
            </p>
            <p>
              If you receive marketing correspondence by email or telephone from
              us, you can opt-out from any or all of our marketing by clicking
              &ldquo;My Account&rdquo; then &ldquo;Preferences&rdquo; function
              in your account settings.&nbsp;
            </p>
            <p>
              You can also update your preferences and subscriptions by visiting
              our website and you can update your notification settings in our
              mobile applications.&nbsp;
            </p>
            <ol start={3}>
              <li>
                <p>How we store and secure your personal information&nbsp;</p>
              </li>
            </ol>
            <p>
              We mostly hold personal information electronically in our IT
              systems and databases which may utilise cloud technology in
              connection with the storage of personal information.&nbsp;
            </p>
            <p>
              We use third party service providers to store some or all personal
              information. It is not practicable for us to specify in advance
              which country will have jurisdiction for any off-shore activities.
              All of our service providers, however, are required to comply with
              the Privacy Act in relation to the transfer or storage of personal
              information.&nbsp;
            </p>

            <h3>3.1 Data security&nbsp;</h3>

            <p>
              We have risk management systems in place to protect the personal
              information which we hold and limit misuse or loss and
              unauthorised access, modification or disclosure. This includes
              firewalls and encryption of customer data during storage and
              transmission. We will destroy or de-identify personal information
              once we no longer require it for our business purposes.&nbsp;
            </p>
            <p>
              When using our website, mobile applications and services you
              should be aware that no data transmission over the internet can be
              guaranteed as totally secure. Although we have risk management
              systems in place and strive to protect such information, we do not
              guarantee your personal information is secure and you provide your
              personal information at your own risk.
            </p>
            <p>
              Please contact us immediately if you become aware of a breach of
              security.&nbsp;
            </p>
            <p>
              To help us keep your personal information safe, please do not
              share your login details, preferences, unique reference details or
              any other information with any other person.
            </p>
            <p>
              Your personal information may be stored, processed or provided to
              third party suppliers which may be located outside Australia
              (including the United States) where the data protection laws may
              differ. We will take reasonable steps to ensure your personal
              information is treated securely and in accordance with this
              Privacy Policy and the laws of Australia.&nbsp;
            </p>
            <h3>3.2 Data retention</h3>

            <p>
              We will retain your personal information for as long as reasonably
              necessary to fulfil any valid or lawful purpose including:
            </p>
            <ul>
              <li>
                <p>providing services to you;</p>
              </li>
              <li>
                <p>
                  investigating and defending any actual or potential claim or
                  complaint;
                </p>
              </li>
              <li>
                <p>
                  exercising our rights and obligations under contract or law;
                  or
                </p>
              </li>
              <li>
                <p>as otherwise permitted by law.</p>
              </li>
            </ul>
            <p>
              When it is no longer reasonably necessary to keep your personal
              information, we will destroy your personal information. However,
              we may de-identify data which we may retain for statistical
              purposes.
            </p>
            <ol start={4}>
              <li>
                <p>How we use cookies and data-tracking tools</p>
              </li>
            </ol>
            <p>
              We use cookies and web-tracking/analytic tools to collect and
              analyse information about how you interact with our websites and
              mobile applications. Our cookies collect IP or MAC addresses, the
              city associated with the IP or MAC address, the date and time of
              your visit to our website, pages viewed, the website you viewed
              before our website and the type of browser used.
            </p>

            <h3>4.1 Why we use cookies and similar technologies</h3>

            <p>
              Cookies and similar technologies allow our websites and mobile
              applications to interact more efficiently with the device used and
              to help us improve the content and functionality of our websites
              and mobile applications. They also generate detailed statistics
              about traffic to our websites, how individuals interact with
              advertisements on our (and third party) websites, browsing and
              other activity, and can measure and record conversions and sales.
            </p>
            <p>
              We use cookies, web tracking tools and similar technologies to
              help us present targeted and personalised advertising and other
              content about us on our (and third party) websites and mobile
              applications. This may also involve us working with third party
              service providers, advertisers and digital platforms that also use
              cookies and similar technologies to deliver this content to
              individuals. &nbsp;
            </p>
            <ol start={5}>
              <li>
                <p>How we are transparent about our practices&nbsp;</p>
              </li>
            </ol>
            <p>
              We may update our Privacy Policy from time to time. By continuing
              to access and use our websites and mobile applications, or
              otherwise continue to deal with us, you accept our Privacy Policy
              as it applies from time to time.&nbsp;
            </p>
            <p>
              Any changes to our Privacy Policy will be published on our
              website. You may obtain a copy of our current policy from
              our&nbsp;
              <a
                className="u-underline"
                href={process.env.REACT_APP_FRONTEND_URL + routes.privacyPolicy}
              >
                website
              </a>
              &nbsp;or by contacting us on the details below. It is your
              responsibility to check the website from time to time in order to
              determine whether there have been any changes.&nbsp;
            </p>
            <ol start={6}>
              <li>
                <p>
                  How we can communicate with you about your personal
                  information&nbsp;
                </p>
              </li>
            </ol>
            <p>
              We will take reasonable steps to ensure that the personal
              information which we collect remains accurate and complete.&nbsp;
            </p>
            <p>
              You have a right to request access to or correct your personal
              information held by us and you may request further information
              about our personal information handling practices.&nbsp;
            </p>
            <p>
              We will provide you with access to your personal information held
              by us unless we are permitted under the Privacy Act or law to
              refuse to provide you with such access. If this is the case we
              will let you know the reasons in writing.&nbsp;
            </p>
            <p>Please contact us using the details below if you:&nbsp;</p>
            <ul>
              <li>
                <p>
                  wish to have access to the personal information we hold about
                  you;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  consider that the personal information we hold about you is
                  not accurate, complete, irrelevant, out of date or misleading;
                  or&nbsp;
                </p>
              </li>
              <li>
                <p>
                  require further information on our personal information
                  handling practice.&nbsp;
                </p>
              </li>
            </ul>
            <p>You can contact us about your personal information by:</p>
            <div className="u-pl--24 o-terms-of-use">
              <h3>Writing to us at:</h3>

              <p>Privacy Officer</p>
              <p>Grocerize Pty Ltd&nbsp;</p>
              <p>Suite 401, Level 1</p>
              <p>45 Watt Street</p>
              <p>Newcastle NSW 2300</p>
              <p>Australia</p>
              <br />
              <br />
              <h3>Emailing us at</h3>
              <p>hello@grocerize.com.au</p>
            </div>
            <p></p>
            <p>
              We will need to verify your identity before we can process your
              request.&nbsp;
            </p>
            <p>
              There is no charge for requesting access to your personal
              information but we may require you to meet our reasonable costs in
              actually providing you with access.&nbsp;
            </p>
            <p>
              We will take reasonable steps, consistent with our obligations
              under the Privacy Act, to correct that information if you so
              request. We will respond to all requests for access and/or
              correction within a reasonable time of you making a request (a
              more complex request will usually take us 30 days) and will give
              you access in the manner you request unless it is unreasonable or
              impracticable for us to do so.&nbsp;
            </p>
            <h3>6.2 Complaints&nbsp;</h3>

            <p>
              If you have a complaint about the way in which we have handled any
              privacy issue, including your request for access or correction of
              your personal information, you should contact us using the above
              details.&nbsp;
            </p>
            <p>
              If you remain dissatisfied with the way in which we have handled a
              privacy issue, we suggest you approach an independent advisor or
              contact the Office of the Australian Information Commissioner for
              guidance on alternative courses of action which may be
              available.&nbsp;
            </p>
            <ol start={7}>
              <li>
                <p>How you can delete your personal information&nbsp;</p>
              </li>
            </ol>
            <p>
              If you wish to delete your personal information this can be done
              by clicking on “My details” and&nbsp;
              <a
                className="u-underline"
                href={process.env.REACT_APP_FRONTEND_URL + routes.myDetails}
              >
                “Delete your account”
              </a>
              &nbsp;.We will respond to all requests for deletion of personal
              information within a reasonable time of you making a request (a
              deletion request may take us [60] days). We may keep your personal
              information even after you request deletion:
            </p>
            <ul>
              <li>
                <p>
                  to comply with the Privacy Act, law, a legal request, claim,
                  proceedings or obligation, or a government agency request or
                  investigation;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  an investigation of possible violations of our Privacy Policy
                  and terms and conditions of business;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  for safety, security and integrity purposes and to prevent
                  harm&nbsp;
                </p>
              </li>
              <li>
                <p>in accordance with this Privacy Policy; or&nbsp;</p>
              </li>
              <li>
                <p>to protect us and our rights.</p>
              </li>
            </ul>

            <h3 style={{ fontSize: "1.6rem" }}>Terminology</h3>
            <p>In this policy we use the expressions:</p>
            <p>
              &ldquo;<strong>personal information</strong>&rdquo; to refer to
              information or an opinion about an individual whose identity is
              apparent, or can reasonably be ascertained, from the information
              or opinion. It also means information protected under the Privacy
              Act that relates to the affairs or personal particulars of a
              person;&nbsp;
            </p>
            <p>
              &ldquo;<strong>Privacy Policy</strong>&rdquo; which refers to
              the&nbsp;Privacy Act 1988&nbsp;(Cth);
            </p>
            <p>
              &ldquo;<strong>we</strong>&rdquo;, &ldquo;<strong>us</strong>
              &rdquo; and &ldquo;<strong>our</strong>&rdquo; as a reference to
              Grocerize Pty Ltd ACN 645 750 018 and other related
              subsidiaries;&nbsp;
            </p>
            <p>
              &ldquo;<strong>you</strong>&rdquo; and &ldquo;
              <strong>your</strong>&rdquo; to refer to each and every individual
              whose personal information we may handle from time to time.&nbsp;
            </p>
            <p>
              Any reference to us assuming an obligation under the Privacy Act,
              Australian Privacy Principles or other privacy legislation can be
              interpreted as a reference to us also procuring our service
              providers to undertake a reciprocal obligation to the extent
              relevant.&nbsp;
            </p>
            <br />
          </div>
        </PageTemplate>
      </div>
    );
  }
}

export default PrivacyPolicy;
