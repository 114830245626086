import { COLES, WOOLWORTHS } from "../../redux/enums";
import { findByVendor } from "../../utils/itemPricingFunctions";

interface CliqueProperties {
  item?: CliqueItem;
  cart?: CliqueCart;
}

export interface CliqueItem {
  id: string;
  name: string;
  qty?: number;
  savings: number;
  total: number;
  coles: {
    value?: number;
  };
  woolworths: {
    value?: number;
  };
  preference: "coles" | "woolworths";
}

export interface CliqueCart {
  type: "coles" | "woolworths" | "share";
}

interface CliqueUserState {
  id: number;
  email?: String;
  prefereredStore: "Coles" | "Woolworths" | "No preference";
  source?: String;
  postcode?: String | null;
}

export const updateContextPage = async (properties: CliqueProperties) => {
  window.clique.contextUpdate({
    name: "experience",
    properties: properties,
  });
};

// this will be automatically called by clique
export const trackPage = async (name: string, category: string) => {
  window.clique.contextUpdate({
    name: "page",
    properties: {
      name,
      category,
    },
  });

  window.clique.sendPage();
};

export const sendEngagement = async (
  name?: string,
  properties?: CliqueProperties
) => {
  // send engagement event to the DW
  window.clique.sendEngagement({
    name: name ?? "create-shopping-list",
    ts: new Date().getTime(),
    properties,
  });
};

export const sendIdentity = async (properties: CliqueUserState) => {
  window.clique.sendIdentify({
    id: properties.id,
    properties: {
      source: properties?.source,
      email: properties?.email,
      postcode: properties?.postcode,
      preferredStore: properties?.prefereredStore,
    },
  });
};

export const trackAuthentication = async (
  user: CliqueUserState,
  type: "login" | "signup"
) => {
  await sendEngagement(type);
};

export const trackCart = async (title: string, cart: BasketState) => {
  await updateCart(cart);
  await sendEngagement(title);
};

export const trackItemCart = async (title: string, item: ItemData) => {
  const formattedItem = await formatItem(item);

  await sendEngagement(title, {
    item: formattedItem,
  });
};

export const trackCheckout = async (
  title: string,
  cart: BasketState,
  type?: string
) => {
  await updateCheckout(cart, type);
  await sendEngagement(title);
};

export const formatItem = async (item: ItemData): Promise<CliqueItem> => {
  const coles = findByVendor(item.item_pricing, COLES);
  const woolworths = findByVendor(item.item_pricing, WOOLWORTHS);

  const lowestVendor =
    (coles?.price || 0) < (woolworths?.price || 0) ? coles : woolworths;

  const highestVendor =
    (coles?.price || 0) > (woolworths?.price || 0) ? coles : woolworths;

  const total = (lowestVendor?.price || 0) * (item?.quantity || 0);

  let savings =
    (highestVendor?.price || 0) * (item?.quantity || 0) -
    (lowestVendor?.price || 0) * (item?.quantity || 0);

  // no savings for positive value
  // Negative values are savings $ over the other store
  if (savings > 0) {
    savings = 0;
  }

  return {
    id: item.id.toString(),
    name: item.name,
    qty: item.quantity,
    total: total,
    coles: {
      value: coles?.price,
    },
    woolworths: {
      value: woolworths?.price,
    },
    preference: lowestVendor?.vendor_id === COLES ? "coles" : "woolworths",
    savings: savings,
  };
};

export const updateCart = async (basket: BasketState) => {
  //@ts-ignore
  window.clique.contextUpdate({
    name: "experience",
    properties: {
      context: {
        ecommerce: {
          cart: {
            items: basket.basketItems,
            total: basket.basketItems.reduce(
              (accumulator, current) =>
                accumulator +
                (typeof current?.quantity === "undefined"
                  ? 0
                  : current.quantity),
              0
            ),
          },
          savings: basket.basketMetaData?.totalSavingsUsingGrocerize,
          totalColes: basket.basketMetaData?.colesOnlyTotal,
          totalWoolworths: basket.basketMetaData?.woolworthsOnlyTotal,
        },
      },
    },
  });
};

export const updateCheckout = async (basket: BasketState, type?: string) => {
  for (const item of basket.basketItems) {
    const formattedItem = await formatItem(item);

    const { id, name, coles, woolworths, preference, qty } = formattedItem;

    window.clique.ecommerceCartItemAdd({
      id,
      name,
      coles,
      woolworths,
      preference,
      qty,
    });
  }

  window.clique.sendEngagement({
    name: "checkout",
    properties: {
      cart: {
        type,
      },
    },
  });
};
