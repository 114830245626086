import { Component } from "react";
import { COLES, WOOLWORTHS } from "../../../../redux/enums";
import { findByVendor } from "../../../../utils/itemPricingFunctions";
import ItemPricingDisplay from "./ItemPricingDisplay";

interface Props {
  itemData: ItemData;
  itemCloseMatch?: ItemData;
  toggleCloseMatch?: any;
}

class ItemPricing extends Component<Props, {}> {
  render() {
    let closeMatchColesPricing: ItemPricings = {
      vendor_id: COLES,
      vendor_product_code: "",
      available: false,
      price: 0,
      volume: "?",
      special: false,
    };

    let closeMatchWoolsPricing: ItemPricings = {
      vendor_id: WOOLWORTHS,
      vendor_product_code: "",
      available: false,
      price: 0,
      volume: "?",
      special: false,
    };

    const colesPricing = findByVendor(this.props.itemData.item_pricing, COLES);
    const woolsPricing = findByVendor(
      this.props.itemData.item_pricing,
      WOOLWORTHS
    );

    if (this.props.itemCloseMatch) {
      const coles = findByVendor(this.props.itemCloseMatch.item_pricing, COLES);
      const wools = findByVendor(
        this.props.itemCloseMatch.item_pricing,
        WOOLWORTHS
      );

      if (coles) {
        closeMatchColesPricing = coles;
      }

      if (wools) {
        closeMatchWoolsPricing = wools;
      }
    }

    if (colesPricing && woolsPricing) {
      return (
        <>
          {this.props.itemCloseMatch ? (
            <ItemPricingDisplay
              id={this.props.itemCloseMatch.id}
              item_pricing={closeMatchColesPricing}
              key={this.props.itemCloseMatch.id}
              isCloseMatch={true}
              onClick={this.props.toggleCloseMatch}
              isAvailableAtBoth={this.props.itemData.availableAtBoth}
            />
          ) : (
            <ItemPricingDisplay
              id={this.props.itemData.id}
              item_pricing={colesPricing}
            />
          )}

          <ItemPricingDisplay
            id={this.props.itemData.id}
            item_pricing={woolsPricing}
            key={this.props.itemData.id}
            isAvailableAtBoth={this.props.itemData.availableAtBoth}
          />
        </>
      );
    }
    // if only one is present render the other as NaN
    else if (!colesPricing && woolsPricing) {
      return (
        <>
          {this.props.itemCloseMatch ? (
            <ItemPricingDisplay
              id={this.props.itemCloseMatch.id}
              item_pricing={closeMatchColesPricing}
              key={1}
              isCloseMatch={true}
              onClick={this.props.toggleCloseMatch}
              isAvailableAtBoth={this.props.itemData.availableAtBoth}
            />
          ) : (
            <ItemPricingDisplay
              id={this.props.itemData.id}
              item_pricing={closeMatchColesPricing}
            />
          )}

          <ItemPricingDisplay
            id={this.props.itemData.id}
            item_pricing={woolsPricing}
            key={2}
            isAvailableAtBoth={this.props.itemData.availableAtBoth}
          />
        </>
      );
    } else if (colesPricing && !woolsPricing) {
      return (
        <>
          <ItemPricingDisplay
            id={this.props.itemData.id}
            item_pricing={colesPricing}
            key={1}
            isAvailableAtBoth={this.props.itemData.availableAtBoth}
          />

          {this.props.itemCloseMatch ? (
            <ItemPricingDisplay
              id={this.props.itemCloseMatch.id}
              item_pricing={closeMatchWoolsPricing}
              key={2}
              isCloseMatch={true}
              onClick={this.props.toggleCloseMatch}
              isAvailableAtBoth={this.props.itemData.availableAtBoth}
            />
          ) : (
            <ItemPricingDisplay
              id={this.props.itemData.id}
              item_pricing={closeMatchWoolsPricing}
            />
          )}
        </>
      );
    } else {
      // it should not be possible to have no pricing but render some whitespace just in case
      return <div className="" style={{ width: "70px", height: "21px" }}></div>;
    }
  }
}

export default ItemPricing;
