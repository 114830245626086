import React, { Component } from "react";

interface Props {
  type: "coles" | "wools";
  itemsCount: number;
  toggled: boolean;
  setVendor: any;
}

interface State {}

class ToggleBox extends Component<Props, State> {
  render() {
    return (
      <button
        onClick={() => this.props.setVendor(this.props.type)}
        className={
          "u-w-half u-w-175px u-p--2 u-br--small u-background--grey u-bold " +
          (!this.props.toggled
            ? ""
            : this.props.type === "coles"
            ? " u-border--coles--2"
            : " u-border--woolworths--2")
        }
      >
        <div
          className={
            "u-text-left u-br--small--inset u-background--grey  u-ph--20 u-pv--12 u-h-all u-w-all" +
            (this.props.type === "coles" ? "" : " u-pl--32")
          }
        >
          <div
            className={
              "u-bold u-main-font--vsmall  u-color-grey" +
              (!this.props.toggled
                ? ""
                : this.props.type === "coles"
                ? " u-color-coles"
                : " u-color-woolworths")
            }
            style={{ fontSize: "14px", lineHeight: "14px" }}
          >
            {this.props.type === "coles" ? "Coles" : "Woolworths"}
          </div>
          <div
            className={
              "u-semi-bold u-pt--8 u-bold " +
              (this.props.toggled ? " u-color-darkgrey" : "u-color-grey")
            }
            style={{ fontSize: "14px" }}
          >
            {this.props.itemsCount} items
          </div>
        </div>
      </button>
    );
  }
}

export default ToggleBox;
