import axios from "axios";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PageTemplate from "../../pages/PageTemplate";
import { routes } from "../../utils/routes";
import FAQAccordian from "../faqs/FAQAccordian";

interface Props {}

interface State {
  faqData: FAQ[];
}

interface FAQ {
  title: string;
  body: string;
}

class FAQsView extends Component<Props, State> {
  state: State = {
    faqData: [],
  };
  componentDidMount = async () => {
    const { data } = await axios.get(
      process.env.REACT_APP_BACKEND_URL + "/faqs"
    );

    this.setState({ faqData: data });
  };

  getWhiteSpaceHeight = () => {
    const searchNavHeight =
      document.getElementById("search-navbar")?.getBoundingClientRect()
        .bottom || 0;
    const faqsHeight =
      document.getElementById("faqs")?.getBoundingClientRect().height || 215;
    const footerHeight =
      document.getElementById("footer")?.getBoundingClientRect().height || 215;
    const whiteSpaceHeight =
      window.innerHeight - (searchNavHeight + faqsHeight + footerHeight);
    return whiteSpaceHeight + "px";
  };

  render() {
    const faqData = this.state.faqData;
    const whiteSpaceHeight = this.getWhiteSpaceHeight();

    return (
      <PageTemplate sidebarType="options" hideItemSearching={true}>
        <div
          id="faqs"
          className="u-background--white u-flex u-justify-center u-border--bottom--darkgrey u-h-200px--desktop u-h-150px--mobile"
        >
          <div className="u-pt--48 u-pb--48" style={{ width: "85%" }}>
            <div className="u-main-font--large u-bold">FAQs</div>
            <div
              className="u-main-font--small u-pt--12"
              style={{ fontSize: 14 }}
            >
              Can't find an answer?{" "}
              <Link to={routes.contact}>
                <span className="u-color-magenta">Message us</span>
              </Link>
            </div>
          </div>
        </div>

        <div className="u-background--white u-flex u-justify-center">
          <div style={{ width: "85%" }}>
            <div className="u-pt--42 u-pb--48">
              {faqData.length
                ? faqData.map((faq, i) => {
                    return (
                      <FAQAccordian key={i} title={faq.title} body={faq.body} />
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

export default FAQsView;
