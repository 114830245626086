import { Component, ReactNode } from "react";

interface Props {
  isCloseMatch?: boolean;

  multibuyQuantity: number;
  multibuyPrice: number;

  closeMatchTextColor: string;
  closeMatchBackground: string;
}
interface State {
  closeMatchStyle: string;
  multiBuyPromoStyle: string;
}
class MultiBuyPromo extends Component<Props, State> {
  state = {
    closeMatchStyle:
      this.props.closeMatchTextColor === "u-color-white"
        ? "u-color-closeMatch"
        : "u-color-white" + this.props.closeMatchBackground ===
          "u-background--white"
        ? "u-background--closeMatch"
        : "u-background--white",
    multiBuyPromoStyle: "u-background--yellow u-color-charcoal",
  };

  render(): ReactNode {
    const { multibuyPrice, multibuyQuantity, isCloseMatch } = this.props;
    const { multiBuyPromoStyle, closeMatchStyle } = this.state;

    return (
      <div className="u-w-all u-mt--8">
        <div
          className={`u-h-21px--desktop
            ${isCloseMatch ? closeMatchStyle : multiBuyPromoStyle}
          `}
        >
          <div className="u-h-all u-flex u-justify-center u-main-font--vsmall u-bold u-align-center">
            {`${multibuyQuantity} for $${multibuyPrice.toFixed(2)}`}
          </div>
        </div>
      </div>
    );
  }
}

export default MultiBuyPromo;
