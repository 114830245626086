import { Component } from "react";

import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modalActions";
import { Icon } from "../../icons/Icon";
import FiltersDropdownModal from "./FiltersDropdownModal";

interface Props {
  showModal: any;
}

class FiltersDropdown extends Component<Props, {}> {
  handleClickToExpand = () => {
    const button = document.getElementById("item-filter-dropdown");

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = () => (
        <div
          style={{
            marginTop: postionOfButton.bottom + "px",
            marginLeft: postionOfButton.left + "px",
            width: postionOfButton.right - postionOfButton.left + "px",
          }}
        >
          <FiltersDropdownModal />
        </div>
      );
      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    return (
      <button
        id="item-filter-dropdown"
        className="u-flex u-align-center u-justify-center u-h-all  u-background--grey u-w-all  u-ph--24 "
        style={{
          borderRadius: "2px",
        }}
        onClick={() => this.handleClickToExpand()}
      >
        <Icon id="sliders" size="s" />
        <div
          className="u-hide--tablet u-main-font--small u-color-charcoal u-text-oneline u-semi-bold u-ml--16"
          style={{ fontSize: "14px" }}
        >
          Add filters
        </div>
      </button>
    );
  }
}

export default connect(null, { showModal })(FiltersDropdown);
