import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modalActions";
import { prettifySortTypeIdToString } from "../../../redux/actions/priceAlertActions";
import { Icon } from "../../icons/Icon";
import ListsSortModal from "./ListsSortModal";

interface Props {
  lists: ListsState;
  showModal: any;
}

interface State {}

class ListsSortButton extends Component<Props, State> {
  showSortOptions = () => {
    const button = document.getElementById("list-sort-dropdown");

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = () => (
        <div
          style={{
            marginTop: postionOfButton.bottom + 20 + "px",
            marginLeft: postionOfButton.left - 5 + "px",
            width: postionOfButton.right - postionOfButton.left + "px",
          }}
        >
          <ListsSortModal />
        </div>
      );
      this.props.showModal({ component: modalContent });
    }
  };

  render() {
    return (
      <button
        id="list-sort-dropdown"
        className="u-w-30pct u-pv--12 u-flex u-align-center"
        onClick={this.showSortOptions}
      >
        <Icon id="chevron_up_down" size="s" />

        <div className="u-text-oneline u-ml--16" style={{ fontSize: "14px" }}>
          Sort by{" "}
          {window.innerWidth > 600 && (
            <span className="u-bold ">
              {prettifySortTypeIdToString(this.props.lists.currentSortType)}
            </span>
          )}
        </div>
      </button>
    );
  }
}

export default connect(null, { showModal })(ListsSortButton);
