import { Component } from "react";
import ItemCardMobileLoading from "./ItemCardLoadingMobile";

class ItemCard extends Component<{}, {}> {
  render() {
    if (window.innerWidth < 600) {
      return <ItemCardMobileLoading />;
    }
    return (
      <div
        className="u-w-250--desktop u-background--white u-br--small u-p--8 u-border--darkgrey"
        style={{ height: "464px" }}
      >
        <div className="u-m0auto u-w-all u-flex u-justify-center u-mt--24">
          <div
            className="u-glint--animation"
            style={{
              height: "140px",
              width: "140px",
            }}
          />
        </div>
        <div className="u-p--24">
          <div className="u-pv--8 ">
            <div
              className="u-glint--animation"
              style={{
                height: "18px",
                width: "100%",
                marginTop: "6px",
              }}
            ></div>
            <div
              className="u-glint--animation"
              style={{
                height: "18px",
                width: "100%",
                marginTop: "6px",
              }}
            ></div>
          </div>
          <div className="u-flex">
            <div className="u-w-all">
              <div
                className="u-glint--animation"
                style={{
                  height: "18px",
                  width: "100%",

                  marginTop: "6px",
                }}
              ></div>

              <div
                className="u-glint--animation"
                style={{
                  height: "22px",
                  width: "100%",
                  marginTop: "6px",
                }}
              ></div>

              <div
                className="u-glint--animation"
                style={{
                  height: "18px",
                  width: "100%",

                  marginTop: "6px",
                }}
              ></div>
            </div>
          </div>

          <div
            className="u-glint--animation"
            style={{
              height: "50px",
              width: "100%",
              marginTop: "24px",
            }}
          ></div>
        </div>
      </div>
    );
  }
}

export default ItemCard;
