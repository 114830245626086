import React, { Component } from "react";
import { connect } from "react-redux";
import { findListAndReplaceBasket } from "../../../redux/actions/basketActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  list: List;
  hideModal: any;
  findListAndReplaceBasket: any;
}

interface State {
  showAddListToCart: boolean;
}

class AddListToCartModalContentMobile extends Component<Props, State> {
  state = {
    showAddListToCart: false,
  };

  addListToCart = async (list: List) => {
    this.props.findListAndReplaceBasket(list.id);

    this.props.hideModal();
  };

  showAddListToCart = async () => {
    this.setState({ showAddListToCart: true });
  };

  render() {
    const list = this.props.list;

    return (
      <div className="u-border--bottom--darkgrey">
        {this.state.showAddListToCart ? (
          <div className="u-background--grey u-w-all u-p--24 u-main-font--small ">
            <div className="u-color-magenta u-bold">Add list to cart</div>
            <div className="u-pv--12">
              Add <span className="u-bold">"{list.title}"</span> to your current
              cart?
            </div>
            <button
              className="u-background--magenta u-color-white u-ph--16 u-pv--8 u-br--small"
              onClick={() => this.addListToCart(list)}
            >
              Add
            </button>
          </div>
        ) : (
          <button
            className="u-p--24  u-main-font--small u-semi-bold u-w-all u-text-left"
            onClick={() => this.showAddListToCart()}
          >
            Add list to cart
          </button>
        )}
      </div>
    );
  }
}

export default connect(null, { findListAndReplaceBasket, hideModal })(
  AddListToCartModalContentMobile
);
