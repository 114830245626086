import React, { Component } from "react";
import { connect } from "react-redux";
import { sortUserFavourite } from "../../../redux/actions/favouritesActions";
import { Icon } from "../../icons/Icon";

interface Props {
  sortUserFavourite: any;
}

interface State {
  isSortExpanded: boolean;
  selectedSort: number;
}

class FavouritesSortDropdown extends Component<Props, State> {
  state = {
    isSortExpanded: false,
    selectedSort: 0,
  };

  componentDidMount() {
    // monitor click if sort items is expanded to fade in the background
    this.fadeInBackground();
    window.addEventListener("click", this.fadeInBackground);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.fadeInBackground);
  }

  //Fade the background once sort items is expanded
  fadeInBackground = () => {
     const isSortExpanded = this.state.isSortExpanded;
     const favouritesElement = document.getElementById("favourites");

     if(isSortExpanded){
      favouritesElement!.className += " u-background--fadein u-z-index--0 u-opacity--point5";
     }else{
      favouritesElement!.className  = "u-h-all";
     }
  }

  handleExpandOfSorts = () => {
    this.setState({ isSortExpanded: !this.state.isSortExpanded });
  };
  updateSortSelection = (sort: SortOptions) => {
    sort.callback();

    this.setState({ isSortExpanded: !this.state.isSortExpanded });
  };

  render() {
    const sorts: SortOptions[] = [
      {
        sort_id: 1,
        name: "Name (A-Z)",
        callback: () => this.props.sortUserFavourite("A-Z"),
      },
      {
        sort_id: 2,
        name: "Name (Z-A)",
        callback: () => this.props.sortUserFavourite("Z-A"),
      },
      {
        sort_id: 3,
        name: "Date Created",
        callback: () => this.props.sortUserFavourite("Created"),
      },
    ];
    return (
      <div className="">
        <div className="u-w-all  u-flex u-align-center  ">
          <button
            className=" u-p--24 u-pl--32 u-w-all u-flex u-align-center"
            onClick={() => this.handleExpandOfSorts()}
          >
            <Icon id="chevron_up_down" size="s" />
          </button>
        </div>
        {this.state.isSortExpanded ? (
          <div
            className="u-background--white u-fixed u-w-all u-left--0"
            style={{ zIndex: 2 }}
          >
            {sorts.map((sort, index) => {
              return (
                <div className="u-flex u-align-center " key={index}>
                  <button
                    className="u-pr--12 u-pl--48 u-pv--12 u-w-all u-main-font--small u-text-left u-border--bottom--grey "
                    onClick={() => this.updateSortSelection(sort)}
                  >
                    <div
                      key={index}
                      id={"sort-" + sort.sort_id}
                      className={
                        "u-semi-bold" +
                        (this.state.selectedSort === sort.sort_id
                          ? " u-color-magenta"
                          : " u-color-charcoal")
                      }
                    >
                      {sort.name}
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default connect(null, { sortUserFavourite })(FavouritesSortDropdown);
