import { Component, Dispatch, ReactNode } from "react";
import { connect } from "react-redux";
import { closeModalDropdown } from "../../redux/actions/modalDropdownActions";
import { changeSort } from "../../redux/actions/basketActions";

interface Props {
  changeSort: (id: number) => void;
  closeModalDropdown: () => void;
  modalDropdown: ModalDropdownState;
}

interface State {
  selectedSort: number;
}

class GrocerizeSortItemsModalDropdown extends Component<Props, State> {
  state = {
    selectedSort: 1,
  };

  updateSelectedBasketFilter = (id: number) => {
    this.setState({ selectedSort: id });

    this.props.changeSort(id);

    this.props.closeModalDropdown();
  };

  render() {
    const basketSortTypes = [
      { id: 1, name: "Order added" },
      { id: 2, name: "Name (A-Z)" },
      { id: 3, name: "Name (Z-A)" },
    ];

    const isOpen = this.props.modalDropdown.isOpen;

    return (
      <div className={!isOpen ? "u-opacity--0 u-vis--hidden" : ""}>
        <div className="o-modal-dropdown__container">
          <div className="u-flex u-hide--mobile u-justify-center">
            <div className="o-caret-marker"></div>
          </div>
          <div className="u-background--white u-w-all u-br--vsmall">
            {basketSortTypes.map((sort, index) => {
              return (
                <div
                  className="u-flex u-align-center "
                  key={index}
                >
                  <button
                    className="u-pt--16 u-pr--16 u-pb--16 u-pl--60 u-w-all u-main-font--small"
                    onClick={() => this.updateSelectedBasketFilter(sort.id)}
                  >
                    <div
                      className={
                        "u-semi-bold u-text-left" +
                        (this.state.selectedSort === sort.id
                          ? " u-color-magenta"
                          : " u-color-charcoal")
                      }
                    >
                      {sort.name}
                    </div>
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className={`o-modal-dropdown__overlay }`}
          onClick={this.props.closeModalDropdown}
        />
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  modalDropdown: state.modalDropdown,
});

export default connect(mapStateToProps, { changeSort, closeModalDropdown })(
  GrocerizeSortItemsModalDropdown
);
