import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import DeleteFavouriteModalContentMobile from "./favouritesOptions/DeleteFavouriteModalContentMobile";

interface Props {
  itemData: ItemData;
  showModal: any;
  user?: UserState;
}

interface State {}

class FavouriteCardMobile extends Component<Props, State> {
  showDeleteFavourite = () => {
    const itemCard = document.getElementById(this.props.itemData.id.toString());
    if (itemCard) {
      const modalContent = () => (
        <div className="u-overflow-hidden">
          <DeleteFavouriteModalContentMobile itemData={this.props.itemData} />
        </div>
      );
      this.props.showModal({
        position: "bottom",
        component: modalContent,
      });
    }
  };

  render() {
    const itemData = this.props.itemData;

    return (
      <div className="u-background--white u-p--12 u-border--bottom--darkgrey">
        <div className="u-w-all u-flex u-align-center" style={{ fontSize: 14 }}>
          <div className="u-w-25pct u-pl--12--mobile">
            <img
              style={{ height: "80px" }}
              alt={itemData.name}
              src={itemData.image_url}
            />
          </div>
          <div className="u-pl--20 u-w-half">
            <div>{itemData.name}</div>
          </div>
          <div className="u-w-25pct u-text-center">
            <button
              className="u-color-magenta u-semi-bold"
              onClick={this.showDeleteFavourite}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { showModal })(FavouriteCardMobile);
