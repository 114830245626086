import React, { Component } from "react";
import { Icon } from "../../../icons/Icon";

interface Props {
  isSpecial: boolean;
}

class SpecialPriceIndicator extends Component<Props, {}> {
  render() {
    const { isSpecial } = this.props;
    return (
      <div
        className={isSpecial ? "u-background--yellow" : ""}
        style={{
          width: "40px",
          height: "40px",
          paddingLeft: "10px",
          paddingTop: "10px",
          borderRadius: "8px 0 80% 0",
        }}
      >
        {isSpecial ? <Icon id="dollar" size="s" fill="#484848" /> : null}
      </div>
    );
  }
}

export default SpecialPriceIndicator;
