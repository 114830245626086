import { Component } from "react";
import { connect } from "react-redux";
import {
  decreaseBasketItemQuantity,
  increaseBasketItemQuantity,
  removeFromBasket,
} from "../../redux/actions/basketActions";
import { saveToLocalStorage } from "../../utils/localStorageFunctions";
import { Icon } from "../icons/Icon";
import BasketVendorPricing from "./BasketVendorPricing";

interface Props {
  itemData: ItemData;
  increaseBasketItemQuantity?: any;
  decreaseBasketItemQuantity?: any;
  removeFromBasket?: any;
  basket?: BasketState;
}

interface State {
  quantityOfItemInBasket: number;
  itemInBasket?: ItemData | undefined;
}

class BasketItemCard extends Component<Props, State> {
  state = {
    quantityOfItemInBasket: 0,
    itemInBasket: undefined,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.basket?.basketItems !== this.props.basket?.basketItems) {
      // check if product is in the basket
      const itemInBasket = this.props.basket?.basketItems.find(
        (item) => item.id === this.props.itemData.id
      );

      if (itemInBasket?.quantity) {
        // the product has been added
        this.setState({
          itemInBasket,
          quantityOfItemInBasket: itemInBasket.quantity,
        });
      } else {
        // the product has been removed from basket
        this.setState({ itemInBasket: undefined, quantityOfItemInBasket: 0 });
      }
    }
  };

  removeFromBasket = async () => {
    await this.props.removeFromBasket(this.props.itemData);
    saveToLocalStorage({
      basketMetaData: this.props.basket?.basketMetaData,
      basketItems: this.props.basket?.basketItems,
    });
  };

  render() {
    const colessPricing = this.props.itemData.item_pricing.find(
      (item) => item.vendor_id === 1 && item.available
    );
    const woolworthsPricing = this.props.itemData.item_pricing.find(
      (item) => item.vendor_id === 2 && item.available
    );
    return (
      <div
        className="u-background--white u-w-all u-border--bottom--darkgrey u-relative"
        style={{ height: "150px" }}
      >
        <button
          className="u-w-all u-flex u-justify-flex-end u-absolute u-p--12"
          onClick={this.removeFromBasket}
        >
          <Icon id="clear" size="xs" stroke={"#d0d0d0"} />
        </button>
        <div className="u-flex u-pt--16 u-ml--48  u-mr--32 ">
          <img
            className="u-background--grey u-mr--32 u-object-fit--cover"
            style={{ width: "60px", height: "60px" }}
            src={this.props.itemData.image_url}
            alt={this.props.itemData.name}
          />
          <div
            className="u-color-charcoal u-main-font--small u-semi-bold u-text-unselectable  u-pt--8  u-flex u-align-center"
            style={{
              height: "64px",
              lineHeight: "18px",
              fontSize: "14px",
            }} // this is to design
          >
            <div
              className=" u-pl--16 u-truncate--3l u-h-all u-overflow-hidden "
              style={{
                whiteSpace: "break-spaces",
              }}
            >
              {this.props.itemData.name}
            </div>
          </div>
        </div>
        <div className="u-mt--12 u-ph--16 u-flex u-align-center">
          <div className="u-relative">
            <div
              className="u-flex u-justify-between u-br--vsmall u-border--darkgrey u-main-font--large "
              style={{
                fontSize: "23px",
                width: "138px",
                height: "40px",
              }}
            >
              <button
                className="u-h-all u-w-all u-flex u-justify-center u-align-center u-color-charcoal"
                onClick={() =>
                  this.props.decreaseBasketItemQuantity(this.props.itemData)
                }
              >
                -
              </button>
              <div
                className="u-h-all u-w-all u-flex u-justify-center u-align-center u-color-charcoal u-bold"
                style={{
                  fontSize: "18px",
                }}
              >
                {this.props.itemData.quantity || 0}
              </div>
              <button
                className="u-h-all u-w-all u-flex u-justify-center u-align-center u-color-charcoal"
                onClick={() =>
                  this.props.increaseBasketItemQuantity(this.props.itemData)
                }
              >
                +
              </button>
            </div>
          </div>
          <div
            className="u-flex u-justify-between"
            style={{ height: "40px", width: "252px" }}
          >
            <BasketVendorPricing
              pricing={colessPricing}
              missingVendorId={!colessPricing ? 1 : undefined}
            />
            <BasketVendorPricing
              pricing={woolworthsPricing}
              missingVendorId={!woolworthsPricing ? 2 : undefined}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, {
  increaseBasketItemQuantity,
  decreaseBasketItemQuantity,
  removeFromBasket,
})(BasketItemCard);
