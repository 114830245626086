import { httpClient } from "../clients/auth/addTokenToHTTPRequests";

export async function getSharableURL() {
  return await httpClient.instance
    .patch("user/lists/current/publicUrl")
    .then((res) => {
      return res.data.public_url;
    })
    .catch((err) => {
      console.log(err);
      return "/";
    });
}

export async function shareURL(url: string, text: string = "") {
  if (navigator.share) {
    await navigator
      .share({
        title: "Grocerize",
        text,
        url,
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

export function totalQuantities(
  colesCategories: PublicListStoreSortCategory[],
  woolworthsCategories: PublicListStoreSortCategory[]
) {
  let totalColesItems = 0;
  let totalWoolworthsItems = 0;
  if (colesCategories) {
    colesCategories.forEach((category) => {
      category.items.forEach((item) => {
        totalColesItems += item.quantity;
      });
    });
  }

  if (woolworthsCategories) {
    woolworthsCategories.forEach((category) => {
      category.items.forEach((item) => {
        totalWoolworthsItems += item.quantity;
      });
    });
  }
  return {
    totalColesItems,
    totalWoolworthsItems,
  };
}
