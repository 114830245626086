import moment from "moment";
import { Component } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../utils/routes";
import { Icon } from "../icons/Icon";
import Logo from "./Logo";

class Footer extends Component<{}, {}> {
  render() {
    const currentYear = moment().local().year();

    const links = [
      // { key: 2, href: "/", text: "About" },
      // { key: 1, href: "/", text: "Features" },
      // { key: 3, href: "/", text: "Latest News" },
      { key: 4, href: routes.faqs, text: "FAQs" },
      { key: 5, href: routes.contact, text: "Contact us" },
      { key: 6, href: routes.termsOfUse, text: "Terms of Use" },
      { key: 6, href: routes.privacyPolicy, text: "Privacy Policy" },
    ];

    return (
      <div
        id="footer"
        className="u-w-all u-flex u-justify-center u-background--secondary u-hide--mobile"
        style={{ height: "158px", marginTop: "auto" }}
      >
        <div className="" style={{ width: "1080px" }}>
          <div className="u-border--bottom--grey u-flex u-pt--42 u-pb--12 u-justify-between">
            <Logo />
            <div>
              <Link
                to={{ pathname: "https://www.facebook.com/Grocerize/" }}
                target="_blank"
                className="u-ph--32"
              >
                <Icon id="facebook" fill="#ffffff" />
              </Link>
              <Link
                to={{
                  pathname: "https://www.instagram.com/grocerize/?hl=en",
                }}
                target="_blank"
              >
                <Icon id="instagram" fill="#ffffff" />
              </Link>
            </div>
          </div>
          <div className="u-flex u-align-cetner u-justify-between u-pt--12 u-main-font--vsmall u-color-white">
            <div
              className="u-flex u-align-center"
              style={{ marginLeft: "-8px" }} // hack to align without changing the map
            >
              {links.map((link, index) => {
                return (
                  <Link to={link.href} key={index}>
                    <div
                      className="u-ph--8"
                      key={link.key}
                      style={{ fontSize: 14 }}
                    >
                      {link.text}
                    </div>
                  </Link>
                );
              })}
            </div>
            <div style={{ fontSize: 14 }}>
              Copyright © {currentYear} Grocerize
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
