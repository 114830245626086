import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../redux/actions/modalActions";
import { updateUserShoppingPreference } from "../../redux/actions/userActions";

interface Props {
  updateUserShoppingPreference: any;
  user: UserState;
  hideModal: any;
}

interface State {}

class ShoppingPreferenceOptionsModal extends Component<Props, State> {
  render() {
    const shoppingPreferenceOptions = [
      {
        name: "Coles",
        index: 0,
      },
      {
        name: "Woolworths",
        index: 1,
      },
    ];
    return (
      <div className="o-modal__container">
        <div className="u-w-all u-flex u-justify-center">
          <div className="o-caret-marker"></div>
        </div>
        <div
          className="u-background--white u-pt--8  u-br--vsmall"
          style={{
            marginLeft: "-50px",
            marginRight: "100px",
            width: "230px",
          }}
        >
          {shoppingPreferenceOptions.map((option, index) => {
            const isLast = index === shoppingPreferenceOptions.length - 1;
            return (
              <button
                className={
                  "u-w-all u-text-left u-semi-bold u-flex u-align-center u-hoverover--darker-background u-pv--12 u-ph--24" +
                  (isLast ? "" : " u-border--bottom--grey")
                }
                key={index}
                style={{ fontSize: "14px" }}
                onClick={() => {
                  this.props.updateUserShoppingPreference(option.name);
                  this.props.hideModal();
                }}
              >
                {option.name}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps, { updateUserShoppingPreference, hideModal })(
  ShoppingPreferenceOptionsModal
);
