export const sortByVendor = (
  items: ItemPricings[],
  order: "asc" | "desc" = "asc"
) => {
  return items.sort((itemOne, itemTwo) => {
    if (order === "asc") {
      return itemOne.vendor_id - itemTwo.vendor_id;
    }
    return itemTwo.vendor_id - itemOne.vendor_id;
  });
};

export const findByVendor = (items: ItemPricings[], vendor_id: 1 | 2) => {
  return items.find((item) => item.vendor_id === vendor_id);
};
