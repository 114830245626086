import { combineReducers } from "redux";

import basketReducer from "./reducers/basketReducer.ts";
import categoryReducer from "./reducers/categoryReducer.ts";
import colesNotificationReducer from "./reducers/colesNotificationReducer.ts";
import favouritesReducer from "./reducers/favouritesReducer.ts";
import filterReducer from "./reducers/filterReducer.ts";
import itemReducer from "./reducers/itemReducer.ts";
import listReducer from "./reducers/listReducer.ts";
import modalDropdownReducer from "./reducers/modalDropdownReducer.ts";
import modalReducer from "./reducers/modalReducer.ts";
import priceAlertReducer from "./reducers/priceAlertReducer.ts";
import searchReducer from "./reducers/searchReducer.ts";
import tourReducer from "./reducers/tourReducer";
import userReducer from "./reducers/userReducer.ts";

export default combineReducers({
  basket: basketReducer,
  categories: categoryReducer,
  filters: filterReducer,
  items: itemReducer,
  modal: modalReducer,
  modalDropdown: modalDropdownReducer,
  lists: listReducer,
  favourites: favouritesReducer,
  priceAlerts: priceAlertReducer,
  search: searchReducer,
  user: userReducer,
  tourGuideModal: tourReducer,
  colesNotification: colesNotificationReducer,
});
