interface filterAction {
  type: "FILTER_CHANGE"; // ADD OTHERS HERE
  filter_id: number;
}

const initialState: FilterState = {
  filter_ids: [],
};

export default function filterReducer(
  state = initialState,
  action: filterAction
) {
  switch (action.type) {
    case "FILTER_CHANGE":
      if (state.filter_ids.includes(action.filter_id)) {
        // remove the id
        const newSelection = state.filter_ids.filter(function (value) {
          return value !== action.filter_id;
        });
        // update state
        return {
          ...state,
          filter_ids: newSelection,
        };
      } else {
        // duplicate
        const newSelection = state.filter_ids;
        // add
        newSelection.push(action.filter_id);
        // update
        return {
          ...state,
          filter_ids: newSelection,
        };
      }

    default:
      return state;
  }
}

export const filterOptions = [
  { filter_id: 1, name: "On special" },
  { filter_id: 2, name: "Favourite item" },
  { filter_id: 3, name: "Price alert list" },
  { filter_id: 4, name: "Close match items" },
  { filter_id: 5, name: "Exclusive to Coles" },
  { filter_id: 6, name: "Exclusive to Woolworths" },
  { filter_id: 7, name: "Available at both" },
];
