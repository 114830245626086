import { Component } from "react";
import variables from "../../styles/utils/variables.scss";
import { Icon } from "../icons/Icon";
import ItemCountCheckbox from "./ItemCountCheckbox";
interface Props {
  listItem: PublicListItem;
}

interface State {
  mode: string;
  touchstartX: number;
  touchendX: number;
}

class PublicListCard extends Component<Props, State> {
  state = {
    mode: "collapsed",
    touchstartX: 0,
    touchendX: 0,
  };
  componentDidMount = () => {
    this.attachSwipeEventListeners();
  };

  attachSwipeEventListeners = () => {
    const slider = document.getElementById(
      "swipe-item-" + this.props.listItem.id
    );

    if (slider) {
      // listen to when the touch event starts and ends
      slider.addEventListener("touchstart", (e) => this.updateTouchStart(e), {
        passive: true,
      });
      slider.addEventListener("touchend", (e) => this.updateTouchEnd(e), {
        passive: true,
      });
    }
  };
  updateTouchStart = (e: any) => {
    this.setState({ touchstartX: e.changedTouches[0].screenX });
  };

  updateTouchEnd = (e: any) => {
    // check there was a start - else it is a touch
    if (this.state.touchstartX !== 0) {
      const touchendX = e.changedTouches[0].screenX;
      // it is a significant swipe if over 50px
      if (
        touchendX > this.state.touchstartX + 50 ||
        touchendX < this.state.touchstartX - 50
      ) {
        this.setState({
          mode: this.state.mode === "completed" ? "expanded" : "completed",
        });
      }
    }

    // reset
    this.setState({ touchendX: 0, touchstartX: 0 });
  };

  render() {
    const violet = variables.violetColor;

    return (
      <div>
        <div className="u-w-all u-flex u-align-center u-justify-between u-border--top--grey">
          <ItemCountCheckbox
            itemCount={this.props.listItem.quantity}
            onComplete={() =>
              this.setState({
                mode:
                  this.state.mode === "completed" ? "expanded" : "completed",
              })
            }
            mode={this.state.mode}
          />
          <button
            id={"swipe-item-" + this.props.listItem.id}
            onClick={() =>
              this.setState({
                mode: this.state.mode === "expanded" ? "collapsed" : "expanded",
              })
            }
            className=" u-flex u-align-center u-justify-between u-w-all  u-p--16"
          >
            <div
              className={
                "u-flex u-align-center" +
                (this.state.mode === "completed" ? "  u-color-grey" : "")
              }
            >
              <div className="u-text-left u-truncate--2l u-overflow--hidden">
                {this.props.listItem.item.name}
              </div>
            </div>
            <div className="">
              {this.state.mode === "collapsed" ? (
                <Icon
                  id="chevron_right"
                  stroke={violet}
                  fill="none"
                  size="l"
                  className="u-rotate--negative90deg"
                />
              ) : this.state.mode === "expanded" ? (
                <Icon
                  id="chevron_down"
                  stroke={violet}
                  fill="none"
                  size="l"
                  className="u-rotate--negtive360deg"
                />
              ) : (
                <Icon
                  id="chevron_right"
                  stroke={"lightgrey"}
                  fill="none"
                  size="l"
                  className="u-rotate--negative90deg"
                />
              )}
            </div>
          </button>
        </div>
        {this.state.mode === "expanded" ? (
          <div className="u-w-all u-flex u-justify-center u-pb--16">
            <div
              style={{ height: "90px", width: "90px" }}
              className="u-background--grey"
            >
              <img
                src={this.props.listItem.item.image_url}
                height="90px"
                width="90px"
                alt={this.props.listItem.item.name}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default PublicListCard;
