import React, { Component } from "react";
import IndexView from "../views/IndexView";

interface Props {}

interface State {
  isMobile: boolean;
}

class LandingPage extends Component<Props, State> {
  state = {
    isMobile: false,
  };

  componentDidMount() {
    this.storeUserInteraction();

    window.addEventListener("resize", this.setScreenWidth);

    //Get screen width
    this.setScreenWidth();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenWidth);
  }

  setScreenWidth = () => {
    const windowWidth = window.innerWidth;
    this.setState({
      isMobile: windowWidth < 600,
    });
  };

  storeUserInteraction = () => {
    localStorage.setItem("has-visited-website", "yes");
  };

  render() {
    return <IndexView />;
  }
}

export default LandingPage;
