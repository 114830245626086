import React, { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { sortPriceAlert } from "../../redux/actions/priceAlertActions";
import PriceAlertSortButton from "../priceAlerts/priceAlertOptions/PriceAlertSortButton";
import PriceAlertsSortDropdown from "../priceAlerts/priceAlertOptions/PriceAlertsSortDropdown";
import PriceAlertRows from "../priceAlerts/PriceAlertRows";

interface Props {
  priceAlerts: PriceAlertsState;
  sortPriceAlert: any;
}

interface State {}

class PriceAlertsView extends Component<Props, State> {
  render() {
    const isMobile = window.innerWidth < 600;
    return (
      <div className="u-w-all u-background--white">
        {isMobile ? (
          <div className="u-hide--desktop u-background--grey u-w-all u-flex u-justify-between">
            <div
              className="u-main-font--medium u-bold  u-pv--24 u-ph--32"
              style={{ fontSize: 16 }}
            >
              Price Alerts
              <span className="u-color-magenta u-ph--12 u-bold">
                {this.props.priceAlerts.priceAlerts?.length | 0}
              </span>
            </div>
            <PriceAlertsSortDropdown />
          </div>
        ) : (
          <div className="u-hide--mobile u-border--bottom--grey u-ph--48 u-pt--48 u-pb--12 u-flex u-justify-center">
            <div className="u-w-80pct">
              <div className="u-main-font--large u-pt--32 u-bold u-flex u-align-baseline ">
                Price Alerts
                <span className="u-color-magenta u-ph--12">
                  {this.props.priceAlerts.priceAlerts?.length | 0}
                </span>
              </div>
              <div className="u-w-all u-pr--52 u-mt--48 u-flex u-align-center u-justify-between u-main-font--vsmall">
                <PriceAlertSortButton priceAlerts={this.props.priceAlerts} />
                <div
                  className="u-text-center u-ph--8 u-mr--100--desktop"
                  style={{ width: "130px" }}
                >
                  Alert me when the price drops to
                </div>
              </div>
            </div>
          </div>
        )}
        <div id="price-alert" className="u-h-all">
          <PriceAlertRows />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  priceAlerts: state.priceAlerts,
});

export default connect(mapStateToProps, { showModal, sortPriceAlert })(
  PriceAlertsView
);
