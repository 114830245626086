import React, { Component } from "react";
import { connect } from "react-redux";
import { prettifySortTypeIdToString } from "../../../redux/actions/listActions";
import { showModal } from "../../../redux/actions/modalActions";
import { Icon } from "../../icons/Icon";
import PriceAlertsSortModal from "./PriceAlertsSortModal";

interface Props {
  priceAlerts: PriceAlertsState;
  showModal: any;
}

interface State {}

class PriceAlertSortButton extends Component<Props, State> {
  showSortOptions = () => {
    const button = document.getElementById("list-sort-dropdown");

    if (button) {
      const postionOfButton = button.getBoundingClientRect();

      const modalContent = () => (
        <div
          style={{
            marginTop: postionOfButton.bottom + 20 + "px",
            marginLeft: postionOfButton.left - 5 + "px",
            width: postionOfButton.right - postionOfButton.left + "px",
          }}
        >
          <PriceAlertsSortModal />
        </div>
      );
      this.props.showModal({ component: modalContent });
    }
  };
  render() {
    return (
      <button
        id="list-sort-dropdown"
        className="u-w-25pct u-pv--12 u-flex u-align-center"
        onClick={this.showSortOptions}
      >
        <Icon id="chevron_up_down" size="s" />

        <div className="u-text-oneline u-ml--16" style={{ fontSize: "14px" }}>
          Sort by{" "}
          <span className="u-bold">
            {prettifySortTypeIdToString(this.props.priceAlerts.currentSortType)}
          </span>
        </div>
      </button>
    );
  }
}

export default connect(null, { showModal })(PriceAlertSortButton);
