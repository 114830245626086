import React, { Component } from "react";
import { connect } from "react-redux";
import GrocerizeManualSlider from "./GrocerizeManualSlider";
import GrocerizePricesOverview from "./GrocerizePricesOverview";
import GrocerizeSwitch from "./GrocerizeSwitch";

interface Props {
  user: UserState;
}

class GrocerizeOverviewDisplay extends Component<Props, {}> {
  render() {
    return (
      <div className="u-w-all u-background--white u-flex u-justify-center">
        <div
          className="u-w-all u-flex--desktop u-justify-between"
          style={{ maxWidth: "1300px" }}
        >
          <GrocerizeSwitch />
          {this.props.user.prefers_grocerize_auto_mode ? (
            <GrocerizePricesOverview />
          ) : (
            <GrocerizeManualSlider />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});

export default connect(mapStateToProps)(GrocerizeOverviewDisplay);
