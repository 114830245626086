import { Component } from "react";

import { connect } from "react-redux";
import ItemCardMobile from "./ItemCardMobile";
import AddToBasketButton from "./components/AddToBasketButton";
import FavouriteIndicator from "./components/FavouriteIndicator";
import HalfThePriceIndicator from "./components/HalfThePriceIndicator";
import ItemPricing from "./components/ItemPricing";
import SaveItemToListButton from "./components/SaveItemToListButton";
import SavePriceAlertButton from "./components/SavePriceAlertButton";
import PriceAlertIndicator from "./components/SpecialPriceIndicator";

interface Props {
  itemData: ItemData;
  basket?: BasketState;
  forDisplay?: boolean;
}

interface State {
  windowWidth: number;
  imageLoadHasFailed: boolean;
  quantityOfItemInBasket: number;
  itemInBasket?: ItemData | undefined;
  closeMatchItemInBasket?: ItemData | undefined;
  quantityOfCloseMatchItemInBasket: number;
  isReset?: boolean;
  itemCloseMatch?: ItemData | undefined;
  closeMatchImageLoadHasFailed: boolean;
  flipCard: boolean;
  isDescriptionTooLong: boolean;
  isProductUnavailable: boolean;
}

class ItemCard extends Component<Props, State> {
  state = {
    windowWidth: 0,
    imageLoadHasFailed: false,
    closeMatchImageLoadHasFailed: false,
    quantityOfItemInBasket: 0,
    quantityOfCloseMatchItemInBasket: 0,
    itemInBasket: undefined,
    closeMatchItemInBasket: undefined,
    isReset: false,
    itemCloseMatch: this.props.itemData.close_match_a?.item_a,
    flipCard: false,
    isDescriptionTooLong: false,
    isProductUnavailable: false,
  };

  flipCard = () => {
    this.setState({
      flipCard: !this.state.flipCard,
    });
  };

  componentDidMount() {
    // get window screen width and height and set it for the components
    this.setScreenWidth();
    // track if the user rotates or changes
    window.addEventListener("resize", this.setScreenWidth);
    this.checkIfProductIsAlreadyInUsersBasket();

    this.checkPricing();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setScreenWidth);
  }

  componentDidUpdate = (prevProps: Props) => {
    if (prevProps.basket?.basketItems !== this.props.basket?.basketItems) {
      this.checkIfProductIsAlreadyInUsersBasket();
    }

    if (
      this.state.itemCloseMatch !== this.props.itemData.close_match_a?.item_a
    ) {
      this.setState({
        itemCloseMatch: this.props.itemData.close_match_a?.item_a,
      });
    }
  };

  checkPricing = () => {
    const newItemData = this.state.itemInBasket
      ? this.state.itemInBasket
      : this.props.itemData;

    if (newItemData.item_pricing.length === 1) {
      if (
        !newItemData.item_pricing[0].available &&
        newItemData.item_pricing[0].price
      ) {
        this.setState({ isProductUnavailable: true });
      } else {
        this.setState({ isProductUnavailable: false });
      }
    }
  };

  checkIfProductIsAlreadyInUsersBasket = () => {
    // check if product is in the basket
    const itemInBasket = this.props.basket?.basketItems?.find(
      (item) => item.id === this.props.itemData.id
    );

    const closeMatchItemInBasket = this.props.basket?.basketItems?.find(
      (item) => item.id === this.state.itemCloseMatch?.id
    );

    if (itemInBasket?.quantity) {
      // the product has been added
      this.setState({
        itemInBasket,
        quantityOfItemInBasket: itemInBasket.quantity,
      });
    } else {
      // the product has been removed from basket
      this.setState({ itemInBasket: undefined, quantityOfItemInBasket: 0 });
    }

    if (closeMatchItemInBasket?.quantity) {
      // the product has been added
      this.setState({
        closeMatchItemInBasket,
        quantityOfCloseMatchItemInBasket: closeMatchItemInBasket.quantity,
      });
    } else {
      // the product has been removed from basket
      this.setState({
        closeMatchItemInBasket: undefined,
        quantityOfCloseMatchItemInBasket: 0,
      });
    }
  };

  descriptionRef = (elementToBeTruncated: HTMLDivElement) => {
    const isTooLong = elementToBeTruncated
      ? elementToBeTruncated?.scrollHeight > elementToBeTruncated?.clientHeight
      : false;
    this.setState({ isDescriptionTooLong: isTooLong });
  };

  setScreenWidth = () => {
    const windowWidth = window.innerWidth;
    this.setState({ windowWidth });
  };

  render() {
    const itemData: ItemData = this.props.itemData;

    const closeMatchItem = this.state.itemCloseMatch;

    if (!itemData.item_pricing.length) {
      return null;
    }

    // if mobile screen width show mobile card
    if (this.state.windowWidth < 600 && !this.props.forDisplay) {
      return (
        <ItemCardMobile
          itemData={this.props.itemData}
          closeMatchItem={closeMatchItem}
          closeMatchItemInBasket={this.state.closeMatchItemInBasket}
          quantityOfCloseMatchItemInBasket={
            this.state.quantityOfCloseMatchItemInBasket
          }
          isProductUnavailable={this.state.isProductUnavailable}
        />
      );
    }

    return (
      <div>
        <div
          id={itemData.id.toString()}
          className={
            "card u-background--white u-br--small u-relative u-border--darkgrey " +
            (this.state.flipCard ? "flip" : "reset")
          }
          style={{
            width: "250px",
            height: "500px",
          }}
        >
          <div className=" u-p--8 u-flex u-flex-column">
            <div className="u-m0auto u-w-all u-text-center u-flex u-justify-center u-mt--32 u-text-unselectable ">
              {itemData.image_url && !this.state.imageLoadHasFailed ? (
                <img
                  id="item-image"
                  style={{ height: "140px" }}
                  src={itemData.image_url}
                  alt={itemData.name}
                  onError={() => {
                    this.setState({ imageLoadHasFailed: true });
                  }}
                />
              ) : (
                <div
                  className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                  style={{ height: "140px", width: "140px" }}
                >
                  No image found.
                </div>
              )}
            </div>
            <div className="u-ph--24">
              <div
                className="u-color-charcoal u-main-font--small u-semi-bold u-text-unselectable  u-pt--8 u-tooltip-wrap "
                style={{
                  height: "64px",
                  lineHeight: "18px",
                  fontSize: "14px",
                }}
              >
                <div className="u-truncate--3l" ref={this.descriptionRef}>
                  {itemData.name}
                </div>
                {this.state.isDescriptionTooLong && (
                  <span className="u-tooltip-content">{itemData.name}</span>
                )}
              </div>
              <div
                className="u-flex u-text-unselectable "
                style={{ marginTop: "30px", marginBottom: "8px" }}
              >
                {
                  <ItemPricing
                    itemData={itemData}
                    itemCloseMatch={closeMatchItem}
                    toggleCloseMatch={this.flipCard}
                  />
                }
              </div>
            </div>

            <AddToBasketButton
              itemData={
                this.state.itemInBasket
                  ? this.state.itemInBasket
                  : this.props.itemData
              }
              quantityOfItemInBasket={this.state.quantityOfItemInBasket}
              forDisplay={this.props.forDisplay}
            />

            <div className="u-flex u-justify-between u-ph--16 u-pv--16">
              <SaveItemToListButton
                itemData={itemData}
                forDisplay={this.props.forDisplay}
              />
              <SavePriceAlertButton
                itemData={itemData}
                forDisplay={this.props.forDisplay}
              />
            </div>
          </div>
          <div className="u-flex u-justify-between u-top--0 u-absolute u-w-all">
            {itemData.isSpecial && !itemData.isHalfThePrice && (
              <PriceAlertIndicator isSpecial={itemData.isSpecial || false} />
            )}

            {itemData.isSpecial && itemData.isHalfThePrice && (
              <HalfThePriceIndicator isHalfThePrice={itemData.isHalfThePrice} />
            )}

            {closeMatchItem ? (
              <div className="u-bold u-background--blue u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                Close match available
              </div>
            ) : this.state.isProductUnavailable ? (
              <div className="u-bold u-background--darkgrey u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                Product Unavailable
              </div>
            ) : (
              <></>
            )}
            <FavouriteIndicator item_id={itemData.id} />
          </div>
        </div>
        {/* CLOSE MATCH ITEM CARD - BEHIND ITEM CARD*/}
        {closeMatchItem ? (
          <div
            className={
              "card u-background--white u-br--small u-relative u-border--closeMatch " +
              (this.state.flipCard ? "reset" : "flip")
            }
            style={{
              width: "250px",
              height: "500px",
              marginTop: "-500px",
            }}
          >
            <div className=" u-p--8 ">
              <div className="u-m0auto u-w-all u-text-center u-flex u-justify-center u-mt--32 u-text-unselectable">
                {closeMatchItem.image_url &&
                !this.state.closeMatchImageLoadHasFailed ? (
                  <img
                    id="item-image"
                    style={{ height: "140px" }}
                    src={closeMatchItem.image_url}
                    alt={closeMatchItem.name}
                    onError={() => {
                      this.setState({ closeMatchImageLoadHasFailed: true });
                    }}
                  />
                ) : (
                  <div
                    className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
                    style={{ height: "140px", width: "140px" }}
                  >
                    No image found.
                  </div>
                )}
              </div>
              <div className="u-ph--24">
                <div
                  className="u-color-charcoal u-main-font--small u-semi-bold u-text-unselectable  u-pt--8 "
                  style={{
                    height: "64px",
                    lineHeight: "18px",
                    fontSize: "14px",
                  }}
                >
                  <div
                    className=" u-truncate--5l u-overflow-hidden u-align-center u-flex"
                    style={{
                      whiteSpace: "break-spaces",
                      display: "-webkit-box",
                    }}
                  >
                    {closeMatchItem.name}
                  </div>
                </div>
                <div
                  className="u-flex u-text-unselectable "
                  style={{ marginTop: "30px", marginBottom: "8px" }}
                >
                  {
                    <ItemPricing
                      itemData={closeMatchItem}
                      itemCloseMatch={itemData}
                      toggleCloseMatch={this.flipCard}
                    />
                  }
                </div>
              </div>

              <AddToBasketButton
                itemData={
                  this.state.closeMatchItemInBasket
                    ? this.state.closeMatchItemInBasket
                    : closeMatchItem
                }
                quantityOfItemInBasket={
                  this.state.quantityOfCloseMatchItemInBasket
                }
                forDisplay={this.props.forDisplay}
              />
              <div className="u-flex u-justify-between u-ph--16 u-pv--16">
                <SaveItemToListButton itemData={closeMatchItem} />
                <SavePriceAlertButton itemData={closeMatchItem} />
              </div>
            </div>
            <div className="u-flex u-justify-between u-top--0 u-absolute u-w-all">
              {itemData.isSpecial && !itemData.isHalfThePrice && (
                <PriceAlertIndicator isSpecial={itemData.isSpecial || false} />
              )}

              {itemData.isSpecial && itemData.isHalfThePrice && (
                <HalfThePriceIndicator
                  isHalfThePrice={itemData.isHalfThePrice}
                />
              )}

              {closeMatchItem ? (
                <div className="u-bold u-background--blue u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                  Close match
                </div>
              ) : this.state.isProductUnavailable ? (
                <div className="u-bold u-background--darkgrey u-color-white u-main-font--vsmall u-pv--4 u-ph--12 u-br--bottom--small u-h-fit">
                  Product Unavailable
                </div>
              ) : (
                <></>
              )}
              <FavouriteIndicator item_id={closeMatchItem.id} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, null)(ItemCard);
