export const openModalDropdown = () => {
  return {
    type: "OPEN_MODAL_DROPDOWN",
  };
};

export const closeModalDropdown = () => {
  return {
    type: "CLOSE_MODAL_DROPDOWN",
  };
};
