import React, { Component } from "react";

interface Props {
  colesPrice: number;
  woolworthsPrice: number;
  quantity: number;
  selected_vendor_id: number;
  woolsPricing?: ItemPricings | null;
  colesPricing?: ItemPricings | null;
}

class GrocerizeItemShowSavings extends Component<Props, {}> {
  render() {
    // calc the price difference
    const difference = this.props.colesPrice - this.props.woolworthsPrice;
    // if the price is not zero, show it (but only when they have selected the cheaper)
    if (
      // coles is more expensive and the user hasn't selected coles
      ((difference > 0 && this.props.selected_vendor_id === 2) ||
      // wools is more expensive and the user hasn't sleected wools
      (difference < 0 && this.props.selected_vendor_id === 1)) && (this.props.colesPricing?.available && this.props.woolsPricing?.available)
    ) {
      return (
        <div className="u-w-all u-flex u-justify-center u-pr--32--desktop">
          {/* spacing to mimic the number */}
          <div
            className="u-hide--desktop"
            style={{ paddingLeft: 38, paddingRight: 38 }}
          ></div>
          {/* sshow savings on coles side */}
          <div className="u-w-all u-main-font--vsmall u-text-oneline--mobile">
            {this.props.selected_vendor_id === 1 ? (
              <div className="u-flex u-justify-center">
                <span
                  className={
                    "u-semi-bold u-mr--4 " +
                    (difference > 0 ? " u-color-woolworths " : " u-color-coles")
                  }
                >
                  ${Math.abs(difference * this.props.quantity).toFixed(2)}
                </span>
                <span> savings</span>
              </div>
            ) : null}
          </div>
          {/* show savings on wools side */}
          <div className="u-w-all u-main-font--vsmall u-text-oneline--mobile">
            {this.props.selected_vendor_id === 2 ? (
              <div className="u-flex u-justify-center">
                <span
                  className={
                    "u-semi-bold u-mr--4 " +
                    (difference > 0 ? " u-color-woolworths " : " u-color-coles")
                  }
                >
                  ${Math.abs(difference * this.props.quantity).toFixed(2)}
                </span>
                <span> savings</span>
              </div>
            ) : null}
          </div>
        </div>
      );
    } else {
      // else show an empty div with height ass to stop jumping divs on mobile
      return <div style={{ height: 18 }}></div>;
    }
  }
}

export default GrocerizeItemShowSavings;
