import React, { Component } from "react";
import { routes } from "../utils/routes";
// import PageTemplate from "./PageTemplate";

interface Props {}

interface State {}

class SignUpTermsOfUse extends Component<Props, State> {
  // Convert word file with https://wordtohtml.net/
  // note it does not format perfectly - you will need to check the lists start numbers, add the links
  render() {
    return (
      <div>
          <div className="u-wrap u-m--12 o-terms-of-use">
            <h1>Grocerize&rsquo;s Terms of Use</h1>
            <h3>
              These Terms of Use govern your use of our websites and mobile
              applications.&nbsp;
            </h3>
            <ol>
              <li>
                <p>Conditions of use</p>
              </li>
            </ol>
            <p>
              By installing, accessing, downloading, viewing or using our
              websites and mobile applications you agree to be bound by these
              Terms of Use and our Privacy Policy. If you do not agree with
              these Terms of Use, do not access or use our websites or mobile
              applications. You must use our websites and mobile applications in
              accordance with these Terms of Use.
            </p>
            <p>
              We may update our Terms of Use from time to time. By continuing to
              access or use our websites and mobile applications you accept our
              Terms of Use as applies from time to time. Any changes to our
              Terms of Use will be published on our website (
              <a
                className=" u-underline"
                href={process.env.REACT_APP_FRONTEND_URL + routes.termsOfUse}
              >
                {process.env.REACT_APP_FRONTEND_URL + routes.termsOfUse}
              </a>
              ) and it is your responsibility to check the website from time to
              time to determine whether there are any changes.&nbsp;
            </p>
            <p>
              We may at any time, for any reason and without notice, modify,
              suspend, withdraw or terminate operation of or access to our
              websites and mobile applications including any functionalities. We
              reserve the right to restrict and reduce the volume of data being
              stored by our websites and mobile applications for any user. We
              may also upgrade our websites and mobile applications to fix an
              issue, add support or provide new functions or services.
            </p>
            <p>
              You will be responsible for any fees and charges (including third
              party fees and charges) incurred by you in relation to accessing
              and using our websites and mobile applications.
            </p>
            <p>
              As long as you comply with these Terms of Use, we grant you a
              non-exclusive, non-transferable and revocable licence to use our
              websites and mobile applications for your personal use in
              accordance with these Terms of Use.
            </p>
            <ol start={2}>
              <li>
                <p>Our services and subscriptions</p>
              </li>
            </ol>
            <p>
              You will need to create an account to use all or part of our
              services. Our service options will be explained to you when you
              sign up for our services. We provide number service options some
              of which are provided free of charge (Free Subscription) while
              other options require payment before they can be accessed (Paid
              Subscription).&nbsp;
            </p>
            <p>
              We may update our service options, including what they include,
              how much they cost, payment terms and any specific terms and
              conditions that may apply from time to time. Any price changes
              will take effect from the start of the next subscription period.
              By continuing your subscription, you agree to accept the new
              price. Your subscription will automatically renew before the end
              of the then-current subscription period. If you cancel your
              subscription, the subscription will take effect the day after the
              last day of the then-current subscription period.&nbsp;
            </p>
            <p>
              If you cancel your Paid Subscription you will still be able to use
              your Free Subscription unless you tell us you no longer want any
              subscription. You must also ensure your billing information is
              accurate and complete. You can manage your subscriptions and
              preferences anytime by clicking &ldquo;My Account&rdquo; then
              &ldquo;Preferences&rdquo; function in your account settings.
            </p>
            <p>
              Except when required by law, paid subscription costs are
              non-refundable.
            </p>
            <p>
              We may also offer trials, promotional plans, memberships or
              services, including offering of third party products and services.
              Promotions may be governed by separate rules. We are not
              responsible for third party promotions or their product and
              services.&nbsp;
            </p>
            <p>
              We use a third party to process your payments (payment gateway)
              who will collect and store your payment details. We will not
              collect your payment information. The payment gateway will collect
              and use your payment information (which is personal information)
              in accordance with their terms of use and privacy policy. You are
              responsible for reviewing the payment gateway&rsquo;s privacy
              policy, terms of use and other terms and conditions. By continuing
              to your Paid Subscription, you agree to the terms and conditions
              of the payment gateway. We make no warranties and accept no
              liability for any loss, damage or injury arising from your access
              to or use of the payment gateway. We may change our payment
              gateway and by continuing your subscription you agree that we may
              continue processing your subscription costs using the new payment
              gateway and you accept the payment gateway terms and conditions
              that apply from time to time.&nbsp;
            </p>
            <ol start={3}>
              <li>
                <p>Products, prices and other information</p>
              </li>
            </ol>
            <p>
              The products, prices and other product information stated on our
              websites and mobile applications are subject to change at any time
              and without notice. Whilst we will use our reasonable commercial
              endeavours to ensure the accuracy of the data, we do not guarantee
              the information provided on our websites and mobile applications
              is accurate, adequate, true or complete and we cannot guarantee
              the information is correct. Some data required by our websites and
              mobile applications has been estimated to enable ranking of
              products.&nbsp;
            </p>
            <p>
              Whilst product prices are correct at time of publishing (based on
              your registered postcode), the information available on our
              websites and mobile applications may be out of date from time to
              time. &nbsp;Some stores may not stock certain products or some
              products may be out of stock from time to time. The products on
              our websites and mobile applications may not represent all the
              products available in the market.
            </p>
            <p>
              You, especially if you have special dietary requirements or food
              sensitivities, should assess the accuracy, relevance and
              suitability of this information for your personal circumstances.
              Our websites and mobile applications contain no advice, personal
              recommendations or suggestions about the suitability of a product
              for you. Before making a choice about which products may be
              suitable for you, you should evaluate your own individual needs,
              objectives and situation. We will not be liable for your
              choices.&nbsp;
            </p>
            <p>
              The information should be used as a guide only and should not be
              relied upon as a substitute for professional advice. We are not
              liable for any loss, damage or injury you suffer in connection
              with the access to, use of or reliance on the Content and product
              information on our websites and mobile applications.&nbsp;
            </p>
            <p>
              You agree the product information and associated alternatives on
              our websites and mobile applications:
            </p>
            <ul>
              <li>
                <p>is provided &ldquo;as is&rdquo;;&nbsp;</p>
              </li>
              <li>
                <p>
                  is general information only and does not take into account
                  your individual needs or circumstances;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  has been compiled based on the information on the
                  product&rsquo;s packaging at the time of data collection, and
                  as the information is provided by third parties we may not be
                  in a position to verify the information. We do not guarantee
                  product information is accurate, adequate, true or complete.
                  We accept no liability in relation to product information. The
                  information may be updated, altered or removed, from time to
                  time, at any time, without notice to you;
                </p>
              </li>
              <li>
                <p>
                  has been provided on the basis you undertake the
                  responsibility for assessing the accuracy, adequacy, truth,
                  completeness and relevance of product information, including
                  for your personal circumstances, and you rely on it entirely
                  at your own risk. It is your responsibility to check that the
                  alternative offerings are acceptable to you;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  should be used as a guide only and should not be relied upon.
                  You should consult your doctor or other suitably qualified
                  professional for advice or if you have questions or concerns
                  about your health or nutritional needs; and
                </p>
              </li>
              <li>
                <p>
                  you ensure you are clear on the ingredients of the product
                  prior to consumption. Where further dietary information is
                  important to you, for example because you have an allergy or
                  specific dietary requirement, you should seek advice from a
                  suitably qualified professional or the product manufacturer.
                </p>
              </li>
            </ul>
            <ol start={4}>
              <li>
                <p>Customer Product Reviews</p>
              </li>
            </ol>
            <p>
              We welcome your reviews and comments about products on our
              websites and mobile applications. If you submit a review, comment
              or other information, (collectively, the&nbsp;Information) you
              acknowledge and agree that, you grant us a free, irrevocable and
              perpetual right to use, alter, publish or delete the Information
              for any purpose whatsoever. We accept no liability for reviews and
              comments posted by users on our websites and mobile applications.
            </p>
            <p>
              We may alter reviews and comments to remove any information that
              may personally identifying a reviewer or unlawful. We may elect
              not to publish reviews that contain inappropriate or irrelevant
              content. We are not responsible for any of the reviews or comments
              posted on our websites or mobile applications and we do not share
              the opinions, views, or commentary of any testimonials.&nbsp;
            </p>
            <ol start={5}>
              <li>
                <p>Intellectual Property Rights</p>
              </li>
            </ol>
            <p>
              Our websites, mobile applications and the Content on our websites
              and mobile applications is protected by copyright and may be
              subject to other intellectual property rights. You must not use
              our websites or mobile applications in any way that may infringe
              the intellectual property rights of any person.&nbsp;
            </p>
            <p>
              Except to the extent permitted by law or as expressly provided in
              these Terms of Use, you must not (and you must not allow or
              procure a third party to) copy, reproduce, reverse engineer,
              republish, upload, post, publicly display, encode, translate,
              transmit, store, publish, distribute or otherwise exploit any part
              of, or Content on, our websites or mobile applications without our
              prior written consent.&nbsp;
            </p>
            <p>
              Downloaded materials must be used solely in accordance with these
              Terms of Use and does not transfer any right or ownership of the
              Content to you. You must not remove any copyright or other
              proprietary notice from Content.&nbsp;
            </p>
            <p>
              Trade marks (registered or unregistered) and logos must not be
              used or modified in any way without our prior written
              consent.&nbsp;
            </p>
            <p>
              Nothing in these Terms of Use constitutes a transfer of any
              intellectual property rights.
            </p>
            <ol start={6}>
              <li>
                <p>Security</p>
              </li>
            </ol>
            <p>
              You are responsible for ensuring the security of your computer,
              mobile or other device you use to access, download or use our
              websites and mobile applications.&nbsp;
            </p>
            <p>
              You are responsible for maintaining the confidentiality of the
              information you hold for your account, including your username and
              password, and for any and all activity that occurs under your
              account (Account Information).
            </p>
            <p>
              We may restrict or terminate your account or access to our
              websites and mobile applications as a result of:
            </p>
            <ul>
              <li>
                <p>
                  you failing to keep your Account Information secure and
                  confidential;
                </p>
              </li>
              <li>
                <p>use of your Account Information by third parties;&nbsp;</p>
              </li>
              <li>
                <p>
                  use of your Account Information that interferes with the
                  operation, functionality or integrity of our websites and
                  mobile applications; or
                </p>
              </li>
              <li>
                <p>
                  access or use of your Account Information or our websites and
                  mobile applications which maybe unlawful.&nbsp;
                </p>
              </li>
            </ul>
            <p>
              You must immediately notify us of any unauthorised access or use
              of your Account Information, or any other breach of security.
            </p>
            <ol start={7}>
              <li>
                <p>Links and external sites</p>
              </li>
            </ol>
            <p>
              Our websites and mobile applications may provide links to or allow
              you to interact with other websites or mobile applications of
              third parties (external sites). Links to external sites are
              provided for your convenience and we do not control and are not
              responsible for the content, accuracy or functionality of those
              external sites.&nbsp;
            </p>
            <p>
              Unless expressly stated otherwise, the provision of a link to an
              external site does not constitute an endorsement, recommendation,
              representation or approval of the content, accuracy or
              functionality of that external site or the products or services on
              that external site. You should seek your own independent advice if
              you have any queries or concerns about whether those products or
              services are suitable for you.&nbsp;
            </p>
            <p>By clicking on a link to an external site, you consent to:</p>
            <ul>
              <li>
                <p>
                  our disclosure of your relevant personal information to the
                  provider and operator of that external site; and
                </p>
              </li>
              <li>
                <p>
                  our collection of your relevant personal information from the
                  provider and operator of that external site (such as your
                  login details),
                </p>
              </li>
            </ul>
            <p>
              for the purpose of processing your request to link to the external
              site. We will disclose any personal information in accordance with
              our Privacy Policy.&nbsp;
            </p>
            <p>
              You are responsible for reviewing the privacy policy, terms of use
              and other terms and conditions in relation to external sites. We
              make no warranties and accept no liability for any loss, damage or
              injury arising from your access to or use of (including the
              content on) external sites.
            </p>
            <ol start={8}>
              <li>
                <p>Voice commands&nbsp;</p>
              </li>
            </ol>
            <p>
              The services we may use from time to time include those offered by
              Google (including Google Assistant), Apple (including Siri) and
              other technology providers. Enabling the use of voice commands on
              your devices will involve sharing anonymised data, such as text
              string product searches, from your use of our websites and mobile
              applications with those providers. You can find out more by
              reviewing the privacy policies of those providers, including
              information on how to opt-out of certain activity.
            </p>
            <ol start={9}>
              <li>
                <p>Your communications</p>
              </li>
            </ol>
            <p>
              You are responsible for your own communications. You must not:
            </p>
            <ul>
              <li>
                <p>
                  transmit to us material that is copyrighted, unless you are
                  the copyright owner or have the permission of the copyright
                  owner;
                </p>
              </li>
              <li>
                <p>
                  send material that reveals trade secrets, unless you own them
                  or have the permission of the owner;
                </p>
              </li>
              <li>
                <p>
                  send material that infringes on any other intellectual
                  property rights of others or on the privacy or publicity
                  rights of others;
                </p>
              </li>
              <li>
                <p>
                  send material that is obscene, defamatory, threatening,
                  harassing, abusive, hateful, or embarrassing to another user
                  or any other person or entity;
                </p>
              </li>
              <li>
                <p>
                  intentionally or unintentionally violate or encourage conduct
                  that would violate any local, state, or federal law;
                </p>
              </li>
              <li>
                <p>
                  attempt to breach the security of our websites or mobile
                  applications;
                </p>
              </li>
              <li>
                <p>send advertisements or solicitations of business; or</p>
              </li>
              <li>
                <p>
                  send chain letters or pyramid schemes; or impersonate another
                  person.
                </p>
              </li>
            </ul>
            <ol start={10}>
              <li>
                <p>Third party terms</p>
              </li>
            </ol>
            <p>
              This agreement is between you and us only and you agree that,
              unless expressly provided otherwise, no third party:
            </p>
            <ul>
              <li>
                <p>
                  has responsibility for our websites and mobile applications;
                </p>
              </li>
              <li>
                <p>
                  has responsibility to provide maintenance and support services
                  for our websites and mobile applications;
                </p>
              </li>
              <li>
                <p>
                  has responsibility for any warranties or claims, whether
                  brought by you or by third parties, relating to the supply of
                  our websites or mobile applications to you;
                </p>
              </li>
              <li>
                <p>
                  has responsibility for the investigation, defence, settlement
                  and discharge of any third party intellectual property
                  infringement claim in respect of the contents of our websites
                  and mobile applications; and
                </p>
              </li>
              <li>
                <p>
                  you also represent and warrant that you are not located in a
                  country that is subject to an Australian or U.S. Government
                  embargo, or that has been designated by the Australian or U.S.
                  Government as a &ldquo;terrorist supporting&rdquo; country and
                  that you are not listed on any Australian or U.S. Government
                  list of prohibited or restricted parties.
                </p>
              </li>
            </ul>
            <ol start={11}>
              <li>
                <p>Your Privacy</p>
              </li>
            </ol>
            <p>
              We respect your privacy and are committed to protecting your
              personal information. Your personal information will be managed in
              accordance with our Privacy Policy which is available on our
              website (
              <a
                className="u-underline"
                href={process.env.REACT_APP_FRONTEND_URL + routes.privacyPolicy}
              >
                {process.env.REACT_APP_FRONTEND_URL + routes.privacyPolicy}
              </a>
              ).
            </p>
            <p>
              By accessing or using our websites and mobile applications you
              agree that we may collect your personal information, anonymised
              data and related information about your access to and use of our
              websites and mobile applications, including shopping lists,
              technical information and preferences about your device, system
              and application software. We collect this information to provide
              you with the functionalities of our websites and mobile
              applications and to optimise your user experience, including to
              better understand your requirements and preferences. We will
              handle your information in accordance with our Privacy Policy.
            </p>
            <p>
              Our websites and mobile applications use cookies, web tracking
              tools and similar technologies to improve the Content and
              functionality of our websites and mobile applications. Our use of
              cookies and similar technologies is managed in accordance with our
              Privacy Policy.&nbsp;
            </p>
            <ol start={12}>
              <li>
                <p>Limitations of liability</p>
              </li>
            </ol>
            <p>
              Except where prohibited by law, we will not be liable for any
              loss, expense, damage or injury (including exemplary or
              consequential losses or damages) of any kind in connection with
              your access to or use of our websites and mobile applications,
              including:
            </p>
            <ul>
              <li>
                <p>
                  unauthorised access of or breach of security to our websites
                  or mobile applications;&nbsp;
                </p>
              </li>
              <li>
                <p>
                  any error, fault, act, omission, inaccuracy, completeness or
                  misrepresentation in relation to our websites and mobile
                  applications and their Content; and
                </p>
              </li>
              <li>
                <p>transmission of any computer virus.</p>
              </li>
            </ul>
            <p>
              We are not liable to you for any loss, damage or alteration to
              your equipment (including but not limited to computer equipment,
              handheld device or mobile telephones) as a result of the
              installation or use of our websites or mobile applications.
            </p>
            <p>
              We are not responsible for any issues with voice commands or
              shortcuts or if iOS or Android or other technology providers make
              any changes to technology that impacts the way you access or use,
              or any other functionality of, our websites and mobile
              applications.
            </p>
            <p>
              To the maximum extent permitted by law, you agree to indemnify and
              keep indemnified us against all actions and claims which may be
              brought against us by any person, and for all los, damage or
              injury incurred by us, as a result of your:
            </p>
            <ul>
              <li>
                <p>breach of these Terms of Use;</p>
              </li>
              <li>
                <p>breach of any other legal obligation or law;</p>
              </li>
              <li>
                <p>acts or omissions; and</p>
              </li>
              <li>
                <p>
                  access to and use of the Content on our websites and mobile
                  applications.&nbsp;
                </p>
              </li>
            </ul>
            <p>
              We will use reasonable efforts to make our websites and mobile
              applications available at all times, however the quality and
              availability of our websites and mobile applications may be
              affected by factors outside our reasonable control, including your
              internet connection or other network issues.
            </p>
            <ol start={13}>
              <li>
                <p>Effects of termination</p>
              </li>
            </ol>
            <p>
              Upon any termination, the rights and licenses granted to you will
              immediately terminate and you must cease using the websites and
              mobile applications.
            </p>
            <ol start={14}>
              <li>
                <p>Governing law</p>
              </li>
            </ol>
            <p>
              These Terms of Use will be governed by and construed according to
              the law of New South Wales and the parties submit to the
              jurisdiction of the courts of New South Wales.
            </p>
            <ol start={15}>
              <li>
                <p>Terminology</p>
              </li>
            </ol>
            <p>In these Terms of use we use the expressions:</p>
            <p>
              &ldquo;Content&rdquo; means all copyright, database rights text,
              graphics, user interfaces, visual interfaces, photographs,
              trademarks, logos, sounds, music, artwork, software code and the
              design, structure, selection, coordination, expression,
              &ldquo;look and feel&rdquo; and arrangement of such content and
              other intellectual property rights;
            </p>
            <p>
              &ldquo;Terms of Use&rdquo;&nbsp;mean these terms and conditions
              governing your use of our websites and mobile applications;
            </p>
            <p>
              &ldquo;us&rdquo; or &ldquo;we&rdquo; or &ldquo;our&rdquo; means
              Grocerize ACN 645 750 018 and related entities;
            </p>
            <p>
              &ldquo;you&rdquo; and &ldquo;your&rdquo; to refer to each and
              every individual who accesses our websites or mobile application
              and the person to which a device is registered or a person
              authorised to use the device, upon which the Application is
              installed.
            </p>
          </div>
      </div>
    );
  }
}

export default SignUpTermsOfUse;
