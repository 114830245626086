import React, { Component } from "react";

class ThreeDots extends Component {
  render() {
    return (
      <div className="u-flex u-justify-center u-align-center">
        <div className="u-dot"></div>
        <div className="u-dot"></div>
        <div className="u-dot"></div>
      </div>
    );
  }
}

export default ThreeDots;
