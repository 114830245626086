import { Component } from "react";
import { connect } from "react-redux";
import { saveNewCategoryFilterSelection } from "../../../redux/actions/categoryAction";
import { capitalizeFirstLetter } from "../../../utils/UIFunctions";
import { getParentOfNode, searchTreeForId } from "./itemNavigationFunctions";

interface Props {
  search: SearchState;
  items: ItemsState;
  categories: CategoryState;
  saveNewCategoryFilterSelection: any;
}

interface State {
  searchTerm: string;
}

class SearchTitleBanner extends Component<Props, State> {
  state = {
    searchTerm: "",
  };
  componentDidMount = () => {
    if (this.props.search.searchTerm) {
      this.setState({
        searchTerm: capitalizeFirstLetter(this.props.search.searchTerm),
      });
    }
  };
  componentDidUpdate(prevProps: Props) {
    if (this.props.search.searchTerm !== prevProps.search.searchTerm) {
      this.setState({
        searchTerm: capitalizeFirstLetter(this.props.search.searchTerm),
      });
    }
  }

  showBreadcrumbs = () => {
    // only show if categories are selected
    if (this.props.categories.selectedNode) {
      let currentSelectedCategories = [this.props.categories.selectedNode];

      // if there are parent cats, show them too in the breadcrumbs
      if (this.props.categories.selectedNode.parent_crawler_id) {
        //  find current selected parent
        let parentCat = getParentOfNode(
          this.props.categories.categoryTree,
          this.props.categories.selectedNode
        );

        // if parent is found
        if (parentCat) {
          // add it
          currentSelectedCategories.push(parentCat);

          // if the parent cat has a parent we need to show those too
          while (parentCat && parentCat.parent_crawler_id) {
            parentCat = searchTreeForId(
              this.props.categories.categoryTree,
              parentCat.parent_crawler_id
            );

            currentSelectedCategories.push(parentCat);
          }
        }
      }

      // reverse it so that the top level tree is shown first
      currentSelectedCategories.reverse();
      return (
        <div className="u-ph--12 u-flex u-align-center">
          {currentSelectedCategories.map((cat, index) => {
            return (
              <button
                className="u-main-font--small u-bold u-color-darkgrey "
                key={index}
                onClick={() => {
                  this.props.saveNewCategoryFilterSelection(cat);
                }}
              >
                <span className=" u-link u-text-oneline ">{cat.name}</span>
                {index !== currentSelectedCategories.length - 1 ? (
                  <span className="u-ph--8">/</span>
                ) : null}
              </button>
            );
          })}
        </div>
      );
    }
  };

  render() {
    return (
      <div
        className={
          "u-w-all u-border--bottom--grey--mobile u-flex u-align-center " +
          (this.props.search.searchTerm ? "" : " u-hide--mobile")
        }
      >
        <div className="u-flex u-align-center ">
          {this.showBreadcrumbs()}
          {this.props.search.searchTerm &&
          this.props.search.searchTerm.length ? (
            <div className="u-flex u-flex u-align-center ">
              <div className="u-main-font--medium u-bold u-color-charcoal">
                {this.state.searchTerm}
              </div>
              <div className="u-ph--12 u-color-magenta u-bold">
                ({this.props.items.totalProductsFound} items)
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  search: state.search,
  categories: state.categories,
  items: state.items,
});

export default connect(mapStateToProps, { saveNewCategoryFilterSelection })(
  SearchTitleBanner
);
