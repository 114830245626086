import { Dispatch } from "react";
import { httpClient } from "../../clients/auth/addTokenToHTTPRequests";
import {
  SAVE_USER_DATA,
  TOGGLE_AUTO_MODE,
  UPDATE_USER_MARKETING_EMAILS_PREFERENCE,
  UPDATE_USER_SAVINGS_LIMIT,
  UPDATE_USER_SHOPING_PREFERENCE,
  UPDATE_USER_SYSTEM_EMAILS_PREFERENCE,
} from "../enums";

export const saveUserData = (userData: UserState) => {
  return {
    type: SAVE_USER_DATA,
    userData,
  };
};

export const checkUserStatus = () => async () => {
  return await httpClient.instance
    .get("/user/status")
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteUserFromAuth0 = () => async () => {
  return await httpClient.instance
    .delete("/user")
    .then((response) => {
      console.log("deleteUseFromAuth0: ", response);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUserData = () => async (dispatch: Dispatch<any>) => {
  return await httpClient.instance
    .get("/user")
    .then((res) => {
      dispatch({
        type: SAVE_USER_DATA,
        userData: res.data,
      });
    })
    .catch((e) => {
      // if error save favs as empty
      console.log(e);
    });
};

export const updateUserShoppingPreference =
  (shoppingPreference: string) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .patch("/user", {
        shopping_preference: shoppingPreference,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_USER_SHOPING_PREFERENCE,
          shoppingPreference,
        });
      })
      .catch((e) => {
        // if error save favs as empty
        console.log(e);
      });
  };

export const updateUserSavingsLimit =
  (manualPriceDifference: number) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .patch("/user", {
        manual_price_difference: manualPriceDifference,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_USER_SAVINGS_LIMIT,
          manualPriceDifference,
        });
      })
      .catch((e) => {
        // if error save favs as empty
        console.log(e);
      });
  };

export const updateUserGrocerizeAutoModePreference =
  (prefersGrocerizeAutoMode: boolean) => async (dispatch: Dispatch<any>) => {
    dispatch({
      type: TOGGLE_AUTO_MODE,
      prefersGrocerizeAutoMode,
    });
  };

export const updateUserSystemEmailsPreference =
  (sendSystemEmails: boolean) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .patch("/user", {
        send_system_emails: sendSystemEmails,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_USER_SYSTEM_EMAILS_PREFERENCE,
          sendSystemEmails,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

export const updateUserMarketingEmailsPreference =
  (sendMarketingEmails: boolean) => async (dispatch: Dispatch<any>) => {
    return await httpClient.instance
      .patch("/user", {
        send_marketing_emails: sendMarketingEmails,
      })
      .then(() => {
        dispatch({
          type: UPDATE_USER_MARKETING_EMAILS_PREFERENCE,
          sendMarketingEmails,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
