import React, { Component } from "react";
import ToggleBox from "./ToggleBox";

interface Props {
  setVendor: any;
  colesCount: number;
  woolsCount: number;
  initialVendor: "coles" | "wools" | undefined;
}

interface State {
  selected: string;
}

class Toggle extends Component<Props, State> {
  state = {
    selected: this.props.initialVendor || "coles",
  };

  toggleSwitch = () => {
    if (this.state.selected === "coles") {
      this.setState({ selected: "wools" });
      this.props.setVendor("wools");
    } else {
      this.setState({ selected: "coles" });
      this.props.setVendor("coles");
    }
  };

  setVendorAs = (type: "wools" | "coles") => {
    this.setState({ selected: type });
    this.props.setVendor(type);
  };
  render() {
    return (
      <div className="u-p--6 u-background--white u-w-all--mobile u-flex u-align-center ">
        <ToggleBox
          type={"coles"}
          itemsCount={this.props.colesCount}
          toggled={this.state.selected === "coles"}
          setVendor={this.setVendorAs}
        />
        <button
          className={
            "u-background--white u-br--all u-flex u-align-center " +
            (this.state.selected === "coles"
              ? " u-border--coles"
              : " u-border--woolworths")
          }
          style={{
            width: "60px",
            height: "37px",
            marginLeft: "-26px",
            marginRight: "-26px",
            zIndex: 2,
          }}
          onClick={() => this.toggleSwitch()}
        >
          <div className="u-w-all u-ph--6">
            <div
              className={
                "u-br--all  u-border--grey" +
                (this.state.selected === "coles"
                  ? " u-background--coles"
                  : " u-background--woolworths u-float--right")
              }
              style={{
                width: "25px",
                height: "25px",
                zIndex: 2,
              }}
            ></div>
          </div>
        </button>
        <ToggleBox
          type={"wools"}
          itemsCount={this.props.woolsCount}
          toggled={this.state.selected === "wools"}
          setVendor={this.setVendorAs}
        />
      </div>
    );
  }
}

export default Toggle;
