import React, { Component } from "react";
import { Icon } from "../components/icons/Icon";

interface Props {
  onCorrectPassword: any;
}

interface State {
  showPasswordInput: boolean;
  passwordGuess: string;
}

class MaintenanceMode extends Component<Props, State> {
  state = {
    showPasswordInput: false,
    passwordGuess: "",
  };

  validatePassword = () => {
    // if matches then process else do nothing
    if (
      this.state.passwordGuess ===
      process.env.REACT_APP_MAINTENANCE_MODE_PASSWORD
    ) {
      this.props.onCorrectPassword();
    }
  };

  render() {
    return (
      <div
        className="u-background--grocerize u-flex u-h-all "
        style={{ width: window.innerWidth, minHeight: "100vh", height: "100%" }}
      >
        <div className=" u-w-80pct u-h-all u-mauto u-pv--48">
          <div className="u-flex--desktop  u-justify-center u-align-center u-w-all u-h-all ">
            <div
              className="u-ph--48 u-flex u-justify-center"
              onClick={() => this.setState({ showPasswordInput: true })}
            >
              <Icon id="grocerize" height={120} width={120} fill={"white"} />
            </div>
            <div className="u-main-font--large u-color-white u-pt--100--mobile">
              <div className="u-bold " style={{ fontSize: 60, lineHeight: 1 }}>
                We will be back soon!
              </div>
              <div className="u-pt--48">
                Grocerize is currently down for maintenance!
              </div>
              {this.state.showPasswordInput ? (
                <input
                  className="o-signup-input u-br--small u-pt--24"
                  value={this.state.passwordGuess}
                  autoFocus={true}
                  onChange={(e) =>
                    this.setState({ passwordGuess: e.currentTarget.value })
                  }
                  onBlur={() => this.validatePassword()}
                ></input>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MaintenanceMode;
