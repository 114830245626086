import React, { Component } from "react";
import { Icon } from "../icons/Icon";

interface Props {
  content: ActionPopupContent;
}

interface ActionPopupContent {
  displayName: string;
  subtitle: any;
  actionHTML: any;
  cancelAction: any;
}
interface State {}

class BasketFooterActionPopups extends Component<Props, State> {
  render() {
    return (
      <div className="u-relative">
        <div className="u-absolute u-bottom--0 u-w-all ">
          <div className="u-slide-in--bottom u-w-all u-background--white u-br--top--medium  u-shadow  u-p--24 u-slide-in--bottom ">
            <div className="u-flex u-justify-between u-align-center">
              <div
                className=" u-main-font--small u-bold u-color-magenta u-pt--24"
                style={{ fontSize: 16 }}
              >
                {this.props.content.displayName}
              </div>
              <button onClick={this.props.content.cancelAction}>
                <Icon id="clear" stroke="#e1e1e1" />
              </button>
            </div>
            <div
              className=" u-main-font--small u-pv--20 "
              style={{ fontSize: 14 }}
            >
              {typeof this.props.content.subtitle === "string"
                ? this.props.content.subtitle
                : this.props.content.subtitle()}
            </div>

            {this.props.content.actionHTML()}
          </div>
        </div>
      </div>
    );
  }
}

export default BasketFooterActionPopups;
