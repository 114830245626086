import { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { trackAuthentication } from "../../clients/analytics/cliquemarket";
import { captureDataOnSignUp } from "../../clients/analytics/mixpanel";

interface Props {
  user: UserState;
}
interface State {
  isAccepted: boolean;
}

class Welcome extends Component<Props, State> {
  state = {
    isAccepted: false,
  };

  acceptAgreement = () => {
    this.setState({ isAccepted: true });

    if(!process.env.REACT_APP_DEV_MODE) {
      captureDataOnSignUp(this.props.user);
      trackAuthentication(
        {
          id: this.props.user.id,
          email: this.props.user.email,
          prefereredStore: this.props.user.shopping_preference,
          source: this.props.user.auth0_id,
          postcode: this.props.user.postcode,
        },
        "signup"
      );
    };
  }

  render() {
    if (this.state.isAccepted) {
      return <Redirect to="/browse" />;
    }
    return (
      <div>
        <div className="u-main-font--small u-color-charcoal u-background--white">
          <div className=" u-ph--32 u-pt--48">
            <div
              className="u-main-font--medium u-color-charcoal u-bold "
              style={{ fontSize: "21px" }}
            >
              Welcome to Grocerize
            </div>
            <div
              className="u-main-font--medium u-color-charcoal u-pt--32"
              style={{ fontSize: "16px" }}
            >
              {/* Your 1-month free access to premium starts now! Enjoy Access to all the premium features and start saving money on your grocery shopping! */}
              {/* @TODO why do we have duplicate Welcome and WelcomeDEsktop Components? */}
              Thanks for signing up Grocerize.
              <br />
              <br />
              Your feedback is greatly appreciated during this period via the
              link in the navigation bar.
              <br />
              <br />
              The commercial launch of Grocerize will be in the coming months.
            </div>
            {/* <div
      className="u-main-font--small u-color-charcoal u-pt--48"
      style={{ fontSize: "14px" }}
    >
      Subscribe for premium at any time during your free trial and you won’t be charged until the trial period ends.
    </div> */}
            <br />
            <br />
            <div className="u-pv--20">
              <button
                className="u-w-all u-background--green u-pv--20 u-bold u-color-white u-br--small"
                onClick={this.acceptAgreement}
              >
                {/* Plan, Shop and Save now  */}I Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
});
export default connect(mapStateToProps)(Welcome);
