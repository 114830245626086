import React, { Component } from "react";
import { findColesAndWoolsPricing } from "../../utils/UIFunctions";

import GrocerizeItemShowSavings from "./GrocerizeItemShowSavings";
import GrocerizeItemTogglePrices from "./GrocerizeItemTogglePrices";

interface Props {
  itemData: ItemData;
}

interface State {
  toggleSetToColes: boolean;
  imageLoadHasFailed: boolean;
  woolworthsPrice?: number;
  colesPrice?: number;
  woolsPricing?: ItemPricings | null;
  colesPricing?: ItemPricings | null;
  itemData?: ItemData | {};
}

class GrocerizeBasketItemCard extends Component<Props, State> {
  state = {
    toggleSetToColes: true,
    imageLoadHasFailed: false,
    woolworthsPrice: 0,
    colesPrice: 0,
    colesPricing: null,
    woolsPricing: null,
    itemData: this.props.itemData,
  };

  componentDidUpdate = (prevProps: Props) => {
    // if the selected vendor changes update state
    if (this.props.itemData !== prevProps.itemData) {
      this.setState({ itemData: this.props.itemData });
    }
  };

  componentDidMount = () => {
    // extract the prices
    const { colesPricing, woolsPricing } = findColesAndWoolsPricing(
      this.props.itemData
    );

    const colesPrice =
      colesPricing?.price && colesPricing.available ? colesPricing.price : 0;
    const woolworthsPrice =
      woolsPricing?.price && woolsPricing.available ? woolsPricing.price : 0;

    this.setState({
      colesPrice,
      woolworthsPrice,
      woolsPricing,
      colesPricing,
    });
  };

  renderImageAndName = () => {
    return (
      <div className="u-flex u-align-center u-pl--48 u-pl--40--mobile">
        {!this.state.imageLoadHasFailed ? (
          <img
            style={{ height: "80px", width: "80px" }}
            src={this.props.itemData.image_url}
            alt={this.props.itemData.name}
            onError={() => {
              this.setState({ imageLoadHasFailed: true });
            }}
          />
        ) : (
          <div
            className="u-background--grey u-w-all  u-main-font--vsmall u-semi-bold u-text-center"
            style={{ height: "80px", width: "80px" }}
          >
            No image found.
          </div>
        )}
        <div className="u-main-font--small u-semi-bold u-pl--48 u-pl--40--mobile u-pr--24--mobile">
          {this.props.itemData.name}
        </div>
      </div>
    );
  };

  render() {
    const woolworthsPrice = this.state.woolworthsPrice;
    const colesPrice = this.state.colesPrice;
    const quantity = this.state.itemData.quantity || 1;
    return (
      <div
        className="u-w-all u-ph--12 u-border--bottom--darkgrey u-pt--12--mobile u-flex u-justify-center u-align-center"
        key={this.props.itemData.id}
      >
        <div
          className="u-w-all u-flex--desktop u-justify-between u-align-center u-pr--8 u-pv--12"
          style={{ maxWidth: "1275px" }}
        >
          {this.renderImageAndName()}
          {/* render savings, quantity and toggled pricing */}
          <div className="u-flex u-align-center u-pt--12--mobile u-pr--24--mobile">
            {/* only show pricing infront on quanitty on desktop (else show underneath) */}
            <div className="u-flex u-align-center">
              <div className="u-hide--mobile">
                <GrocerizeItemShowSavings
                  woolworthsPrice={woolworthsPrice}
                  colesPrice={colesPrice}
                  woolsPricing={this.state.woolsPricing}
                  colesPricing={this.state.colesPricing}
                  quantity={quantity}
                  selected_vendor_id={
                    this.state.itemData.selected_vendor_id || 1
                  }
                />
              </div>
              <div className="u-h-all u-bold u-main-font--medium u-border--darkgrey u-br--small u-ph--32 u-pv--32 u-mr--8 u-background--white">
                {this.props.itemData.quantity}
              </div>
            </div>

            {/* Show toggles */}
            <GrocerizeItemTogglePrices
              itemData={this.state.itemData}
              woolworthsPrice={this.state.woolworthsPrice}
              colesPrice={this.state.colesPrice}
              woolsPricing={this.state.woolsPricing}
              colesPricing={this.state.colesPricing}
            />
          </div>
          <div className="u-flex u-align-center u-pt--12--mobile u-hide--desktop">
            <GrocerizeItemShowSavings
              woolworthsPrice={woolworthsPrice}
              colesPrice={colesPrice}
              quantity={quantity}
              selected_vendor_id={this.state.itemData.selected_vendor_id || 1}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default GrocerizeBasketItemCard;
