import { withAuth0 } from "@auth0/auth0-react";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import {
  findListAndReplaceBasket,
  findListAndAddToBasket,
} from "../../../redux/actions/basketActions";
import { hideModal } from "../../../redux/actions/modalActions";
import DeleteListModalContent from "./DeleteListModalContent";
import RenameListModalContent from "./RenameListModalContent";

interface Props {
  list: List;
  auth0: any;
  findListAndReplaceBasket: any;
  findListAndAddToBasket: any;
  hideModal: any;
}

interface State {
  showRename: boolean;
  showDelete: boolean;
  redirectToBrowse: boolean;
}

class ListOptionsModal extends Component<Props, State> {
  state = {
    showRename: false,
    showDelete: false,
    redirectToBrowse: false,
  };

  addListToCart = async (list: List) => {
    this.props.findListAndAddToBasket(list.id);
    this.setState({ redirectToBrowse: true });
  };

  replaceCartWithList = async (list: List) => {
    this.props.findListAndReplaceBasket(list.id);

    this.setState({ redirectToBrowse: true });
  };

  renameList = async () => {
    this.setState({ showRename: true });
  };

  deleteList = async () => {
    this.setState({ showDelete: true });
  };

  render() {
    const listOptions = [
      {
        name: "Add list to current cart",
        function: this.addListToCart,
      },
      {
        name: "Replace cart with list",
        function: this.replaceCartWithList,
      },
      {
        name: "Rename list",
        function: this.renameList,
      },
      {
        name: "Delete list",
        function: this.deleteList,
      },
    ];
    if (this.state.redirectToBrowse) {
      this.props.hideModal();
      return <Redirect to={"/browse"} />;
    }
    return (
      <div className="o-modal__container">
        <div className="u-w-all u-flex u-justify-end">
          <div className="o-caret-marker"></div>
        </div>
        {this.state.showDelete ? (
          <DeleteListModalContent list={this.props.list} />
        ) : this.state.showRename ? (
          <RenameListModalContent list={this.props.list} />
        ) : (
          <div
            className="u-background--white u-pt--8  u-br--vsmall"
            style={{
              marginLeft: "-50px",
              marginRight: "100px",
              width: "230px",
            }}
          >
            {listOptions.map((option, index) => {
              const isLast = index === listOptions.length - 1;
              return (
                <button
                  className={
                    "u-w-all u-text-left u-semi-bold u-flex u-align-center u-hoverover--darker-background u-pv--12 u-ph--24" +
                    (isLast ? "" : " u-border--bottom--grey")
                  }
                  key={index}
                  style={{ fontSize: "14px" }}
                  onClick={() => option.function(this.props.list)}
                >
                  {option.name}
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withAuth0(
  connect(null, {
    findListAndReplaceBasket,
    findListAndAddToBasket,
    hideModal,
  })(ListOptionsModal)
);
