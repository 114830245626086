import React, { Component } from "react";
import { connect } from "react-redux";
import { httpClient } from "../../../clients/auth/addTokenToHTTPRequests";
import { getLists } from "../../../redux/actions/listActions";
import { hideModal } from "../../../redux/actions/modalActions";

interface Props {
  list: List;
  getLists: any;
  hideModal: any;
}

interface State {
  title: string;
}

class RenameListModalContent extends Component<Props, State> {
  state = {
    title: "Title",
  };

  componentDidMount = () => {
    this.setState({ title: this.props.list.title.toString() });
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ title: e.currentTarget.value });
  };

  saveTitleChanged = async () => {
    await httpClient.instance
      .patch("/user/lists", {
        list_id: this.props.list.id,
        title: this.state.title,
      })
      .then(async () => {
        await this.props.getLists();
        await this.props.hideModal();
      });
  };

  render() {
    return (
      <div
        className="u-background--white u-pt--8  u-br--vsmall o-modal__container"
        style={{
          marginLeft: "-250px",
          marginRight: "100px",
          width: "375px",
        }}
      >
        <div className="u-ph--32 u-pb--32 u-pt--24">
          <div className="u-color-magenta u-semi-bold u-main-font--small u-pb--8">
            Rename list
          </div>
          <div className=" u-main-font--vsmall u-pb--16">
            Rename <span className="u-bold">"{this.props.list.title}"</span>
          </div>
          <div className="u-outline--darkgrey u-br--small u-flex u-justify-between u-p--12">
            <input
              className="u-w-all"
              placeholder={"List name"}
              autoFocus
              value={this.state.title}
              type="text"
              onChange={(e) => this.searchKeyStrokeChange(e)}
            />
            <button
              className="u-background--magenta u-br--small u-color-white u-p--8 u-ph--16 u-semi-bold u-main-font--vsmall"
              onClick={() => this.saveTitleChanged()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { getLists, hideModal })(RenameListModalContent);
