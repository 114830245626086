import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { hideModal } from "../../../redux/actions/modalActions";
import {
  saveBulkSearch,
  saveNewSearch,
} from "../../../redux/actions/searchActions";
import { routes } from "../../../utils/routes";
import { capitalizeFirstLetter } from "../../../utils/UIFunctions";

interface Props {
  saveBulkSearch: any;
  saveNewSearch: any;
  hideModal: any;
  search: SearchState;
}

interface State {
  rawGroceryList: string;
}

class UploadListModal extends Component<Props, State> {
  state = {
    rawGroceryList: "",
  };

  componentDidMount = () => {
    // if there is a pre-existing list, load this
    const currentSearchTerms = this.props.search.allSearchTerms;
    if (currentSearchTerms.length > 0) {
      this.setState({
        rawGroceryList: currentSearchTerms.join("\n") + "\n",
      });
    }
  };

  uploadGroceries = () => {
    // split uploaded list into individual search terms and remove empty rows
    const splitSearchTerms = this.state.rawGroceryList
      .split("\n")
      .filter((word) => word.trim().length > 0);

    // clean terms up
    const termsArray = splitSearchTerms.map((term) => {
      // remove punctuation
      term = term.replace(/[./#!$%^&*;:{}=\-_`~()"]/g, "");
      // capitalise first letter
      term = capitalizeFirstLetter(term);
      return term;
    });

    // remove duplicates from list
    const uniqueSearchTerms = termsArray.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    this.props.saveBulkSearch(uniqueSearchTerms);
    // make first search term current search item
    this.props.saveNewSearch(uniqueSearchTerms[0]);
    this.props.hideModal();
  };

  render() {
    return (
      <div
        className="o-modal--content-centered o-modal__container"
        style={{ width: "730px" }}
      >
        <div
          className="u-main-font--small u-color-charcoal u-background--white u-br--top--small"
          style={{
            paddingLeft: "75px",
            paddingRight: "75px",
            fontSize: "14px",
          }}
        >
          <div className=" u-pt--64 u-w-all ">
            <div
              className="u-main-font--medium u-bold"
              style={{ fontSize: "21px" }}
            >
              Upload a list and/or search for multiple items at once
            </div>

            <div className="u-pt--32 u-main-font--small">
              Type or paste your list below putting each item{" "}
              <span className="u-bold">on a new line</span>.
            </div>
            <textarea
              className="u-p--6 u-mv--16 u-main-font--small u-border--darkgrey--thick u-br--small u-background--grey"
              autoFocus
              style={{
                height: "400px",
                width: "100%",
                resize: "none",
                // lineHeight: "4ch",   /// removing the lines shown on the list (may want them back soon)
                // backgroundImage:
                //   "linear-gradient(transparent, transparent calc(4ch - 1px), #E7EFF8 0px)",
                // backgroundSize: "100% 4ch",
              }}
              placeholder="apples&#10;bananas&#10;pears"
              value={this.state.rawGroceryList}
              onChange={(e) => {
                this.setState({ rawGroceryList: e.currentTarget.value });
              }}
            ></textarea>
          </div>
        </div>
        <Link to={routes.browse}>
          <button
            className="u-w-all u-background--magenta u-pv--20 u-bold u-color-white u-br--bottom--small"
            onClick={this.uploadGroceries}
          >
            Search
          </button>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  search: state.search,
});

export default connect(mapStateToProps, {
  saveBulkSearch,
  saveNewSearch,
  hideModal,
})(UploadListModal);
