import React, { Component } from "react";
import { connect } from "react-redux";
import {
  addItemsToList,
  createList,
  getLists,
} from "../../../../redux/actions/listActions";
import { hideModal } from "../../../../redux/actions/modalActions";
import variables from "../../../../styles/utils/variables.scss";
import { Icon } from "../../../icons/Icon";

interface Props {
  lists: ListsState;
  itemData: ItemData;
  hideModal: any;
  heightOfCard: number;
  getLists: any;
}

interface State {
  selectedListId: number | null;
  quantity: number;
  showCreateNewListInput: boolean;
  listTitle: string;
  successfullySubmitted: boolean;
}

class SaveItemToListModal extends Component<Props, State> {
  state = {
    selectedListId: null,
    quantity: 1,
    showCreateNewListInput: false,
    listTitle: "",
    successfullySubmitted: false,
  };

  searchKeyStrokeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ listTitle: e.currentTarget.value });
  };

  submitItem = () => {
    if (this.state.showCreateNewListInput || this.state.selectedListId) {
      if (this.state.listTitle.length && this.state.showCreateNewListInput) {
        // if creating new list
        this.createNewList();
      } else {
        // else add to list
        this.addToList();
      }

      this.props.getLists();
      this.setState({ successfullySubmitted: true });
    }
  };

  hideModalAfterTimeout = () => {
    setTimeout(() => {
      // call to get lists from the db to show the new added lists
      this.props.getLists();
      // hide the modal
      this.props.hideModal();
    }, 1300);
  };

  createNewList = async () => {
    await createList({
      title: this.state.listTitle,
      is_current_basket: false,
      items: [{ ...this.props.itemData, quantity: this.state.quantity }],
    })
      .then(() => {})
      .catch((e) => {
        console.log(e);
      });
  };

  addToList = async () => {
    if (this.state.selectedListId) {
      await addItemsToList({
        list_id: this.state.selectedListId,
        quantity: this.state.quantity,
        item_id: this.props.itemData.id,
      }).catch((error) => console.log(error));
    }
  };

  render() {
    const violet = variables.violetColor;

    const heightOfCard =
      window.innerWidth > 600 ? this.props.heightOfCard : 290;
    const header = 66;
    const quantityButton = 66;
    const footer = 50;
    const lists = heightOfCard - header - quantityButton - footer;

    if (this.state.successfullySubmitted) {
      this.hideModalAfterTimeout();
    }

    return (
      <div className="o-modal__container u-relative u-w-inherit">
        <div className="u-bottom--0--mobile   o-modal--content u-w-inherit u-w-all--mobile">
          <div
            className="u-relative u-overflow-hidden"
            style={{ height: heightOfCard }}
          >
            <div className="u-background--grey u-w-all u-h-all u-br--small u-slide-in--bottom u-flex u-flex u-flex-column">
              {this.state.showCreateNewListInput ? (
                <div
                  className="u-p--16  u-flex u-align-center u-main-font--small u-hoverover--darker-background u-w-all"
                  style={{ height: header }}
                >
                  <div className="u-outline--darkgrey u-br--small u-flex u-justify-between u-p--12 u-background--white">
                    <input
                      className="u-w-all"
                      placeholder={"List name?"}
                      autoFocus
                      value={this.state.listTitle}
                      type="text"
                      onChange={(e) => this.searchKeyStrokeChange(e)}
                    />
                  </div>
                </div>
              ) : (
                <button
                  className="u-ph--16 u-pv--24 u-flex u-align-center u-main-font--small u-hoverover--darker-background u-w-all"
                  style={{ height: header }}
                  onClick={() =>
                    this.setState({
                      showCreateNewListInput: true,
                      selectedListId: null,
                    })
                  }
                >
                  <Icon id="plus" size="xs" fill={violet} />
                  <span
                    className="u-ph--8 u-pv--8 u-semi-bold"
                    style={{ fontSize: "14px" }}
                  >
                    Create new list
                  </span>
                </button>
              )}
              <div
                className="u-border--bottom--darkgrey u-background--white u-overflow--scroll-x--hidden u-overflow--scroll--thinner"
                style={{ height: lists }}
              >
                {this.state.successfullySubmitted ? (
                  <div className="u-background--grey u-w-all u-h-all u-br--small u-flex u-justify-center u-align-center ">
                    <div className="o-animate-tick ">
                      <Icon id="tickAnimated" size="xl" />
                    </div>
                  </div>
                ) : (
                  this.props.lists.lists.map((list) => {
                    return (
                      <button
                        key={list.id?.toString()}
                        onClick={() =>
                          typeof list.id === "number"
                            ? this.setState({
                                selectedListId: list.id,
                                showCreateNewListInput: false,
                                listTitle: "",
                              })
                            : null
                        }
                        className={
                          "u-background--white u-flex u-justify-between u-p--12 u-ph--24 u-main-font--vsmall u-hoverover--darker-background u-w-all u-semi-bold " +
                          (this.state.selectedListId === list.id
                            ? "u-color-magenta u-bold"
                            : "")
                        }
                      >
                        <div className="u-w--130px u-p--2 u-text-left u-word-break u-word-wrap">
                          {list.title}
                        </div>
                        <div>{list.itemsCount}</div>
                      </button>
                    );
                  })
                )}
              </div>

              <div
                className="u-w-all u-flex u-justify-center u-background--white "
                style={{ height: quantityButton }}
              >
                <div className="u-flex u-justify-between u-br--vsmall  u-border--darkgrey u-w-all u-pv--12 u-ph--24 u-mv--12 u-mh--24">
                  <button
                    style={{
                      fontSize: "22px",
                    }}
                    className="u-h-all u-flex u-justify-center u-align-center  "
                    onClick={() =>
                      this.setState({
                        quantity:
                          this.state.quantity === 1
                            ? 1
                            : this.state.quantity - 1,
                      })
                    }
                  >
                    -
                  </button>
                  <div
                    className="u-h-all u-w-all u-flex u-justify-center u-align-center u-bold u-mh--12"
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    {this.state.quantity}
                  </div>
                  <button
                    style={{
                      fontSize: "22px",
                    }}
                    className="u-h-all u-flex u-justify-center u-align-center "
                    onClick={() =>
                      this.setState({ quantity: this.state.quantity + 1 })
                    }
                  >
                    +
                  </button>
                </div>
              </div>
              <button
                className={
                  "u-w-all u-flex u-justify-center u-align-center u-br--bottom--small--desktop" +
                  (this.state.selectedListId || this.state.listTitle.length
                    ? " u-background--magenta "
                    : " u-background--secondary ")
                }
                onClick={() => this.submitItem()}
                style={{ height: footer }}
              >
                <div
                  className="u-color-white u-bold u-main-font--small u-flex u-align-center"
                  style={{ fontSize: 14 }}
                >
                  {this.state.selectedListId
                    ? "Add to list"
                    : this.state.showCreateNewListInput // if we are creating a list label it as such
                    ? "Create list"
                    : "Select a list"}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => ({
  lists: state.lists,
});

export default connect(mapStateToProps, { hideModal, getLists })(
  SaveItemToListModal
);
