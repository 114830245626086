import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { httpClient } from "../../../clients/auth/addTokenToHTTPRequests";
import { showModal } from "../../../redux/actions/modalActions";
import { routes } from "../../../utils/routes";
import { userSignedUpWithSSO } from "../../../utils/userFunctions";
import ChangedPasswordEmailModal from "../../popups/ChangedPasswordEmailModal";

interface Option {
  id: number;
  text: string;
  count?: number | null;
  href: string;
}
interface Setting {
  id: number;
  text: string;
  modalFunction?: any;
  showForThisUser?: boolean;
}

interface Props {
  user: UserState;
  showModal: any;
  lists: ListsState;
  favourites: FavouritesState;
  priceAlerts: PriceAlertsState;
}
interface State {
  selectedId: number;
  options: Option[];
}
class ListsDrillDownFilter extends Component<Props, State> {
  state = {
    selectedId: 1,
    options: [
      // Main options to highlight
      {
        id: 1,
        text: "My Lists",
        count: this.props.lists.lists.length | 0,
        href: "/my/lists",
      },
      {
        id: 2,
        text: "My Favourites",
        count: this.props.favourites.favourites?.length || 0,
        href: "/my/favourites",
      },
      {
        id: 3,
        text: "My Price Alerts",
        count: this.props.priceAlerts.priceAlerts?.length || 0,
        href: "/my/alerts",
      },
      { id: 4, text: "My Details", count: null, href: "/my/details" },
      { id: 5, text: "My Preferences", count: null, href: "/my/preferences" },
      // End of main options

      // Start of second options to highlight
      { id: 6, text: "FAQs", href: routes.faqs },
      // { id: 7, text: "Blog", href: "/blog" },
      // { id: 8, text: "Coming Soon", href: "/coming" },
      { id: 9, text: "Contact us", href: "/contact" },
      { id: 10, text: "Terms of Use", href: routes.termsOfUse },
      { id: 11, text: "Privacy Policy", href: routes.privacyPolicy },
      // End of second options to highlight
    ],
  };

  componentDidMount = () => {
    const currentOptionShown = this.state.options.find((option: Option) => {
      if (option.href === window.location.pathname) {
        return option;
      }
      return undefined;
    });
    this.setState({ selectedId: currentOptionShown?.id || 1 });
  };

  updateNavOptions = (newOption: Option) => {
    this.setState({
      options: this.state.options.map((option) =>
        option.id !== newOption.id ? option : newOption
      ),
    });
  };

  componentDidUpdate = (prevProps: Props) => {
    // monitor changes in favourites
    if (prevProps.favourites.favourites !== this.props.favourites.favourites) {
      this.updateNavOptions({
        id: 2,
        text: "My Favourites",
        count: this.props.favourites.favourites.length | 0,
        href: "/my/favourites",
      });
    }

    if (prevProps.lists.lists !== this.props.lists.lists) {
      this.updateNavOptions({
        id: 1,
        text: "My Lists",
        count: this.props.lists.lists.length | 0,
        href: "/my/lists",
      });
    }

    if (
      prevProps.priceAlerts.priceAlerts !==
        this.props.priceAlerts.priceAlerts &&
      this.props.priceAlerts.priceAlerts // seeing an initialisation error that I cannot solve here
    ) {
      this.updateNavOptions({
        id: 3,
        text: "My Price Alerts",
        count: this.props.priceAlerts.priceAlerts.length | 0,
        href: "/my/alerts",
      });
    }
  };

  showChangePasswordModal = async () => {
    // show "sending you an email modal"
    const modalContent = () => <ChangedPasswordEmailModal />;

    this.props.showModal({ component: modalContent });
  };

  stylingOfOption = (id: number) => {
    let styling =
      "u-truncate u-text-left u-align-left u-pv--8 u-w-all  u-color-charcoal ";
    if (id === this.state.selectedId) {
      styling +=
        "u-bold u-border--left--magenta  u-pl--28  u-background--grey "; // dirty hack to inset the left border
    } else {
      styling += " u-pl--32 ";
    }

    return styling;
  };

  onClickOfCatFilter = (id: number) => {
    // if already has been clicked
    if (this.state.selectedId === id) {
      this.setState({ selectedId: 0 });
    } else {
      this.setState({ selectedId: id });
    }
  };

  openStripeBilling = async () => {
    await httpClient.instance
      .post(
        process.env.REACT_APP_BACKEND_URL + "/payments/create-portal-session",

        { customer: this.props.user.stripe_id }
      )
      .then((res) => {
        // redirect them to stripe
        window.open(res.data.url);
      })
      .catch((e) => console.log(e));
  };

  render() {
    const options = this.state.options;
    const mainOptionStart = 0; // Starts with the 1st element in the options array
    const mainOptionEnd = 5; // Ends with the 5th element in the options array
    const secondOptionStart = 5; // Starting point, in the options array, for the 2nd list of options to highlight
    const secondOptionEnd = options.length; // End point, in the options array, for the 2nd list of options to highlight

    const settings: Setting[] = [
      // Will remove temporarily for now
      // {
      //   id: 1,
      //   text: "Billing & Invoices",
      //   modalFunction: () => this.openStripeBilling(),
      // },
      {
        id: 1,
        text: "Change Password",
        modalFunction: () => this.showChangePasswordModal(),
        showForThisUser: userSignedUpWithSSO(this.props.user),
      },
    ];

    return (
      <div
        className="u-background--white u-border--right--grey u-h-all u-pb--64"
        style={{ width: "290px" }}
      >
        <div
          className="u-w-all u-flex u-align-center"
          style={{ height: "167px" }}
        >
          <div className="u-ph--32 u-pv--32">
            <div className="u-main-font--small u-semi-bold">
              {this.props.user?.first_name
                ? "Hi " + this.props.user.first_name
                : "Hello"}
            </div>

            <div className="u-main-font--vsmall u-color-magenta u-pv--12">
              Basic account
            </div>
          </div>
        </div>

        {/* OPTIONS */}

        {options
          .slice(mainOptionStart, mainOptionEnd)
          .map((option: Option, index: number) => {
            return (
              <Link
                to={option.href}
                className="u-w-all u-hoverover--darker-background "
                onClick={() => this.onClickOfCatFilter(option.id)}
                key={index}
              >
                <div className="u-main-font--small u-semi-bold">
                  <div
                    className={this.stylingOfOption(option.id)}
                    style={{ fontSize: "14px" }}
                  >
                    {option.text}
                    <span className="u-color-grey u-pl--8 u-no-decoration u-text-left u-align-left">
                      {option?.count ? "(" + option.count + ")" : ""}
                    </span>
                  </div>
                </div>
              </Link>
            );
          })}

        {/* SETTINGS */}
        <div className="u-mt--48">
          {settings.map((setting: Setting, index: number) => {
            return (
              <div
                className="u-w-all u-hoverover--darker-background u-cursor--pointer"
                key={index}
                onClick={() => setting.modalFunction()}
              >
                <div className="u-main-font--small">
                  <div
                    className="u-truncate u-text-left u-align-left u-pv--8 u-w-all  u-color-charcoal  u-pl--32 "
                    style={{ fontSize: "14px" }}
                  >
                    {setting.text}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* JUNK DRAWER */}
        <div className="u-mt--48">
          {options
            .slice(secondOptionStart, secondOptionEnd)
            .map((option: Option, index: number) => {
              return (
                <Link
                  to={option.href}
                  className="u-w-all u-hoverover--darker-background "
                  onClick={() => {
                    this.onClickOfCatFilter(option.id);
                  }}
                  key={index}
                >
                  <div className="u-main-font--small">
                    <div
                      className={
                        "u-truncate u-text-left u-align-left u-pv--8 u-w-all  u-color-charcoal  u-pl--32 " +
                        this.stylingOfOption(option.id)
                      }
                      style={{ fontSize: "14px" }}
                    >
                      {option.text}
                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  lists: state.lists,
  favourites: state.favourites,
  priceAlerts: state.priceAlerts,
});
export default connect(mapStateToProps, { showModal })(ListsDrillDownFilter);
