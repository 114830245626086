import { Component } from "react";
import { connect } from "react-redux";
import { updateURLSlugs } from "../../components/naviagtions/itemNavs/itemNavigationFunctions";
import { saveNewCategoryNavigationSelection } from "../../redux/actions/categoryAction";
import {
  removeFilter,
  toggleHalfThePriceProducts,
  toggleUnavailableProducts,
} from "../../redux/actions/itemActions";
import { hideModal } from "../../redux/actions/modalActions";
import { saveNewSearch } from "../../redux/actions/searchActions";
import { Icon } from "../icons/Icon";

interface Props {
  hideModal: () => void;
  removeFilter: any;
  saveNewCategoryNavigationSelection: (
    selectedNode: CategoryTreeNode | null
  ) => {};
  saveNewSearch: (searchTerm: string) => {};
  toggleHalfThePriceProducts: (
    isHalfThePriceClicked: boolean,
    callTheAPI: boolean,
    categoryFilterId?: number
  ) => void;
  toggleUnavailableProducts: (isUnavailableProductsHidden: boolean) => void;
  updateURLSlugs: (selectedNode: CategoryTreeNode | null) => void;
  isHalfThePriceClicked?: boolean;
  isForMobile?: boolean;
  category?: CategoryTreeNode | null;
}

class HalfThePrice extends Component<Props, {}> {
  filterByHalfThePrice = () => {
    this.props.toggleHalfThePriceProducts(
      !this.props.isHalfThePriceClicked,
      true,
      this.props.category?.crawler_id
    );
    if (this.props.isForMobile) {
      this.props.hideModal();
    }

    this.clearAppliedFilter();
  };

  clearAppliedFilter = () => {
    // this.props.saveNewSearch("");
    this.props.removeFilter();
    this.props.toggleUnavailableProducts(true);
  };

  render() {
    const { isForMobile } = this.props;
    return (
      <>
        {isForMobile && (
          <div
            onClick={this.filterByHalfThePrice}
            className="u-w-all u-flex u-justify-between u-ph--20 u-pv--16 u-border--bottom--grey u-color-charcoal"
          >
            <div className="u-main-font--small u-semi-bold u-flex u-align-center">
              1/2 Price
            </div>
            <Icon
              id="chevron_right"
              fill="none"
              className="u-rotate--negative90deg u-stroke--magenta"
            />
          </div>
        )}
        {!isForMobile && (
          <button
            className={`u-color-white u-bold u-main-font--14--small u-ph--12 u-pv--8 u-text-oneline u-br--vsmall ${
              this.props.isHalfThePriceClicked
                ? "u-background--magenta"
                : "u-sub-background u-border--charcoal--500"
            }`}
            onClick={this.filterByHalfThePrice}
          >
            1/2 Price
          </button>
        )}
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  isHalfThePriceClicked: state.items.isHalfThePriceClicked,
  category: state.categories.selectedNode,
});

export default connect(mapStateToProps, {
  hideModal,
  removeFilter,
  saveNewSearch,
  toggleHalfThePriceProducts,
  toggleUnavailableProducts,
  updateURLSlugs,
  saveNewCategoryNavigationSelection,
})(HalfThePrice);
