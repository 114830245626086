import React, { Component } from "react";
import { Icon } from "../icons/Icon";

interface Props {
  itemCount: number;
  onComplete: any;
  mode: string;
}

interface State {
  checked: boolean;
}

class ItemCountCheckbox extends Component<Props, State> {
  completeItem = () => {
    this.props.onComplete();
  };
  render() {
    return (
      <button
        onClick={() => this.completeItem()}
        className={
          " u-m--16 u-border--darkgrey u-flex u-align-center u-justify-center u-br--vsmall" +
          (this.props.mode === "completed" ? " u-background--magenta" : "")
        }
        style={{ height: "27px", width: "27px" }}
      >
        {this.props.mode === "completed" ? (
          <Icon id="tick" fill="white" size="s" />
        ) : (
          <div>{this.props.itemCount}</div>
        )}
      </button>
    );
  }
}

export default ItemCountCheckbox;
