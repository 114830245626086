export function resetCss() {
  const isIOS =
    //@ts-ignore
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  let scrollTop = 0;

  if (isIOS) {
    document.body.style.position = "";
    document.body.style.top = "";
    document.body.style.width = "";
    window.scrollTo(0, scrollTop);
  } else {
    document.body.style.overflow = "auto";
    document.body.style.paddingRight = "0px";
  }
}
