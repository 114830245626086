import React, { Component } from "react";
import CategoriesNavigation from "./itemNavs/CategoriesNavigation";
import SearchNav from "./itemNavs/SearchNav";
interface Props {
  isFixed?: boolean;
  isCategoryClick?:boolean;
}

class Header extends Component<Props, {}> {
  render() {
    return (
      <div id="header">
        <CategoriesNavigation isFixed={this.props.isFixed} isCategoryClick={this.props.isCategoryClick} />

        <SearchNav />
      </div>
    );
  }
}

export default Header;
