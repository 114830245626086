import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon } from "../components/icons/Icon";
import PageTemplate from "./PageTemplate";

interface Props {}

interface State {}

class Page404 extends Component<Props, State> {
  render() {
    return (
      <PageTemplate sidebarType="none">
        <div className="u-background--white u-w-all">
          <div className="u-wrap" style={{ height: "60vh" }}>
            <div className=" u-bold u-color-magenta u-pt--48">
              <Icon id="warning" size="xxxl" />
              <div className="u-main-font--vlarge">Sorry!</div>
              <div className="u-main-font--large">
                Uh oh! 404 error - this page does not exsist.
              </div>
              <div className="u-mt--48">
                <Link to="/browse">
                  <button className="u-background--magenta u-br--small u-color-white u-bold u-ph--24 u-pv--16">
                    Back to home
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </PageTemplate>
    );
  }
}

export default Page404;
