import React from "react";
import { Route } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/views/Loading";

interface ProtectedRouteArgs {
  component: any;
  exact: boolean;
  path: string;
}

const ProtectedRoute = ({ component, path, exact }: ProtectedRouteArgs) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <Loading />,
    })}
    exact={exact}
    path={path}
  />
);

export default ProtectedRoute;
