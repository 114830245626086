import { Component } from "react";
import { connect } from "react-redux";
import { trackCheckout } from "../../clients/analytics/cliquemarket";
import { toggleColesNotification } from "../../redux/actions/colesNotificationActions";
import { hideModal, showModal } from "../../redux/actions/modalActions";
import variables from "../../styles/utils/variables.scss";
import { Icon } from "../icons/Icon";
import SharingPopup from "./SharingPopup";

interface Props {
  basket?: BasketState;
  showModal: any;
  hideModal: any;
  toggleColesNotification: any;
}

class ColesAlertPopup extends Component<Props> {
  propertiesToTrack = (vendor?: "coles" | "woolworths") => {
    const metaData = this.props.basket?.basketMetaData;
    return {
      totalSaved: metaData?.totalSavingsUsingGrocerize || 0,
      pushed: vendor,
      cart: {
        items:
          (metaData?.countOfColesItems || 0) +
          (metaData?.countOfWoolsItems || 0),
        coles: {
          items: metaData?.countOfColesItems || 0,
          value: metaData?.colesOnlyTotal || 0,
        },
        woolworths: {
          items: metaData?.countOfWoolsItems || 0,
          value: metaData?.woolworthsOnlyTotal || 0,
        },
      },
    };
  };

  closeModal = () => {
    this.props.hideModal();

    this.props.toggleColesNotification(true);
  };

  showPushToCartPopup = async (type: "push_to_coles" | "chrome_extension") => {
    const modalContent = () => <SharingPopup type={type} />;
    const versionNumber =
      document.getElementById("ChromeExtVersion")?.textContent;

    if (!versionNumber) {
      type = "chrome_extension";
    }

    this.closeModal();
    if (this.props.basket && !process.env.REACT_APP_DEV_MODE) {
      trackCheckout("checkout", this.props.basket, "share");
    }
    this.props.showModal({ position: "centered", component: modalContent });
  };

  getStringifiedBasketList = () => {
    let pushToCartList = [];
    const basketItems = this.props.basket?.basketItems;
    if (basketItems) {
      for (let i = 0; i < basketItems.length; i++) {
        const item = basketItems[i];
        let selectedVendorId, vendorProductCode;
        // Check if item has both pricing options
        if (item.item_pricing.length > 1) {
          // Check if item is available at both stores
          if (
            item.item_pricing[0].available &&
            item.item_pricing[1].available
          ) {
            // Add item to pushToCartList
            selectedVendorId = item.selected_vendor_id;
            // Get the vendor product code of the selected vendor id from item pricings
            if (item.selected_vendor_id === item.item_pricing[0].vendor_id) {
              vendorProductCode = item.item_pricing[0].vendor_product_code;
            } else if (
              item.selected_vendor_id === item.item_pricing[1].vendor_id
            ) {
              vendorProductCode = item.item_pricing[1].vendor_product_code;
            }
          }
        } else {
          // Set selected vendor id to the available vendor
          selectedVendorId = item.item_pricing[0].vendor_id;
          vendorProductCode = item.item_pricing[0].vendor_product_code;
        }

        // Add item to push to cart list
        pushToCartList.push({
          // NOTE: vendor_product_code is the actual product code of the corresponding vendor
          vendor_product_code: vendorProductCode,
          quantity: item.quantity,
          selected_vendor: selectedVendorId,
        });
      }
    }

    return JSON.stringify(pushToCartList);
  };

  render() {
    const violet = variables.violetColor;
    return (
      <div className="u-br--small o-modal__container">
        <div
          className="o-modal--content-centered u-modal--content-bottom--mobile u-w-all--mobile u-relative"
          style={{
            width: window.innerWidth > 600 ? "500px" : "100%",
          }}
        >
          <button
            className="u-absolute"
            style={{ top: 18, right: 18 }}
            onClick={() => this.closeModal()}
          >
            <Icon id="clear" stroke="grey" size="s" />
          </button>
          <div
            style={{
              fontSize: "14px",
            }}
            className="u-main-font--small u-color-charcoal u-background--white u-br--small"
          >
            <div
              style={{ paddingTop: 18, paddingLeft: 18 }}
              className="u-text-center"
            >
              <Icon id="grocerize" fill={violet} width={115} height={29} />
            </div>
            <div className="u-ph--48 u-text-center u-mauto">
              <div
                className=" u-align-middle u-w-all u-text-center"
                style={{ paddingTop: 30 }}
              >
                <div className="u-main-font--small u-bold">
                  Push cart items to Coles!
                </div>
                <div className="u-pt--24 u-ph--8 u-pb--24">
                  Before your items can be pushed to Coles, you must be logged
                  in via a Coles account. Click{" "}
                  <a
                    className="u-link u-color-coles"
                    target="_blank"
                    href="https://auth.colesgroupprofile.com.au/login?state=hKFo2SBGMFZrUlFkeUNYOUtIXzd6V1ZKdkNzTTNCdmxXUkR6WaFupWxvZ2luo3RpZNkgZklwRV9OblJNY0l1c20xRlY1VU5xc3llSFZTTGpFUC2jY2lk2SBPUXNOSVVJUEJvWVJYVUtkUUpCMDZzWXFyMlhMNUJVVQ&client=OQsNIUIPBoYRXUKdQJB06sYqr2XL5BUU&protocol=oauth2&redirect_uri=https%3A%2F%2Fwww.coles.com.au%2Fauth%2Fcallback&audience=customer-services&scope=openid%20read%3Aprofile%20read%3Apreferences%20update%3Apreferences%20update%3Aproduct-list%20read%3Aproduct-list%20update%3Aloyalty-account%20read%3Aloyalty-account%20read%3Acol%20update%3Acol%20sso%3Acol%20offline_access%20read%3Aaddress%20update%3Aaddress%20update%3Aprofile%20read%3Acontact%20update%3Acontact%20delete%3Aaddress&response_type=code&code_challenge_method=S256&code_challenge=58ClVFTM7Cz8nl2CNtKOMgTT63aRmHjCmTFZb2QIOIo&adobe_mc=MCMID%3D64146838447866607011891763254491697867%7CMCORGID%3D0B3D037254C7DE490A4C98A6%2540AdobeOrg%7CTS%3D1707718779"
                  >
                    here
                  </a>{" "}
                  to login to Coles. Return{" "}
                  <button
                    id="ChromeExtPushToColesButton"
                    className="u-link u-color-magenta"
                    onClick={() => this.showPushToCartPopup("push_to_coles")}
                  >
                    here
                  </button>{" "}
                  to push to cart
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, {
  showModal,
  hideModal,
  toggleColesNotification,
})(ColesAlertPopup);
