import { Component } from "react";

interface Props {
  onClickFunc: any;
}

interface State {}

class Welcome extends Component<Props, State> {
  render() {
    return (
      <div className="u-overflow-scroll--mobile u-br--small  o-signup-card">
        <div className="u-main-font--small u-color-charcoal u-background--white o-signup-card--content u-br--small">
          <div className=" u-ph--32 u-pt--48">
            <div
              className="u-main-font--medium u-color-charcoal u-bold "
              style={{ fontSize: "21px" }}
            >
              Welcome to Grocerize
            </div>
            <div
              className="u-main-font--medium u-color-charcoal u-pt--32"
              style={{ fontSize: "16px" }}
            >
              {/* Your 1-month free access to premium starts now! Enjoy Access to all the premium features and start saving money on your grocery shopping! */}
              {/* @TODO why do we have duplicate Welcome and WelcomeDEsktop Components? */}
              Thanks for signing up Grocerize.
              <br />
              <br />
              Your feedback is greatly appreciated during this period via the
              link in the navigation bar.
              <br />
              <br />
              The commercial launch of Grocerize will be in the coming months.
            </div>
            {/* <div
              className="u-main-font--small u-color-charcoal u-pt--48"
              style={{ fontSize: "14px" }}
            >
              Subscribe for premium at any time during your free trial and you won’t be charged until the trial period ends.
            </div> */}
            <br />
            <br />
            <div className="u-pv--20">
              <button
                className="u-w-all u-background--green u-pv--20 u-bold u-color-white u-br--small"
                onClick={() => this.props.onClickFunc()}
              >
                {/* Plan, Shop and Save now  */}I Accept
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;
