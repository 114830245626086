import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../redux/actions/modalActions";
import { Icon } from "../icons/Icon";
import ListSortDropdown from "../lists/listOptions/ListSortDropdown";
import ListsSortButton from "../lists/listOptions/ListsSortButton";

import ListRows from "../lists/ListRows";

import variables from "../../styles/utils/variables.scss";

interface Props {
  lists: ListsState;
  showModal: any;
}

class ListsView extends Component<Props, {}> {
  render() {
    const violet = variables.violetColor;

    const isMobile = window.innerWidth < 600;
    return (
      <div className="u-w-all u-background--white ">
        {isMobile ? (
          <div className="u-hide--desktop u-background--grey u-w-all u-flex u-justify-between">
            <div className="u-main-font--medium u-bold  u-pv--24 u-ph--32">
              My lists
              <span className="u-color-magenta u-ph--12">
                {this.props.lists.lists.length}
              </span>
            </div>
            <ListSortDropdown />
          </div>
        ) : (
          <div className="u-hide--mobile u-border--bottom--grey u-ph--48 u-pt--48 u-pb--12 u-flex u-justify-center">
            <div className="u-w-80pct">
              <div className="u-main-font--large u-pt--32 u-bold u-flex u-align-baseline ">
                My Lists
                <span className="u-color-magenta u-ph--12">
                  {this.props.lists.lists.length}
                </span>
              </div>
              <div className="u-w-80pct u-mt--48 u-flex u-align-center u-main-font--vsmall ">
                <ListsSortButton lists={this.props.lists} />
                <div
                  className="u-text-right u-ph--8 u-flex u-align-center u-justify-end"
                  style={{ width: "116px" }}
                ></div>
                <div
                  className="u-text-right u-ph--8 u-flex u-align-center u-justify-end"
                  style={{ width: "116px" }}
                >
                  Shop total
                </div>
                <div
                  className=" u-text-right u-ph--8 u-flex u-align-center u-justify-end"
                  style={{ width: "116px" }}
                >
                  <Icon id="grocerize" size="s" fill={violet} />
                  <div className="u-bold u-color-magenta u-pl--4">Savings</div>
                </div>
                <div
                  className="u-text-right u-ph--8 u-flex u-align-center u-justify-end"
                  style={{ width: "116px" }}
                >
                  Items
                </div>
              </div>
            </div>
          </div>
        )}

        <div id="list" className="u-h-all ">
          <ListRows />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  lists: state.lists,
});

export default connect(mapStateToProps, { showModal })(ListsView);
