import { Component } from "react";
import { connect } from "react-redux";
import { showModal } from "../../../redux/actions/modalActions";
import {
  removeBulkList,
  removeBulkSearchTerm,
  saveNewSearch,
} from "../../../redux/actions/searchActions";
import variables from "../../../styles/utils/variables.scss";
import { Icon } from "../../icons/Icon";
import UploadListModal from "../../lists/listOptions/UploadListModal";

interface Props {
  saveNewSearch: any;
  removeBulkSearchTerm: any;
  removeBulkList: any;
  search: SearchState;
  showModal: any;
}

interface State {}

class SearchTermsBanner extends Component<Props, State> {
  openUplodListModal = () => {
    const modalContent = () => <UploadListModal />;

    this.props.showModal({ component: modalContent });
  };

  render() {
    const violet = variables.violetColor;

    return (
      <div className="u-ph--20">
        <div className="u-w-all u-flex u-flex-wrap u-mb--8">
          {this.props.search.allSearchTerms.map((searchTerm) => (
            <div className="u-mr--12">
              <button
                className={
                  (this.props.search.searchTerm === searchTerm
                    ? "u-color-magenta u-semi-bold"
                    : "") + " u-p--6 u-underline"
                }
                onClick={() => this.props.saveNewSearch(searchTerm)}
              >
                {searchTerm}
              </button>
              <button
                className="u-h-all"
                onClick={() => this.props.removeBulkSearchTerm(searchTerm)}
              >
                <Icon id="clear" size="xs" stroke={violet} />
              </button>
            </div>
          ))}
        </div>
        <button
          className="u-ph--32 u-pv--4 u-mv--24 u-mr--16 u-background--magenta u-color-white u-main-font--small u-br--small"
          onClick={() => this.props.removeBulkList()}
        >
          Clear list items
        </button>
        <button
          className="u-ph--32 u-pv--4 u-mv--24 u-mr--16 u-background--magenta u-color-white u-main-font--small u-br--small"
          onClick={() => this.openUplodListModal()}
        >
          Modify list items
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  search: state.search,
});

export default connect(mapStateToProps, {
  saveNewSearch,
  removeBulkSearchTerm,
  removeBulkList,
  showModal,
})(SearchTermsBanner);
